import types from '../../types';

export function overlapSetTitle(title) {
  return {
    type: types.OVERLAP_SET_TITLE,
    payload: { title },
  };
}

// SAVE (NEW AND EDIT)
export function overlapNewSaveRequest(profiles, overlapId) {
  return {
    type: types.OVERLAP_NEW_SAVE_REQUEST,
    payload: { profiles, overlapId },
  };
}

export function overlapNewSaveSuccess() {
  return {
    type: types.OVERLAP_NEW_SAVE_SUCCESS,
  };
}

export function overlapNewSaveFailure() {
  return {
    type: types.OVERLAP_NEW_SAVE_FAILURE,
  };
}

export function overlapNewClearList() {
  return {
    type: types.OVERLAP_NEW_CLEAR_LIST,
  };
}

// GET INFLUENCERS (EDIT)
export function overlapEditGetInfluencersRequest(overlapId) {
  return {
    type: types.OVERLAP_EDIT_GET_INFLUENCERS_REQUEST,
    payload: { overlapId },
  };
}

export function overlapEditGetInfluencersSuccess(data) {
  return {
    type: types.OVERLAP_EDIT_GET_INFLUENCERS_SUCCESS,
    payload: { data },
  };
}

export function overlapEditGetInfluencersFailure() {
  return {
    type: types.OVERLAP_EDIT_GET_INFLUENCERS_FAILURE,
  };
}

// DELETE
export function overlapNewDeleteRequest(overlapId) {
  return {
    type: types.OVERLAP_NEW_DELETE_REQUEST,
    payload: { overlapId },
  };
}

export function overlapNewDeleteSuccess() {
  return {
    type: types.OVERLAP_NEW_DELETE_SUCCESS,
  };
}

export function overlapNewDeleteFailure() {
  return {
    type: types.OVERLAP_NEW_DELETE_FAILURE,
  };
}
