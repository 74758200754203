import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiBeCampaign, apiBeTracking } from '~/services/api';
import { serviceWrapperSaga } from '~/helpers';

import {
  campaignsDetailActions,
  campaignsDetailSuccess,
  campaignsDetailFailure,
  campaignsDetailCreatorListRequest,
  campaignsDetailCreatorListSuccess,
  campaignsDetailCreatorListFailure,
  campaignsDetailCreatorAddSuccess,
  campaignsDetailCreatorAddFailure,
  campaignsDetailEditTitleSuccess,
  campaignsDetailEditTitleFailure,
  campaignsDetailEditCampaignSuccess,
  campaignsDetailEditCampaignFailure,
  campaignsDetailCreatorDeleteSuccess,
  campaignsDetailCreatorDeleteFailure,
  campaignsDetailCommentsListSuccess,
  campaignsDetailCommentsListFailure,
  campaignsDetailCommentsAddSuccess,
  campaignsDetailCommentsAddFailure,
  campaignsDetailCommentsEditSuccess,
  campaignsDetailCommentsEditFailure,
  campaignsDetailTrackingSaveSuccess,
  campaignsDetailTrackingSaveFailure,
} from './actions';

export function* getCampaign({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(apiBeCampaign.get, `campaigns/${id}`);

    yield put(campaignsDetailSuccess(response.data));
  } catch (error) {
    yield put(campaignsDetailFailure());
  }
}

export function* getCreators({ payload }) {
  try {
    const { id, page } = payload;

    const limit = 30;

    const response = yield call(
      apiBeCampaign.get,
      `campaigns/${id}/creator_networks?limit=${limit}&page=${page}`
    );

    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(campaignsDetailCreatorListSuccess(response.data, page, lastPage));
  } catch (error) {
    yield put(campaignsDetailCreatorListFailure());
  }
}

export function* addCreator({ payload }) {
  const { creator, isActiveCampaign, campaignId } = payload;
  try {
    const { campaign } = yield select((state) => state.campaignsDetail);
    const idCampaign = campaignId || campaign.data.id;
    yield call(
      apiBeCampaign.patch,
      `campaigns/${idCampaign}/creator_networks`,
      {
        creator_id: creator.creator_id,
        creator_network_id: creator.creator_network.id,
      }
    );
    yield put(campaignsDetailCreatorAddSuccess(creator.creator_network.id));
  } catch (error) {
    yield put(
      campaignsDetailCreatorAddFailure(
        creator.creator_network.id,
        isActiveCampaign
      )
    );
  }
}

export function* editTitle({ payload }) {
  try {
    const { title } = payload;

    const { id } = yield select((state) => state.campaignsDetail.campaign.data);

    yield call(apiBeCampaign.patch, `campaigns/${id}`, { title });

    yield put(campaignsDetailEditTitleSuccess(title));
  } catch (error) {
    yield put(campaignsDetailEditTitleFailure());
  }
}

export function* editCampaign({ payload }) {
  const { data } = payload;

  const { id } = yield select((state) => state.campaignsDetail.campaign.data);

  const response = yield serviceWrapperSaga(
    apiBeCampaign.patch,
    `campaigns/${id}`,
    data
  );

  if (response.status >= 400) {
    yield put(campaignsDetailEditCampaignFailure(response.data.message));
    return;
  }

  yield put(campaignsDetailEditCampaignSuccess(data));
}

export function* deleteCreator({ payload }) {
  const { creatorNetworkIds, isActiveCampaign, campaignId } = payload;
  try {
    const { campaign } = yield select((state) => state.campaignsDetail);

    const idCampaign = campaignId || campaign.data.id;

    yield all(
      creatorNetworkIds.map((creatorNetworkId) =>
        call(
          apiBeCampaign.delete,
          `campaigns/${idCampaign}/creator_networks/${creatorNetworkId}`
        )
      )
    );
    yield put(
      campaignsDetailCreatorDeleteSuccess(creatorNetworkIds, isActiveCampaign)
    );
  } catch (err) {
    yield put(
      campaignsDetailCreatorDeleteFailure(creatorNetworkIds, isActiveCampaign)
    );
  }
}

export function* getComments({ payload }) {
  try {
    const { creatorNetworkId, campaignId } = payload;

    const { campaign } = yield select((state) => state.campaignsDetail);

    const idCampaign = campaignId || campaign.data.id;

    const response = yield call(
      apiBeCampaign.get,
      `campaigns/${idCampaign}/creator_networks/${creatorNetworkId}/comments`
    );

    yield put(campaignsDetailCommentsListSuccess(response.data));
  } catch (error) {
    yield put(campaignsDetailCommentsListFailure());
  }
}

export function* addComment({ payload }) {
  try {
    const { creatorNetworkId, comment, campaignId } = payload;

    const profile = yield select((state) => state.profile.data);

    const { campaign } = yield select((state) => state.campaignsDetail);

    const idCampaign = campaignId || campaign.data.id;

    const response = yield call(
      apiBeCampaign.post,
      `campaigns/${idCampaign}/creator_networks/${creatorNetworkId}/comments`,
      { comment }
    );

    const data = {
      id: response.headers.etag,
      comment,
      created: new Date(),
      added_by: { name: profile.name },
    };

    yield put(campaignsDetailCommentsAddSuccess(data));
  } catch (error) {
    yield put(campaignsDetailCommentsAddFailure());
  }
}

export function* editComment({ payload }) {
  const { creatorNetworkId, commentId, comment, oldComment, campaignId } =
    payload;
  try {
    const { campaign } = yield select((state) => state.campaignsDetail);

    const idCampaign = campaignId || campaign.data.id;

    yield call(
      apiBeCampaign.patch,
      `campaigns/${idCampaign}/creator_networks/${creatorNetworkId}/comments/${commentId}`,
      { comment }
    );

    yield put(campaignsDetailCommentsEditSuccess());
  } catch (error) {
    yield put(
      campaignsDetailCommentsEditFailure(
        creatorNetworkId,
        commentId,
        oldComment
      )
    );
  }
}

export function* saveTracking({ payload }) {
  try {
    const { creatorNetworks, campaignId, tracking } = payload;
    const requestPayload = {
      creator_networks: creatorNetworks.length > 0 ? creatorNetworks : 'all',
      campaign_id: campaignId,
      prefix: tracking.prefix,
      redirect_to: tracking.redirect_to,
    };
    if (tracking?.id) {
      yield call(apiBeTracking.patch, `links/${tracking?.id}`, requestPayload);
      yield put(
        campaignsDetailTrackingSaveSuccess(
          creatorNetworks[0].creator_id,
          tracking
        )
      );
    } else {
      yield call(apiBeTracking.post, 'links', requestPayload);
      const { currentPage } = select(
        (state) => state.campaignsDetail.creators_list
      );
      yield put(campaignsDetailCreatorListRequest(campaignId, currentPage));
      yield put(campaignsDetailTrackingSaveSuccess());
    }
  } catch (error) {
    yield put(campaignsDetailTrackingSaveFailure());
  }
}

export default all([
  takeLatest(campaignsDetailActions.CAMPAIGNS_DETAIL_REQUEST, getCampaign),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_REQUEST,
    getCreators
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST,
    addCreator
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_REQUEST,
    editTitle
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_REQUEST,
    editCampaign
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST,
    deleteCreator
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_REQUEST,
    getComments
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_REQUEST,
    addComment
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_REQUEST,
    editComment
  ),
  takeLatest(
    campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_REQUEST,
    saveTracking
  ),
]);
