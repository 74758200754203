import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  .thumbnail {
    width: 125px;
    height: 222px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .options {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 5;
    top: 5px;
    padding: 0 5px;
  }

  .datetime {
    font-family: 'Rubik';
    font-size: 12px;
    color: ${colorPalette.text};
    margin-top: 8px;
  }
`;
