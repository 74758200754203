import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex: 1;

  .grid {
    flex: 1;

    header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tabs {
        display: flex;
        flex-direction: row;
        margin-left: 50px;

        .tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 0;
          cursor: pointer;
          width: 180px;
          border-bottom: 2px solid #dbdbdb;

          &.active {
            border-bottom: 2px solid ${colorPalette.primary};
          }

          span {
            color: ${colorPalette.primary};
            text-transform: uppercase;
            font-weight: 700;
            margin-top: 7px;
          }
        }
      }

      .container-options-tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        height: 86px;

        .info {
          font-size: 12px;
          line-height: 0.83;
          letter-spacing: 0.2px;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 4px;
          height: 9px;
        }

        .buttons-row {
          width: 210px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .container-button-campaign {
          margin-right: 24px;

          .info {
            text-align: left;
          }
        }

        .container-button-update {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .info {
            text-align: right;
          }

          .update-response-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;

            span {
              margin-left: 5px;
              font-size: 12px;
            }

            &.error {
              span {
                color: #f15750;
              }
            }

            &.success {
              span {
                color: #21c344;
              }
            }
          }

          .loader-container {
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;

            .ldio-2kuacvfnrbd div {
              width: 12px;
              height: 12px;
            }
            .ldio-2kuacvfnrbd div {
              animation: ldio-2kuacvfnrbd 1s linear infinite;
              top: 12.5px;
              left: 12.5px;
            }
            .loadingio-spinner-rolling-c79fgkrc9id {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

  .tabs-contents {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    .box-title {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 20px;
      position: relative;
      font-family: 'Rubik';
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);

      &::before {
        content: '';
        position: absolute;
        left: -24px;
        width: 6px;
        height: 24px;
        background: ${colorPalette.primary};
      }
    }
  }
`;
