import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fafafa;
  border-radius: 3px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  .button-campaign {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    top: -18px;
    right: 8px;
    z-index: 10;
    cursor: pointer;
    font-size: 28px;
  }

  .presentation {
    display: flex;
    flex-direction: row;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #ccc;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .infos {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 10px;
      position: relative;

      .networks-tabs {
        position: absolute;
        top: -40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .tab {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ccc;
          background: #fff;
          cursor: pointer;
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
            0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: ${colorPalette.primary};
          }
        }
      }

      .title {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;

        &:hover {
          color: ${colorPalette.primary};
        }
      }

      .identifier {
        color: #707070;
      }
    }
  }

  .statistics {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 120px);
    margin-top: 5px;

    .statistics-item {
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      .icon-wrapper {
        color: #707070;
      }

      .description {
        margin-top: 5px;
        color: #707070;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
      }
    }
  }
`;
