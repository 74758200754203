import types from '../types';

export function creditsRequest() {
  return {
    type: types.CREDITS_REQUEST,
  };
}

export function creditsSuccess(data) {
  return {
    type: types.CREDITS_SUCCESS,
    payload: { data },
  };
}

export function creditsFailure() {
  return {
    type: types.CREDITS_FAILURE,
  };
}
