import React, { useState, useRef } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoIosCloseCircle } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function InputTags({
  tags,
  handleAddTag,
  handleRemoveTag,
  ...rest
}) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  function handleInputChange(e) {
    setInputValue(e.target.value);
  }

  function handleSubmit() {
    if (inputValue.trim().length > 0) {
      handleAddTag(inputValue);
      setInputValue('');
      inputRef.current.focus();
    }
  }

  function handleInputKeyDown(e) {
    if (e.keyCode === 9 || e.keyCode === 13) {
      handleSubmit();
    }
  }

  return (
    <Container>
      <div className="input-container">
        <div className="icon-wrapper">
          <AiOutlineSearch size="20" />
        </div>
        <input
          type="text"
          {...rest}
          value={inputValue}
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
          ref={inputRef}
        />
        <button
          className="button-add"
          type="button"
          onClick={() => handleSubmit()}
        >
          ADD
        </button>
      </div>
      <div className="instruction">Press enter or click "ADD"</div>
      <div className="tag-list">
        {tags.map((tag, index) => (
          <div className="tag-list-item" key={Math.random()}>
            <span className="tag-list-item-name">{tag}</span>
            <div
              className="icon-wrapper"
              onClick={() => handleRemoveTag(index)}
            >
              <IoIosCloseCircle size="20" />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

InputTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleAddTag: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
};
