import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import Loader from '~/components/Loader';
import Button from '~/components/Button';
import RadioButton from '~/components/RadioButton';

import { clearIdentifiers } from '~/helpers';

import { Container } from './styles';

export default function ModalAddChannel({
  handleHideModal,
  handleAddChannel,
  loading,
  success,
  error,
}) {
  const [networkActive, setNetworkActive] = useState('instagram');
  const [profiles, setProfiles] = useState('');
  const [internalError, setInternalError] = useState('');

  function handleInputChange(event) {
    setProfiles(event.target.value);
  }

  function handleSubmit() {
    const listProfiles = clearIdentifiers(profiles);

    if (profiles.length === 0) {
      setInternalError('Fill in at least one profile to be added');
    }

    handleAddChannel(listProfiles, networkActive);
  }

  function renderDescription() {
    if (networkActive === 'youtube') {
      return (
        <ul>
          <li>1-) Access the video of the desired channel</li>
          <li>2-) Click on the channel's</li>
          <li>3-) Copy the link</li>
        </ul>
      );
    }

    return (
      <strong>Enter the IDs or links of the placements you want to add:</strong>
    );
  }

  function render() {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <>
          <div className="head">
            <div className="icon-wrapper close-modal">
              <MdClose size="30" onClick={() => handleHideModal()} />
            </div>
          </div>
          <div className="error-container">
            <p>There was a problem sending data, please try again</p>
            <Button onClick={() => handleSubmit()}>Resend</Button>
          </div>
        </>
      );
    }

    if (success) {
      return (
        <>
          <div className="head">
            <div className="icon-wrapper close-modal">
              <MdClose size="30" onClick={() => handleHideModal()} />
            </div>
          </div>
          <div className="modal-description">
            <h3>Add channels</h3>
            <div className="success">
              Channels successfully added to the queue. You will receive an
              email when the channels are on the platform.
            </div>
          </div>
          <div className="modal-options success">
            <span onClick={() => handleHideModal()}>OK</span>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="head">
          <div className="icon-wrapper close-modal">
            <MdClose size="30" onClick={() => handleHideModal()} />
          </div>
        </div>
        <div className="modal-description">
          <h3>Choose wich network you want to add the channels:</h3>
          <div className="options">
            <div className="row">
              <RadioButton
                isActive={networkActive === 'instagram'}
                onClick={() => setNetworkActive('instagram')}
              />
              <span>Instagram</span>
            </div>
            <div className="row">
              <RadioButton
                isActive={networkActive === 'youtube'}
                onClick={() => setNetworkActive('youtube')}
              />
              <span>Youtube</span>
            </div>
          </div>
          {renderDescription()}
          {internalError && <div className="error">{internalError}</div>}
          <textarea
            cols="30"
            rows="10"
            placeholder={`Enter ${
              networkActive === 'youtube' ? 'channels' : 'IDs'
            } separated by a comma`}
            onChange={handleInputChange}
          />
          <div className="textarea-label">
            Identifiers must be separated by a comma
          </div>
        </div>
        <div className="modal-options">
          <span onClick={() => handleHideModal()}>CANCEL</span>
          <span onClick={() => handleSubmit()}>ADD</span>
        </div>
      </>
    );
  }

  return <Container>{render()}</Container>;
}

ModalAddChannel.propTypes = {
  handleHideModal: PropTypes.func.isRequired,
  handleAddChannel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};
