import React, { useState, useEffect, memo } from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { campaignsDetailTrackingSaveRequest } from '~/store/modules/campaigns/detail/actions';

import Button from '~/components/Button';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import { toSlug } from '~/helpers';

import { Container } from './styles';

function ModalTrackLinkMulti({ creatorNetworks, handleClose, campaignId }) {
  const dispatch = useDispatch();

  const [prefix, setPrefix] = useState('');
  const [redirectTo, setRedirectTo] = useState('');

  const {
    loading: loadingSave,
    error: errorSave,
    saved,
  } = useSelector((state) => state.campaignsDetail.tracking_save);

  useEffect(() => {
    if (saved) {
      setPrefix('');
      setRedirectTo('');
    }
  }, [saved]);

  const onChangePrefix = (e) => setPrefix(toSlug(e.target.value));

  const onChangeRedirectTo = (e) => setRedirectTo(e.target.value);

  const onSubmit = () => {
    if (loadingSave) return;

    dispatch(
      campaignsDetailTrackingSaveRequest(creatorNetworks, campaignId, {
        redirect_to: redirectTo,
        prefix,
      })
    );
  };

  const render = () => {
    if (errorSave) {
      return (
        <LoadingDataErrorHandling
          refreshData={() =>
            dispatch(
              campaignsDetailTrackingSaveRequest(creatorNetworks, campaignId, {
                redirect_to: redirectTo,
                prefix,
              })
            )
          }
        />
      );
    }

    return (
      <div className="content">
        <h3>Tracking</h3>
        <p>
          Add the URL you want to track for{' '}
          {creatorNetworks.length > 0 ? 'selected' : 'all'} creators
        </p>
        <input
          type="text"
          value={prefix}
          onChange={onChangePrefix}
          placeholder="Prefix"
        />
        <span className="preview">https://go.cely.co/{prefix}/identifier</span>
        <input
          type="text"
          value={redirectTo}
          onChange={onChangeRedirectTo}
          placeholder="Redirect to"
        />
        {saved && <span className="saved">Saved!</span>}
        <div className="container-button-submit">
          <Button onClick={onSubmit}>
            {loadingSave ? 'SAVING...' : 'SAVE'}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <div className="container-close">
        <div className="button-close" onClick={handleClose}>
          <CloseOutlinedIcon color="#818181" />
        </div>
      </div>
      {render()}
    </Container>
  );
}

ModalTrackLinkMulti.propTypes = {
  creatorNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      creator_id: PropTypes.number,
      creator_network_id: PropTypes.number,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
};

export default memo(ModalTrackLinkMulti);
