import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons/';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import debounce from 'lodash.debounce';

import { campaignsNewReset } from '~/store/modules/campaigns/new/actions';
import {
  campaignsHeaderSetFilter,
  campaignsHeaderListRequest,
} from '~/store/modules/campaigns/header/actions';

import InputSearch from '~/components/InputSearch';
import Modal from '~/components/Modal';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { useOutsideAlerter } from '~/helpers';

import ModalNewCampaign from '~/pages/Campaigns/List/ModalNewCampaign';

import CampaignItem from './CampaignItem';

import { Container } from './styles';

function Campaigns() {
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const [modalNewCampaignIsOpen, setModalNewCampaignIsOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const {
    filter: { title },
    data: campaigns,
    loading: loadingList,
    error: errorList,
    page,
    last_page,
    paginate,
  } = useSelector((state) => state.campaignsHeader);

  const { data } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(campaignsHeaderListRequest());
  }, [dispatch]);

  useOutsideAlerter(containerRef, () => {
    setShowDropDown(false);
  });

  const toogleDropDown = () => {
    setShowDropDown((state) => !state);
  };

  const handleOpenNewCampaignModal = () => {
    setModalNewCampaignIsOpen(true);
  };

  const handleCloseNewCampaignModal = () => {
    setModalNewCampaignIsOpen(false);
    dispatch(campaignsNewReset());
  };

  const debouncedSave = useCallback(
    debounce(() => dispatch(campaignsHeaderListRequest()), 1000),
    []
  );

  const handleInputSearchChange = (e) => {
    const keyword = e.target.value;
    dispatch(campaignsHeaderSetFilter(keyword));
    debouncedSave();
  };

  const handleScroll = useCallback(
    (e) => {
      const finalScroll =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (finalScroll && !paginate.loading && !loadingList && page < last_page)
        dispatch(campaignsHeaderListRequest(true));
    },
    [dispatch, last_page, loadingList, page, paginate.loading]
  );

  const renderLoadingPaginate = useCallback(() => {
    return paginate.loading && <Loader />;
  }, [paginate.loading]);

  const renderList = () => {
    if (loadingList) {
      return <Loader />;
    }

    if (errorList) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(campaignsHeaderListRequest())}
        />
      );
    }

    if (campaigns.length === 0) {
      return (
        <div className="no-results">
          You haven't created any campaigns yet. Click "new campaign" button
          above to get started
        </div>
      );
    }

    return (
      <Scrollbars
        onScroll={handleScroll}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {campaigns.map((campaign) => (
          <CampaignItem key={campaign.id} campaign={campaign} />
        ))}
      </Scrollbars>
    );
  };

  return (
    <>
      <Container ref={containerRef}>
        <div className="select-box" onClick={toogleDropDown}>
          <span className="title">
            {data?.settings?.active_campaign?.title || 'Select a campaign'}
          </span>
          {showDropDown ? (
            <ArrowDropUp style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          ) : (
            <ArrowDropDown style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          )}
        </div>
        {showDropDown && (
          <>
            <div className="drop-list">
              <div className="head-list">
                <div className="input-search-wrapper">
                  <InputSearch
                    value={title}
                    height={32}
                    placeholder="Search in campaigns"
                    onChange={handleInputSearchChange}
                  />
                </div>
                <div
                  className="button-new"
                  onClick={handleOpenNewCampaignModal}
                >
                  NEW CAMPAIGN
                </div>
              </div>
              <div className="container-list">
                {renderList()}
                {renderLoadingPaginate()}
              </div>
            </div>
          </>
        )}
      </Container>
      <Modal
        width={600}
        height={545}
        isOpen={modalNewCampaignIsOpen}
        handleCloseModal={handleCloseNewCampaignModal}
      >
        <ModalNewCampaign handleClose={handleCloseNewCampaignModal} />
      </Modal>
    </>
  );
}

export default Campaigns;
