import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MdKeyboardArrowDown } from 'react-icons/md';

import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import format from 'date-fns/format';

import Modal from '~/components/Modal';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { abbreviateNumber } from '~/helpers';

import {
  selectGroup,
  tocGroupsListRequest,
  tocRequest,
  tocSelectTag,
} from '~/store/modules/toc/actions';

import { Container } from './styles';

import ModalContent from '../Posts/Modal';

function TrackOfCompetition() {
  const dispatch = useDispatch();

  const groupsLoading = useSelector((state) => state.toc.groups.loading);
  const groupsError = useSelector((state) => state.toc.groups.error);
  const groupsData = useSelector((state) => state.toc.groups.data);
  const groupSelected = useSelector((state) => state.toc.groups.selected);

  const {
    tagSelected,
    loading: dataByGroupLoading,
    error: dataByGroupError,
    data: dataByGroupData,
    current_page,
    last_page,
    paginate: { loading: loadingPaginate },
  } = useSelector((state) => state.toc.dataByGroup);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurretIndex, setModalCurretIndex] = useState(0);

  useEffect(() => {
    dispatch(tocGroupsListRequest());
  }, [dispatch]);

  function handleShowModal(index) {
    setModalCurretIndex(index);
    setModalIsOpen(true);
  }

  function handleHideModal() {
    setModalIsOpen(false);
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        handleCloseModal={handleHideModal}
        width={880}
        height={600}
      >
        <ModalContent
          index={modalCurretIndex}
          data={dataByGroupData?.post}
          handleHideModal={handleHideModal}
        />
      </Modal>
    );
  }

  function renderGroups() {
    if (groupsLoading) {
      return <MenuItem value="loading">Carregando...</MenuItem>;
    }

    if (groupsError)
      return (
        <MenuItem value="error">Não foi possível carregar os grupos</MenuItem>
      );

    return groupsData.map((group) => (
      <MenuItem key={group.id} value={group.id}>
        {group.name}
      </MenuItem>
    ));
  }

  function selectTag(tag) {
    dispatch(tocSelectTag(tag));
    dispatch(tocRequest());
  }

  function renderTags() {
    return dataByGroupData?.group?.tags.map((tag) => (
      <div
        key={Math.random()}
        className={`tag-item ${tagSelected === tag.name && 'selected'}`}
        onClick={() => selectTag(tag.name)}
      >
        <div className="tag-total-posts">{tag.total_posts}</div>
        <div className="tag-name">{tag.name}</div>
      </div>
    ));
  }

  function renderSeeMore() {
    if (dataByGroupData?.post?.length > 0 && !loadingPaginate) {
      return (
        <div
          className="button-see-more"
          onClick={() => dispatch(tocRequest(true))}
        >
          <div className="icon-wrapper">
            <MdKeyboardArrowDown size="16" />
          </div>
          <span>See more</span>
        </div>
      );
    }

    return null;
  }

  function renderLoadingSeeMore() {
    if (loadingPaginate) {
      return <Loader />;
    }

    return null;
  }

  function renderPosts() {
    return (
      <>
        <h4>Latest posts</h4>
        <div className="posts-list">
          {dataByGroupData.post.map((post, index) => (
            <div className="post-list-item" key={post.id}>
              <div
                className="thumb"
                onClick={() => handleShowModal(index)}
                style={{
                  backgroundImage: `url(${
                    post.medias.length > 0 ? post.medias[0]?.path : ''
                  })`,
                }}
              />
              <div className="posts-list-info">
                <CalendarTodayOutlinedIcon
                  style={{ fontSize: 15, color: '#797979' }}
                />
                <span>{format(new Date(post.published_at), 'dd/MM/yyyy')}</span>
              </div>
              <div className="posts-list-info">
                <ThumbUpOutlinedIcon
                  style={{ fontSize: 15, color: '#797979' }}
                />
                <span>{abbreviateNumber(post.total_likes, 1)}</span>
              </div>
              <div className="posts-list-info">
                <CommentOutlinedIcon
                  style={{ fontSize: 15, color: '#797979' }}
                />
                <span>{abbreviateNumber(post.total_comments, 1)}</span>
              </div>
              <div className="posts-list-info">
                <a
                  href={post.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewOutlinedIcon
                    style={{ fontSize: 15, color: '#797979' }}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  function renderResults() {
    if (dataByGroupLoading) {
      return <Loader />;
    }

    if (dataByGroupError) {
      return (
        <LoadingDataErrorHandling refreshData={() => dispatch(tocRequest())} />
      );
    }

    return (
      <>
        {dataByGroupData?.group?.tags?.length > 0 && (
          <div className="tags">{renderTags()}</div>
        )}
        {dataByGroupData?.post?.length > 0 && (
          <div className="posts">{renderPosts()}</div>
        )}
        {current_page < last_page && (
          <>
            <div className="container-loading-see-more">
              {renderLoadingSeeMore()}
            </div>
            <div className="container-see-more">{renderSeeMore()}</div>
          </>
        )}
      </>
    );
  }

  function handleGroups(e) {
    dispatch(selectGroup(e.target.value));
    const notSearch = ['loading', 'error', 'select'];
    if (!notSearch.includes(e.target.value)) dispatch(tocRequest());
  }

  return (
    <Container>
      <div className="box-content track-of-competition">
        <p>Select a tag group to see the resulting posts.</p>
        <FormControl variant="outlined" style={{ marginTop: 20, width: 300 }}>
          <Select value={groupSelected} onChange={handleGroups}>
            {groupsData.length > 0 && (
              <MenuItem value="select">Selecione</MenuItem>
            )}
            {renderGroups()}
          </Select>
        </FormControl>
        <div className="results">{renderResults()}</div>
        {renderModal()}
      </div>
    </Container>
  );
}

export default TrackOfCompetition;
