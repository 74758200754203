import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 40px;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-section {
      margin-right: 30px;
      margin-bottom: 0;
    }

    .MuiSelect-select {
      width: 200px;
      height: 24px;
      display: flex;
      align-items: center;
      padding: 3px 10px;
      color: ${colorPalette.text};
    }
  }

  .stories-content {
    margin-top: 10px;

    & > p {
      text-align: center;
    }

    .options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .filters {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiSelect-select {
          width: 110px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          color: ${colorPalette.text};
        }

        .dates-container {
          width: 300px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-left: 20px;

          input {
            font-size: 14px;
            font-family: 'Hind';
            color: ${colorPalette.text};
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 157px;
        height: 32px;
        border-radius: 4px;
        border: solid 1px #bfbfbf;

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          padding: 4px;

          &.with-border {
            border-right: 1px solid #bfbfbf;
          }
        }
      }
    }

    .stories-list {
      display: grid;
      grid-template-columns: repeat(4, 125px);
      grid-gap: 3rem;
    }
  }
`;
