import types from '../types';

export function search(groupName) {
  return {
    type: types.SOV_LIST_SEARCH,
    payload: { groupName },
  };
}

export function select(groupId) {
  return {
    type: types.SOV_LIST_SELECT,
    payload: { groupId },
  };
}

export function clearSelected() {
  return {
    type: types.SOV_LIST_CLEAR_SELECTED,
  };
}

export function listRequest() {
  return {
    type: types.SOV_LIST_REQUEST,
  };
}

export function listSuccess(data) {
  return {
    type: types.SOV_LIST_SUCCESS,
    payload: { data },
  };
}

export function listFailure() {
  return {
    type: types.SOV_LIST_FAILURE,
  };
}
