export const forgotPasswordSaveActions = {
  SAVE_PASSWORD_REQUEST: '@forgotPassword/save/SAVE_PASSWORD_REQUEST',
  SAVE_PASSWORD_SUCCESS: '@forgotPassword/save/SAVE_PASSWORD_SUCCESS',
  SAVE_PASSWORD_FAILURE: '@forgotPassword/save/SAVE_PASSWORD_FAILURE',
};

export function savePasswordRequest(code, password) {
  return {
    type: forgotPasswordSaveActions.SAVE_PASSWORD_REQUEST,
    payload: { code, password },
  };
}

export function savePasswordSuccess(data) {
  return {
    type: forgotPasswordSaveActions.SAVE_PASSWORD_SUCCESS,
    payload: { data },
  };
}

export function savePasswordFailure() {
  return {
    type: forgotPasswordSaveActions.SAVE_PASSWORD_FAILURE,
  };
}
