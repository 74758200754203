import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  campaignsHeaderActions,
  campaignsHeaderListSuccess,
  campaignsHeaderListFailure,
} from './actions';

import { apiBeCampaign } from '~/services/api';

export function* getCampaigns({ payload }) {
  const { isPaginate } = payload;
  try {
    const {
      page,
      filter: { title },
    } = yield select((state) => state.campaignsHeader);

    const limit = 20;

    const params = { page: isPaginate ? page + 1 : page, limit, title };

    const response = yield call(apiBeCampaign.get, 'campaigns', { params });

    const { data } = response;

    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(campaignsHeaderListSuccess(data, lastPage, isPaginate));
  } catch (err) {
    yield put(campaignsHeaderListFailure(isPaginate));
  }
}

export default all([
  takeLatest(
    campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_REQUEST,
    getCampaigns
  ),
]);
