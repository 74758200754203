import React, { useState, useEffect, memo } from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdContentCopy } from 'react-icons/md';

import { campaignsDetailTrackingSaveRequest } from '~/store/modules/campaigns/detail/actions';

import Button from '~/components/Button';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import { toSlug, copyToClipboard } from '~/helpers';

import { Container } from './styles';

function ModalTrackLinkSingle({ creatorNetworks, campaignId, handleClose }) {
  const dispatch = useDispatch();

  const [selectedTracking, setSelectedTracking] = useState(null);
  const [prefix, setPrefix] = useState('');
  const [redirectTo, setRedirectTo] = useState('');

  const {
    loading: loadingSave,
    error: errorSave,
    saved,
  } = useSelector((state) => state.campaignsDetail.tracking_save);

  const { data: creators, loading: loadingCreators } = useSelector(
    (state) => state.campaignsDetail.creators_list
  );

  useEffect(() => {
    if (selectedTracking) {
      setPrefix(selectedTracking.prefix);
      setRedirectTo(selectedTracking.redirects[0].redirect_to);
    }
  }, [selectedTracking]);

  useEffect(() => {
    if (saved) {
      setSelectedTracking(null);
      setPrefix('');
      setRedirectTo('');
    }
  }, [saved]);

  const onChangePrefix = (e) => setPrefix(toSlug(e.target.value));

  const onChangeRedirectTo = (e) => setRedirectTo(e.target.value);

  const onSubmit = () => {
    if (loadingSave) return;

    dispatch(
      campaignsDetailTrackingSaveRequest(creatorNetworks, campaignId, {
        id: selectedTracking?.id,
        redirect_to: redirectTo,
        prefix,
      })
    );
  };

  const render = () => {
    if (loadingSave || loadingCreators) {
      return <Loader />;
    }

    if (errorSave) {
      return <LoadingDataErrorHandling refreshData={onSubmit} />;
    }

    if (creatorNetworks.length > 0) {
      const selectedCreator = creators.filter(
        (creator) => creator.creator_id === creatorNetworks[0].creator_id
      );

      const { tracking } = selectedCreator[0];

      return (
        <div className="content">
          <div className="container-form">
            <h3>Tracking</h3>
            <p>Add the URL you want to track for this creator</p>
            <input
              type="text"
              value={prefix}
              onChange={onChangePrefix}
              placeholder="Prefix"
            />
            <span className="preview">
              https://go.cely.co/{prefix}/identifier
            </span>
            <input
              type="text"
              value={redirectTo}
              onChange={onChangeRedirectTo}
              placeholder="Redirect to"
            />
            {saved && <span className="saved">Saved!</span>}
            <div className="container-button-submit">
              <Button onClick={onSubmit}>
                {selectedTracking ? 'SAVE' : 'ADD'}
              </Button>
            </div>
          </div>

          <div className="list-tracking">
            <Scrollbars
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Prefix</th>
                    <th>Redirect to</th>
                    <th>URL</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {tracking.map((row) => (
                    <tr key={row.id}>
                      <td>{row.prefix}</td>
                      <td>
                        <MdContentCopy
                          style={{
                            marginRight: 5,
                            fontSize: 16,
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            copyToClipboard(row.redirects[0].redirect_to)
                          }
                        />
                        <span title={row.redirects[0].redirect_to}>
                          {row.redirects[0].redirect_to}
                        </span>
                      </td>
                      <td>
                        <MdContentCopy
                          style={{
                            marginRight: 5,
                            fontSize: 16,
                            cursor: 'pointer',
                          }}
                          onClick={() => copyToClipboard(row.url)}
                        />
                        <span title={row.url}>{row.url}</span>
                      </td>
                      <td>
                        <EditOutlinedIcon
                          onClick={() => setSelectedTracking(row)}
                          style={{ cursor: 'pointer' }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbars>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Container>
      <div className="container-close">
        <div className="button-close" onClick={handleClose}>
          <CloseOutlinedIcon color="#818181" />
        </div>
      </div>
      {render()}
    </Container>
  );
}

ModalTrackLinkSingle.propTypes = {
  creatorNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      creator_id: PropTypes.number,
      creator_network_id: PropTypes.number,
    })
  ).isRequired,
  campaignId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(ModalTrackLinkSingle);
