import { takeLatest, call, put, all } from 'redux-saga/effects';
import types from '../types';

import { apiTocSov } from '~/services/api';

import {
  tagGroupsListSuccess,
  tagGroupsListFailure,
  tagGroupsCreateSuccess,
  tagGroupsCreateFailure,
  tagGroupsUpdateSuccess,
  tagGroupsUpdateFailure,
  tagGroupsDeleteSuccess,
  tagGroupsDeleteFailure,
  tagGroupsListReprocessSuccess,
  tagGroupsListReprocessFailure,
} from './actions';

export function* getGroups() {
  try {
    const response = yield call(apiTocSov.get, 'groups');
    yield put(tagGroupsListSuccess(response.data));
  } catch (err) {
    yield put(tagGroupsListFailure());
  }
}

export function* createGroup({ payload }) {
  try {
    const { name, created } = payload;
    const response = yield call(apiTocSov.post, 'groups', {
      name,
      tags: [],
    });
    const data = {
      id: response.headers.etag,
      name,
      tags: [],
      created,
    };
    yield put(tagGroupsCreateSuccess(data));
  } catch (err) {
    yield put(tagGroupsCreateFailure());
  }
}

export function* updateGroup({ payload }) {
  try {
    const { data, isTag } = payload;
    yield call(apiTocSov.patch, `groups/${data.id}`, data);
    yield put(tagGroupsUpdateSuccess(data, isTag));
  } catch (err) {
    yield put(tagGroupsUpdateFailure());
  }
}

export function* deleteGroup({ payload }) {
  try {
    const { groupsIds } = payload;
    yield all(
      groupsIds.map((groupId) => call(apiTocSov.delete, `groups/${groupId}`))
    );
    yield put(tagGroupsDeleteSuccess(groupsIds));
  } catch (err) {
    yield put(tagGroupsDeleteFailure());
  }
}

export function* reprocessGroup({ payload }) {
  try {
    const { groupsIds } = payload;
    yield all(
      groupsIds.map((groupId) =>
        call(apiTocSov.post, `groups/${groupId}/reprocess`)
      )
    );
    yield put(tagGroupsListReprocessSuccess(groupsIds));
  } catch (err) {
    yield put(tagGroupsListReprocessFailure());
  }
}

export default all([
  takeLatest(types.TAG_GROUPS_LIST_REQUEST, getGroups),
  takeLatest(types.TAG_GROUPS_CREATE_REQUEST, createGroup),
  takeLatest(types.TAG_GROUPS_UPDATE_REQUEST, updateGroup),
  takeLatest(types.TAG_GROUPS_DELETE_REQUEST, deleteGroup),
  takeLatest(types.TAG_GROUPS_LIST_REPROCESS_REQUEST, reprocessGroup),
]);
