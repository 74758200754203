import produce from 'immer';
import { savePasswordActions } from './actions';

export const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default function savePassword(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case savePasswordActions.SAVE_PASSWORD_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case savePasswordActions.SAVE_PASSWORD_SUCCESS: {
        draft.loading = false;
        break;
      }
      case savePasswordActions.SAVE_PASSWORD_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}
