import React from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { Container } from './styles';

export default function Regions() {
  const { data } = useSelector((state) => state.audienceProfile);

  const flags = {
    BR: {
      name: 'Brasil',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/br.svg',
    },
    US: {
      name: 'Estados Unidos',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/us.svg',
    },
    PE: {
      name: 'Peru',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/pe.svg',
    },
    AR: {
      name: 'Argentina',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/ar.svg',
    },
    DE: {
      name: 'Alemanha',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/de.svg',
    },
    FR: {
      name: 'França',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/fr.svg',
    },
    KR: {
      name: 'Coreia do Sul',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/kr.svg',
    },
    RU: {
      name: 'Rússia',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/ru.svg',
    },
    IN: {
      name: 'Indonésia',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/id.svg',
    },
    ES: {
      name: 'Espanha',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/es.svg',
    },
    MX: {
      name: 'México',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/mx.svg',
    },
    CL: {
      name: 'Chile',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/cl.svg',
    },
    IT: {
      name: 'Itália',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/it.svg',
    },
    PT: {
      name: 'Portugal',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/pt.svg',
    },
    IR: {
      name: 'República Islâmica do Irã',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/ir.svg',
    },
    GB: {
      name: 'Reino Unido',
      url: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/4x3/gb.svg',
    },
  };

  return (
    <Container>
      <div className="column-division countries">
        <h3>COUNTRIES</h3>
        <Scrollbars style={{ height: 231 }}>
          <ul>
            {data?.countries?.map((row) => {
              if (flags[row.code]) {
                return (
                  <li key={Math.random()}>
                    <span>
                      <img
                        src={flags[row.code].url}
                        alt={flags[row.code].name}
                      />
                      {flags[row.code].name}
                    </span>
                    <span>{`${Math.round(row.value)}%`}</span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </Scrollbars>
      </div>
      <div className="column-division cities">
        <h3>CITIES</h3>
        <Scrollbars style={{ height: 231 }}>
          <ul>
            {data?.cities?.map((row) => (
              <li key={Math.random()}>
                <span>{row.name}</span>
                <span>{`${Math.round(row.value)}%`}</span>
              </li>
            ))}
          </ul>
        </Scrollbars>
      </div>
    </Container>
  );
}
