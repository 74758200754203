import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 16px;
  height: 100%;
  border-radius: 4px;

  .container-close {
    display: flex;
    justify-content: flex-end;

    .button-close {
      color: #818181;
      height: 24px;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;

    .container-form {
      display: flex;
      flex-direction: column;

      h3 {
        font-family: 'Rubik';
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.17px;
        color: #000000;
        margin-bottom: 5px;
      }

      p {
        line-height: 1.37;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 30px;
      }

      input[type='text'] {
        width: 100%;
        height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.32);
        border-radius: 4px;
        font-family: 'Rubik', sans-serif;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        padding: 0 16px;
      }

      .preview {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        margin: 5px 0 10px;
      }

      .saved {
        text-align: center;
        color: #24e84e;
        margin: 10px 0 5px;
      }

      .container-button-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }
    }

    .list-tracking {
      display: flex;
      flex: 1;
      margin-left: 50px;
      height: 280px;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          &:nth-child(odd) {
            background: #fff;
          }

          &:not(.row-footer):hover {
            background: rgba(90, 19, 217, 0.1);
          }

          th {
            text-align: left;
            font-family: 'Rubik';
            font-size: 12.2px;
            letter-spacing: 0.4px;
            color: rgba(0, 0, 0, 0.6);
            border-bottom: 1px solid #dbdbdb;
            background: #f5f5f5 !important;
            padding: 12px 8px;
          }

          td {
            text-align: left;
            padding: 12px 8px;
            color: rgba(0, 0, 0, 0.6);

            span {
              font-family: 'Rubik';
              font-size: 12.2px;
              line-height: 1.31;
              letter-spacing: 0.4px;
              color: rgba(0, 0, 0, 0.6);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 150px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
`;
