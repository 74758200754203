import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavigateBefore,
  SaveAlt,
  DeleteOutlineOutlined,
  Instagram,
  YouTube,
  CommentOutlined,
  LinkOutlined,
  VideocamOff,
  Videocam,
  Block,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import {
  setSelectedCreatorStore,
  campaignsDetailCreatorAddRequest,
  campaignsDetailRequest,
  campaignsDetailCreatorListRequest,
  campaignsDetailEditTitleRequest,
  campaignsDetailEditCampaignReset,
  campaignsDetailCreatorDeleteRequest,
  campaignsDetailTrackingSaveReset,
} from '~/store/modules/campaigns/detail/actions';

import InputSearchCreators from '~/components/InputSearchCreators';

import Button from '~/components/Button';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import Checkbox from '~/components/Checkbox';
import InputEditTitle from '~/components/InputEditTitle';
import Modal from '~/components/Modal';
import ModalDeleteContent from '~/components/ModalDeleteContent';
import Pagination from '~/components/Pagination';

import ModalEditCampaign from './ModalEditCampaign';
import ModalComments from './ModalComments';
import ModalCopyCreators from './ModalCopyCreators';
import ModalTrackLinkMulti from './ModalTrackLinkMulti';
import ModalTrackLinkSingle from './ModalTrackLinkSingle';
import ModalStoriesMonitor from './ModalStoriesMonitor';
import Tracking from './Tracking';

import { abbreviateNumber } from '~/helpers';
import { Container, ActionGroup } from './styles';

import imageCreatorsEmpty from '~/assets/images/Campaign/Detail/empty.png';

function Detail({ match }) {
  const dispatch = useDispatch();

  const networksIcons = {
    INSTAGRAM: () => <Instagram style={{ fontSize: 18, color: '#666666' }} />,
    YOUTUBE: () => <YouTube style={{ fontSize: 18, color: '#666666' }} />,
  };

  const { id } = match.params;

  const {
    data: campaign,
    loading,
    error,
  } = useSelector((state) => state.campaignsDetail.campaign);

  const [selectedCreators, setSelectedCreators] = useState([]);
  const [campaignTitle, setCampaignTitle] = useState('');
  const [modalEditCampaignIsOpen, setModalEditCampaignIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalCommentsIsOpen, setModalCommentsIsOpen] = useState(false);
  const [modalTrackLinkMultiIsOpen, setModalTrackLinkMultiIsOpen] =
    useState(false);
  const [modalTrackLinkSingleIsOpen, setModalTrackLinkSingleIsOpen] =
    useState(false);
  const [selectedUniqueCreatorNetworkId, setSelectedUniqueCreatorNetworkId] =
    useState(0);
  const [selectedCreatorNetworks, setSelectedCreatorNetworks] = useState([]);
  const [modalStoriesMonitorIsOpen, setModalStoriesMonitorIsOpen] =
    useState(false);

  const [modalCopyCreatorsIsOpen, setModalCopyCreatorsIsOpen] = useState(false);

  const {
    data: creators,
    loading: loadingCreators,
    error: errorCreators,
    currentPage,
    lastPage,
  } = useSelector((state) => state.campaignsDetail.creators_list);

  const { error: errorEditTitle } = useSelector(
    (state) => state.campaignsDetail.edit_title
  );

  const {
    loading: loadingDelete,
    error: errorDelete,
    confirmDelete,
  } = useSelector((state) => state.campaignsDetail.delete_creator);

  const { data: profile } = useSelector((state) => state.profile);
  const { access_token } = useSelector((state) => state.auth.token);
  const selectedCreatorId = useSelector(
    (state) => state.campaignsDetail.selectedCreatorId
  );

  const [tabActive, setTabActive] = useState('list');

  useEffect(() => {
    dispatch(campaignsDetailRequest(id));
    dispatch(campaignsDetailCreatorListRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (campaign) {
      setCampaignTitle(campaign.title);
    }
  }, [campaign]);

  useEffect(() => {
    if (errorEditTitle) {
      setCampaignTitle(campaign.title);
    }
  }, [campaign, errorEditTitle]);

  useEffect(() => {
    if (confirmDelete > 0) {
      setModalDeleteIsOpen(false);

      if (creators && creators.length === 0 && currentPage - 1 > 0) {
        dispatch(campaignsDetailCreatorListRequest(id, currentPage - 1));
      }
    }
  }, [confirmDelete, creators, dispatch, id, currentPage]);

  const handleSelectCreator = (creator) => {
    if (selectedCreators.includes(creator.creator_network.id)) {
      const filterCreators = selectedCreators.filter(
        (creatorNetworkIdSelected) =>
          creatorNetworkIdSelected !== creator.creator_network.id
      );
      setSelectedCreators(filterCreators);
    } else {
      setSelectedCreators([...selectedCreators, creator.creator_network.id]);
    }

    const filterCreatorsNetworks = selectedCreatorNetworks.filter(
      (row) => row.creator_network_id === creator.creator_network.id
    );

    if (filterCreatorsNetworks.length > 0) {
      const removedFilter = selectedCreatorNetworks.filter(
        (row) => row.creator_network_id !== creator.creator_network.id
      );
      setSelectedCreatorNetworks(removedFilter);
    } else {
      setSelectedCreatorNetworks([
        ...selectedCreatorNetworks,
        {
          creator_id: creator.creator_id,
          creator_network_id: creator.creator_network.id,
        },
      ]);
    }
  };

  const handleChangeTitle = (e) => {
    setCampaignTitle(e.target.value);
  };

  const handleBlurTitle = () => {
    dispatch(campaignsDetailEditTitleRequest(campaignTitle));
  };

  const handleSearchSelectCreator = useCallback(
    (creator) => {
      const creatorExists = creators.filter(
        (row) => row.creator_network.id === creator.creator_network.id
      );

      if (creatorExists.length === 0) {
        creator.created = new Date();
        creator.added_by = { name: profile.name };
        creator.tracking = [];
        dispatch(
          campaignsDetailCreatorAddRequest(
            creator,
            profile.settings?.active_campaign?.id === campaign.id
          )
        );
      }
    },
    [campaign, creators, dispatch, profile]
  );

  const handleOpenEditCampaignModal = () => {
    setModalEditCampaignIsOpen(true);
  };

  const handleCloseEditCampaignModal = () => {
    setModalEditCampaignIsOpen(false);
    dispatch(campaignsDetailEditCampaignReset());
  };

  const handleOpenCommentsModal = (creatorNetworkId) => {
    setSelectedCreatorNetworks([]);
    setSelectedUniqueCreatorNetworkId(creatorNetworkId);
    setModalCommentsIsOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setModalCommentsIsOpen(false);
    setSelectedUniqueCreatorNetworkId(0);
  };

  const handleOpenDeleteModal = () => {
    setModalDeleteIsOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedCreatorNetworks([]);
    setSelectedCreators([]);
    setModalDeleteIsOpen(false);
  };

  const handleConfirmDelete = () => {
    dispatch(
      campaignsDetailCreatorDeleteRequest(
        selectedCreators,
        profile.settings?.active_campaign?.id === campaign.id
      )
    );
  };

  const handleSingleDelete = (creator) => {
    handleSelectCreator(creator);
    handleOpenDeleteModal();
  };

  const handleFirstPage = () => {
    dispatch(campaignsDetailCreatorListRequest(id));
  };

  const handleLastPage = () => {
    dispatch(campaignsDetailCreatorListRequest(id, lastPage));
  };

  const handlePaginate = (selectedPage) => {
    dispatch(campaignsDetailCreatorListRequest(id, selectedPage));
  };

  const handleOpenTrackLinkModalToMultiCreators = () => {
    setSelectedCreators([]);
    setSelectedCreatorNetworks([]);
    setModalTrackLinkMultiIsOpen(true);
  };

  const handleCloseTrackLinkModalMulti = () => {
    setModalTrackLinkMultiIsOpen(false);
    setSelectedUniqueCreatorNetworkId(0);
    setSelectedCreators([]);
    setSelectedCreatorNetworks([]);
    dispatch(campaignsDetailTrackingSaveReset());
  };

  const handleOpenTrackLinkModalSingle = (creator) => {
    setSelectedCreators([]);
    setSelectedCreatorNetworks([
      {
        creator_id: creator.creator_id,
        creator_network_id: creator.creator_network.id,
      },
    ]);
    setModalTrackLinkSingleIsOpen(true);
  };

  const handleCloseTrackLinkModalSingle = () => {
    setModalTrackLinkSingleIsOpen(false);
    setSelectedUniqueCreatorNetworkId(0);
    setSelectedCreators([]);
    setSelectedCreatorNetworks([]);
    dispatch(campaignsDetailTrackingSaveReset());
  };

  const handleOpenModalStoriesMonitor = (creator) => {
    dispatch(setSelectedCreatorStore(creator.creator_id));
    setModalStoriesMonitorIsOpen(true);
  };

  const handleCloseModalStoriesMonitor = () => {
    dispatch(setSelectedCreatorStore(null));
    setModalStoriesMonitorIsOpen(false);
  };

  const renderClickTrackingColumn = (creator) => {
    if (!creator.creator_network.can_have_clicks_tracked) {
      return <Block />;
    }

    return (
      <LinkOutlined
        style={{
          color: 'rgba(0, 0, 0, 0.54)',
          cursor: 'pointer',
        }}
        onClick={() => handleOpenTrackLinkModalSingle(creator)}
        title="Add track link"
      />
    );
  };

  const renderStoriesMonitorColumn = (creator) => {
    if (!creator.creator_network.can_have_stories_monitored) {
      return <Block />;
    }

    if (creator?.monitor?.is_active) {
      return (
        <Videocam
          style={{
            color: '#ff0000',
            cursor: 'pointer',
          }}
          onClick={() => handleOpenModalStoriesMonitor(creator)}
          title="Stories Monitor Off"
        />
      );
    }

    return (
      <VideocamOff
        style={{
          color: 'rgba(0, 0, 0, 0.54)',
          cursor: 'pointer',
        }}
        onClick={() => handleOpenModalStoriesMonitor(creator)}
        title="Stories Monitor Off"
      />
    );
  };

  const renderCreators = () => {
    if (errorCreators) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(campaignsDetailCreatorListRequest(id))}
        />
      );
    }

    if (creators.length === 0) {
      return (
        <div className="container-empty">
          <img src={imageCreatorsEmpty} alt="empty" />
          <p>
            You haven't added any influencers to the campaign yet. Start by
            searching for a name in the search session or add by the
            influencer's PPP.
          </p>
          <Link to="/search">
            <Button width={312} onPress={() => false}>
              SEARCH INFLUENCERS
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <>
        <table className="table-creators">
          <thead>
            <tr>
              <th>
                <div className="delete-container">
                  <LinkOutlined
                    onClick={() => setModalTrackLinkMultiIsOpen(true)}
                    style={{
                      color:
                        selectedCreatorNetworks.length > 0
                          ? 'rgba(0, 0, 0, 0.54)'
                          : 'rgba(0, 0, 0, 0.1)',
                      cursor:
                        selectedCreatorNetworks.length > 0
                          ? 'pointer'
                          : 'default',
                    }}
                  />
                  <DeleteOutlineOutlined
                    onClick={handleOpenDeleteModal}
                    style={{
                      color:
                        selectedCreators.length > 0
                          ? 'rgba(0, 0, 0, 0.54)'
                          : 'rgba(0, 0, 0, 0.1)',
                      cursor:
                        selectedCreators.length > 0 ? 'pointer' : 'default',
                    }}
                  />
                </div>
              </th>
              <th className="creator-col">INFLUENCER</th>
              <th>NETWORK</th>
              <th>ADDED BY</th>
              <th>ADDED ON</th>
              <th>ENGAGEMENT REARCH</th>
              <th>AEM</th>
              <th>ENGAGEMENT TAX</th>
              <th>FOLLOWERS</th>
              <th>COMMENTS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {creators.map((creator) => (
              <tr key={creator.creator_network.id}>
                <td>
                  <div className="delete-container">
                    <Checkbox
                      name="creators_selected[]"
                      checked={selectedCreators.includes(
                        creator.creator_network.id
                      )}
                      onChange={() => handleSelectCreator(creator)}
                    />
                  </div>
                </td>
                <td className="creator-col">
                  <div className="creator-details">
                    <Link to={`/creator/${creator.creator_id}`}>
                      <img
                        src={creator.creator_network.profile_picture}
                        alt={creator.creator_network.title}
                      />
                    </Link>
                    <div className="infos">
                      <Link
                        to={`/creator/${creator.creator_id}`}
                        className="title"
                      >
                        {creator.creator_network.title}
                      </Link>
                      {creator.creator_network.network !== 'YOUTUBE' && (
                        <Link
                          to={`/creator/${creator.creator_id}`}
                          className="identifier"
                        >
                          {`@${creator.creator_network.identifier}`}
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  {creator.creator_network.permalink ? (
                    <a
                      href={creator.creator_network.permalink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {networksIcons[creator.creator_network.network]()}
                    </a>
                  ) : (
                    networksIcons[creator.creator_network.network]()
                  )}
                </td>
                <td>{creator.added_by?.name}</td>
                <td>{format(new Date(creator.created), 'dd/MM/yyyy')}</td>
                <td>{creator.creator_network?.statistic?.reach}</td>
                <td>
                  {abbreviateNumber(
                    creator.creator_network?.statistic?.fix_all_posts?.aem || 0,
                    3
                  )}
                </td>
                <td>{creator.creator_network?.statistic?.engagement_tax} %</td>
                <td>
                  {abbreviateNumber(creator.creator_network.total_followers, 3)}
                </td>
                <td>
                  <div className="col-comment">
                    <CommentOutlined
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleOpenCommentsModal(creator.creator_network.id)
                      }
                      title="Open comments"
                    />
                    {creator?.logged_user_last_comment && (
                      <span
                        className="logged_user_last_comment"
                        title={creator?.logged_user_last_comment?.comment}
                      >
                        {creator?.logged_user_last_comment?.comment}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <ActionGroup>
                    <DeleteOutlineOutlined
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSingleDelete(creator)}
                    />
                    {renderClickTrackingColumn(creator)}
                    {renderStoriesMonitorColumn(creator)}
                  </ActionGroup>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="row-footer">
              <td colSpan="7" className="totals">
                Totals
                <span className="profiles-count">{`${creators.length} profiles`}</span>
              </td>
              <td>
                {abbreviateNumber(
                  creators
                    .map(
                      (el) =>
                        el?.creator_network?.statistic?.fix_all_posts?.aem || 0
                    )
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue
                    ),
                  3
                )}
              </td>
              <td>
                {abbreviateNumber(
                  creators
                    .map((el) => el.creator_network.total_followers)
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue
                    ),
                  3
                )}
              </td>
              <td />
              <td />
            </tr>
          </tfoot>
        </table>
        <Pagination
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handlePaginate={handlePaginate}
          currentPage={currentPage}
          totalPages={lastPage}
        />
      </>
    );
  };

  const handleExportPdf = () => {
    window.location.href = `${process.env.REACT_APP_API_BE_CAMPAIGN}/v1/campaigns/${campaign.id}/export?format=pdf&access_token=${access_token}`;
  };

  const handleChangeTabActive = (tab) => {
    setTabActive(tab);
  };

  const render = () => {
    if (loading || loadingCreators) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(campaignsDetailRequest(id))}
        />
      );
    }

    if (campaign) {
      return (
        <div className="grid">
          <div className="head">
            <div className="edit-title-container">
              <Link to="/campaigns">
                <div className="icon-wrapper">
                  <NavigateBefore style={{ fontSize: 32 }} />
                </div>
              </Link>
              <div className="infos">
                <InputEditTitle
                  value={campaignTitle}
                  onChange={handleChangeTitle}
                  onBlur={handleBlurTitle}
                />
                <span className="client-name">{campaign.client}</span>
              </div>
            </div>

            <div className="actions-container">
              <div className="buttons-container">
                <Button
                  className={tabActive !== 'list' ? 'negative' : ''}
                  width={110}
                  onClick={() => handleChangeTabActive('list')}
                >
                  LISTS
                </Button>
                <Button
                  className={tabActive !== 'tracking' ? 'negative' : ''}
                  width={130}
                  onClick={() => handleChangeTabActive('tracking')}
                >
                  TRACKING
                </Button>
                <Button
                  className="negative"
                  width={88}
                  onClick={handleExportPdf}
                >
                  <>
                    <SaveAlt style={{ marginRight: 8 }} />
                    PDF
                  </>
                </Button>
                <Button
                  className="negative"
                  width={109}
                  onClick={handleOpenEditCampaignModal}
                >
                  BRIEFING
                </Button>
              </div>
              <div className="search-container">
                <InputSearchCreators
                  isCampaign
                  handleSelectCreator={handleSearchSelectCreator}
                />
              </div>
            </div>
          </div>

          {tabActive === 'list' && (
            <>
              <div className="buttons-container">
                <Button onClick={handleOpenTrackLinkModalToMultiCreators}>
                  <>
                    <LinkOutlined style={{ color: '#fff', marginRight: 8 }} />
                    TRACKING ALL
                  </>
                </Button>
                <Button
                  onClick={() => setModalCopyCreatorsIsOpen(true)}
                  disabled={selectedCreators.length === 0}
                >
                  COPY CREATORS
                </Button>
              </div>

              {renderCreators()}
            </>
          )}

          {tabActive === 'tracking' && <Tracking campaingId={id} />}
        </div>
      );
    }

    return null;
  };

  return (
    <Container>
      {render()}
      <Modal
        width={600}
        height={545}
        isOpen={modalEditCampaignIsOpen}
        handleCloseModal={handleCloseEditCampaignModal}
      >
        <ModalEditCampaign handleClose={handleCloseEditCampaignModal} />
      </Modal>
      <Modal
        width={668}
        height={550}
        isOpen={modalCommentsIsOpen}
        handleCloseModal={handleCloseCommentsModal}
      >
        <ModalComments
          handleClose={handleCloseCommentsModal}
          creatorNetworkId={selectedUniqueCreatorNetworkId}
        />
      </Modal>
      <Modal
        width={400}
        height={220}
        isOpen={modalDeleteIsOpen}
        handleCloseModal={handleCloseDeleteModal}
      >
        <ModalDeleteContent
          loading={loadingDelete}
          error={errorDelete}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleCloseDeleteModal}
        />
      </Modal>
      <Modal
        width={400}
        height={340}
        isOpen={modalTrackLinkMultiIsOpen}
        handleCloseModal={handleCloseTrackLinkModalMulti}
      >
        <ModalTrackLinkMulti
          creatorNetworks={selectedCreatorNetworks}
          handleClose={handleCloseTrackLinkModalMulti}
          campaignId={id}
        />
      </Modal>
      <Modal
        width={1000}
        height={400}
        isOpen={modalTrackLinkSingleIsOpen}
        handleCloseModal={handleCloseTrackLinkModalSingle}
      >
        <ModalTrackLinkSingle
          creatorNetworks={selectedCreatorNetworks}
          campaignId={id}
          handleClose={handleCloseTrackLinkModalSingle}
        />
      </Modal>
      {selectedCreatorId && (
        <Modal
          width={1145}
          height={600}
          isOpen={modalStoriesMonitorIsOpen}
          handleCloseModal={handleCloseModalStoriesMonitor}
        >
          <ModalStoriesMonitor
            handleCloseModal={handleCloseModalStoriesMonitor}
          />
        </Modal>
      )}
      <Modal
        width={1145}
        height={700}
        isOpen={modalCopyCreatorsIsOpen}
        handleCloseModal={() => setModalCopyCreatorsIsOpen(false)}
      >
        <ModalCopyCreators
          handleCloseModal={() => setModalCopyCreatorsIsOpen(false)}
          creatorsNetworksIds={selectedCreatorNetworks}
        />
      </Modal>
    </Container>
  );
}

Detail.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Detail;
