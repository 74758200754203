import React, { useState, useCallback } from 'react';
import {
  GetAppOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import {
  setOrderBy,
  listRequest,
  setStartDate,
  setEndDate,
} from '~/store/modules/campaigns/detail/storiesMonitor/actions';

import Loader from '~/components/Loader';

import { storiesMonitorBaseUrl } from '~/services/api';
import { dateIsValid } from '~/helpers';

import StoryItem from './StoryItem';
import SlideShow from './SlideShow';

import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 135,
  },
}));

function Monitored() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [checkedStories, setCheckedStories] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const [slideShow, setSlideShow] = useState({ show: false, startIndex: 0 });

  const campaign = useSelector((state) => state.campaignsDetail.campaign);
  const orderBy = useSelector((state) => state.storiesMonitor.orderBy);
  const startDate = useSelector(
    (state) => state.storiesMonitor.list.start_date
  );
  const endDate = useSelector((state) => state.storiesMonitor.list.end_date);
  const list = useSelector((state) => state.storiesMonitor.list);
  const stories = showHidden
    ? list.data?.stories.filter((story) => story.show === false)
    : list.data?.stories.filter((story) => story.show === true);

  const selectedCreatorId = useSelector(
    (state) => state.campaignsDetail.selectedCreatorId
  );
  const creatorList = useSelector(
    (state) => state.campaignsDetail.creators_list.data
  );
  const creatorSelected = creatorList.find(
    (item) => item.creator_id === selectedCreatorId
  );

  const handleCheck = useCallback(
    (storyId) => {
      if (checkedStories.includes(storyId)) {
        const filter = checkedStories.filter((id) => id !== storyId);
        setCheckedStories(filter);
      } else {
        setCheckedStories((state) => [...state, storyId]);
      }
    },
    [checkedStories]
  );

  const handleDownload = () => {
    if (checkedStories.length === 0) return false;

    for (let i = 0; i < checkedStories.length; i += 1) {
      const story = list.data.stories.find(
        (item) => item.id === checkedStories[i]
      );

      if (story) {
        window.open(
          `${storiesMonitorBaseUrl}stories/${story.id}/download`,
          '_blank'
        );
      }
    }

    return true;
  };

  const handleToggleShowHidden = () => {
    setShowHidden((state) => !state);
  };

  const handleLoadMoreStories = () => {
    if (list.paginate.loading || list.page === list.last_page) {
      return false;
    }

    dispatch(
      listRequest(creatorSelected.creator_network.id, campaign.data.id, true)
    );

    return true;
  };

  const handleScroll = (e) => {
    const finalScroll =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (finalScroll) {
      handleLoadMoreStories();
    }
  };

  const handleOpenSlide = (startIndex) => {
    setSlideShow({ show: true, startIndex });
  };

  const handleBackSlide = () => {
    setSlideShow({ show: false, startIndex: 0 });
  };

  const handleChangeOrderBy = (event) => {
    dispatch(setOrderBy(event.target.value));
    const start = dateIsValid(startDate) ? startDate : null;
    const end = dateIsValid(endDate) ? endDate : null;

    dispatch(
      listRequest(
        creatorSelected.creator_network.id,
        campaign.data.id,
        false,
        start,
        end
      )
    );
  };

  const startDateOnChange = (e) => {
    dispatch(setStartDate(e.target.value));
  };

  const startDateOnBlur = () => {
    const start = dateIsValid(startDate) ? startDate : null;
    const end = dateIsValid(endDate) ? endDate : null;

    if (start || end || (!start && !end)) {
      dispatch(
        listRequest(
          creatorSelected.creator_network.id,
          campaign.data.id,
          false,
          start,
          end
        )
      );
    }
  };

  const endDateOnChange = (e) => {
    dispatch(setEndDate(e.target.value));
  };

  const endDateOnBlur = () => {
    const start = dateIsValid(startDate) ? startDate : null;
    const end = dateIsValid(endDate) ? endDate : null;

    if (start || end || (!start && !end)) {
      dispatch(
        listRequest(
          creatorSelected.creator_network.id,
          campaign.data.id,
          false,
          start,
          end
        )
      );
    }
  };

  const renderStories = () => {
    if (
      !list.data ||
      (list?.data &&
        list?.data?.stories.length === 0 &&
        startDate === '' &&
        endDate === '')
    ) {
      return <p>Monitored stories will appear here</p>;
    }

    return (
      <>
        <div className="options">
          <div className="filters">
            <FormControl variant="outlined">
              <Select value={orderBy} onChange={handleChangeOrderBy}>
                <MenuItem value="DESC">Oldest first</MenuItem>
                <MenuItem value="ASC">Newer first</MenuItem>
              </Select>
            </FormControl>
            <div className="dates-container">
              <TextField
                id="date-from"
                label="From"
                type="date"
                defaultValue={startDate}
                onChange={startDateOnChange}
                onBlur={startDateOnBlur}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date-to"
                label="To"
                type="date"
                defaultValue={endDate}
                onChange={endDateOnChange}
                onBlur={endDateOnBlur}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div className="buttons">
            <div className="button with-border">
              <GetAppOutlined
                style={{
                  color: '#bfbfbf',
                  cursor: 'pointer',
                }}
                onClick={handleDownload}
              />
            </div>
            <div
              className="button"
              title={
                showHidden
                  ? 'Showing Hidden Stories'
                  : 'Hiding the Hidden Stories'
              }
            >
              {!showHidden ? (
                <VisibilityOffOutlined
                  style={{
                    color: '#bfbfbf',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggleShowHidden}
                />
              ) : (
                <VisibilityOutlined
                  style={{
                    color: '#bfbfbf',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggleShowHidden}
                />
              )}
            </div>
          </div>
        </div>
        <Scrollbars
          onScroll={handleScroll}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '440px',
            marginTop: 25,
          }}
        >
          <div className="stories-list">
            {stories.map((story, index) => (
              <StoryItem
                key={story.id}
                data={story}
                handleCheck={() => handleCheck(story.id)}
                checked={checkedStories.includes(story.id)}
                showHidden={showHidden}
                onClick={() => handleOpenSlide(index)}
              />
            ))}
          </div>
          {list.paginate.loading && <Loader />}
        </Scrollbars>
      </>
    );
  };

  return (
    <Container>
      <div className="head">
        <h2 className="title-section">Monitored Stories</h2>
      </div>
      <div className="stories-content">
        {slideShow.show ? (
          <SlideShow
            showHidden={showHidden}
            handleBack={handleBackSlide}
            startIndex={slideShow.startIndex}
          />
        ) : (
          renderStories()
        )}
      </div>
    </Container>
  );
}

export default Monitored;
