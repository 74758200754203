export const addNewUserActions = {
  ADD_NEW_USER_REQUEST: '@addNewUser/ADD_NEW_USER_REQUEST',
  ADD_NEW_USER_SUCCESS: '@addNewUser/ADD_NEW_USER_SUCCESS',
  ADD_NEW_USER_FAILURE: '@addNewUser/ADD_NEW_USER_FAILURE',
  ADD_NEW_USER_RESET: '@addNewUser/ADD_NEW_USER_RESET',
};

export function addNewUserRequest(data) {
  return {
    type: addNewUserActions.ADD_NEW_USER_REQUEST,
    payload: { data },
  };
}

export function addNewUserSuccess() {
  return {
    type: addNewUserActions.ADD_NEW_USER_SUCCESS,
  };
}

export function addNewUserFailure(message) {
  return {
    type: addNewUserActions.ADD_NEW_USER_FAILURE,
    payload: { message },
  };
}

export function addNewUserReset() {
  return {
    type: addNewUserActions.ADD_NEW_USER_RESET,
  };
}
