import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { selectMenu } from '~/store/modules/menu/actions';

import GroupList from '~/containers/TagGroups/GroupList';
import TagForm from '~/containers/TagGroups/TagForm';
import Footer from '~/containers/Footer';

import { Container, Content } from './styles';

export default function TagGroups() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectMenu('tag-groups'));
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <GroupList />
        <TagForm />
      </Content>
      <Footer />
    </Container>
  );
}
