/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useCallback } from 'react';
import {
  ArrowBackIos,
  ArrowForwardIos,
  GetAppOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons/';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import colorPalette from '~/styles/colorPalette';
import { storiesMonitorBaseUrl } from '~/services/api';

import { toggleStoriesStatusRequest } from '~/store/modules/campaigns/detail/storiesMonitor/actions';

import { Container } from './styles';

function SlideShow({ handleBack, startIndex, showHidden }) {
  const dispatch = useDispatch();

  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const list = useSelector((state) => state.storiesMonitor.list);
  const stories = showHidden
    ? list.data?.stories.filter((story) => story.show === false)
    : list.data?.stories.filter((story) => story.show === true);
  const story = stories[currentIndex];

  const toggleStatus = useSelector(
    (state) => state.storiesMonitor.toggle_stories_status
  );

  const handlePrev = useCallback(() => {
    if (currentIndex - 1 < 0) {
      setCurrentIndex(stories.length - 1);
    } else {
      setCurrentIndex((state) => state - 1);
    }
  }, [currentIndex, stories]);

  const handleNext = useCallback(() => {
    if (currentIndex + 1 > stories.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((state) => state + 1);
    }
  }, [currentIndex, stories]);

  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === 37) {
        handlePrev();
      }
      if (e.keyCode === 39) {
        handleNext();
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [handlePrev, handleNext]);

  const renderMedia = () => {
    if (story.media_type === 'IMAGE') {
      return <img src={story.url} alt="" />;
    }

    if (story.media_type === 'VIDEO') {
      return (
        <video
          key={story.url}
          width="270"
          height="420"
          autoPlay
          onEnded={handleNext}
        >
          <source src={story.url} type="video/mp4" />
        </video>
      );
    }

    return null;
  };

  const handleDownload = () => {
    window.open(
      `${storiesMonitorBaseUrl}stories/${story.id}/download`,
      '_blank'
    );

    return true;
  };

  const handleToggleVisibility = () => {
    if (!toggleStatus.loading) {
      dispatch(toggleStoriesStatusRequest(story.id, !story.show));
    }
  };

  return (
    <Container>
      <div className="options" onClick={handleBack}>
        <div className="back-button">
          <ArrowBackIos
            style={{ color: colorPalette.primary, fontSize: '12px' }}
          />
          <span>BACK</span>
        </div>
        <div className="buttons">
          <div className="button with-border">
            <GetAppOutlined
              style={{
                color: '#bfbfbf',
                cursor: 'pointer',
              }}
              onClick={handleDownload}
            />
          </div>
          <div className="button">
            {!story.show ? (
              <VisibilityOffOutlined
                style={{
                  color: '#bfbfbf',
                  cursor: 'pointer',
                }}
                onClick={handleToggleVisibility}
              />
            ) : (
              <VisibilityOutlined
                style={{
                  color: '#bfbfbf',
                  cursor: 'pointer',
                }}
                onClick={handleToggleVisibility}
              />
            )}
          </div>
        </div>
      </div>
      <div className="slide-content">
        <ArrowBackIos
          style={{ color: '#707070', cursor: 'pointer' }}
          onClick={handlePrev}
        />
        <div className="media-container">{renderMedia()}</div>
        <ArrowForwardIos
          style={{ color: '#707070', cursor: 'pointer' }}
          onClick={handleNext}
        />
      </div>
    </Container>
  );
}

SlideShow.propTypes = {
  handleBack: PropTypes.func.isRequired,
  startIndex: PropTypes.number.isRequired,
  showHidden: PropTypes.bool.isRequired,
};

export default SlideShow;
