import React, { useState, useRef, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import Loader from '~/components/Loader';

import { Container } from './styles';

export default function ModalCreate({
  handleHideModal,
  handleConfirmCreate,
  loading,
}) {
  const [groupName, setGroupName] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  function handleInputChange(event) {
    setGroupName(event.target.value);
  }

  function handleInputKeyUp(e) {
    if (e.keyCode === 13 && groupName.trim().length > 0) {
      handleConfirmCreate(groupName);
    }
  }

  function render() {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="head">
          <div className="icon-wrapper close-modal">
            <MdClose size="30" onClick={() => handleHideModal()} />
          </div>
        </div>
        <div className="modal-description">
          <h3>Name this group</h3>
          <input
            ref={inputRef}
            type="text"
            value={groupName}
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
          />
        </div>
        <div className="modal-options">
          <span onClick={() => handleHideModal()}>CANCEL</span>
          <span onClick={() => handleConfirmCreate(groupName)}>OK</span>
        </div>
      </>
    );
  }
  return <Container>{render()}</Container>;
}

ModalCreate.propTypes = {
  handleHideModal: PropTypes.func.isRequired,
  handleConfirmCreate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
