import styled from 'styled-components';

export const Container = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 2px;
  cursor: pointer;

  &.checked {
    background-color: #818181;
    border: 2px solid transparent;

    .icon-wrapper {
      display: block;
    }
  }

  input {
    display: none;
  }

  .icon-wrapper {
    color: #fff;
    display: none;
  }
`;
