const getElementsArray = (elements) => {
  let index = -1;

  const elementsReplaced = elements.map((item) => {
    index += 1;
    return {
      id: index,
      ...item,
    };
  });

  return elementsReplaced;
};

export const getTableHeaderArray = (headerItens) => {
  return getElementsArray(headerItens);
};

export const getTableBodyArray = (bodyRows) => {
  let index = -1;

  const tableBodyArray = bodyRows.map((row) => {
    index += 1;
    const bodyRow = {
      id: index,
      elements: getElementsArray(row.elements),
    };
    return bodyRow;
  });

  return tableBodyArray;
};
