import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .graphic-label {
    font-size: 12px;
    line-height: 1.31;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 20px;
  }
`;
