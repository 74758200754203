import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiBeCreator } from '~/services/api';

import {
  creatorsQueueActions,
  creatorsQueueSuccess,
  creatorsQueueFailure,
} from './actions';

export function* getCreatorsQueue({ payload }) {
  const { mine, isPaginate } = payload;
  try {
    const page = yield select((state) => state.creatorsQueue.page);

    const limit = 20;

    const params = {
      mine: mine ? 1 : 0,
      page: isPaginate ? page + 1 : page,
      limit,
    };

    const response = yield call(apiBeCreator.get, 'collect', { params });

    const { data } = response;

    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(creatorsQueueSuccess(data, lastPage, isPaginate));
  } catch (err) {
    yield put(creatorsQueueFailure(isPaginate));
  }
}

export default all([
  takeLatest(creatorsQueueActions.CREATORS_QUEUE_REQUEST, getCreatorsQueue),
]);
