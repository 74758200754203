import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { signInRequest } from '~/store/modules/auth/actions';

import ColLeftImage from '~/assets/images/SignIn/col-left-image.png';

import { Container, ColLeft, ColRight, ButtonSubmit } from './styles';

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const message = useSelector((state) => state.auth.message);

  return (
    <Container>
      <ColLeft>
        <div className="content">
          <h1>Bell</h1>
          <img src={ColLeftImage} alt="Social" />
        </div>
      </ColLeft>
      <ColRight>
        <div className="container-content">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values) => {
              dispatch(signInRequest(values.email, values.password));
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <h3>Login</h3>
                <input
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {message && <div className="form-response">{message}</div>}
                <ButtonSubmit onClick={() => false}>
                  {loading ? 'LOADING...' : 'LOGIN'}
                </ButtonSubmit>
                <Link to="/forgot-password" className="forgot-password">
                  Forgot password?
                </Link>
              </form>
            )}
          </Formik>
        </div>
      </ColRight>
    </Container>
  );
}
