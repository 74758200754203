import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => `${props.width}px`};

  input[type='text'] {
    border: none;
    font-family: 'Rubik';
    font-size: 34.5px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #000000;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  .overlap-title-edit {
    display: ${(props) => (props.active ? 'none' : 'flex')};
    flex-direction: row;
    align-items: center;

    .page-title {
      margin: 0;
      margin-right: 15px;
      font-family: 'Rubik';
      font-size: 34.5px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: #000000;
      cursor: text;
    }

    svg {
      cursor: pointer;
    }
  }
`;
