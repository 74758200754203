import styled from 'styled-components';

const setColor = (lightTheme) => (lightTheme ? '#fff' : '#f5f5f5');

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${(props) => `${props.height}px`};
  padding: 13px 16px;
  border-radius: 10px;
  background-color: ${(props) => setColor(props.lightTheme)};

  input {
    display: flex;
    flex: 1;
    border: none;
    background-color: ${(props) => setColor(props.lightTheme)};
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.38);
  }
`;
