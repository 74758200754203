import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 8px 32px 48px;

  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;

    .title-section {
      font-family: 'Rubik';
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      color: ${colorPalette.text};
    }
  }
`;
