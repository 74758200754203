import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 600px;
    position: absolute;
    top: 0;

    &.prev {
      left: -100px;
    }

    &.next {
      right: -100px;
    }

    .icon-wrapper {
      cursor: pointer;
      color: #fff;
    }
  }

  .picture {
    width: 480px;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #ccc;
  }

  .infos {
    display: flex;
    flex-direction: column;
    flex: 1;

    .head {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 30px 20px 20px;

      h3 {
        font-size: 34.5px;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.87);
        font-family: 'Rubik';
      }

      .icon-wrapper {
        position: absolute;
        top: -5px;
        right: 0;

        &.close-modal {
          cursor: pointer;
        }
      }
    }

    .metrics {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background-color: #f5f5f5;
      margin: 0 20px;

      .metric-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;

        &.border {
          border-bottom: 1px solid #dbdbdb;
        }

        .metric-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }

          &.publipost {
            margin-right: 60px;
          }

          &.external-link {
            .icon-wrapper {
              color: rgba(0, 0, 0, 0.6);
            }
          }

          span {
            font-size: 14px;
            letter-spacing: 0.4px;
            color: rgba(0, 0, 0, 0.6);
            margin-left: 5px;
          }
        }
      }
    }

    .profile-last-update {
      text-align: right;
      margin: 0 20px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 5px;
    }

    .description {
      margin: 30px 20px;
      flex: 1;

      .title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 20px;
        overflow-wrap: break-word;
      }
    }

    .expected-current-engagement {
      margin: 0 20px 20px;

      h3 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 16px;
        font-family: 'Rubik';
        letter-spacing: 0.25px;
      }

      .row-engagement {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          color: rgba(0, 0, 0, 0.6);
        }

        .container-bar {
          margin-top: 5px;

          .inside-bar {
            background: #59e582;
            border-radius: 10px;
            height: 5px;
          }
          .engagement-value {
            margin-top: 5px;
            font-size: 12px;
            text-align: right;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
`;
