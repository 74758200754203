import produce from 'immer';
import types from '../../types';

export const INITIAL_STATE = {
  title: 'Name this overlap',
  save: {
    loading: false,
    successMessage: false,
    saved: false,
    error: false,
  },
  update: {
    getInfluencers: {
      data: null,
      loading: false,
      error: false,
    },
  },
  delete: {
    loading: false,
    completedDelete: 0,
    error: false,
  },
};

export default function overlapNew(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.OVERLAP_SET_TITLE: {
        draft.title = action.payload.title;
        break;
      }
      case types.OVERLAP_NEW_CLEAR_LIST: {
        draft.title = 'Name this overlap';
        draft.save.saved = false;
        draft.save.successMessage = false;
        draft.update.getInfluencers.data = null;
        break;
      }

      case types.OVERLAP_NEW_SAVE_REQUEST: {
        draft.save.loading = true;
        draft.save.error = false;
        draft.save.saved = false;
        break;
      }
      case types.OVERLAP_NEW_SAVE_SUCCESS: {
        draft.save.loading = false;
        draft.save.successMessage = true;
        draft.save.saved = true;
        break;
      }
      case types.OVERLAP_NEW_SAVE_FAILURE: {
        draft.save.loading = false;
        draft.save.error = true;
        break;
      }
      case types.OVERLAP_EDIT_GET_INFLUENCERS_REQUEST: {
        draft.update.getInfluencers.loading = true;
        draft.update.getInfluencers.error = false;
        draft.save.saved = false;
        break;
      }
      case types.OVERLAP_EDIT_GET_INFLUENCERS_SUCCESS: {
        draft.update.getInfluencers.loading = false;
        draft.update.getInfluencers.data = action.payload.data;
        draft.title = action.payload.data.title;
        break;
      }
      case types.OVERLAP_EDIT_GET_INFLUENCERS_FAILURE: {
        draft.update.getInfluencers.loading = false;
        draft.update.getInfluencers.data = null;
        draft.update.getInfluencers.error = true;
        break;
      }
      case types.OVERLAP_NEW_DELETE_REQUEST: {
        draft.delete.loading = true;
        draft.delete.error = false;
        break;
      }
      case types.OVERLAP_NEW_DELETE_SUCCESS: {
        draft.delete.completedDelete += 1;
        draft.delete.loading = false;
        draft.delete.error = false;
        break;
      }
      case types.OVERLAP_NEW_DELETE_FAILURE: {
        draft.delete.loading = false;
        draft.delete.error = true;
        break;
      }
      default:
    }
  });
}
