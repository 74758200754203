import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';

import colorPalette from '~/styles/colorPalette';

import { Container } from './styles';

export default function ToggleFilter({ title, info, className, children }) {
  const [active, setActive] = useState(false);

  return (
    <Container className={className}>
      <div className="row-dropdown">
        <strong onClick={() => setActive(!active)}>
          {!active ? (
            <IoMdArrowDropright
              size="20"
              style={{ position: 'relative', top: '5px' }}
            />
          ) : (
            <IoMdArrowDropdown
              size="20"
              style={{ position: 'relative', top: '5px' }}
            />
          )}
          {title}
        </strong>
        {info.length > 0 && (
          <AiOutlineQuestionCircle
            style={{
              position: 'relative',
              top: '-3px',
              left: '5px',
              color: colorPalette.primary,
            }}
            size="18"
            title={info}
          />
        )}
      </div>
      {active && children}
    </Container>
  );
}

ToggleFilter.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

ToggleFilter.defaultProps = {
  info: '',
  className: '',
};
