import { takeLatest, put, all, select, call } from 'redux-saga/effects';

import { apiBeTracking } from '~/services/api';
import { serviceWrapperSaga } from '~/helpers';

import {
  trackingLinkActions,
  registerSuccess,
  registerFailure,
  listSuccess,
  listFailure,
  toggleStoriesStatusSuccess,
  toggleStoriesStatusFailure,
  totalClicksError,
  totalClicksSuccess,
} from './actions';

export function* register({ payload }) {
  const dataList = yield select((state) => state.trackingLink.list.data);

  const method = dataList?.id ? apiBeTracking.patch : apiBeTracking.post;

  const url = dataList?.id ? `destinies/${dataList.id}` : 'destinies';

  const response = yield serviceWrapperSaga(method, url, payload);

  if (response.status >= 400) {
    yield put(registerFailure(response.data.message));
    return;
  }

  yield put(
    registerSuccess(response.headers.etag, payload.start_time, payload.end_time)
  );
}

export function* fetch({ payload }) {
  const { campaignId, isPaginate, page } = payload;
  const orderBy = yield select((state) => state.trackingLink.orderBy);
  const list = yield select((state) => state.trackingLink.list);
  const limit = 10;
  const params = {
    page: Number(page),
    limit,
    campaign_id: campaignId,
    order_by: `published_at ${orderBy}`,
  };

  const response = yield serviceWrapperSaga(apiBeTracking.get, 'destinies', {
    params,
  });

  if (response.status >= 500) {
    yield put(listFailure(isPaginate));
  }

  if (response.status === 404) {
    yield put(listSuccess(null, list.last_page, isPaginate));
  } else {
    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);
    const total = response.headers['x-total-count'];
    yield put(listSuccess(response.data, lastPage, isPaginate, total));
  }
}

export function* getTotalClicks({ payload }) {
  try {
    const { campaignId } = payload;

    const response = yield call(
      apiBeTracking.get,
      `destinies/total_clicks?campaign_id=${campaignId}`
    );

    yield put(totalClicksSuccess(response.data));
  } catch (error) {
    yield put(totalClicksError());
  }
}

export function* toggleStoriesStatus({ payload }) {
  try {
    const { storyId, show } = payload;

    yield call(apiBeTracking.patch, `stories/${storyId}`, { show });

    yield put(toggleStoriesStatusSuccess(storyId, show));
  } catch (error) {
    yield put(toggleStoriesStatusFailure());
  }
}

export default all([
  takeLatest(trackingLinkActions.REGISTER_REQUEST, register),
  takeLatest(trackingLinkActions.LIST_REQUEST, fetch),
  takeLatest(
    trackingLinkActions.TOGGLE_STORIES_STATUS_REQUEST,
    toggleStoriesStatus
  ),
  takeLatest(trackingLinkActions.TOTAL_CLICKS_REQUEST, getTotalClicks),
]);
