import produce from 'immer';
import { campaignsNewActions } from './actions';

export const INITIAL_STATE = {
  loading: false,
  success: false,
  error: '',
};

export default function campaignsNew(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case campaignsNewActions.CAMPAIGNS_NEW_REQUEST: {
        draft.loading = true;
        draft.success = false;
        draft.error = '';
        break;
      }
      case campaignsNewActions.CAMPAIGNS_NEW_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        break;
      }
      case campaignsNewActions.CAMPAIGNS_NEW_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case campaignsNewActions.CAMPAIGNS_NEW_RESET: {
        draft.loading = false;
        draft.success = false;
        draft.error = '';
        break;
      }
      default:
    }
  });
}
