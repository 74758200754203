import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosMenu } from 'react-icons/io';
import {
  ErrorOutline,
  Autorenew,
  AccountCircleOutlined,
  PermIdentityOutlined,
  LockOutlined,
  ExitToAppOutlined,
} from '@material-ui/icons/';

import { signOut } from '~/store/modules/auth/actions';
import { menuOpen, menuClose } from '~/store/modules/menu/actions';
import { creditsRequest } from '~/store/modules/credits/actions';

import Menu from '~/containers/Header/Menu';
import Loader from '~/components/Loader';

import Campaigns from './Campaigns';
import CreatorsQueue from './CreatorsQueue';

import { Container } from './styles';
import colorPalette from '~/styles/colorPalette';
import { useOutsideAlerter } from '~/helpers';

export default function Header() {
  const userMenuRef = useRef(null);

  const dispatch = useDispatch();

  const [showUserMenu, setShowUserMenu] = useState(false);

  const { data: profile } = useSelector((state) => state.profile);

  const { active: menuIsActive, menuSelectedSlug } = useSelector(
    (state) => state.menu
  );

  const { creditsData, creditsLoading, creditsError } = useSelector(
    (state) => state.credits
  );

  const menuList = [
    { slug: 'search', name: 'search', style: {} },
    {
      slug: 'overlap',
      name: 'overlap',
      style: {},
    },
    { slug: 'campaigns', name: 'campaigns', style: {} },
  ];

  useOutsideAlerter(userMenuRef, () => {
    setShowUserMenu(false);
  });

  function handleToggleMenu() {
    if (menuIsActive) {
      dispatch(menuClose());
    } else {
      dispatch(menuOpen());
    }
  }

  function handleLogout() {
    dispatch(signOut());
  }

  return (
    <>
      <Menu />
      <Container>
        <div className="box-menu-logo">
          <div className="icon-wrapper" onClick={() => handleToggleMenu()}>
            <IoIosMenu size="35" />
          </div>
          <Link to="/search" className="main-link">
            Bell
          </Link>
        </div>
        <nav className="box-navigation">
          {menuList.map((row) => (
            <div
              key={Math.random()}
              className={`nav-item ${
                menuSelectedSlug === row.slug ? 'selected' : ''
              }`}
            >
              <Link to={`/${row.slug}`} style={row.style} className="link">
                {row.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="box-options">
          <CreatorsQueue />
          <Campaigns />
          <div className="container-credits">
            <div className="amount">
              {creditsLoading && <Loader />}
              {creditsData && creditsData?.balance && creditsData.balance}
              {creditsError && (
                <div className="error">
                  <div className="error-description">
                    <ErrorOutline
                      style={{
                        color: '#f15750',
                        fontSize: 20,
                      }}
                    />
                    <div>Unable to load your credits, please try again</div>
                  </div>
                  <Autorenew
                    onClick={() => dispatch(creditsRequest())}
                    style={{
                      color: colorPalette.primary,
                      fontSize: 20,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              )}
            </div>
            <span>CREDITS</span>
          </div>
          <div className="user-infos">
            <PermIdentityOutlined
              style={{ fontSize: 35, cursor: 'pointer', color: '#757575' }}
              onClick={() => setShowUserMenu(true)}
            />
            {showUserMenu && (
              <div className="float-user-menu" ref={userMenuRef}>
                <div className="infos-container">
                  <div className="user-icon">
                    <AccountCircleOutlined style={{ fontSize: 35 }} />
                  </div>
                  <div className="infos-content">
                    <span className="name" title={profile?.name}>
                      {profile?.name}
                    </span>
                    <span
                      className="organization"
                      title={profile?.organization?.name}
                    >
                      {profile?.organization?.name}
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <Link
                      to="/change-password"
                      className={`content-nav ${
                        menuSelectedSlug === 'change-password' && 'active'
                      }`}
                    >
                      <div className="icon-wrapper">
                        <LockOutlined />
                      </div>
                      <span>Change password</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/add-new-user"
                      className={`content-nav ${
                        menuSelectedSlug === 'add-new-user' && 'active'
                      }`}
                    >
                      <div className="icon-wrapper">
                        <PermIdentityOutlined />
                      </div>
                      <span>Add new user</span>
                    </Link>
                  </li>
                  <li>
                    <div className="content-nav" onClick={handleLogout}>
                      <div className="icon-wrapper">
                        <ExitToAppOutlined />
                      </div>
                      <span>Logout</span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
