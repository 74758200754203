import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import TextareaAutosize from 'react-textarea-autosize';

import {
  campaignsDetailCommentsListRequest,
  campaignsDetailCommentsAddRequest,
  campaignsDetailCommentsEditRequest,
} from '~/store/modules/campaigns/detail/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import Comment from './Comment';

import { Container } from './styles';

function ModalComments({ handleClose, creatorNetworkId, campaignId }) {
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');

  const {
    data: comments,
    loading: loadingCommentsList,
    error: errorCommentsList,
  } = useSelector((state) => state.campaignsDetail.comments_list);

  const { loading: loadingCommentsAdd, error: errorCommentsAdd } = useSelector(
    (state) => state.campaignsDetail.comments_add
  );

  useEffect(() => {
    if (creatorNetworkId > 0) {
      dispatch(
        campaignsDetailCommentsListRequest(creatorNetworkId, campaignId)
      );
    }
  }, [dispatch, creatorNetworkId, campaignId]);

  const handleInputCommentChange = useCallback((e) => {
    setComment(e.target.value.replace(/\n/g, ''));
  }, []);

  const handleInputCommentKeyPress = useCallback(
    (e) => {
      const code = e.keyCode || e.which;
      if (code === 13 && comment.trim().length > 0 && !e.shiftKey) {
        dispatch(
          campaignsDetailCommentsAddRequest(
            creatorNetworkId,
            comment,
            campaignId
          )
        );
        setComment('');
      }
    },
    [campaignId, comment, creatorNetworkId, dispatch]
  );

  const handleCommentEdit = useCallback(
    (commentId, newComment, oldComment) => {
      dispatch(
        campaignsDetailCommentsEditRequest(
          creatorNetworkId,
          commentId,
          newComment,
          oldComment,
          campaignId
        )
      );
    },
    [campaignId, creatorNetworkId, dispatch]
  );

  const render = () => {
    if (loadingCommentsList) {
      return <Loader />;
    }

    if (errorCommentsList) {
      return (
        <LoadingDataErrorHandling
          refreshData={() =>
            dispatch(campaignsDetailCommentsListRequest(creatorNetworkId))
          }
        />
      );
    }

    return (
      <div className="center-content">
        <div className="list-comments">
          <h3>Comments</h3>
          {comments.length === 0 && (
            <p className="no-comments">No comments added yet</p>
          )}
          {comments.length > 0 && (
            <div className="scroll-container">
              <Scrollbars
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                {comments.map((row) => (
                  <Comment
                    key={row.id}
                    data={row}
                    handleCommentEdit={handleCommentEdit}
                    campaignId={campaignId}
                  />
                ))}
              </Scrollbars>
            </div>
          )}
        </div>

        {loadingCommentsAdd && <span className="loading">Loading...</span>}
        {errorCommentsAdd && (
          <span className="error">
            There was an error inserting the comment, please try again
          </span>
        )}
        <TextareaAutosize
          maxRows={2}
          placeholder="Add a comment"
          onChange={handleInputCommentChange}
          onKeyPress={handleInputCommentKeyPress}
          value={comment}
        />
      </div>
    );
  };

  return (
    <Container>
      <div className="container-close">
        <div className="button-close" onClick={handleClose}>
          <CloseOutlinedIcon style={{ color: '#818181' }} />
        </div>
      </div>
      {render()}
    </Container>
  );
}

ModalComments.defaultProps = {
  campaignId: false,
};

ModalComments.propTypes = {
  handleClose: PropTypes.func.isRequired,
  creatorNetworkId: PropTypes.number.isRequired,
  campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ModalComments;
