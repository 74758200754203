/* eslint-disable prefer-destructuring */
import { takeLatest, put, all, select, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import { apiBeCreator, creatorBaseUrl } from '~/services/api';
import { searchNewTypes, searchSuccess, searchFailure } from './actions';

export function* searchInstagram(isPaginate = false) {
  const cancelTokenSource = axios.CancelToken.source();

  const network = yield select((state) => state.search.network_selected);

  try {
    const { page, fields } = yield select((state) => state.search[network]);

    const limit = 20;

    const params = { page: isPaginate ? page + 1 : page, limit };

    const terms = fields.keywords.value.join(',');

    if (terms.length > 0) {
      params.terms = terms;
    }

    if (fields.onlyBio.value) {
      params.in_bio_only = fields.onlyBio.value;
    }

    if (fields.keywordsRemoved.value.length > 0) {
      const exceptTerms = fields.keywordsRemoved.value.join(',');

      params.except_terms = exceptTerms;
    }

    const posts_published_at = {};

    if (fields.periodFrom.value.replace('_', '').length === 10) {
      const splitPeriodFrom = fields.periodFrom.value.split('/');
      const convertedPeriodFrom = `${splitPeriodFrom[2]}-${splitPeriodFrom[1]}-${splitPeriodFrom[0]}`;
      posts_published_at.start = convertedPeriodFrom;
    }

    if (fields.periodTo.value.replace('_', '').length === 10) {
      const splitPeriodTo = fields.periodTo.value.split('/');
      const convertedPeriodTo = `${splitPeriodTo[2]}-${splitPeriodTo[1]}-${splitPeriodTo[0]}`;
      posts_published_at.end = convertedPeriodTo;
    }

    if (posts_published_at?.start || posts_published_at?.end) {
      params.posts_published_at = posts_published_at;
    }

    if (fields.regionInfluencerPostMore.value.length > 0) {
      params.posts_region = fields.regionInfluencerPostMore.value;
    }

    const audience_region = {};

    if (fields.audienceRegion.value.length > 0) {
      audience_region.name = fields.audienceRegion.value;
    }

    if (fields.audienceRegionLimit.value > 0) {
      audience_region.percentage = fields.audienceRegionLimit.value;
    }

    if (audience_region?.name || audience_region?.percentage) {
      params.audience_region = audience_region;
    }

    const gender = {};

    if (fields.audienceGender.value !== 'all') {
      gender.gender = fields.audienceGender.value;
    }

    if (fields.audienceGenderLimit.value > 0) {
      gender.percentage = fields.audienceGenderLimit.value;
    }

    if (gender?.gender || gender?.percentage) {
      params.gender = gender;
    }

    const age = {};

    if (fields.audienceAge.value !== 'all') {
      const splited = fields.audienceAge.value.split('-');
      age.start = splited[0];
      age.end = splited[1];
    }

    if (fields.audienceAgeLimit.value > 0) {
      age.percentage = fields.audienceAgeLimit.value;
    }

    if ((age.start && age.end) || age.percentage) {
      params.age = age;
    }

    const aem = {};

    if (fields.aemMin.value.length > 0) {
      aem.start = fields.aemMin.value;
    }

    if (fields.aemMax.value.length > 0) {
      aem.end = fields.aemMax.value;
    }

    if (aem?.start || aem?.end) {
      params.aem = aem;
    }

    const followers = {};

    if (fields.followersMin.value.length > 0) {
      followers.start = fields.followersMin.value;
    }

    if (fields.followersMax.value.length > 0) {
      followers.end = fields.followersMax.value;
    }

    if (followers?.start || followers?.end) {
      params.followers = followers;
    }

    const response = yield axios.request({
      method: 'get',
      url: `${creatorBaseUrl}search`,
      cancelToken: cancelTokenSource.token,
      headers: {
        Authorization: apiBeCreator.defaults.headers.Authorization,
      },
      params,
    });

    const { data } = response;

    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(searchSuccess(data, lastPage, isPaginate));
  } catch (err) {
    yield put(searchFailure(isPaginate));
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* search({ payload }) {
  const { isPaginate } = payload;
  const network = yield select((state) => state.search.network_selected);

  const refs = {
    instagram: (is_paginate) => searchInstagram(is_paginate),
  };

  yield refs[network](isPaginate);
}

export default all([takeLatest(searchNewTypes.SEARCH_REQUEST, search)]);
