/* eslint-disable import/no-cycle */
import axios from 'axios';

const apiTocSov = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_TOCSOV}/v1/`,
});

const apiOverlap = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_OVERLAP}/v1/`,
});

const creatorBaseUrl = `${process.env.REACT_APP_API_BE_CREATOR}/v1/`;

const apiBeCreator = axios.create({
  baseURL: creatorBaseUrl,
});

const apiBeAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_AUTH}`,
});

const apiBeUser = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_AUTH}/v1/`,
});

const apiBeCampaign = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_CAMPAIGN}/v1/`,
});

const apiBeTracking = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_TRACKING}/v1/`,
});

const apiBeTrackingPosts = axios.create({
  baseURL: `${process.env.REACT_APP_API_BE_TRACKING_POSTS}/v1/`,
});

const storiesMonitorBaseUrl = `${process.env.REACT_APP_API_BE_STORIES_MONITOR}/v1/`;

const apiBeStoriesMonitor = axios.create({
  baseURL: storiesMonitorBaseUrl,
});

export {
  apiTocSov,
  apiOverlap,
  creatorBaseUrl,
  apiBeCreator,
  apiBeAuth,
  apiBeUser,
  apiBeCampaign,
  apiBeTracking,
  apiBeTrackingPosts,
  apiBeStoriesMonitor,
  storiesMonitorBaseUrl,
};
