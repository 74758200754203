import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdClose, MdDone } from 'react-icons/md';

import {
  tagGroupsUpdateRequest,
  tagGroupsListSelectItem,
} from '~/store/modules/tagGroups/actions';

import Loader from '~/components/Loader';

import { Container } from './styles';

export default function TagForm() {
  const dispatch = useDispatch();

  const [tagName, setTagName] = useState('');

  const updateLoading = useSelector((state) => state.tagGroups.update.loading);
  const updateSaved = useSelector((state) => state.tagGroups.update.saved);
  const confirmTagCreate = useSelector(
    (state) => state.tagGroups.update.confirmTagCreate
  );

  const listData = useSelector((state) => state.tagGroups.list.data);
  const groupSelectedId = useSelector(
    (state) => state.tagGroups.list.groupSelectedId
  );
  const groupSelectedList = listData.filter(
    (row) => row.id === groupSelectedId
  );
  const groupSelected = groupSelectedList[0];

  useEffect(() => {
    dispatch(tagGroupsListSelectItem(null));
  }, [dispatch]);

  useEffect(() => {
    setTagName('');
  }, [confirmTagCreate]);

  function handleInputChange(e) {
    setTagName(e.target.value);
  }

  function handleInputKeyUp(e) {
    if (e.keyCode === 13 && tagName.trim().length > 0) {
      const { name, created, organization, id, tags, updated_at } =
        groupSelected;

      const newTags = tags ? [...tags, { name: tagName }] : [{ name: tagName }];

      dispatch(
        tagGroupsUpdateRequest(
          {
            name,
            created,
            organization,
            id,
            tags: newTags,
            updated_at,
          },
          true
        )
      );
    }
  }

  function handleDeleteTag(indexRemove) {
    const { name, created, organization, id, tags, updated_at } = groupSelected;

    const newTags = tags.filter((value, index) => index !== indexRemove);

    dispatch(
      tagGroupsUpdateRequest(
        {
          name,
          created,
          organization,
          id,
          tags: newTags,
          updated_at,
        },
        true
      )
    );
  }

  return (
    <Container>
      <div className="content">
        <h2>
          {groupSelectedId
            ? `Enter tags for ${groupSelected.name}`
            : 'Enter tags'}
        </h2>
        <div className="row-input">
          <input
            type="text"
            placeholder={
              groupSelectedId
                ? `Enter tags for ${groupSelected.name}`
                : 'Enter tags'
            }
            value={tagName}
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
            disabled={!groupSelectedId}
          />
          {updateLoading && <Loader />}
          {updateSaved && (
            <div className="icon-wrapper done-tasks">
              <MdDone size="40" />
            </div>
          )}
        </div>
        <div className="tag-list">
          {groupSelectedId &&
            groupSelected?.tags?.length > 0 &&
            groupSelected?.tags.map((tag, index) => (
              <div className="tag-list-item" key={Math.random()}>
                <div className="keyword">{tag.name}</div>
                <div className="button-delete-tag-item">
                  <div
                    className="icon-wrapper"
                    onClick={() => handleDeleteTag(index)}
                  >
                    <MdClose size="14" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  );
}
