import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { toggleStoriesStatusRequest } from '~/store/modules/campaigns/detail/storiesMonitor/actions';

import Checkbox from '~/components/Checkbox';

import { Container } from './styles';

function StoryItem({ data, handleCheck, checked, showHidden, onClick }) {
  const dispatch = useDispatch();

  const toggleStatus = useSelector(
    (state) => state.storiesMonitor.toggle_stories_status
  );

  const handleToggleVisibility = () => {
    if (!toggleStatus.loading) {
      dispatch(toggleStoriesStatusRequest(data.id, !data.show));
    }
  };

  if (!showHidden && !data.show) {
    return null;
  }

  return (
    <Container>
      <div className="options">
        {data.show ? (
          <VisibilityOutlined
            style={{
              color: '#fff',
              cursor: 'pointer',
            }}
            onClick={handleToggleVisibility}
          />
        ) : (
          <VisibilityOffOutlined
            style={{
              color: '#fff',
              cursor: 'pointer',
            }}
            onClick={handleToggleVisibility}
          />
        )}
        <Checkbox
          name="storyItems[]"
          borderColor="#fff"
          onClick={handleCheck}
          checked={checked}
        />
      </div>
      <div
        onClick={onClick}
        className="thumbnail"
        style={{
          backgroundImage: `url(${data.thumb})`,
        }}
      />
      <div className="datetime">
        {format(new Date(data.published_at), 'dd/MM/yyyy | HH:ii')}
      </div>
    </Container>
  );
}

StoryItem.propTypes = {
  data: PropTypes.shape({
    thumb: PropTypes.string,
    published_at: PropTypes.string,
  }).isRequired,
  handleCheck: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  showHidden: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StoryItem;
