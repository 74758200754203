import React from 'react';
import PropTypes from 'prop-types';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Container, RotateIcon } from './styles';

export default function Button({ onClick, children, width, loading, ...rest }) {
  return (
    <Container
      className={loading ? 'loading' : ''}
      onClick={onClick}
      width={width}
      {...rest}
    >
      {loading && (
        <RotateIcon>
          <AutorenewIcon style={{ marginRight: 8 }} />
        </RotateIcon>
      )}
      {children}
    </Container>
  );
}

Button.defaultProps = {
  width: 200,
  loading: false,
};

Button.propTypes = {
  width: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  loading: PropTypes.bool,
};
