import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';

import { statisticsRequest } from '~/store/modules/creator/statistics/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { Container } from './styles';

export default function Engagement({ typePost }) {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector(
    (state) => state.creatorStatistics
  );

  const networkIndexSelected = useSelector(
    (state) => state.creator.tabs.networkIndexSelected
  );

  const refType = {
    allPosts: 'all_posts',
    adPosts: 'ad_posts',
  };

  useEffect(() => {
    dispatch(statisticsRequest());
  }, [dispatch, networkIndexSelected]);

  const render = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(statisticsRequest())}
        />
      );
    }

    if (data) {
      const statistics = data[refType[typePost]] || [];

      const labels = ['7 days', '15 days', '30 days', '90 days'];

      const dataAem = [];

      for (let i = 0; i < statistics.length; i += 1) {
        dataAem.push(statistics[i] ? parseInt(statistics[i].aem, 10) : 0);
      }

      const dataChart = {
        labels,
        datasets: [
          {
            label: 'Average Engagement',
            backgroundColor: 'rgba(118,36,232,0.2)',
            borderColor: 'rgba(118,36,232,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(118,36,232,0.4)',
            hoverBorderColor: 'rgba(118,36,232,1)',
            data: dataAem,
          },
        ],
      };

      return (
        <>
          <div>
            <Bar
              data={dataChart}
              width={490}
              height={320}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
              }}
            />
          </div>
          <div className="graphic-label">
            * Average engagement over the period, considering the total number
            of engagements to date.
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Container>
      <div className="box-title">AE History (Average Engagement)</div>
      {render()}
    </Container>
  );
}

Engagement.propTypes = {
  typePost: PropTypes.string.isRequired,
};
