import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 8px 8px 40px;
  height: 100%;
  border-radius: 4px;

  .container-close {
    display: flex;
    justify-content: flex-end;

    .button-close {
      color: #818181;
      height: 24px;
      cursor: pointer;
    }
  }

  .center-content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .list-comments {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 572px;
    margin-bottom: 20px;

    h3 {
      font-family: 'Rubik';
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.17px;
      color: #000000;
      margin-bottom: 5px;
      margin-bottom: 24px;
    }

    .scroll-container {
      width: 572px;
      height: 330px;

      textarea {
        margin-top: 10px;
        width: 100%;
      }
    }

    .no-comments {
      line-height: 1.37;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .loading {
    color: ${colorPalette.primary};
  }

  .error {
    color: red;
  }

  textarea {
    width: 572px;
    height: 42px;
    padding: 8px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    resize: none;
  }
`;
