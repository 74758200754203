import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { sendEmailRequest } from '~/store/modules/forgotPassword/request/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { validateEmail } from '~/helpers';

import ColLeftImage from '~/assets/images/Invite/col-left-image.png';
import MessageSent from '~/assets/images/ForgotPassword/message-sent.png';

import { Container, ColLeft, ColRight, ButtonSubmit } from './styles';

function Request() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { loading, success, error } = useSelector(
    (state) => state.forgotPasswordRequest
  );

  const handleInputEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid e-mail');
      return;
    }

    dispatch(sendEmailRequest(email));
  };

  const renderFooterContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(sendEmailRequest(email))}
        />
      );
    }

    return (
      <ButtonSubmit onClick={handleSubmit}>send instructions</ButtonSubmit>
    );
  };

  const renderContentForm = () => {
    if (success) {
      return (
        <>
          <h3>Check your mail</h3>
          <img src={MessageSent} alt="Message Sent" className="message-sent" />
          <h4>
            We have sent you a password recover instructions to your email.
          </h4>
        </>
      );
    }

    return (
      <>
        <h3>Forgot password?</h3>
        <h4>
          Enter the email address associated with your account and we’ll send an
          email with instructions to reset your password.
        </h4>
        <div className="row">
          <div className="row">
            <input
              type="email"
              name="email"
              placeholder="E-mail address"
              value={email}
              onChange={handleInputEmailChange}
            />
            {emailError.length > 0 && <span>{emailError}</span>}
          </div>
          <div className="footer-form-container">{renderFooterContent()}</div>
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <>
        <ColLeft>
          <div className="content">
            <h1>Bell</h1>
            <img src={ColLeftImage} alt="Social" />
          </div>
        </ColLeft>
        <ColRight>
          <div className="container-content">{renderContentForm()}</div>
        </ColRight>
      </>
    );
  };

  return <Container>{render()}</Container>;
}

export default Request;
