import React from 'react';

import { Container } from './styles';

export default function Loader() {
  return (
    <Container>
      <div className="loadingio-spinner-rolling-c79fgkrc9id">
        <div className="ldio-2kuacvfnrbd">
          <div />
        </div>
      </div>
    </Container>
  );
}
