import { takeLatest, call, put, all } from 'redux-saga/effects';
import types from '../../types';

import { apiBeCreator } from '~/services/api';

import { creatorSuccess, creatorFailure } from './actions';

export function* getProfile({ payload }) {
  try {
    const { creatorId } = payload;

    const response = yield call(apiBeCreator.get, `creators/${creatorId}`);

    const { data } = response;

    yield put(creatorSuccess(data));
  } catch (err) {
    yield put(creatorFailure());
  }
}

export default all([takeLatest(types.CREATOR_REQUEST, getProfile)]);
