import styled from 'styled-components';

export const Container = styled.div`
  .head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0;

    .edit-title-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .infos {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        .client-name {
          font-family: Rubik;
          font-size: 12.2px;
          line-height: 1.31;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.6);
          margin-top: 5px;
        }
      }

      .button-edit-title {
        cursor: pointer;
        margin-left: 15px;
      }
    }

    .actions-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 40px;

        .button-pdf {
          margin-right: 16px;
        }
      }

      .search-container {
        width: 320px;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;

    & > * {
      margin-right: 10px;
    }
  }

  .container-empty {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 238px;
      height: 280px;
    }

    p {
      width: 312px;
      font-family: 'Rubik';
      font-size: 16.3px;
      font-weight: 500;
      line-height: 1.48;
      letter-spacing: 0.15px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      margin: 24px 0;
    }
  }

  .table-creators {
    border-collapse: collapse;
    margin-top: 10px;

    tr {
      &:nth-child(odd) {
        background: #f5f5f5;
      }

      &:not(.row-footer):hover {
        background: rgba(90, 19, 217, 0.1);
      }

      th {
        padding: 16px 0;
        font-family: 'Rubik';
        font-size: 12.2px;
        letter-spacing: 0.4px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid #dbdbdb;
        background: #fff !important;

        &.creator-col {
          text-align: left;
        }

        .col-creator-align {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .delete-container {
          display: flex;
          justify-content: center;
        }
      }

      td {
        text-align: center;
        padding: 16px 0;
        font-family: 'Rubik';
        font-size: 12.2px;
        line-height: 1.31;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.6);

        &.creator-col {
          text-align: left;
        }

        &.totals {
          text-align: left;
          padding-left: 35px;
          font-family: 'Rubik';
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.08px;
          color: rgba(0, 0, 0, 0.87);

          .profiles-count {
            margin-left: 95px;
            font-family: 'Rubik';
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.08px;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .delete-container {
          display: flex;
          justify-content: center;
        }

        .creator-details {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 64px;
            height: 64px;
            background-color: #ccc;
            border-radius: 4px;
          }

          .infos {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .title {
              font-family: 'Rubik';
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.48;
              letter-spacing: 0.15px;
              color: rgba(0, 0, 0, 0.87);

              &:hover {
                text-decoration: underline;
              }
            }

            .identifier {
              font-size: 14px;
              line-height: 1.37;
              letter-spacing: 0.25px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .col-comment {
          display: flex;
          flex-direction: column;
          align-items: center;

          .logged_user_last_comment {
            margin-top: 15px;
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;
          }
        }
      }
    }
  }
`;

export const ActionGroup = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  width: 100%;
`;
