import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import {
  channelAddRequest,
  channelAddReset,
} from '~/store/modules/channel/actions';

import Modal from '~/components/Modal';
import ModalAddChannel from './ModalAddChannel';

import { useOutsideAlerter } from '~/helpers';

import { menuOpen, menuClose } from '~/store/modules/menu/actions';

import { Container } from './styles';

export default function Menu() {
  const dispatch = useDispatch();

  const [modalAddChannelIsOpen, setModalAddChannelIsOpen] = useState(false);

  const channelLoading = useSelector((state) => state.channel.add.loading);
  const channelSuccess = useSelector((state) => state.channel.add.success);
  const channelError = useSelector((state) => state.channel.add.error);

  const active = useSelector((state) => state.menu.active);
  const menuSelectedSlug = useSelector((state) => state.menu.menuSelectedSlug);

  const menuRef = useRef(null);

  useOutsideAlerter(menuRef, () => {
    if (active) dispatch(menuClose());
  });

  function handleToggleMenu() {
    if (active) {
      dispatch(menuClose());
    } else {
      dispatch(menuOpen());
    }
  }

  function handleShowModalAddChannel() {
    setModalAddChannelIsOpen(true);
  }

  function handleHideModalAddChannel() {
    setModalAddChannelIsOpen(false);
    dispatch(channelAddReset());
  }

  function renderModalAddChannel() {
    return (
      <Modal
        isOpen={modalAddChannelIsOpen}
        handleCloseModal={handleHideModalAddChannel}
        width={600}
        height={420}
      >
        <ModalAddChannel
          error={channelError}
          success={channelSuccess}
          loading={channelLoading}
          handleAddChannel={(profiles, network) =>
            dispatch(channelAddRequest(profiles, network))
          }
          handleHideModal={handleHideModalAddChannel}
        />
      </Modal>
    );
  }

  return (
    <Container active={active} ref={menuRef}>
      {renderModalAddChannel()}
      <div className="head">
        <div className="icon-wrapper" onClick={() => handleToggleMenu()}>
          <CloseOutlinedIcon style={{ fontSize: 35 }} />
        </div>
      </div>
      <ul>
        <li>
          <Link
            to="/search"
            className={`content-nav ${
              menuSelectedSlug === 'search' && 'active'
            }`}
          >
            <div className="icon-wrapper">
              <SearchOutlinedIcon />
            </div>
            <span>Search Influencers</span>
          </Link>
        </li>
        <li>
          <Link
            to="/overlap"
            className={`content-nav ${
              menuSelectedSlug === 'overlap' && 'active'
            }`}
          >
            <div className="icon-wrapper">
              <FileCopyOutlinedIcon />
            </div>
            <span>Overlap</span>
          </Link>
        </li>
        <li>
          <Link
            to="/tag-groups"
            className={`content-nav ${
              menuSelectedSlug === 'tag-groups' && 'active'
            }`}
          >
            <div className="icon-wrapper">
              <BookmarksOutlinedIcon />
            </div>
            <span>Tag groups</span>
          </Link>
        </li>
        <li>
          <Link
            to="/share-of-voice"
            className={`content-nav ${
              menuSelectedSlug === 'share-of-voice' && 'active'
            }`}
          >
            <div className="icon-wrapper">
              <TrendingUpIcon />
            </div>
            <span>Share of voice</span>
          </Link>
        </li>
        <li>
          <div className="content-nav" onClick={handleShowModalAddChannel}>
            <div className="icon-wrapper">
              <AddOutlinedIcon />
            </div>
            <span>Add channel</span>
          </div>
        </li>
      </ul>
    </Container>
  );
}
