import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
  margin-right: 15px;

  &:hover {
    background: rgba(90, 19, 217, 0.1);
  }

  .col-left {
    display: flex;
    flex: 1;
    flex-direction: column;

    .title {
      line-height: 1.37;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        text-decoration: underline;
      }
    }

    .total-creators {
      margin-top: 5px;
      line-height: 1.37;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .col-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .col-right {
    display: flex;
    flex-direction: column;

    .date {
      font-family: 'Rubik';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: colorPalette.primary,
    },
    '&$checked + $track': {
      backgroundColor: colorPalette.primary,
    },
  },
  checked: {},
  track: {
    backgroundColor: '#d5d5d5',
  },
})(Switch);
