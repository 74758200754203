import produce from 'immer';
import types from '../types';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: null,
};

export default function credits(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREDITS_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case types.CREDITS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload.data;
        break;
      }
      case types.CREDITS_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}
