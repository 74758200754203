import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BsEyeSlash, BsEye, BsCheck } from 'react-icons/bs';
import { Container } from './styles';

function InputPassword({
  value,
  minimumCheckCharacters,
  errors,
  classError,
  ...rest
}) {
  const [shownPassword, setShownPassword] = useState(false);

  const tooglePasswordVisibility = useCallback(() => {
    setShownPassword((state) => !state);
  }, []);

  return (
    <Container classError={classError}>
      <input
        {...rest}
        value={value}
        type={shownPassword ? 'text' : 'password'}
      />
      <div onClick={tooglePasswordVisibility}>
        {shownPassword ? <BsEyeSlash size={24} /> : <BsEye size={24} />}
      </div>
      <span>{errors}</span>
      {minimumCheckCharacters && (
        <>
          {errors ? (
            <span>{errors}</span>
          ) : (
            <span>
              6 characters minimum
              {value.length >= 6 && (
                <BsCheck size={20} style={{ fill: '#59e582' }} />
              )}
            </span>
          )}
        </>
      )}
    </Container>
  );
}

export default InputPassword;

InputPassword.defaultProps = {
  minimumCheckCharacters: false,
  errors: '',
  classError: '',
};
InputPassword.propTypes = {
  value: PropTypes.string.isRequired,
  minimumCheckCharacters: PropTypes.bool,
  errors: PropTypes.string,
  classError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
