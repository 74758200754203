import produce from 'immer';
import types from '../../types';

export const INITIAL_STATE = {
  list: {
    data: [],
    loading: false,
    error: false,
    filter: {
      title: '',
    },
    page: 1,
    last_page: 1,
    paginate: {
      loading: false,
      error: false,
    },
  },
  delete: {
    loading: false,
    completedDelete: 0,
    error: false,
  },
};

export default function overlapList(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.OVERLAP_LIST_RESET: {
        draft.list.data = null;
        draft.list.loading = false;
        draft.list.error = false;
        draft.list.filter.title = '';
        draft.list.page = 1;
        draft.list.last_page = 1;
        draft.list.paginate.loading = false;
        draft.list.paginate.error = false;
        break;
      }
      case types.OVERLAP_LIST_SET_FILTER_TITLE: {
        draft.list.filter.title = action.payload.title;
        break;
      }
      case types.OVERLAP_LIST_REQUEST: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.list.paginate.loading = true;
          draft.list.paginate.error = false;
        } else {
          draft.list.page = 1;
          draft.list.last_page = 1;
          draft.list.loading = true;
          draft.list.error = false;
        }
        break;
      }
      case types.OVERLAP_LIST_SUCCESS: {
        const { data, lastPage, isPaginate } = action.payload;

        draft.list.last_page = lastPage;

        if (isPaginate) {
          draft.list.page += 1;
          draft.list.paginate.loading = false;
          draft.list.data = [...draft.list.data, ...data];
        } else {
          draft.list.loading = false;
          draft.list.data = action.payload.data;
        }
        break;
      }
      case types.OVERLAP_LIST_FAILURE: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.list.paginate.loading = false;
          draft.list.paginate.error = true;
        } else {
          draft.list.loading = false;
          draft.list.error = true;
        }
        break;
      }
      case types.SIGN_OUT_REQUEST: {
        draft.list.data = null;
        draft.list.error = false;
        break;
      }
      case types.OVERLAP_LIST_DELETE_REQUEST: {
        draft.delete.loading = true;
        draft.delete.error = false;
        break;
      }
      case types.OVERLAP_LIST_DELETE_SUCCESS: {
        const filteredList = draft.list.data.filter(
          (row) => row.id !== action.payload.overlapId
        );
        draft.list.data = filteredList;
        draft.delete.completedDelete += 1;
        draft.delete.loading = false;
        draft.delete.error = false;
        break;
      }
      case types.OVERLAP_LIST_DELETE_FAILURE: {
        draft.delete.loading = false;
        draft.delete.error = true;
        break;
      }
      default:
    }
  });
}
