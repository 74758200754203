import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

export default function Divisions() {
  const { genders } = useSelector((state) => state.audienceProfile.data);

  const male = genders.filter((row) => row.gender === 'male');
  const female = genders.filter((row) => row.gender === 'female');

  const { ages: maleAges } = male[0];
  const { ages: femaleAges } = female[0];

  if (maleAges.length < 6 || femaleAges.length < 6) return null;

  return (
    <Container>
      <div className="column-division">
        <h3>DIVISION BY AGE - FEMALE</h3>
        <ul>
          <li>
            <span>13 - 17</span>
            <span>{`${Math.round(
              (femaleAges[0] && femaleAges[0]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>18 - 24</span>
            <span>{`${Math.round(
              (femaleAges[1] && femaleAges[1]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>25 - 34</span>
            <span>{`${Math.round(
              (femaleAges[2] && femaleAges[2]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>35 - 44</span>
            <span>{`${Math.round(
              (femaleAges[3] && femaleAges[3]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>45 - 54</span>
            <span>{`${Math.round(
              (femaleAges[4] && femaleAges[4]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>55 - 64</span>
            <span>{`${Math.round(
              (femaleAges[5] && femaleAges[5]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>65+</span>
            <span>{`${Math.round(
              (femaleAges[5] && femaleAges[5]?.total) || 0
            )}%`}</span>
          </li>
        </ul>
      </div>
      <div className="column-division">
        <h3>DIVISION BY AGE - MALE</h3>
        <ul>
          <li>
            <span>13 - 17</span>
            <span>{`${Math.round(
              (maleAges[0] && maleAges[0]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>18 - 24</span>
            <span>{`${Math.round(
              (maleAges[1] && maleAges[1]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>25 - 34</span>
            <span>{`${Math.round(
              (maleAges[2] && maleAges[2]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>35 - 44</span>
            <span>{`${Math.round(
              (maleAges[3] && maleAges[3]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>45 - 54</span>
            <span>{`${Math.round(
              (maleAges[4] && maleAges[4]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>55 - 64</span>
            <span>{`${Math.round(
              (maleAges[5] && maleAges[5]?.total) || 0
            )}%`}</span>
          </li>
          <li>
            <span>65+</span>
            <span>{`${Math.round(
              (maleAges[6] && maleAges[6]?.total) || 0
            )}%`}</span>
          </li>
        </ul>
      </div>
    </Container>
  );
}
