import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import types from '../types';

import { apiTocSov, apiBeCreator } from '~/services/api';

import {
  tocGroupsListSuccess,
  tocGroupsListFailure,
  tocSuccess,
  tocFailure,
} from './actions';

export function* getGroups() {
  try {
    const response = yield call(apiTocSov.get, 'groups');
    yield put(tocGroupsListSuccess(response.data));
  } catch (err) {
    yield put(tocGroupsListFailure());
  }
}

export function* getToc({ payload }) {
  const { isPaginate } = payload;
  try {
    const { profile, tabs } = yield select((state) => state.creator);
    const { networkIndexSelected } = tabs;
    const { id: creatorId, creator_networks } = profile.data;
    const creatorNetworkId = creator_networks[networkIndexSelected].id;

    const { groups, dataByGroup } = yield select((state) => state.toc);
    const { selected: groupId } = groups;
    const { tagSelected: tag, current_page } = dataByGroup;
    const page = isPaginate ? current_page + 1 : current_page;
    const limit = 8;

    const response = yield call(
      apiBeCreator.get,
      `creators/${creatorId}/creator_networks/${creatorNetworkId}/posts`,
      {
        params: {
          group: groupId,
          tag,
          page,
          limit,
        },
      }
    );
    const lastPage = Math.round(response.headers['x-total-count'] / limit);

    yield put(tocSuccess(response.data, lastPage, isPaginate));
  } catch (err) {
    yield put(tocFailure(isPaginate));
  }
}

export default all([
  takeLatest(types.TOC_GROUPS_LIST_REQUEST, getGroups),
  takeLatest(types.TOC_REQUEST, getToc),
]);
