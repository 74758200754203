import types from '../../types';

// LIST
export function overlapListReset() {
  return {
    type: types.OVERLAP_LIST_RESET,
  };
}

export function overlapListSetFilterTitle(title) {
  return {
    type: types.OVERLAP_LIST_SET_FILTER_TITLE,
    payload: { title },
  };
}

export function overlapListRequest(isPaginate) {
  return {
    type: types.OVERLAP_LIST_REQUEST,
    payload: { isPaginate },
  };
}

export function overlapListSuccess(data, lastPage, isPaginate) {
  return {
    type: types.OVERLAP_LIST_SUCCESS,
    payload: { data, lastPage, isPaginate },
  };
}

export function overlapListFailure(isPaginate) {
  return {
    type: types.OVERLAP_LIST_FAILURE,
    payload: { isPaginate },
  };
}

// DELETE
export function overlapListDeleteRequest(overlapId) {
  return {
    type: types.OVERLAP_LIST_DELETE_REQUEST,
    payload: { overlapId },
  };
}

export function overlapListDeleteSuccess(overlapId) {
  return {
    type: types.OVERLAP_LIST_DELETE_SUCCESS,
    payload: { overlapId },
  };
}

export function overlapListDeleteFailure() {
  return {
    type: types.OVERLAP_LIST_DELETE_FAILURE,
  };
}
