import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin-bottom: 20px;

  .input-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-wrapper {
      color: #9e9e9e;
    }

    input {
      border: none;
      padding: 5px 10px;
      display: flex;
      flex: 1;
      color: rgba(0, 0, 0, 0.38);

      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
      &::-moz-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    .button-add {
      color: ${colorPalette.primary};
      font-weight: bold;
      background: none;
      border: none;
      margin: 0;
      width: 35px;
    }
  }

  .instruction {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 5px;
    font-size: 12px;
  }

  .tag-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;

    .tag-list-item {
      border-radius: 4px;
      background-color: #e4e4e4;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      .tag-list-item-name {
        letter-spacing: 0.25px;
        flex: 1;
      }

      .icon-wrapper {
        color: #8d8d8d;
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
`;
