import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { differenceInDays } from 'date-fns';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  selectTab,
  creatorRequest,
} from '~/store/modules/creator/profile/actions';

import {
  campaignsDetailCreatorAddRequest,
  campaignsDetailCreatorDeleteRequest,
} from '~/store/modules/campaigns/detail/actions';

import {
  channelUpdateRequest,
  channelUpdateReset,
} from '~/store/modules/channel/actions';

import Loader from '~/components/Loader';
import Button from '~/components/Button';
import Modal from '~/components/Modal';

import Instagram from './Instagram';
import Youtube from './Youtube';
import Footer from '~/containers/Footer';

import ModalComments from '~/pages/Campaigns/Detail/ModalComments';

import { Container } from './styles';

const networks = {
  INSTAGRAM: {
    icon: <FaInstagram size="21" />,
    component: () => <Instagram />,
  },
  YOUTUBE: {
    icon: <FaYoutube size="21" />,
    component: () => <Youtube />,
  },
};

function Creator({ match }) {
  const { creatorId } = match.params;

  const dispatch = useDispatch();

  const [permissionUpdate, setPermissionUpdate] = useState(false);
  const [diffInDays, setDiffInDays] = useState(0);
  const [modalCommentsIsOpen, setModalCommentsIsOpen] = useState(false);

  const loading = useSelector((state) => state.creator.profile.loading);
  const data = useSelector((state) => state.creator.profile.data);
  const networkIndexSelected = useSelector(
    (state) => state.creator.tabs.networkIndexSelected
  );
  const networkTypeSelected = useSelector(
    (state) => state.creator.tabs.networkTypeSelected
  );

  // Channel state
  const loadingUpdateChannel = useSelector(
    (state) => state.channel.update.loading
  );
  const successUpdateChannel = useSelector(
    (state) => state.channel.update.success
  );
  const errorUpdateChannel = useSelector((state) => state.channel.update.error);

  const { data: profile } = useSelector((state) => state.profile);

  useEffect(() => {
    if (data) {
      const network = data.creator_networks[networkIndexSelected];

      const { updated } = network;

      setDiffInDays(differenceInDays(new Date(), new Date(updated)));

      if (diffInDays >= 30) {
        setPermissionUpdate(true);
      } else {
        setPermissionUpdate(false);
      }
    }
  }, [data, diffInDays, networkIndexSelected]);

  useEffect(() => {
    dispatch(channelUpdateReset());
    dispatch(creatorRequest(creatorId));
  }, [dispatch, creatorId]);

  const renderTabs = () => {
    return data?.creator_networks.map((row, index) => (
      <div
        className={`tab ${networkIndexSelected === index ? 'active' : ''}`}
        key={Math.random()}
        onClick={() => {
          dispatch(selectTab(index, row.network));
        }}
      >
        <div className="icon-wrapper">{networks[row.network].icon}</div>
        <span>{row.network}</span>
      </div>
    ));
  };

  const handleOpenCommentsModal = () => {
    setModalCommentsIsOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setModalCommentsIsOpen(false);
  };

  const handleAddCreatorActiveCampaign = () => {
    const creator = {
      creator_id: data.id,
      creator_network: data.creator_networks[networkIndexSelected],
    };
    const isActiveCampaign = true;
    const campaignId = profile.settings?.active_campaign?.id;

    dispatch(
      campaignsDetailCreatorAddRequest(creator, isActiveCampaign, campaignId)
    );
  };

  const handleRemoveCreatorActiveCampaign = () => {
    const creator_network_id = data.creator_networks[networkIndexSelected].id;
    const creatorNetworkIds = [creator_network_id];
    const isActiveCampaign = true;
    const campaignId = profile.settings?.active_campaign?.id;

    dispatch(
      campaignsDetailCreatorDeleteRequest(
        creatorNetworkIds,
        isActiveCampaign,
        campaignId
      )
    );
  };

  const renderButtonCampaign = () => {
    if (!profile?.settings?.active_campaign?.id) {
      return (
        <>
          <div className="info">Select a campaign above</div>
          <Button onClick={() => false} className="inative" width={195}>
            ADD TO CAMPAIGN
          </Button>
        </>
      );
    }

    if (data.creator_networks[networkIndexSelected].is_in_active_campaign) {
      return (
        <>
          <div className="info">Influencer added to campaign</div>
          <div className="buttons-row">
            <Button onClick={handleRemoveCreatorActiveCampaign} width={100}>
              REMOVE
            </Button>
            <Button onClick={handleOpenCommentsModal} width={100}>
              COMMENT
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="info" />
        <Button onClick={handleAddCreatorActiveCampaign} width={195}>
          ADD TO CAMPAIGN
        </Button>
      </>
    );
  };

  const renderOptionsTab = () => {
    return (
      <div className="container-options-tab">
        <div className="container-button-campaign">
          {data.creator_networks[networkIndexSelected]
            .can_be_added_to_campaign && renderButtonCampaign()}
        </div>

        <div className="container-button-update">
          <div className="info" aria-label="message_can_update_creator">
            {permissionUpdate
              ? 'Data has been updated more than 30 days ago'
              : `Profile updated ${diffInDays} days ago`}
          </div>
          {loadingUpdateChannel ? (
            <div className="loader-container">
              <Loader />
            </div>
          ) : (
            <Button
              onClick={() =>
                permissionUpdate && dispatch(channelUpdateRequest())
              }
              width={220}
              className={`${!permissionUpdate && 'inative'}`}
            >
              <>
                <AutorenewIcon
                  style={{
                    color: permissionUpdate ? '#fff' : '#8c8c8c',
                    fontSize: 20,
                    marginRight: 5,
                  }}
                />
                UPDATE PROFILE
              </>
            </Button>
          )}
          {errorUpdateChannel && (
            <div className="update-response-message error">
              <ErrorOutlineIcon
                style={{
                  color: '#f15750',
                  fontSize: 15,
                }}
              />
              <span>
                An error occurred while trying to update this profile, please
                try again
              </span>
            </div>
          )}
          {successUpdateChannel && (
            <div className="update-response-message success">
              <DoneIcon
                style={{
                  color: '#21c344',
                  fontSize: 15,
                }}
              />
              <span>
                The profile has been sent to the update queue, wait for
                processing to finish
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const render = () => {
    if (loading) {
      return <Loader />;
    }

    if (data) {
      return (
        <>
          <header>
            <h2 className="page-title">Statistic Report</h2>
            <div className="tabs">{renderTabs()}</div>
            {renderOptionsTab()}
          </header>
          <div className="tabs-contents">
            {networks[networkTypeSelected].component()}
          </div>
          <Modal
            width={668}
            height={550}
            isOpen={modalCommentsIsOpen}
            handleCloseModal={handleCloseCommentsModal}
          >
            <ModalComments
              handleClose={handleCloseCommentsModal}
              creatorNetworkId={data.creator_networks[networkIndexSelected].id}
              campaignId={profile.settings?.active_campaign?.id}
            />
          </Modal>
        </>
      );
    }

    return null;
  };

  return (
    <Container>
      <div className="grid">{render()}</div>
      <Footer />
    </Container>
  );
}

Creator.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      creatorId: PropTypes.string,
    }),
  }).isRequired,
};

export default Creator;
