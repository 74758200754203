import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  changePasswordActions,
  changePasswordSuccess,
  changePasswordFailure,
} from './actions';

import { apiBeUser } from '~/services/api';

export function* saveNewPassword({ payload }) {
  try {
    const { current, newPassword } = payload;

    yield call(apiBeUser.patch, 'me/password', {
      current,
      new: newPassword,
    });

    yield put(changePasswordSuccess());
  } catch (err) {
    yield put(changePasswordFailure());
  }
}

export default all([
  takeLatest(changePasswordActions.CHANGE_PASSWORD_REQUEST, saveNewPassword),
]);
