import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  background-color: #f5f5f5;
  position: fixed;
  left: 0;
  top: 80px;
  height: 100%;
  flex: 1;

  .react-datepicker__navigation--previous {
    left: -220px;
  }
  .react-datepicker__navigation--next {
    right: -220px;
  }

  h3 {
    font-size: 24.4px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    margin: 30px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    display: none;

    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      cursor: pointer;
      width: 100%;
      border-bottom: 2px solid #dbdbdb;

      &.active {
        border-bottom: 2px solid ${colorPalette.primary};
      }

      span {
        color: ${colorPalette.primary};
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 7px;
        font-size: 16px;
      }
    }
  }
  .container-tabs-contents {
    display: flex;
    flex-direction: column;
    padding: 30px 18px 100px;
    flex: 1;

    .tab-content {
      display: flex;
      flex-direction: column;

      .instruction {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 5px;
        font-size: 12px;
      }

      h4 {
        color: ${colorPalette.primary};
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 15px;
      }

      label,
      p {
        font-family: Hind;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.6);
      }

      strong {
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 10px;
        display: block;
        font-family: 'Rubik';
      }

      select {
        background: #fff;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
        margin-bottom: 20px;
        min-width: 145px;
        color: rgba(0, 0, 0, 0.5);
      }

      .MuiFormControl-root {
        .MuiSelect-selectMenu {
          min-width: 150px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
          padding: 10px;
          background: #fff;
        }
        .MuiSelect-icon {
          color: ${colorPalette.primary};
        }
        .MuiSelect-selectMenu {
          font-family: 'Hind';
          font-size: 14px;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        label {
          margin-left: 10px;
        }

        .col {
          display: flex;
          flex-direction: column;

          &.full {
            flex: 1;
          }

          .container-select {
            width: 50%;
          }
        }
      }

      .row-from-to {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        .col-from-to {
          display: flex;
          flex-direction: column;
          width: 40%;

          label {
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 5px;
          }

          input {
            border: none;
            border-bottom: 1px solid #cccccc;
            padding: 10px;
            background: none;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    button {
      margin-top: 20px;
      width: 100%;
    }
  }
`;
