import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MoodOutlinedIcon from '@material-ui/icons/MoodOutlined';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { statisticsRequest } from '~/store/modules/creator/statistics/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { abbreviateNumber } from '~/helpers';

import { Container } from './styles';

export default function EstimatedStatistics() {
  const dispatch = useDispatch();

  const { data: dataCreator } = useSelector((state) => state.creator.profile);
  const { networkIndexSelected } = useSelector((state) => state.creator.tabs);
  const dataNetwork = dataCreator.creator_networks[networkIndexSelected];

  const {
    data: dataStatistics,
    loading,
    error,
  } = useSelector((state) => state.creatorStatistics);

  useEffect(() => {
    dispatch(statisticsRequest());
  }, [dispatch]);

  const render = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(statisticsRequest())}
        />
      );
    }

    if (dataStatistics) {
      return (
        <div className="items-statistics">
          <div className="item border">
            <div className="icon-wrapper">
              <MoodOutlinedIcon />
            </div>
            <span>{abbreviateNumber(dataNetwork?.total_followers, 1)}</span>
            <span>followers</span>
          </div>
          <div className="item border">
            <div className="icon-wrapper">
              <ThumbsUpDownOutlinedIcon />
            </div>
            <span>
              {abbreviateNumber(dataStatistics?.all_posts[0]?.aem, 1)}
            </span>
            <span>engagement</span>
          </div>
          <div className="item">
            <div className="icon-wrapper">
              <ThumbsUpDownOutlinedIcon />
            </div>
            <span>{`${abbreviateNumber(
              dataStatistics.engagement_tax,
              1
            )}%`}</span>
            <span>engagement tax</span>
          </div>
          <div className="item border">
            <div className="icon-wrapper">
              <VideoCallIcon />
            </div>
            <span>{abbreviateNumber(dataStatistics.avm, 1)}</span>
            <span>avm</span>
          </div>
          <div className="item border">
            <div className="icon-wrapper">
              <PeopleOutlineOutlinedIcon />
            </div>
            <span>{abbreviateNumber(dataStatistics.reach, 1)}</span>
            <span>estimated reach</span>
          </div>
          <div className="item">
            <div className="icon-wrapper">
              <CollectionsOutlinedIcon />
            </div>
            <span>
              {abbreviateNumber(dataStatistics.creator_network?.total_posts, 1)}
            </span>
            <span>videos</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Container>
      <h3 className="box-title">Estimated statistics</h3>
      {render()}
    </Container>
  );
}
