import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .btn-queue-container {
    color: ${colorPalette.text};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &.has-creators {
      color: ${colorPalette.primary};
    }

    .amount {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
      text-align: center;
      color: #fff;
      font-weight: 700;
      position: absolute;
      right: -10px;
      top: -14px;
    }
  }

  .float-creators-queue-container {
    width: 340px;
    position: absolute;
    right: -170px;
    top: 52px;
    background: #fafafa;
    z-index: 100;
    border: 1px solid #e4e4e4;
    border-top: none;
    padding: 10px;

    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .is-mine-contas {
      display: flex;
      flex-direction: row;
      align-items: center;

      .is-mine-label {
        font-size: 12px;
        color: ${colorPalette.text};
      }
    }

    .queue-list {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .no-results {
        color: ${colorPalette.text};
        text-align: center;
        margin-top: 20px;
      }

      .creator-item {
        padding: 10px 0;
        border-bottom: 1px solid #f3f3f3;

        &:last-child {
          border-bottom: none;
        }

        .row {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;

          .thumbnail {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: #ccc;
          }

          .infos {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-left: 10px;
            padding-right: 20px;

            .name {
              color: ${colorPalette.primary};
              font-weight: 700;
              line-height: 14px;

              &:hover {
                text-decoration: underline;
              }
            }

            .identifier {
              color: ${colorPalette.primary};
              font-weight: 700;
              font-size: 14px;
              line-height: 14px;
            }

            .network {
              font-size: 12px;
              color: ${colorPalette.text};
            }

            .process-container {
              width: 100%;
              height: 5px;
              background: #ccc;

              .percentage {
                background: #54d63f;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
