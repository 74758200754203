import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Pagination({
  handleFirstPage,
  handleLastPage,
  handlePaginate,
  currentPage,
  totalPages,
}) {
  return (
    <Container>
      <span className="side-link" onClick={() => handleFirstPage()}>
        FIRST
      </span>
      {currentPage - 3 > 0 && (
        <span onClick={() => handlePaginate(currentPage - 3)}>
          {currentPage - 3}
        </span>
      )}
      {currentPage - 2 > 0 && (
        <span onClick={() => handlePaginate(currentPage - 2)}>
          {currentPage - 2}
        </span>
      )}
      {currentPage - 1 > 0 && (
        <span onClick={() => handlePaginate(currentPage - 1)}>
          {currentPage - 1}
        </span>
      )}
      <span onClick={() => handlePaginate(currentPage)} className="selected">
        {currentPage}
      </span>
      {currentPage + 1 <= totalPages && (
        <span onClick={() => handlePaginate(currentPage + 1)}>
          {currentPage + 1}
        </span>
      )}
      {currentPage + 2 <= totalPages && (
        <span onClick={() => handlePaginate(currentPage + 2)}>
          {currentPage + 2}
        </span>
      )}
      {currentPage + 3 <= totalPages && (
        <span onClick={() => handlePaginate(currentPage + 3)}>
          {currentPage + 3}
        </span>
      )}
      <span className="side-link" onClick={() => handleLastPage()}>
        LAST
      </span>
    </Container>
  );
}

Pagination.propTypes = {
  handleFirstPage: PropTypes.func.isRequired,
  handleLastPage: PropTypes.func.isRequired,
  handlePaginate: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};
