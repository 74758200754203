import React from 'react';

import { Container } from './styles';

export default function Footer() {
  return (
    <Container>
      <div className="grid">
        <div className="row">
          <a
            href="https://www.cely.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            cely.co
          </a>
          <p>© {new Date().getFullYear()} Cely</p>
        </div>
      </div>
    </Container>
  );
}
