export const changePasswordActions = {
  CHANGE_PASSWORD_REQUEST: '@changePassword/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: '@changePassword/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: '@changePassword/CHANGE_PASSWORD_FAILURE',
  RESET_FORM_MESSAGE: '@changePassword/RESET_FORM_MESSAGE',
};

export function changePasswordRequest(current, newPassword) {
  return {
    type: changePasswordActions.CHANGE_PASSWORD_REQUEST,
    payload: { current, newPassword },
  };
}

export function changePasswordSuccess() {
  return {
    type: changePasswordActions.CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordFailure() {
  return {
    type: changePasswordActions.CHANGE_PASSWORD_FAILURE,
  };
}

export function resetFormMessage() {
  return {
    type: changePasswordActions.RESET_FORM_MESSAGE,
  };
}
