import produce from 'immer';
import { trackingLinkActions } from './actions';

export const INITIAL_STATE = {
  orderBy: 'DESC',
  register: {
    loading: false,
    success: false,
    error: '',
  },
  list: {
    start_date: '',
    end_date: '',
    loading: false,
    error: false,
    data: null,
    page: 1,
    last_page: 1,
    total: 1,
    paginate: {
      loading: false,
      error: false,
    },
  },
  total_clicks: {
    loading: false,
    error: false,
    data: null,
  },
  toggle_stories_status: {
    loading: false,
    error: false,
  },
};

export default function trackingLink(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case trackingLinkActions.SET_ORDER_BY: {
        draft.orderBy = action.payload.order;
        break;
      }
      case trackingLinkActions.SET_START_DATE: {
        draft.list.start_date = action.payload.startDate;
        break;
      }
      case trackingLinkActions.SET_END_DATE: {
        draft.list.end_date = action.payload.endDate;
        break;
      }
      case trackingLinkActions.REGISTER_REQUEST: {
        draft.register.loading = true;
        draft.register.success = false;
        draft.register.error = '';
        break;
      }
      case trackingLinkActions.REGISTER_SUCCESS: {
        const { monitorId, startTime, endTime } = action.payload;

        draft.register.loading = false;
        draft.register.success = true;
        draft.list.data = {
          id: monitorId,
          start_time: startTime,
          end_time: endTime,
          stories: draft.list.data ? draft.list.data.stories : [],
        };
        break;
      }
      case trackingLinkActions.REGISTER_FAILURE: {
        draft.register.loading = false;
        draft.register.success = false;
        draft.register.error = action.payload.error;
        break;
      }
      case trackingLinkActions.REGISTER_RESET: {
        draft.register.loading = false;
        draft.register.success = false;
        draft.register.error = '';
        break;
      }
      case trackingLinkActions.LIST_REQUEST: {
        const { isPaginate, page } = action.payload;
        if (isPaginate) {
          draft.list.paginate.loading = true;
          draft.list.paginate.error = false;
          draft.list.page = page;
          draft.list.loading = true;
          draft.list.error = false;
        } else {
          draft.list.page = page;
          draft.list.last_page = 1;
          draft.list.loading = true;
          draft.list.error = false;
        }
        break;
      }
      case trackingLinkActions.LIST_REQUEST_PAGINATE: {
        const { page } = action.payload;
        draft.list.paginate.loading = true;
        draft.list.paginate.error = false;
        draft.list.page = page;
        draft.list.loading = true;
        draft.list.error = false;
        break;
      }
      case trackingLinkActions.LIST_SUCCESS: {
        const { data, lastPage, isPaginate, total } = action.payload;
        draft.list.last_page = lastPage;
        if (isPaginate) {
          draft.list.paginate.loading = false;
          draft.list.data = data;
          draft.list.total = total;
          draft.list.loading = false;
        } else {
          draft.list.loading = false;
          draft.list.data = data;
          draft.list.total = total;
        }
        break;
      }
      case trackingLinkActions.LIST_FAILURE: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.list.paginate.loading = false;
          draft.list.paginate.error = true;
          draft.list.loading = false;
          draft.list.error = true;
        } else {
          draft.list.loading = false;
          draft.list.error = true;
        }
        break;
      }
      case trackingLinkActions.TOTAL_CLICKS_REQUEST: {
        draft.total_clicks.data = null;
        draft.total_clicks.error = false;
        draft.total_clicks.loading = true;
        break;
      }
      case trackingLinkActions.TOTAL_CLICKS_SUCCESS: {
        draft.total_clicks.data = action.payload;
        draft.total_clicks.error = false;
        draft.total_clicks.loading = false;
        break;
      }
      case trackingLinkActions.TOTAL_CLICKS_FAILURE: {
        draft.total_clicks.data = action.payload;
        draft.total_clicks.error = true;
        draft.total_clicks.loading = false;
        break;
      }
      case trackingLinkActions.LIST_RESET: {
        draft.list.loading = false;
        draft.list.error = false;
        draft.list.data = null;
        draft.list.start_date = '';
        draft.list.end_date = '';
        break;
      }
      case trackingLinkActions.TOGGLE_STORIES_STATUS_REQUEST: {
        draft.toggle_stories_status.loading = true;
        draft.toggle_stories_status.error = false;
        break;
      }
      case trackingLinkActions.TOGGLE_STORIES_STATUS_SUCCESS: {
        const { storyId, show } = action.payload;

        draft.toggle_stories_status.loading = false;
        draft.list.data.stories = draft.list.data.stories.map((story) => {
          if (story.id === storyId) {
            story.show = show;
          }
          return story;
        });
        break;
      }
      case trackingLinkActions.TOGGLE_STORIES_STATUS_FAILURE: {
        draft.toggle_stories_status.loading = false;
        draft.toggle_stories_status.error = true;
        break;
      }
      case trackingLinkActions.TOGGLE_STORIES_STATUS_RESET: {
        draft.toggle_stories_status.loading = false;
        draft.toggle_stories_status.error = false;
        break;
      }
      default:
    }
  });
}
