/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';

import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdThumbsUpDown,
} from 'react-icons/md';
import format from 'date-fns/format';

import { abbreviateNumber } from '~/helpers';

import { Container } from '../../../Instagram/Posts/Modal/styles';

export default function Modal({ index, data, handleHideModal }) {
  const [currentIndex, setCurrentIndex] = useState(index);

  const post = data[currentIndex];

  const description = post.description.replace(/(?:\r\n|\r|\n)/g, '<br />');

  const scrollBarStyles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  };

  const barWidth = 340;

  const handlePrev = useCallback(() => {
    if (data[currentIndex - 1]) {
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, data]);

  const handleNext = useCallback(() => {
    if (data[currentIndex + 1]) {
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, data]);

  const keys = {
    37: handlePrev,
    39: handleNext,
  };

  const handleKeyboard = useCallback(
    (e) => {
      if (keys[e.keyCode]) keys[e.keyCode]();
    },
    [keys]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    };
  }, [handleKeyboard, handleNext, handlePrev]);

  const renderMedia = () => {
    return (
      <div className="picture">
        <iframe
          width="480"
          height="600"
          src={`https://www.youtube.com/embed/${post.permalink.replace(
            'https://youtube.com/watch?v=',
            ''
          )}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <Container>
      {data[currentIndex - 1] && (
        <div className="navigation prev">
          <div className="icon-wrapper" onClick={() => handlePrev()}>
            <MdKeyboardArrowLeft size="60" />
          </div>
        </div>
      )}
      {data[currentIndex + 1] && (
        <div className="navigation next">
          <div className="icon-wrapper" onClick={() => handleNext()}>
            <MdKeyboardArrowRight size="60" />
          </div>
        </div>
      )}
      {renderMedia()}
      <div className="infos">
        <div className="head">
          <h3>Post Detail</h3>
          <div className="icon-wrapper close-modal">
            <MdClose size="25" onClick={() => handleHideModal()} />
          </div>
        </div>
        <div className="metrics">
          <div className="metric-row border">
            <div className="metric-item">
              <div className="icon-wrapper">
                <ThumbUpOutlinedIcon
                  style={{ fontSize: 14, color: '#b00020' }}
                />
              </div>
              <span>{abbreviateNumber(post.total_likes, 1)}</span>
            </div>
            <div className="metric-item">
              <div className="icon-wrapper">
                <ThumbDownOutlinedIcon
                  style={{ fontSize: 14, color: '#b00020' }}
                />
              </div>
              <span>{abbreviateNumber(post.total_dislikes, 1)}</span>
            </div>
            <div className="metric-item">
              <div className="icon-wrapper">
                <CommentOutlinedIcon
                  style={{ fontSize: 14, color: '#59e582' }}
                />
              </div>
              <span>{abbreviateNumber(post.total_comments, 1)}</span>
            </div>
            <div className="metric-item">
              <div className="icon-wrapper">
                <MdThumbsUpDown style={{ fontSize: 14, color: '#b00020' }} />
              </div>
              <span>
                {`${abbreviateNumber(
                  post.total_likes + post.total_comments,
                  1
                )} engagement`}
              </span>
            </div>
          </div>
          <div className="metric-row">
            <div className="metric-item">
              <div className="icon-wrapper">
                <CalendarTodayOutlinedIcon
                  style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}
                />
              </div>
              <span>{format(new Date(post.published_at), 'dd/MM/yyyy')}</span>
            </div>
            {post.is_advertising === 1 && (
              <div className="metric-item publipost">
                <div className="icon-wrapper">
                  <CheckCircleOutlineOutlinedIcon
                    style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}
                  />
                </div>
                <span>Publipost</span>
              </div>
            )}
            <div className="metric-item external-link">
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-wrapper">
                  <OpenInNewOutlinedIcon
                    style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="description">
          <Scrollbars style={scrollBarStyles}>
            <h3 className="title">{post.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Scrollbars>
        </div>
        {post.is_abnormal === 1 && post?.abnormal_at && (
          <div
            className="expected-current-engagement"
            key={post?.abnormal_at[0].id}
          >
            <h3>Expected vs. current engagement ({post?.abnormal_at[0].at})</h3>
            <div className="row-engagement">
              <p>Expected average engagement</p>
              <div className="container-bar">
                <div
                  className="inside-bar"
                  style={{
                    width: `${Math.round(
                      (barWidth * post?.abnormal_at[0].expected) /
                        Math.max(
                          post?.abnormal_at[0].expected,
                          post?.abnormal_at[0].current
                        )
                    )}px`,
                  }}
                />
                <div
                  className="engagement-value"
                  style={{
                    width: `${Math.round(
                      (barWidth * post?.abnormal_at[0].expected) /
                        Math.max(
                          post?.abnormal_at[0].expected,
                          post?.abnormal_at[0].current
                        )
                    )}px`,
                  }}
                >
                  {abbreviateNumber(post?.abnormal_at[0].expected, 1)}
                </div>
              </div>
            </div>
            <div className="row-engagement">
              <p>Current engagement</p>
              <div className="container-bar">
                <div
                  className="inside-bar"
                  style={{
                    width: `${Math.round(
                      (barWidth * post?.abnormal_at[0].current) /
                        Math.max(
                          post?.abnormal_at[0].expected,
                          post?.abnormal_at[0].current
                        )
                    )}px`,
                  }}
                />
                <div
                  className="engagement-value"
                  style={{
                    width: `${Math.round(
                      (barWidth * post?.abnormal_at[0].current) /
                        Math.max(
                          post?.abnormal_at[0].expected,
                          post?.abnormal_at[0].current
                        )
                    )}px`,
                  }}
                >
                  {abbreviateNumber(post?.abnormal_at[0].current, 1)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

Modal.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.arrayOf({
    id: PropTypes.number,
    type: PropTypes.string,
    network_id: PropTypes.string,
    permalink: PropTypes.string,
    description: PropTypes.string,
    hashtags: PropTypes.array,
    is_abnormal: PropTypes.number,
    is_advertising: PropTypes.number,
    total_likes: PropTypes.number,
    total_comments: PropTypes.number,
    published_at: PropTypes.string,
    location: PropTypes.string,
    medias: PropTypes.arrayOf({
      id: PropTypes.number,
      type: PropTypes.string,
      path: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    tagged_profiles: PropTypes.arrayOf({
      id: PropTypes.number,
      network_id: PropTypes.string,
      title: PropTypes.string,
      identifier: PropTypes.string,
    }),
  }).isRequired,
  handleHideModal: PropTypes.func.isRequired,
};
