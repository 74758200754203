export const campaignsHeaderActions = {
  CAMPAIGNS_HEADER_SET_FILTER: '@campaigns/header/CAMPAIGNS_HEADER_SET_FILTER',
  CAMPAIGNS_HEADER_LIST_REQUEST:
    '@campaigns/header/CAMPAIGNS_HEADER_LIST_REQUEST',
  CAMPAIGNS_HEADER_LIST_SUCCESS:
    '@campaigns/header/CAMPAIGNS_HEADER_LIST_SUCCESS',
  CAMPAIGNS_HEADER_LIST_FAILURE:
    '@campaigns/header/CAMPAIGNS_HEADER_LIST_FAILURE',
};

export function campaignsHeaderSetFilter(title) {
  return {
    type: campaignsHeaderActions.CAMPAIGNS_HEADER_SET_FILTER,
    payload: { title },
  };
}

export function campaignsHeaderListRequest(isPaginate = false) {
  return {
    type: campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_REQUEST,
    payload: { isPaginate },
  };
}

export function campaignsHeaderListSuccess(
  data,
  last_page,
  isPaginate = false
) {
  return {
    type: campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_SUCCESS,
    payload: { data, last_page, isPaginate },
  };
}

export function campaignsHeaderListFailure(isPaginate = false) {
  return {
    type: campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_FAILURE,
    payload: { isPaginate },
  };
}
