import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin: 0 auto;
  padding: 40px 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.primary};
  width: 100%;
  height: 46px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #fff;
`;

export const TableMetrics = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: 86px;
  padding: 0 32px;
  background-color: #f5f5f5;

  .total {
    margin-right: 8px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #55565a;
    margin-right: 60px;
    white-space: nowrap;
    flex: 0 0 1;
  }

  .metrics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 16px;
      text-transform: uppercase;
      color: #55565a;

      span {
        display: block;
        font-size: 13px;
        margin-bottom: 16px;
      }
    }
  }
`;

export const ContainerEmpty = styled.section`
  margin-top: 24px;
`;
