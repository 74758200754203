import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '~/components/Button';
import Table from '~/components/Table';
import {
  Container,
  TableHeader,
  FormContainer,
  TableMetrics,
  ContainerEmpty,
  ModalContent,
} from './styles';
import {
  getTableBodyArray,
  getTableHeaderArray,
} from '~/helpers/utils/table.util';
import {
  trackingPostAddRequest,
  trackingPostListRequest,
} from '~/store/modules/campaigns/detail/tracking/trackingPosts/actions';
import tableImage from '~/assets/images/tracking/row.png';
import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import Modal from '~/components/Modal';

import CalendarSvg from '~/assets/images/tracking/calendar.svg';
import CommentsSvg from '~/assets/images/tracking/comments.svg';
import EngagementSvg from '~/assets/images/tracking/engagement.svg';
import LikeSvg from '~/assets/images/tracking/like.svg';
// import LocationSvg from '~/assets/images/tracking/location.svg';
import ShareSvg from '~/assets/images/tracking/share.svg';
import CloseSvg from '~/assets/images/tracking/close.svg';

const renderAvatar = (handleOpenModal, post) => {
  const { last_tracking } = post;

  return (
    <Avatar
      alt="Name here"
      src={last_tracking?.path_media || tableImage}
      variant="rounded"
      style={{ width: '71px', height: '80px' }}
      onClick={() => handleOpenModal(post)}
    />
  );
};

const renderCreatorNetwork = (network) => {
  if (network.includes('instagram')) {
    return <InstagramIcon />;
  }
  if (network.includes('youtube')) {
    return <YoutubeIcon />;
  }

  return null;
};

const formatDate = (date) => format(new Date(date), 'dd/MM/YYY');

const renderDeleteIcon = () => <DeleteOutlineIcon />;

function TrackingPosts({ campaingId }) {
  const dispatch = useDispatch();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [newPostLink, setNewPostLink] = useState('');
  const [newPostMessage, setNewPostMessage] = useState('');

  const [openPostModal, setOpenPostModal] = useState(false);
  const [postSelected, setPostSelected] = useState(null);
  const {
    loading: loadingSubmit,
    error: errorSubmit,
    data: dataSubmit,
    success: successSubmit,
  } = useSelector((state) => state.trackingPosts.add);

  const {
    data: trackings,
    last_page: lastPageTracking,
    page: currentPageTracking,
    total: totalTrackingList,
    error: errorTrackingList,
    loading: loadingTrackingList,
  } = useSelector((state) => state.trackingPosts.list);

  useEffect(() => {
    setIsSubmiting(loadingSubmit);

    if (successSubmit) {
      setNewPostMessage('Link successfully added!');
      setNewPostLink(null);
    } else if (errorSubmit) {
      setNewPostMessage('An error has occurred, please try again later.');
    }
  }, [loadingSubmit, successSubmit, errorSubmit, dataSubmit]);

  const handleAddPost = (event) => {
    event.preventDefault();
    if (newPostLink) {
      dispatch(trackingPostAddRequest(newPostLink, campaingId));
    }
  };

  const handleFirstPageTrackingPost = () => {
    dispatch(trackingPostListRequest(campaingId, 1));
  };

  const handleLastPageTrackingPost = () => {
    dispatch(trackingPostListRequest(campaingId, lastPageTracking));
  };

  const handlePaginateTrackingPost = (selectedPage) => {
    dispatch(trackingPostListRequest(campaingId, selectedPage));
  };

  const getInitialData = useCallback(() => {
    dispatch(trackingPostListRequest(campaingId, 1));
  }, [dispatch, campaingId]);

  const handleOpenModal = (post) => {
    setPostSelected(post);
    setOpenPostModal(true);
  };

  const handleCloseModal = () => {
    setOpenPostModal(false);
  };

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const getEngagement = (lastTracking) => {
    if (lastTracking) {
      const { total_likes, total_comments } = lastTracking;

      return `${((total_likes + total_comments) / 1000).toFixed(0)}k`;
    }
    return 0;
  };

  const setTableBody = useCallback(() => {
    const tableBody = trackings.map((item) => {
      const { network_identifier, created_at, last_tracking } = item;

      const addedOn = formatDate(created_at);
      const publishedAt = last_tracking?.published_at
        ? formatDate(last_tracking.published_at)
        : '-';
      const network = renderCreatorNetwork(network_identifier);

      return {
        elements: [
          { content: renderAvatar(handleOpenModal, item) },
          { content: last_tracking?.username || '-', center: true },
          { content: addedOn, center: true },
          { content: network, center: true },
          { content: publishedAt, center: true },
          { content: last_tracking?.total_likes || '-', center: true },
          { content: last_tracking?.total_comments || '-', center: true },
          { content: last_tracking?.total_views || '-', center: true },
          { content: renderDeleteIcon(), center: true },
        ],
      };
    });

    return getTableBodyArray(tableBody);
  }, [trackings]);

  if (loadingTrackingList || !trackings) {
    return <Loader />;
  }

  if (errorTrackingList) {
    return (
      <LoadingDataErrorHandling
        refreshData={() => dispatch(trackingPostListRequest(campaingId, 1))}
      />
    );
  }

  return (
    <Container>
      <TableMetrics>
        <div className="total">TOTAL POSTS:</div>
        <div className="metrics">
          <li>
            <span>Profiles</span>
            {totalTrackingList}
          </li>
          <li>
            <span>Unique Clicks</span>12
          </li>
          <li>
            <span>Total Clicks</span>10
          </li>
        </div>
      </TableMetrics>
      <TableHeader>POST</TableHeader>
      <FormContainer>
        <div className="form">
          <div className="formGroup">
            <label htmlFor="link">LINK</label>
            <input
              id="link"
              type="text"
              name="link"
              placeholder="Post link"
              onChange={(event) => setNewPostLink(event.target.value)}
            />
          </div>
          <Button
            className="negative"
            width={260}
            onClick={handleAddPost}
            loading={isSubmiting}
            disabled={isSubmiting}
          >
            <>
              {!isSubmiting && <AddRoundedIcon style={{ marginRight: 8 }} />}
              ADD POST TO TRACK
            </>
          </Button>
        </div>
        {newPostMessage && (
          <div className="successMessage">{newPostMessage}</div>
        )}
      </FormContainer>
      {trackings.length > 0 && (
        <>
          <Table
            theadList={getTableHeaderArray([
              { content: 'POST', props: { width: 136 } },
              { content: 'USER' },
              { content: 'ADDED ON' },
              { content: 'NETWORK' },
              { content: 'POSTED ON' },
              { content: 'LIKES' },
              { content: 'COMMENTS' },
              { content: 'VIEWS' },
              { content: 'DELETE' },
            ])}
            tbodyList={setTableBody()}
          />
          <Pagination
            handleFirstPage={handleFirstPageTrackingPost}
            handleLastPage={handleLastPageTrackingPost}
            handlePaginate={handlePaginateTrackingPost}
            currentPage={currentPageTracking}
            totalPages={lastPageTracking}
          />
          {postSelected && (
            <Modal
              width={1140}
              height={599}
              isOpen={openPostModal}
              handleCloseModal={handleCloseModal}
            >
              <ModalContent>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseModal}
                >
                  <img src={CloseSvg} alt="close modal" />
                </button>
                <div className="picture">
                  <img
                    src={postSelected.last_tracking?.path_media || tableImage}
                    alt="instagram"
                  />
                </div>
                <div className="info">
                  <span className="title">Post Detail:</span>
                  <div className="engagementMetrics">
                    <li>
                      <img src={LikeSvg} alt="Likes" />
                      {postSelected.last_tracking?.total_likes || '-'}
                    </li>
                    <li>
                      <img src={CommentsSvg} alt="Comments" />
                      {postSelected.last_tracking?.total_comments || '-'}
                    </li>
                    <li>
                      <img src={EngagementSvg} alt="Engagement" />
                      {getEngagement(postSelected.last_tracking) || '-'}{' '}
                      engagement
                    </li>
                  </div>
                  <div className="engagementMetrics">
                    <li>
                      <img src={CalendarSvg} alt="Calendar" />
                      {postSelected.last_tracking
                        ? formatDate(postSelected.last_tracking.last_update)
                        : '-'}
                    </li>
                    {/* <li>
                      <img src={LocationSvg} alt="Location" /> Brazil
                    </li> */}
                    <li>
                      <a
                        href="https://instagram.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ShareSvg} alt="Share" />
                      </a>
                    </li>
                  </div>
                  <p className="description">
                    {postSelected.last_tracking?.description || '-'}
                  </p>
                </div>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
      {trackings.length === 0 && (
        <ContainerEmpty className="container-empty">
          <p>You haven't added any posts to the campaign yet.</p>
        </ContainerEmpty>
      )}
    </Container>
  );
}

TrackingPosts.propTypes = {
  campaingId: PropTypes.string.isRequired,
};

export default TrackingPosts;
