import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  searchRequest,
  searchSetFilter,
  searchClear,
} from '~/store/modules/search/actions';

import ToggleFilter from '../ToggleFilter';

import Button from '~/components/Button';
import InputTags from '~/components/InputTags';
import Checkbox from '~/components/Checkbox';
import InputSearch from '~/components/InputSearch';

import AudiencePercentage from './AudiencePercentage';

export default function Instragram() {
  const dispatch = useDispatch();

  const network = useSelector((state) => state.search.network_selected);

  const keywords = useSelector(
    (state) => state.search[network].fields.keywords.value
  );

  function handleAddKeyword(keyword) {
    const newKeywords = [...keywords, keyword];
    dispatch(searchSetFilter('keywords', newKeywords));
    if (newKeywords.length > 0) dispatch(searchRequest());
  }

  function handleRemoveKeyword(keywordIndex) {
    const newKeywords = [
      ...keywords.filter(
        (keyword) => keywords.indexOf(keyword) !== keywordIndex
      ),
    ];

    dispatch(searchSetFilter('keywords', newKeywords));
    if (newKeywords.length > 0) dispatch(searchRequest());
  }

  const onlyBio = useSelector(
    (state) => state.search[network].fields.onlyBio.value
  );

  function handleCheckOnlyBio() {
    dispatch(searchSetFilter('onlyBio', !onlyBio));
    if (keywords.length > 0) dispatch(searchRequest());
  }

  const keywordsRemoved = useSelector(
    (state) => state.search[network].fields.keywordsRemoved.value
  );

  function handleAddRemovedKeyword(keyword) {
    const newRemovedKeywords = [...keywordsRemoved, keyword];
    dispatch(searchSetFilter('keywordsRemoved', newRemovedKeywords));
    if (newRemovedKeywords.length > 0) dispatch(searchRequest());
  }

  function handleDeleteRemovedKeyword(keywordIndex) {
    const newRemovedKeywords = [
      ...keywordsRemoved.filter(
        (keyword) => keywordsRemoved.indexOf(keyword) !== keywordIndex
      ),
    ];

    dispatch(searchSetFilter('keywordsRemoved', newRemovedKeywords));
    if (newRemovedKeywords.length > 0) dispatch(searchRequest());
  }

  const periodFrom = useSelector(
    (state) => state.search[network].fields.periodFrom.value
  );

  function handleChangePeriodFrom(e) {
    dispatch(searchSetFilter('periodFrom', e.target.value));
  }

  function handleKeyPressPeriodFrom(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && periodFrom.length >= 3) {
      dispatch(searchRequest());
    }
  }

  const periodTo = useSelector(
    (state) => state.search[network].fields.periodTo.value
  );

  function handleChangePeriodTo(e) {
    dispatch(searchSetFilter('periodTo', e.target.value));
  }

  function handleKeyPressPeriodTo(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && periodTo.length >= 3) {
      dispatch(searchRequest());
    }
  }

  const regionInfluencerPostMore = useSelector(
    (state) => state.search[network].fields.regionInfluencerPostMore.value
  );

  function handleChangeRegionInfluencerPostMore(e) {
    dispatch(searchSetFilter('regionInfluencerPostMore', e.target.value));
  }

  function handleKeyPressRegionInfluencerPostMore(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && regionInfluencerPostMore.length >= 3) {
      dispatch(searchRequest());
    }
  }

  const audienceRegion = useSelector(
    (state) => state.search[network].fields.audienceRegion.value
  );

  function handleChangeAudienceRegion(e) {
    dispatch(searchSetFilter('audienceRegion', e.target.value));
  }

  function handleKeyPressAudienceRegion(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && audienceRegion.length >= 3) {
      dispatch(searchRequest());
    }
  }

  const audienceRegionLimit = useSelector(
    (state) => state.search[network].fields.audienceRegionLimit.value
  );

  const audienceGender = useSelector(
    (state) => state.search[network].fields.audienceGender.value
  );

  function handleAudienceGender(e) {
    dispatch(searchSetFilter('audienceGender', e.target.value));
    dispatch(searchRequest());
  }

  const audienceGenderLimit = useSelector(
    (state) => state.search[network].fields.audienceGenderLimit.value
  );

  const audienceAge = useSelector(
    (state) => state.search[network].fields.audienceAge.value
  );

  function handleAudienceAge(e) {
    dispatch(searchSetFilter('audienceAge', e.target.value));
    dispatch(searchRequest());
  }

  const audienceAgeLimit = useSelector(
    (state) => state.search[network].fields.audienceAgeLimit.value
  );

  const aemMin = useSelector(
    (state) => state.search[network].fields.aemMin.value
  );

  function handleChangeAemMin(e) {
    dispatch(searchSetFilter('aemMin', e.target.value));
  }

  function handleKeyPressAemMin(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && aemMin.length > 0) {
      dispatch(searchRequest());
    }
  }

  const aemMax = useSelector(
    (state) => state.search[network].fields.aemMax.value
  );

  function handleChangeAemMax(e) {
    dispatch(searchSetFilter('aemMax', e.target.value));
  }

  function handleKeyPressAemMax(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && aemMax.length > 0) {
      dispatch(searchRequest());
    }
  }

  const followersMin = useSelector(
    (state) => state.search[network].fields.followersMin.value
  );

  function handleChangeSubscribersMin(e) {
    dispatch(searchSetFilter('followersMin', e.target.value));
  }

  function handleKeyPressFollowersMin(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && followersMin.length > 0) {
      dispatch(searchRequest());
    }
  }

  const followersMax = useSelector(
    (state) => state.search[network].fields.followersMax.value
  );

  function handleChangeSubscribersMax(e) {
    dispatch(searchSetFilter('followersMax', e.target.value));
  }

  function handleKeyPressFollowersMax(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && followersMax.length > 0) {
      dispatch(searchRequest());
    }
  }

  function handleSubmit() {
    dispatch(searchRequest());
  }

  function handleClear() {
    dispatch(searchClear());
  }

  return (
    <>
      <InputTags
        tags={keywords}
        placeholder="Enter keywords or @username"
        handleAddTag={handleAddKeyword}
        handleRemoveTag={handleRemoveKeyword}
      />

      <div className="row">
        <Checkbox
          name="onlyBio"
          onChange={handleCheckOnlyBio}
          checked={onlyBio}
        />
        <label>Search only in bio</label>
      </div>

      <ToggleFilter
        title="Remove Keywords"
        info="You can type keywords to remove certain results from the searchNew."
      >
        <InputTags
          tags={keywordsRemoved}
          placeholder="Remove keywords"
          handleAddTag={handleAddRemovedKeyword}
          handleRemoveTag={handleDeleteRemovedKeyword}
        />
      </ToggleFilter>

      <ToggleFilter title="Filter posts by a period of time">
        <div className="row-from-to">
          <div className="col-from-to">
            <label>From</label>
            <InputMask
              mask="99/99/9999"
              value={periodFrom}
              onChange={handleChangePeriodFrom}
              onKeyPress={handleKeyPressPeriodFrom}
            />
          </div>
          <div className="col-from-to">
            <label>To</label>
            <InputMask
              mask="99/99/9999"
              value={periodTo}
              onChange={handleChangePeriodTo}
              onKeyPress={handleKeyPressPeriodTo}
            />
          </div>
        </div>
      </ToggleFilter>

      <ToggleFilter title="Region where the influencer posts more">
        <div className="row">
          <div className="col full">
            <InputSearch
              placeholder="Type the cities, states or countries"
              value={regionInfluencerPostMore}
              onChange={handleChangeRegionInfluencerPostMore}
              onKeyPress={handleKeyPressRegionInfluencerPostMore}
              lightTheme
            />
            <div className="instruction">Minimum 3 letters</div>
          </div>
        </div>
      </ToggleFilter>

      <ToggleFilter title="Audience">
        <>
          <div className="row">
            <div className="col full">
              <strong>Region</strong>
              <div className="row">
                <div className="col full">
                  <InputSearch
                    placeholder="Type the cities, states or countries"
                    value={audienceRegion}
                    onChange={handleChangeAudienceRegion}
                    onKeyPress={handleKeyPressAudienceRegion}
                    lightTheme
                  />
                  <div className="instruction">Minimum 3 letters</div>
                </div>
              </div>
              <AudiencePercentage
                handleChange={(value) =>
                  dispatch(searchSetFilter('audienceRegionLimit', value))
                }
                initialValue={audienceRegionLimit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col full">
              <strong>Gender</strong>
              <div className="container-select">
                <FormControl variant="outlined">
                  <Select
                    id="demo-simple-select-outlined"
                    value={audienceGender}
                    onChange={handleAudienceGender}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <AudiencePercentage
                handleChange={(value) =>
                  dispatch(searchSetFilter('audienceGenderLimit', value))
                }
                initialValue={audienceGenderLimit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col full">
              <strong>Age</strong>
              <div className="container-select">
                <FormControl variant="outlined">
                  <Select
                    id="demo-simple-select-outlined"
                    value={audienceAge}
                    onChange={handleAudienceAge}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="13-17">13-17</MenuItem>
                    <MenuItem value="18-24">18-24</MenuItem>
                    <MenuItem value="25-34">25-34</MenuItem>
                    <MenuItem value="35-44">35-44</MenuItem>
                    <MenuItem value="45-64">45-64</MenuItem>
                    <MenuItem value="65-65">65+</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <AudiencePercentage
                handleChange={(value) =>
                  dispatch(searchSetFilter('audienceAgeLimit', value))
                }
                initialValue={audienceAgeLimit}
              />
            </div>
          </div>
        </>
      </ToggleFilter>

      <ToggleFilter title="Metrics">
        <>
          <strong>Average Engagement (AEM)</strong>
          <div className="row-from-to">
            <div className="col-from-to">
              <label>From</label>
              <input
                type="number"
                placeholder="Enter value"
                value={aemMin}
                onChange={handleChangeAemMin}
                onKeyPress={handleKeyPressAemMin}
              />
            </div>
            <div className="col-from-to">
              <label>To</label>
              <input
                type="number"
                placeholder="Enter value"
                value={aemMax}
                onChange={handleChangeAemMax}
                onKeyPress={handleKeyPressAemMax}
              />
            </div>
          </div>

          <strong>Followers</strong>
          <div className="row-from-to">
            <div className="col-from-to">
              <label>From</label>
              <input
                type="number"
                placeholder="Enter value"
                value={followersMin}
                onChange={handleChangeSubscribersMin}
                onKeyPress={handleKeyPressFollowersMin}
              />
            </div>
            <div className="col-from-to">
              <label>To</label>
              <input
                type="number"
                placeholder="Enter value"
                value={followersMax}
                onChange={handleChangeSubscribersMax}
                onKeyPress={handleKeyPressFollowersMax}
              />
            </div>
          </div>
        </>
      </ToggleFilter>

      <Button onClick={handleSubmit}>SEARCH</Button>
      <Button onClick={handleClear} className="negative">
        CLEAR SEARCH
      </Button>
    </>
  );
}
