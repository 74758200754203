import { addDays } from 'date-fns';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { serviceWrapperSaga } from '~/helpers';
import { formatDateToString } from '~/helpers/utils/date.util';
import { apiBeTrackingPosts } from '~/services/api';

import {
  trackingPostActions,
  trackingPostAddSuccess,
  trackingPostAddFailure,
  trackingPostListFailure,
  trackingPostListSuccess,
} from './actions';

export function* listPostToTracking({ payload }) {
  const { networkIdentifier, campaignId, page } = payload;
  const limit = 10;

  const params = {
    page: Number(page),
    limit,
    campaign_id: campaignId,
  };

  if (networkIdentifier) {
    params.network_identifier = networkIdentifier;
  }

  const response = yield serviceWrapperSaga(
    apiBeTrackingPosts.get,
    'posts_monitor',
    {
      params,
    }
  );

  if (response.status >= 400) {
    yield put(trackingPostListFailure());
  } else {
    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);
    const total = response.headers['x-total-count'];
    yield put(trackingPostListSuccess(response.data, lastPage, total));
  }
}

export function* addPostToTracking({ payload }) {
  try {
    const { networkIdentifier, campaignId } = payload;

    const start_time = new Date();
    const end_time = addDays(start_time, 30);

    const response = yield call(apiBeTrackingPosts.post, 'posts_monitor', {
      network_identifier: networkIdentifier,
      campaign_id: campaignId,
      start_time: formatDateToString(start_time),
      end_time: formatDateToString(end_time),
    });

    yield put(trackingPostAddSuccess(response));
  } catch (error) {
    yield put(trackingPostAddFailure());
  }
}

export default all([
  takeLatest(trackingPostActions.TRACKING_POST_ADD_REQUEST, addPostToTracking),
  takeLatest(
    trackingPostActions.TRACKING_POST_LIST_REQUEST,
    listPostToTracking
  ),
]);
