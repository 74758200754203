import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TextareaAutosize from 'react-textarea-autosize';

import { Container } from './styles';

function Comment({ data, handleCommentEdit }) {
  const [edited, setEdited] = useState(false);
  const [comment, setComment] = useState(data.comment);

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setComment(e.target.value.replace(/\n/g, ''));
  };

  const handleInputBlur = () => {
    setEdited(false);
    if (data.comment.trim() !== comment.trim()) {
      handleCommentEdit(data.id, comment, data.comment);
    }
  };

  const handleInputKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13 && comment.trim().length > 0 && !e.shiftKey) {
      handleInputBlur();
    }
  };

  return (
    <Container>
      <div className="head">
        <div className="infos">
          <span className="added-by">{data.added_by.name}</span>
          <span className="created">
            {format(new Date(data.created), 'dd/MM/yyyy')}
          </span>
        </div>
        <EditOutlinedIcon
          onClick={() => setEdited(true)}
          style={{
            fontSize: 18,
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer',
          }}
        />
      </div>
      {edited ? (
        <TextareaAutosize
          placeholder="Edit comment"
          value={comment}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleInputKeyPress}
          autoFocus
          ref={inputRef}
        />
      ) : (
        <div className="comment">{data.comment}</div>
      )}
    </Container>
  );
}

Comment.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    added_by: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    created: PropTypes.string,
    updated: PropTypes.string,
  }).isRequired,
  handleCommentEdit: PropTypes.func.isRequired,
};

export default memo(Comment);
