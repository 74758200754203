import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  height: 100%;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .icon-wrapper {
      color: #818181;

      &:hover {
        color: ${colorPalette.primary};
      }

      &.close-modal {
        cursor: pointer;
      }
    }
  }

  .error-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.48;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .modal-description {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
    }

    .success {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.48;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
    }

    .options {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 15px 5px 0;

        span {
          margin-left: 10px;
          line-height: 1.37;
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    strong,
    li {
      line-height: 1.69;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
    }

    .error {
      line-height: 1.69;
      letter-spacing: 0.1px;
      color: red;
    }

    textarea {
      height: 98px;
      border-radius: 4px;
      border: solid 1px #ccc;
      margin-top: 10px;
      resize: none;
      padding: 10px;
      font: 14px 'Rubik', sans-serif;
      color: rgba(0, 0, 0, 0.6);

      &::placeholder {
        font: 14px 'Rubik', sans-serif;
      }
    }

    .textarea-label {
      letter-spacing: 0.4px;
      color: red;
      margin-top: 10px;
      font-size: 12px;
    }
  }

  .modal-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    &.success {
      margin-top: 40px;
      justify-content: center;
    }

    span {
      color: #818181;
      cursor: pointer;
      font-weight: bold;
      letter-spacing: 1.25px;
      color: rgba(0, 0, 0, 0.6);
      margin-left: 30px;

      &:hover {
        color: ${colorPalette.primary};
      }
    }
  }
`;
