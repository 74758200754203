import styled from 'styled-components';

export const Container = styled.div`
  .row-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;

    strong {
      cursor: pointer;
    }
  }
`;
