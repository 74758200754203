import React, { useEffect, useState, useRef } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';

import { Container } from './styles';

function InputEditTitle({ width, value, onBlur, ...props }) {
  const [active, setActive] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (active) {
      inputRef.current.focus();
    }
  }, [active]);

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = () => {
    setActive(false);
    onBlur();
  };

  return (
    <Container active={active} width={width}>
      <input
        type="text"
        value={value}
        onBlur={handleBlur}
        ref={inputRef}
        {...props}
      />
      <div className="overlap-title-edit" onClick={handleFocus}>
        <h2 className="page-title">{value}</h2>
        <EditOutlinedIcon style={{ fontSize: 25, color: '#757575' }} />
      </div>
    </Container>
  );
}

InputEditTitle.defaultProps = {
  width: 300,
  onBlur: () => false,
};

InputEditTitle.propTypes = {
  width: PropTypes.number,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

export default InputEditTitle;
