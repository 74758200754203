import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 35px;
  margin-left: 340px;

  @media (max-width: 1366px) {
    padding: 25px;
  }

  .container-loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .results-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;

    .sort-by {
      display: flex;
      flex-direction: column;

      .label {
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }

      .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 32px;
        font-size: 14px;
      }
    }
  }

  .profiles {
    display: grid;
    grid-gap: 2rem 1rem;

    @media (min-width: 1920px) and (max-width: 2560px) {
      grid-template-columns: repeat(7, 288px);
    }
    @media (max-width: 1920px) {
      grid-template-columns: repeat(5, 285px);
    }
    @media (min-width: 1681px) and (max-width: 1900px) {
      grid-template-columns: repeat(4, 304px);
    }
    @media (max-width: 1680px) {
      grid-template-columns: repeat(4, 297px);
    }
    @media (max-width: 1366px) {
      grid-template-columns: repeat(3, 303px);
    }
    @media (max-width: 1280px) {
      grid-template-columns: repeat(3, 275px);
    }
  }

  .no-results {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 60px;

    .no-results-content {
      display: flex;
      flex-direction: row;

      img {
        height: 260px;
      }

      .info {
        margin-left: 25px;

        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 10px;
          font-weight: 700;

          span {
            color: ${colorPalette.primary};
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .search-empty {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media (max-width: 1366px) {
      img {
        width: 350px;
      }
    }
  }

  .paginate {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    button {
      width: 150px;
      margin: 0 10px;
    }
  }
`;
