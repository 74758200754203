import produce from 'immer';
import { duplicateCreatorsActions } from './actions';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  success: false,
};

export default function duplicateCreators(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case duplicateCreatorsActions.DUPLICATE_CREATORS_REQUEST: {
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        break;
      }
      case duplicateCreatorsActions.DUPLICATE_CREATORS_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        break;
      }
      case duplicateCreatorsActions.DUPLICATE_CREATORS_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case duplicateCreatorsActions.DUPLICATE_CREATORS_RESET: {
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      }
      default:
    }
  });
}
