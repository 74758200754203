import produce from 'immer';
import types from '../types';

export const INITIAL_STATE = {
  list: {
    loading: false,
    error: false,
    data: [],
    dataSearch: [],
    selected: [],
  },
};

export default function sov(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SOV_LIST_SEARCH: {
        const searchValue = action.payload.groupName.toLowerCase();
        draft.list.dataSearch = draft.list.data.filter(
          (group) => group.name.toLowerCase().indexOf(searchValue) > -1
        );
        break;
      }
      case types.SOV_LIST_SELECT: {
        const { selected } = draft.list;
        const { groupId } = action.payload;

        if (selected.includes(groupId)) {
          const filter = draft.list.selected.filter((id) => id !== groupId);
          draft.list.selected = filter;
        } else {
          draft.list.selected = [...draft.list.selected, groupId];
        }
        break;
      }
      case types.SOV_LIST_REQUEST: {
        draft.list.loading = true;
        draft.list.error = false;
        draft.list.data = [];
        draft.list.dataSearch = [];
        draft.list.selected = [];
        break;
      }
      case types.SOV_LIST_SUCCESS: {
        draft.list.loading = false;
        draft.list.data = action.payload.data;
        break;
      }
      case types.SOV_LIST_FAILURE: {
        draft.list.loading = false;
        draft.list.error = true;
        break;
      }
      case types.SOV_LIST_CLEAR_SELECTED: {
        draft.list.selected = [];
        break;
      }
      default:
    }
  });
}
