import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import colorPalette from '~/styles/colorPalette';

function RadioButton({ isActive, onClick }) {
  return isActive ? (
    <RadioButtonCheckedIcon
      onClick={onClick}
      style={{ color: colorPalette.primary, cursor: 'pointer' }}
    />
  ) : (
    <RadioButtonUncheckedIcon
      onClick={onClick}
      style={{ color: '#00000099', cursor: 'pointer' }}
    />
  );
}

RadioButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.bool.isRequired,
};

export default RadioButton;
