import produce from 'immer';
import { statisticsTypes } from './actions';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
};

export default function creatorStatistics(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case statisticsTypes.STATISTICS_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case statisticsTypes.STATISTICS_SUCCESS: {
        const { data } = action.payload;

        draft.data = data;
        draft.loading = false;
        break;
      }
      case statisticsTypes.STATISTICS_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}
