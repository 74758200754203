import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Chart from 'react-apexcharts';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

import history from '~/services/history';

import ModalDeleteContent from '~/components/ModalDeleteContent';

import {
  overlapSetTitle,
  overlapNewSaveRequest,
  overlapNewClearList,
  overlapEditGetInfluencersRequest,
  overlapNewDeleteRequest,
} from '~/store/modules/overlap/new/actions';

import InputSearchCreators from '~/components/InputSearchCreators';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import Loader from '~/components/Loader';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import InputEditTitle from '~/components/InputEditTitle';
import Checkbox from '~/components/Checkbox';
import Footer from '~/containers/Footer';

import { abbreviateNumber } from '~/helpers';

import { Container, ModalSettings } from './styles';

export default function NewOverlap({ match }) {
  const dispatch = useDispatch();

  const { id: overlapId } = match.params;

  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [checkedCreators, setCheckedCreators] = useState([]);
  const [graphicData, setGraphicData] = useState(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const [menuSettings, setMenuSetting] = useState(false);

  const title = useSelector((state) => state.overlapNew.title);

  // SAVE STATES (NEW AND UPDATE)
  const loadingSave = useSelector((state) => state.overlapNew.save.loading);
  const errorSave = useSelector((state) => state.overlapNew.save.loading);

  // GET INFLUENCERS STATES (UPDATE)
  const loadingGetInfluencers = useSelector(
    (state) => state.overlapNew.update.getInfluencers.loading
  );
  const dataGetInfluencers = useSelector(
    (state) => state.overlapNew.update.getInfluencers.data
  );
  const errorGetInfluencers = useSelector(
    (state) => state.overlapNew.update.getInfluencers.error
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const completedDelete = useSelector(
    (state) => state.overlapNew.delete.completedDelete
  );
  const loadingDelete = useSelector((state) => state.overlapNew.delete.loading);
  const errorDelete = useSelector((state) => state.overlapNew.delete.error);
  const { access_token } = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(overlapNewClearList());
  }, [dispatch]);

  useEffect(() => {
    setModalIsOpen(false);
  }, [completedDelete]);

  // Limpa lista influenciadores selecionados
  useEffect(() => {
    if (!overlapId) {
      setSelectedInfluencers([]);
      dispatch(overlapNewClearList());
    }
  }, [dispatch, overlapId]);

  // Busca os influenciadores deste overlap
  useEffect(() => {
    if (overlapId) dispatch(overlapEditGetInfluencersRequest(overlapId));
  }, [dispatch, overlapId]);

  // Atualiza a lista de influenciadores selecionados com os influenciadores deste overlap
  useEffect(() => {
    if (overlapId && dataGetInfluencers) {
      setSelectedInfluencers(dataGetInfluencers.creator_networks);
    }
  }, [dataGetInfluencers, dispatch, overlapId]);

  useEffect(() => {
    setCheckedAll(checkedCreators.length === selectedInfluencers.length);
  }, [checkedCreators, selectedInfluencers]);

  function selectCheckedCreators(newCheckedCreators) {
    let labels = [];
    let dataUniqueAudience = [];
    let dataOverlappedAudience = [];

    for (let i = 0; i < newCheckedCreators.length; i += 1) {
      const filter = selectedInfluencers.filter(
        (creator) => creator.creator_id === newCheckedCreators[i]
      );
      const creator = filter[0];

      labels = [...labels, creator.creator_network.identifier];
      dataUniqueAudience = [
        ...dataUniqueAudience,
        creator.total_unique_audience.toFixed(1),
      ];
      dataOverlappedAudience = [
        ...dataOverlappedAudience,
        creator.total_overlapped_audience.toFixed(1),
      ];
    }

    setCheckedCreators(newCheckedCreators);
    setGraphicData({
      series: [
        {
          name: 'Unique Audience',
          data: dataUniqueAudience,
          color: '#be9bf4',
        },
        {
          name: 'Overlapped Audience',
          data: dataOverlappedAudience,
          color: 'rgb(75, 192, 192)',
        },
      ],

      options: {
        fill: {
          colors: ['#be9bf4', 'rgb(75, 192, 192)'],
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
        },
        dataLabels: {
          enabled: true,
          offsetX: 30,
          formatter(val) {
            return `${val}%`;
          },
          style: {
            fontFamily: 'Hind, sans-serif',
            colors: ['#000'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontFamily: 'Hind, sans-serif',
          },
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: 'Hind, sans-serif',
              fontWeight: 'bold',
            },
          },
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              fontFamily: 'Hind, sans-serif',
            },
            formatter: (value) => `${value}%`,
          },
        },
      },
    });
  }

  function checkCreator(creatorId) {
    const newCheckedCreators = checkedCreators.includes(creatorId)
      ? checkedCreators.filter((id) => id !== creatorId)
      : [...checkedCreators, creatorId];

    selectCheckedCreators(newCheckedCreators);
  }

  function checkAllCreators() {
    if (checkedAll) {
      setCheckedCreators([]);
      setGraphicData(null);
      setCheckedAll(false);
    } else {
      const newCheckedCreators = selectedInfluencers.map(
        (creator) => creator.creator_id
      );
      selectCheckedCreators(newCheckedCreators);
      setCheckedAll(true);
    }
  }

  function handleAddInfluencer(creatorNetwork) {
    creatorNetwork.total_overlapped_audience = 0;
    creatorNetwork.total_unique_audience = 0;
    setSelectedInfluencers([creatorNetwork, ...selectedInfluencers]);
  }

  function handleRemoveInfluencer(creatorNetworkId, creatorId) {
    const newList = selectedInfluencers.filter(
      (row) => row.creator_network.id !== creatorNetworkId
    );
    setSelectedInfluencers(newList);
    if (overlapId && checkedCreators.length > 0) {
      checkCreator(creatorId);
    }
  }

  function handleSubmitSave() {
    if (!loadingSave && selectedInfluencers.length >= 2) {
      dispatch(overlapNewSaveRequest(selectedInfluencers));
    }
  }

  function handleSubmitUpdate() {
    if (!loadingSave && selectedInfluencers.length >= 2) {
      dispatch(overlapNewSaveRequest(selectedInfluencers, overlapId));
    }
  }

  function handleShowModal() {
    setModalIsOpen(true);
  }

  function handleHideModal() {
    setModalIsOpen(false);
  }

  function handleConfirmDelete() {
    dispatch(overlapNewDeleteRequest(overlapId));
  }

  function handleExportCSV() {
    window.location.href = `${process.env.REACT_APP_API_BE_OVERLAP}/v1/overlaps/${overlapId}/export?format=csv&access_token=${access_token}`;
  }

  function handleExportXLS() {
    window.location.href = `${process.env.REACT_APP_API_BE_OVERLAP}/v1/overlaps/${overlapId}/export?format=xlsx&access_token=${access_token}`;
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        handleCloseModal={handleHideModal}
        width={400}
        height={220}
      >
        <ModalDeleteContent
          error={errorDelete}
          loading={loadingDelete}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleHideModal}
          title="Delete overlap"
          text="Do you want to delete this overlap?"
        />
      </Modal>
    );
  }

  function calculateGraphicHeight() {
    if (checkedCreators.length <= 10) {
      return 600;
    }

    return checkedCreators.length * 50;
  }

  function toggleMenuSetting() {
    if (menuSettings === false) {
      setMenuSetting(true);
    } else {
      setMenuSetting(false);
    }
  }

  function renderCreatorProcess(process, show_process) {
    if (!process) {
      return '0%';
    }

    if (!show_process) {
      return '100%';
    }

    return `${process}%`;
  }

  if (loadingGetInfluencers || loadingSave) {
    return <Loader />;
  }

  if (errorGetInfluencers) {
    return (
      <Container>
        <LoadingDataErrorHandling
          refreshData={() =>
            dispatch(overlapEditGetInfluencersRequest(overlapId))
          }
        />
      </Container>
    );
  }

  if (errorSave) {
    return (
      <Container>
        <LoadingDataErrorHandling
          refreshData={() =>
            dispatch(dispatch(overlapNewSaveRequest(selectedInfluencers)))
          }
        />
      </Container>
    );
  }

  return (
    <Container>
      <div className="grid content">
        <div className="col-left">
          <div className="header-options-container">
            <div className="title-container">
              <ChevronLeftIcon
                style={{
                  fontSize: 40,
                  color: '#757575',
                  marginRight: 10,
                  cursor: 'pointer',
                }}
                onClick={() => history.push('/overlap')}
              />
              <InputEditTitle
                width={480}
                value={title}
                onChange={(e) => dispatch(overlapSetTitle(e.target.value))}
              />
            </div>
            {overlapId && (
              <div className="buttons-container">
                <SettingsIcon
                  style={{
                    fontSize: 25,
                    color: '#757575',
                    cursor: 'pointer',
                  }}
                  className="settings-icon"
                  onClick={() => toggleMenuSetting()}
                />
                {menuSettings && (
                  <ModalSettings>
                    <button type="button" onClick={handleExportCSV}>
                      <SaveAltOutlinedIcon
                        style={{
                          fontSize: 25,
                          color: '#757575',
                          marginLeft: 10,
                          marginRight: 10,
                          cursor: 'pointer',
                        }}
                      />
                      Download CVS
                    </button>
                    <button type="button" onClick={handleExportXLS}>
                      <SaveAltOutlinedIcon
                        style={{
                          fontSize: 25,
                          color: '#757575',
                          marginLeft: 10,
                          marginRight: 10,
                          cursor: 'pointer',
                        }}
                      />
                      Download XLS
                    </button>
                    <button className="btn-top" type="button">
                      <DeleteOutlinedIcon
                        style={{
                          fontSize: 25,
                          color: '#757575',
                          marginLeft: 10,
                          marginRight: 10,
                          cursor: 'pointer',
                        }}
                        onClick={handleShowModal}
                      />
                      Delete
                    </button>
                  </ModalSettings>
                )}
              </div>
            )}
          </div>
          {dataGetInfluencers && (
            <div className="infos">
              <div className="progress-container">
                <div
                  className="progress-content"
                  style={{
                    width: `${
                      dataGetInfluencers.has_ended
                        ? 100
                        : parseInt(dataGetInfluencers.process, 10)
                    }%`,
                  }}
                />
              </div>
              <div className="labels">
                <span>{`${
                  dataGetInfluencers.has_ended
                    ? 100
                    : parseInt(dataGetInfluencers.process, 10)
                }% progress - Overlap information is being collected.`}</span>
              </div>
              <div className="overlap-totals">
                <h2 className="total">{`Unique Audience of this Sampling ${dataGetInfluencers.formatted_total_unique_audience}%`}</h2>
                <h2 className="total">{`Total Samples ${abbreviateNumber(
                  dataGetInfluencers.total_samples,
                  3
                )}`}</h2>
              </div>
            </div>
          )}
          <div className="container-input-search">
            <InputSearchCreators
              isOverlap
              handleSelectCreator={handleAddInfluencer}
            />
          </div>
          <Scrollbars
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '500px',
            }}
          >
            <table>
              <tr>
                <th>
                  <Checkbox
                    name="creators[]"
                    checked={
                      checkedCreators.length === selectedInfluencers.length
                    }
                    onClick={checkAllCreators}
                  />
                </th>
                <th colSpan={2}>&nbsp;</th>
                <th>TOTAL SAMPLES</th>
                <th>U.A</th>
                <th>O.A</th>
                <th>PROCESSED</th>
                <th>&nbsp;</th>
              </tr>
              {selectedInfluencers.map((creator) => (
                <tr key={creator.creator_id}>
                  <td colSpan={3}>
                    <div className="creator">
                      {overlapId && (
                        <div className="checkbox-container">
                          <Checkbox
                            name="creators[]"
                            checked={checkedCreators.includes(
                              creator.creator_id
                            )}
                            onClick={() => checkCreator(creator.creator_id)}
                          />
                        </div>
                      )}
                      <div
                        className="thumb"
                        style={{
                          backgroundImage: `url(${creator.creator_network.profile_picture})`,
                        }}
                      />
                      <div className="creator-infos">
                        <a
                          href={`/creator/${creator.creator_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="name">
                            {creator.creator_network.title}
                          </span>
                          <span className="identifier">{`@${creator.creator_network.identifier}`}</span>
                          <span className="network">
                            {creator.creator_network.network}
                          </span>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>{` ${abbreviateNumber(
                    creator.total_samples || 0,
                    3
                  )}`}</td>
                  <td>{`${creator.formatted_total_unique_audience || 0}% `}</td>
                  <td>{` ${abbreviateNumber(
                    creator.total_overlapped_audience || 0,
                    3
                  )}%`}</td>
                  <td>
                    {renderCreatorProcess(
                      creator.process,
                      creator.show_process
                    )}
                  </td>
                  <td>
                    <div
                      className="icon-wrapper"
                      onClick={() =>
                        handleRemoveInfluencer(
                          creator.creator_network.id,
                          creator.creator_id
                        )
                      }
                    >
                      <MdDelete size="25" />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Scrollbars>
        </div>
        <div className="col-right">
          <div className="buttons-container">
            <Button
              className="negative"
              height={40}
              onClick={() =>
                overlapId ? handleSubmitUpdate() : handleSubmitSave()
              }
            >
              SAVE OVERLAP
            </Button>
          </div>
          {overlapId && (
            <div className="graphic-container">
              <h3>ESTIMATED UNIQUE AUDIENCE</h3>
              <p>Select the influencers you want to calculate.</p>
              {checkedCreators.length > 0 && (
                <Chart
                  options={graphicData.options}
                  series={graphicData.series}
                  type="bar"
                  height={calculateGraphicHeight()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
      {renderModal()}
    </Container>
  );
}

NewOverlap.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
