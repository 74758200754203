import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { apiBeCreator } from '~/services/api';

import InputSearchList from '~/components/InputSearchList';

import CreatorListItem from './CreatorListItem';

const initialState = {
  keyword: '',
  data: [],
  loading: false,
  error: false,
  page: 1,
  last_page: 1,
  paginate: {
    loading: false,
    error: false,
  },
};

const InputSearchCreators = ({
  height,
  handleSelectCreator,
  isOverlap,
  isCampaign,
}) => {
  const [state, setState] = useState(initialState);

  const creatorRequest = useCallback(() => {
    setState((oldState) => ({ ...oldState, loading: true, error: false }));
  }, []);

  const creatorSuccess = useCallback((data, lastPage) => {
    setState((oldState) => ({
      ...oldState,
      loading: false,
      data,
      last_page: lastPage,
    }));
  }, []);

  const creatorFailure = useCallback(() => {
    setState((oldState) => ({ ...oldState, loading: false, error: true }));
  }, []);

  const creatorReset = useCallback(() => {
    setState(initialState);
  }, []);

  const creatorRequestPaginate = useCallback(() => {
    setState((oldState) => ({
      ...oldState,
      paginate: { loading: true, error: false },
    }));
  }, []);

  const creatorSuccessPaginate = useCallback((data) => {
    setState((oldState) => ({
      ...oldState,
      data: [...oldState.data, ...data],
      page: oldState.page + 1,
      paginate: {
        loading: false,
        error: false,
      },
    }));
  }, []);

  const creatorFailurePaginate = useCallback(() => {
    setState((oldState) => ({
      ...oldState,
      paginate: { loading: false, error: true },
    }));
  }, []);

  const getInfluencers = useCallback(
    async (keyword) => {
      const { page } = state;

      creatorRequest();

      if (keyword.trim() === '') {
        creatorReset();
        return;
      }

      try {
        const limit = 10;
        const terms = `${keyword}`;

        const params = {
          limit,
          page,
          terms,
        };

        const response = await apiBeCreator.get('search', { params });

        const { data } = response;

        const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

        creatorSuccess(data, lastPage);
      } catch (error) {
        creatorFailure();
      }
    },
    [creatorFailure, creatorRequest, creatorReset, creatorSuccess, state]
  );

  const getInfluencersPaginate = useCallback(async () => {
    try {
      const { page, keyword } = state;

      creatorRequestPaginate();

      const limit = 10;
      const terms = `${keyword}`;

      const params = {
        limit,
        page: page + 1,
        terms,
      };

      const response = await apiBeCreator.get('search', { params });

      creatorSuccessPaginate(response.data);
    } catch (err) {
      creatorFailurePaginate();
    }
  }, [
    creatorFailurePaginate,
    creatorRequestPaginate,
    creatorSuccessPaginate,
    state,
  ]);

  const debouncedSave = useCallback(
    debounce((keyword) => getInfluencers(keyword), 1000),
    []
  );

  const handleChange = (e) => {
    const keyword = e.target.value;
    setState((oldState) => ({ ...oldState, keyword }));
    debouncedSave(keyword);
  };

  const handlePaginate = useCallback(() => {
    setState({ ...state, paginate: { loading: true, error: false } });
    getInfluencersPaginate();
  }, [state, getInfluencersPaginate]);

  const renderCreatorList = useCallback(
    (creators) => {
      return creators.map((creator) =>
        creator.creator_networks.map((network) => {
          if (isOverlap && !network.can_be_added_to_overlap) {
            return null;
          }

          if (isCampaign && !network.can_be_added_to_campaign) {
            return null;
          }

          return (
            <CreatorListItem
              key={creator.id}
              creator={network}
              handleSelect={() =>
                handleSelectCreator({
                  creator_id: creator.id,
                  creator_network: network,
                })
              }
            />
          );
        })
      );
    },
    [handleSelectCreator, isCampaign, isOverlap]
  );

  return (
    <InputSearchList
      placeholder="Search for an influencer"
      height={height}
      handleInputChange={handleChange}
      data={state.data}
      loading={state.loading}
      renderList={renderCreatorList}
      handleFinalScroll={handlePaginate}
      loadingPaginate={state.paginate.loading}
      currentPage={state.page}
      lastPage={state.last_page}
      keyword={state.keyword}
    />
  );
};

InputSearchCreators.defaultProps = {
  height: 50,
  isOverlap: false,
  isCampaign: false,
};

InputSearchCreators.propTypes = {
  height: PropTypes.number,
  handleSelectCreator: PropTypes.func.isRequired,
  isOverlap: PropTypes.bool,
  isCampaign: PropTypes.bool,
};

export default memo(InputSearchCreators);
