export const forgotPasswordRequestActions = {
  SEND_EMAIL_REQUEST: '@forgotPassword/request/SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: '@forgotPassword/request/SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: '@forgotPassword/request/SEND_EMAIL_FAILURE',
};

export function sendEmailRequest(email) {
  return {
    type: forgotPasswordRequestActions.SEND_EMAIL_REQUEST,
    payload: { email },
  };
}

export function sendEmailSuccess() {
  return {
    type: forgotPasswordRequestActions.SEND_EMAIL_SUCCESS,
  };
}

export function sendEmailFailure() {
  return {
    type: forgotPasswordRequestActions.SEND_EMAIL_FAILURE,
  };
}
