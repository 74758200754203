import React, { useEffect, useState, useRef } from 'react';
import QueueIcon from '@material-ui/icons/Queue';
import { useDispatch, useSelector } from 'react-redux';
import CachedIcon from '@material-ui/icons/Cached';
import { Scrollbars } from 'react-custom-scrollbars';
import { Container } from './styles';
import { CustomSwitch } from '../Campaigns/CampaignItem/styles';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import { creatorsQueueRequest } from '~/store/modules/creatorsQueue/actions';
import { useOutsideAlerter } from '~/helpers';

function CreatorsQueue() {
  const dispatch = useDispatch();

  const floatListRef = useRef(null);

  const [floatListIsVisible, setFloatListIsVisible] = useState(false);
  const [isMine, setIsMine] = useState(true);

  const creators = useSelector((state) => state.creatorsQueue.creators);
  const loading = useSelector((state) => state.creatorsQueue.loading);
  const error = useSelector((state) => state.creatorsQueue.error);
  const currentPage = useSelector((state) => state.creatorsQueue.page);
  const lastPage = useSelector((state) => state.creatorsQueue.last_page);
  const loadingPaginate = useSelector(
    (state) => state.creatorsQueue.paginate.loading
  );

  const creatorsInProgress = creators.filter((creator) => creator.done === 0);
  const notifications = creatorsInProgress.length;

  useOutsideAlerter(floatListRef, () => {
    setFloatListIsVisible(false);
  });

  useEffect(() => {
    dispatch(creatorsQueueRequest(isMine));
  }, [dispatch, isMine]);

  const handleChangeIsMine = () => {
    if (!loading) {
      setIsMine((state) => !state);
    }
  };

  const handleScroll = (e) => {
    const finalScroll =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (finalScroll && !loadingPaginate && !loading && currentPage < lastPage) {
      dispatch(creatorsQueueRequest(isMine, true));
    }
  };

  const loadQueue = () => {
    if (!loading) {
      dispatch(creatorsQueueRequest(isMine));
    }
  };

  const renderCreatorWithNetwork = (creator) => (
    <div className="creator-item" key={creator.id}>
      <div className="row">
        <a
          href={creator.creator_network.permalink}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="thumbnail"
            style={{
              backgroundImage: `url(${creator.creator_network.profile_picture})`,
            }}
          />
        </a>
        <div className="infos">
          <a
            href={creator.creator_network.permalink}
            target="_blank"
            rel="noreferrer"
            className="name"
          >
            {creator.creator_network.title}
          </a>
          <p className="network">{creator.network.toUpperCase()}</p>
          <div className="process-container">
            <div
              className="percentage"
              style={{
                width: `${Math.round((creator.process / 100) * 100)}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderCreatorWithoutNetwork = (creator) => (
    <div className="creator-item" key={creator.id}>
      <div className="row">
        <div className="thumbnail" />
        <div className="infos">
          <h2 className="identifier">{creator.identifier}</h2>
          <p className="network">{creator.network.toUpperCase()}</p>
          <div className="process-container">
            <div
              className="percentage"
              style={{
                width: `${Math.round((creator.process / 100) * 100)}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderQueue = () => {
    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(creatorsQueueRequest())}
        />
      );
    }

    if (loading) {
      return <Loader />;
    }

    if (creators.length === 0) {
      return <p className="no-results">No data being processed</p>;
    }

    return creators.map((creator) =>
      creator?.creator_network
        ? renderCreatorWithNetwork(creator)
        : renderCreatorWithoutNetwork(creator)
    );
  };

  return (
    <Container ref={floatListRef}>
      <div
        className={`btn-queue-container ${notifications > 0 && 'has-creators'}`}
        onClick={() => setFloatListIsVisible((state) => !state)}
      >
        {notifications > 0 && <div className="amount">{notifications}</div>}
        <QueueIcon />
      </div>

      {floatListIsVisible && (
        <div className="float-creators-queue-container">
          <div className="head">
            <h3>Creators queue</h3>
            <CachedIcon onClick={loadQueue} style={{ cursor: 'pointer' }} />
          </div>
          <div className="is-mine-contas">
            <CustomSwitch checked={isMine} onChange={handleChangeIsMine} />
            <span className="is-mine-label">
              {isMine ? 'Added by me' : 'Added by organization'}
            </span>
          </div>
          <Scrollbars
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              height: '250px',
            }}
            onScroll={handleScroll}
          >
            <div className="queue-list">{renderQueue()}</div>
            {loadingPaginate && <Loader />}
          </Scrollbars>
        </div>
      )}
    </Container>
  );
}

export default CreatorsQueue;
