import produce from 'immer';
import types from '../types';

import { campaignsActions } from '../campaigns/list/actions';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  update_settings: {
    loading: false,
    error: false,
  },
};

export default function profile(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.PROFILE_FETCH_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case types.PROFILE_FETCH_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload.profile;
        break;
      }
      case types.PROFILE_FETCH_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case types.UPDATE_PROFILE_SETTINGS_REQUEST: {
        draft.update_settings.loading = true;
        draft.update_settings.error = false;
        break;
      }
      case types.UPDATE_PROFILE_SETTINGS_SUCCESS: {
        const { settings } = action.payload;

        draft.update_settings.loading = false;
        draft.data.settings = {
          ...draft.data.settings,
          ...settings,
        };
        break;
      }
      case types.UPDATE_PROFILE_SETTINGS_FAILURE: {
        draft.update_settings.loading = false;
        draft.update_settings.error = true;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_DELETE_SUCCESS: {
        if (
          draft.data.settings?.active_campaign?.id === action.payload.campaignId
        ) {
          draft.data.settings.active_campaign_id = null;
          draft.data.settings.active_campaign = null;
        }
        break;
      }
      default:
    }
  });
}
