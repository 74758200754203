export const creatorsQueueActions = {
  CREATORS_QUEUE_REQUEST: '@creatorsQueue/CREATORS_QUEUE_REQUEST',
  CREATORS_QUEUE_SUCCESS: '@creatorsQueue/CREATORS_QUEUE_SUCCESS',
  CREATORS_QUEUE_FAILURE: '@creatorsQueue/CREATORS_QUEUE_FAILURE',
};

export function creatorsQueueRequest(mine, isPaginate = false) {
  return {
    type: creatorsQueueActions.CREATORS_QUEUE_REQUEST,
    payload: { mine, isPaginate },
  };
}

export function creatorsQueueSuccess(creators, lastPage, isPaginate = false) {
  return {
    type: creatorsQueueActions.CREATORS_QUEUE_SUCCESS,
    payload: { creators, lastPage, isPaginate },
  };
}

export function creatorsQueueFailure(isPaginate = false) {
  return {
    type: creatorsQueueActions.CREATORS_QUEUE_FAILURE,
    payload: { isPaginate },
  };
}
