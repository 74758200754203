import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  position: fixed;
  z-index: 20;
  width: 340px;
  height: 100%;
  left: 0;
  top: 0;
  background: #fafafa;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 35px 45px 0;

    .icon-wrapper {
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
    }

    a {
      color: ${colorPalette.primary};
      font-weight: 700;
      font-size: 34px;
    }
  }
  ul {
    margin-top: 100px;

    li {
      padding: 15px 25px;

      &:not(.divider):hover {
        background: #ebebeb;
      }

      &.divider {
        div {
          width: 100%;
          height: 1px;
          opacity: 0.12;
          background-color: rgba(0, 0, 0, 0.87);
        }
      }

      .content-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &.active {
          .icon-wrapper {
            color: ${colorPalette.primary};
          }
          span {
            color: ${colorPalette.primary};
          }
        }

        .icon-wrapper {
          color: rgba(0, 0, 0, 0.6);
        }

        span {
          color: rgba(0, 0, 0, 0.6);
          margin-left: 20px;
          letter-spacing: 0.25px;
        }
      }
    }
  }
`;
