export const duplicateCreatorsActions = {
  DUPLICATE_CREATORS_REQUEST:
    '@campaigns/details/duplicateCreators/DUPLICATE_CREATORS_REQUEST',
  DUPLICATE_CREATORS_SUCCESS:
    '@campaigns/details/duplicateCreators/DUPLICATE_CREATORS_SUCCESS',
  DUPLICATE_CREATORS_FAILURE:
    '@campaigns/details/duplicateCreators/DUPLICATE_CREATORS_FAILURE',
  DUPLICATE_CREATORS_RESET:
    '@campaigns/details/duplicateCreators/DUPLICATE_CREATORS_RESET',
};

export function duplicateCreatorsRequest(campaingIds, creatorsNetworksIds) {
  return {
    type: duplicateCreatorsActions.DUPLICATE_CREATORS_REQUEST,
    payload: { campaingIds, creatorsNetworksIds },
  };
}

export function duplicateCreatorsSuccess() {
  return {
    type: duplicateCreatorsActions.DUPLICATE_CREATORS_SUCCESS,
  };
}

export function duplicateCreatorsFailure() {
  return {
    type: duplicateCreatorsActions.DUPLICATE_CREATORS_FAILURE,
  };
}

export function duplicateCreatorsReset() {
  return {
    type: duplicateCreatorsActions.DUPLICATE_CREATORS_RESET,
  };
}
