import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .grid {
    &.content {
      flex: 1;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 30px;
      .col-left,
      .col-right {
        display: flex;
        flex-direction: column;
        padding-top: 54px;
      }
      .col-left {
        width: 700px;
        .header-options-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .title-container,
          .buttons-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;
            .settings-icon {
              position: absolute;
              right: 43%;
            }
          }
        }
        .infos {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .progress-container {
            width: 100%;
            height: 4px;
            background-color: #d8d8d8;
            .progress-content {
              height: 4px;
              background-color: #be9bf4;
            }
          }
          .labels {
            margin-top: 5px;
            span {
              font-family: 'Rubik';
              font-size: 12.2px;
              line-height: 1.31;
              letter-spacing: 0.4px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          .overlap-totals {
            margin-top: 30px;
            .total {
              font-family: 'Rubik';
              font-size: 20.3px;
              font-weight: 500;
              letter-spacing: 0.25px;
              color: rgba(0, 0, 0, 0.6);
              margin-bottom: 10px;
            }
          }
        }
        .container-input-search {
          width: 100%;
          margin-top: 30px;
        }
        table {
          width: 98%;
          margin-top: 55px;
          border-collapse: collapse;
          tr {
            border-bottom: 1px solid #dbdbdb;
            th,
            td {
              font-family: 'Rubik';
              font-size: 12.2px;
              font-weight: normal;
              line-height: 1.31;
              letter-spacing: 0.4px;
              text-align: center;
              color: rgba(0, 0, 0, 0.6);
              padding-bottom: 15px;
            }
            td {
              padding-top: 15px;
              .creator {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
                .checkbox-container {
                  margin-right: 10px;
                }
                .thumb {
                  width: 60px;
                  height: 60px;
                  border-radius: 3px;
                  background-color: #ccc;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                .creator-infos {
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  text-align: left;
                  a {
                    display: flex;
                    flex-direction: column;
                    .name {
                      font-size: 16px;
                      font-family: 'Rubik';
                      font-weight: 700;
                      letter-spacing: 0.15px;
                      color: rgba(0, 0, 0, 0.87);
                      width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    .identifier {
                      font-size: 14px;
                      letter-spacing: 0.25px;
                      color: rgba(0, 0, 0, 0.6);
                      width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      margin-top: 3px;
                    }
                    .network {
                      font-size: 12px;
                      font-weight: bold;
                      color: ${colorPalette.primary};
                      margin-top: 3px;
                    }
                  }
                }
              }
              .icon-wrapper {
                color: rgba(0, 0, 0, 0.38);
                cursor: pointer;
              }
            }
          }
        }
      }
      .col-right {
        flex: 1;
        padding-left: 20px;
        .buttons-container {
          display: flex;
          justify-content: flex-end;
        }
        .graphic-container {
          margin-top: 70px;
          h3 {
            font-family: 'Rubik';
            font-size: 16.3px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.48;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
          }
          p {
            margin: 16px 0 30px;
            font-size: 16.7px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: 0.5px;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
          }
        }
      }
    }
  }
`;

export const ModalSettings = styled.div`
  align-items: flex-start;
  background: #fff;
  border: 2px solid;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 44%;
  top: 180px;

  width: 150px;
  z-index: 2;
  button {
    align-items: center;
    border: none;
    background-color: transparent;
    display: flex;
    font-size: 15px;
    padding: 10px 5px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
    &.btn-top {
      border-top: 1px solid black;
    }
    &.btn-bottom {
      border-bottom: 1px solid black;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      transition: 0.5s;
    }
  }
`;
