import produce from 'immer';
import types from '../types';

export const INITIAL_STATE = {
  add: {
    loading: false,
    error: false,
    success: false,
  },
  update: {
    loading: false,
    error: false,
    success: false,
  },
};

export default function channel(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CHANNEL_ADD_REQUEST: {
        draft.add.loading = true;
        draft.add.error = false;
        draft.add.success = false;
        break;
      }
      case types.CHANNEL_ADD_SUCCESS: {
        draft.add.loading = false;
        draft.add.success = true;
        break;
      }
      case types.CHANNEL_ADD_FAILURE: {
        draft.add.loading = false;
        draft.add.error = true;
        break;
      }
      case types.CHANNEL_ADD_RESET: {
        draft.add.loading = false;
        draft.add.error = false;
        draft.add.success = false;
        break;
      }
      case types.CHANNEL_UPDATE_REQUEST: {
        draft.update.loading = true;
        draft.update.error = false;
        draft.update.success = false;
        break;
      }
      case types.CHANNEL_UPDATE_SUCCESS: {
        draft.update.loading = false;
        draft.update.success = true;
        break;
      }
      case types.CHANNEL_UPDATE_FAILURE: {
        draft.update.loading = false;
        draft.update.error = true;
        break;
      }
      case types.CHANNEL_UPDATE_RESET: {
        draft.update.loading = false;
        draft.update.error = false;
        draft.update.success = false;
        break;
      }
      default:
    }
  });
}
