import { takeLatest, call, put, all } from 'redux-saga/effects';
import history from '~/services/history';

import { apiBeAuth } from '~/services/api';

import { setToken } from '../../auth/sagas';

import {
  forgotPasswordSaveActions,
  savePasswordSuccess,
  savePasswordFailure,
} from './actions';

export function* save({ payload }) {
  try {
    const { code, password } = payload;
    const response = yield call(
      apiBeAuth.patch,
      'auth/forgot_password/change',
      {
        code,
        new: password,
        app: process.env.REACT_APP_API_CLIENT_ID,
      }
    );

    const { data } = response;

    setToken(data);

    yield put(savePasswordSuccess(data));

    history.push('/search');
  } catch (err) {
    yield put(savePasswordFailure());
  }
}

export default all([
  takeLatest(forgotPasswordSaveActions.SAVE_PASSWORD_REQUEST, save),
]);
