import React from 'react';
import PropTypes from 'prop-types';

import Engagement from './Engagement';

import { Container } from './styles';

export default function Avarages({ typePost }) {
  return (
    <div className="row">
      <div className="box-content avarages">
        <Container>
          <Engagement typePost={typePost} />
        </Container>
      </div>
    </div>
  );
}

Avarages.propTypes = {
  typePost: PropTypes.string.isRequired,
};
