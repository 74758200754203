import types from '../types';

export function menuOpen() {
  return {
    type: types.MENU_OPEN,
  };
}

export function menuClose() {
  return {
    type: types.MENU_CLOSE,
  };
}

export function selectMenu(slug) {
  return {
    type: types.MENU_SELECT,
    payload: { slug },
  };
}
