import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import types from '../types';

import { apiBeCreator } from '~/services/api';

import {
  channelAddSuccess,
  channelAddFailure,
  channelUpdateSuccess,
  channelUpdateFailure,
} from './actions';

export function* addChannel({ payload }) {
  try {
    const { network, identifiers } = payload;

    yield call(apiBeCreator.post, 'collect', {
      network,
      identifiers,
    });
    yield put(channelAddSuccess());
  } catch (err) {
    yield put(channelAddFailure());
  }
}

export function* updateChannel() {
  try {
    const networkIndexSelected = yield select(
      (state) => state.creator.tabs.networkIndexSelected
    );
    const networkSelected = yield select(
      (state) =>
        state.creator.profile.data.creator_networks[networkIndexSelected]
    );
    const { network, identifier } = networkSelected;

    yield call(apiBeCreator.post, 'collect', {
      network,
      identifiers: [identifier],
    });
    yield put(channelUpdateSuccess());
  } catch (err) {
    yield put(channelUpdateFailure());
  }
}

export default all([
  takeLatest(types.CHANNEL_ADD_REQUEST, addChannel),
  takeLatest(types.CHANNEL_UPDATE_REQUEST, updateChannel),
]);
