import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { searchSetNetwork } from '~/store/modules/search/actions';

import { Container } from './styles';
import Instagram from './Instragram';

export default function Filters() {
  const dispatch = useDispatch();

  const network = useSelector((state) => state.search.network_selected);

  const networkContents = {
    instagram: () => <Instagram />,
  };

  function handleSelectTab(networkSelected) {
    dispatch(searchSetNetwork(networkSelected));
  }

  return (
    <Container>
      <Scrollbars style={{ display: 'flex', flex: 1, height: '100%' }}>
        <div className="tabs">
          <div
            className={`tab ${network === 'instagram' && 'active'}`}
            onClick={() => handleSelectTab('instagram')}
          >
            <div className="icon-wrapper">
              <FaInstagram size="30" />
            </div>
          </div>
        </div>
        <div className="container-tabs-contents">
          <div className="tab-content">{networkContents[network]()}</div>
        </div>
      </Scrollbars>
    </Container>
  );
}
