import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const List = styled.div`
  position: absolute;
  z-index: 15;
  top: ${(props) => `${props.top}px`};
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .no-results {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
  }
`;
