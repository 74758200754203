import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 20px 0;

  .container-button-see-more {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 5px;
      color: ${colorPalette.primary};
    }
  }
`;
