import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DeleteOutlineOutlined } from '@material-ui/icons/';
import { Link } from 'react-router-dom';

import { profileUpdateSettingsRequest } from '~/store/modules/profile/actions';
import { campaignsListDeleteRequest } from '~/store/modules/campaigns/list/actions';

import Modal from '~/components/Modal';
import ModalDeleteContent from '~/components/ModalDeleteContent';

import { Container, CustomSwitch } from './styles';

function CampaignItem({ campaign }) {
  const dispatch = useDispatch();

  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  const { data } = useSelector((state) => state.profile);

  const {
    loading: loadingDelete,
    error: errorLoading,
    completedDelete,
  } = useSelector((state) => state.campaignsList.delete);

  useEffect(() => {
    setModalDeleteIsOpen(false);
  }, [completedDelete]);

  const handleChangeActiveCampaign = (e) => {
    if (e.target.checked) {
      const requestPayload = { active_campaign_id: campaign.id };
      const settings = {
        active_campaign_id: campaign.id,
        active_campaign: campaign,
      };
      dispatch(profileUpdateSettingsRequest(requestPayload, settings));
    } else {
      const requestPayload = { active_campaign_id: null };
      const settings = { active_campaign_id: null, active_campaign: null };
      dispatch(profileUpdateSettingsRequest(requestPayload, settings));
    }
  };

  const handleOpenModalDelete = () => {
    setModalDeleteIsOpen(true);
  };

  const handleCloseModalDelete = () => {
    setModalDeleteIsOpen(false);
  };

  const handleConfirmDelete = () => {
    dispatch(campaignsListDeleteRequest(campaign.id));
  };

  const renderModalDelete = () => {
    return (
      <Modal
        isOpen={modalDeleteIsOpen}
        handleCloseModal={handleCloseModalDelete}
        width={400}
        height={240}
      >
        <ModalDeleteContent
          error={errorLoading}
          loading={loadingDelete}
          title="Delete campaign"
          text={`Do you want to delete the ${campaign.title} campaign?`}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleCloseModalDelete}
        />
      </Modal>
    );
  };

  return (
    <Container>
      <div className="col-left">
        <Link to={`/campaigns/${campaign.id}`}>
          <div className="title">{campaign.title}</div>
        </Link>
        <div className="total-creators">
          {campaign?.total_creator_networks || '0'} influencers
        </div>
      </div>
      <div className="col-delete">
        <DeleteOutlineOutlined
          onClick={handleOpenModalDelete}
          style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
        />
      </div>
      <div className="col-right">
        <div className="date">
          {format(new Date(campaign.created), 'dd/MM/yyyy')}
        </div>
        <CustomSwitch
          checked={data?.settings?.active_campaign?.id === campaign.id}
          onChange={handleChangeActiveCampaign}
        />
      </div>
      {renderModalDelete()}
    </Container>
  );
}

CampaignItem.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    created: PropTypes.string,
    total_creator_networks: PropTypes.number,
  }).isRequired,
};

export default CampaignItem;
