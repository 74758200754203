import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';
import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 8px 8px 30px 50px;
  height: 100%;
  border-radius: 4px;

  .container-close {
    display: flex;
    justify-content: flex-end;

    .button-close {
      color: #818181;
      height: 24px;
      cursor: pointer;
    }
  }

  .head {
    h3 {
      font-family: Rubik;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.17px;
      color: #000000;
      margin-bottom: 5px;
    }

    p {
      line-height: 1.37;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;

      .infos {
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
          font-size: 16px;
          line-height: 1.67;
          letter-spacing: 0.5px;
          color: rgba(0, 0, 0, 0.6);
        }

        .required {
          margin-left: 10px;
          line-height: 1.37;
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.38);

          &.active {
            color: #b00020;
          }
        }
      }

      input[type='text'],
      textarea {
        width: 502px;
        height: 36px;
        padding: 8px;
        border-radius: 4px;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 5px;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

        &:focus {
          border: 1px solid ${colorPalette.primary};
          box-shadow: 0 0 1px 0 ${colorPalette.primary};
        }
      }

      textarea {
        height: 94px;
        resize: none;
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  text-transform: uppercase;
  align-self: flex-end;
`;

export const FormResponse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 502px;
  margin-top: 15px;

  p {
    font-family: 'Hind';
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: 0.5px;
    color: ${colorPalette.primary};

    &.error {
      color: #b00020;
    }
  }
`;
