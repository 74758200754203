import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import searchSaga from './search/sagas';
import overlapList from './overlap/list/sagas';
import overlapNew from './overlap/new/sagas';
import profile from './profile/sagas';
import tagGroups from './tagGroups/sagas';
import toc from './toc/sagas';
import sov from './sov/sagas';
import channel from './channel/sagas';
import credits from './credits/sagas';
import creator from './creator/profile/sagas';
import audienceProfile from './creator/audienceProfile/sagas';
import creatorPosts from './creator/posts/sagas';
import creatorStatistics from './creator/statistics/sagas';
import changePassword from './changePassword/sagas';
import addNewUser from './addNewUser/sagas';
import checkCode from './invite/checkCode/sagas';
import savePassword from './invite/savePassword/sagas';
import forgotPasswordRequest from './forgotPassword/request/sagas';
import forgotPasswordCheckCode from './forgotPassword/checkCode/sagas';
import forgotPasswordSave from './forgotPassword/save/sagas';
import campaignsList from './campaigns/list/sagas';
import campaignsNew from './campaigns/new/sagas';
import campaignsDetail from './campaigns/detail/sagas';
import campaignsHeader from './campaigns/header/sagas';
import trackingLink from './campaigns/detail/tracking/trackingClick/sagas';
import trackingPosts from './campaigns/detail/tracking/trackingPosts/sagas';
import storiesMonitor from './campaigns/detail/storiesMonitor/sagas';
import creatorsQueue from './creatorsQueue/sagas';
import duplicateCreators from './campaigns/detail/duplicateCreators/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    searchSaga,
    overlapList,
    overlapNew,
    profile,
    tagGroups,
    toc,
    sov,
    channel,
    credits,
    creator,
    audienceProfile,
    creatorPosts,
    creatorStatistics,
    changePassword,
    addNewUser,
    checkCode,
    savePassword,
    forgotPasswordRequest,
    forgotPasswordCheckCode,
    forgotPasswordSave,
    campaignsList,
    campaignsNew,
    campaignsDetail,
    campaignsHeader,
    trackingLink,
    trackingPosts,
    storiesMonitor,
    creatorsQueue,
    duplicateCreators,
  ]);
}
