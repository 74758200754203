import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 1;

  .box-content {
    background-color: #ffffff;
    width: 607px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 40px 72px;
    border-radius: 8px;
    margin: 32px auto;
  }
`;

export const Title = styled.h2`
  font-family: 'Rubik';
  font-size: 34.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #000000;
`;

export const Subtitle = styled.p`
  margin: 24px 0 25px;
  font-family: 'Hind';
  font-size: 16px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
`;

export const FormResponse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    justify-self: flex-end;
    width: 214px;
  }

  p {
    font-family: 'Hind';
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: 0.5px;
    color: ${colorPalette.primary};
  }
`;
