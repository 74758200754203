import produce from 'immer';
import types from '../types';

import { savePasswordActions } from '../invite/savePassword/actions';
import { forgotPasswordSaveActions } from '../forgotPassword/save/actions';

export const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  message: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SIGN_IN_REQUEST: {
        draft.loading = true;
        draft.message = null;
        break;
      }
      case types.SIGN_IN_SUCCESS: {
        draft.token = action.payload.data;
        draft.signed = true;
        draft.loading = false;
        draft.message = null;
        break;
      }
      case forgotPasswordSaveActions.SAVE_PASSWORD_SUCCESS:
      case savePasswordActions.SAVE_PASSWORD_SUCCESS: {
        draft.token = action.payload.data;
        draft.signed = true;
        break;
      }
      case types.SIGN_IN_FAILURE: {
        draft.loading = false;
        draft.message = action.payload.message;
        break;
      }
      case types.SIGN_OUT_REQUEST: {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case types.SET_TOKENS: {
        draft.token = action.payload.tokens;
        break;
      }
      default:
    }
  });
}
