import React, { useEffect } from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Container, ButtonSubmit, FormResponse } from './styles';

import {
  campaignsDetailEditCampaignRequest,
  campaignsDetailEditCampaignReset,
} from '~/store/modules/campaigns/detail/actions';

const formSchema = Yup.object().shape({
  title: Yup.string().required('required'),
});

function ModalEditCampaign({ handleClose }) {
  const dispatch = useDispatch();

  const { data: campaign } = useSelector(
    (state) => state.campaignsDetail.campaign
  );

  const { loading, success, error } = useSelector(
    (state) => state.campaignsDetail.edit_campaign
  );

  useEffect(() => {
    dispatch(campaignsDetailEditCampaignReset());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [dispatch, handleClose, success]);

  const renderFooterContent = () => {
    if (error.length > 0) {
      return <p className="error">{error}</p>;
    }

    return (
      <>
        <div>{success && <p>Campaign edited successfully!</p>}</div>
      </>
    );
  };

  return (
    <Container>
      <div className="container-close">
        <div className="button-close" onClick={handleClose}>
          <CloseOutlinedIcon color="#818181" />
        </div>
      </div>
      <div className="head">
        <h3>Edit campaign</h3>
        <p>
          Created by: {campaign.created_by.name} |{' '}
          {format(new Date(campaign.created), 'dd/MM/yyyy')}
        </p>
      </div>
      <Formik
        initialValues={{
          title: campaign.title,
          client: campaign.client,
          product: campaign.product,
          briefing: campaign.briefing,
        }}
        validationSchema={formSchema}
        onSubmit={(values) => {
          if (!loading) {
            dispatch(
              campaignsDetailEditCampaignRequest({
                title: values.title,
                client: values.client,
                product: values.product,
                briefing: values.briefing,
              })
            );
          }
        }}
      >
        {({ values, errors, handleChange, handleBlur }) => (
          <Form>
            <div className="fields">
              <div className="row">
                <div className="infos">
                  <span className="label">Campaign name</span>
                  <span className={`required ${errors.title && 'active'}`}>
                    (required)
                  </span>
                </div>
                <input
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="row">
                <div className="infos">
                  <span className="label">Client name</span>
                </div>
                <input
                  type="text"
                  name="client"
                  value={values.client}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="row">
                <div className="infos">
                  <span className="label">Product name</span>
                </div>
                <input
                  type="text"
                  name="product"
                  value={values.product}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="row">
                <div className="infos">
                  <span className="label">Briefing</span>
                </div>
                <textarea
                  name="briefing"
                  value={values.briefing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <FormResponse>
                {renderFooterContent()}
                <ButtonSubmit width={220} onClick={() => false}>
                  {loading ? 'saving...' : 'Edit campaign'}
                </ButtonSubmit>
              </FormResponse>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

ModalEditCampaign.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ModalEditCampaign;
