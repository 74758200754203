import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: #ebebeb;
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-color: #ccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .infos {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;

    .title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }

    .identifier {
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }

    .network {
      font-size: 12px;
      font-weight: bold;
      color: ${colorPalette.primary};
    }
  }
`;
