import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { selectMenu, menuClose } from '~/store/modules/menu/actions';

import {
  overlapListReset,
  overlapListSetFilterTitle,
  overlapListRequest,
  overlapListDeleteRequest,
} from '~/store/modules/overlap/list/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import Footer from '~/containers/Footer';
import LoadMore from '~/components/LoadMore';
import Modal from '~/components/Modal';
import InputSearch from '~/components/InputSearch';
import ModalDeleteContent from '~/components/ModalDeleteContent';

import { abbreviateNumber } from '~/helpers';

import { Container, Percentage } from './styles';

export default function Overlap() {
  const dispatch = useDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOverlapIdDelete, setModalOverlapIdDelete] = useState(null);

  const {
    loading: listLoading,
    data: listData,
    error: listError,
    page: currentPage,
    last_page: lastPage,
    paginate: { loading: loadingPaginate, error: errorPaginate },
    filter: { title: filterTitle },
  } = useSelector((state) => state.overlapList.list);

  const completedDelete = useSelector(
    (state) => state.overlapList.delete.completedDelete
  );
  const loadingDelete = useSelector(
    (state) => state.overlapList.delete.loading
  );
  const errorDelete = useSelector((state) => state.overlapList.delete.error);

  useEffect(() => {
    dispatch(selectMenu('overlap'));
    dispatch(menuClose());
    dispatch(overlapListReset());
  }, [dispatch]);

  useEffect(() => {
    setModalIsOpen(false);
  }, [completedDelete]);

  useEffect(() => {
    if (!listData) {
      dispatch(overlapListRequest());
    }
  }, [listData, dispatch]);

  function handleShowModal(overlapId) {
    setModalIsOpen(true);
    setModalOverlapIdDelete(overlapId);
  }

  function handleHideModal() {
    setModalIsOpen(false);
    setModalOverlapIdDelete(null);
  }

  function handleConfirmDelete() {
    dispatch(overlapListDeleteRequest(modalOverlapIdDelete));
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        handleCloseModal={handleHideModal}
        width={400}
        height={220}
      >
        <ModalDeleteContent
          error={errorDelete}
          loading={loadingDelete}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleHideModal}
          title="Delete overlap"
          text="Do you want to delete this overlap?"
        />
      </Modal>
    );
  }

  function renderList() {
    if (listLoading) {
      return <Loader />;
    }

    if (listError) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(overlapListRequest())}
        />
      );
    }

    if (!listData) {
      return <p>No results</p>;
    }

    return (
      <div className="overlap-list">
        {listData.map((row) => (
          <div className="overlap-list-item" key={row.id}>
            <Link to={`/overlap/edit/${row.id}`} title={row.title}>
              <h3 className="overlap-name">{row.title}</h3>
            </Link>
            <p className="info">
              Created on{' '}
              <strong>{format(new Date(row.created), 'dd/MM/yyyy')}</strong>
            </p>
            <p className="info">
              Unique Audience of this Sampling{' '}
              <strong>{`${row.formatted_total_unique_audience}%`}</strong>
            </p>
            <p className="info">
              Total Samples{' '}
              <strong>{`${abbreviateNumber(row.total_samples, 3)}`}</strong>
            </p>
            <div className="bottom-container">
              <div className="profiles">
                {row.creator_networks.map((network) => (
                  <div className="profile-item" key={network.id}>
                    <div
                      title={`@${network.creator_network.identifier}`}
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${network.creator_network.profile_picture})`,
                      }}
                    />
                  </div>
                ))}
                {row.total_creator_networks > 5 && (
                  <div className="remains">{`+ ${
                    row.total_creator_networks - 5
                  }`}</div>
                )}
              </div>
              <div className="options">
                <Link to={`/overlap/edit/${row.id}`}>
                  <div className="icon-wrapper">
                    <EditOutlinedIcon style={{ fontSize: 25 }} />
                  </div>
                </Link>
                <div
                  className="icon-wrapper"
                  onClick={() => handleShowModal(row.id)}
                >
                  <DeleteOutlineOutlinedIcon style={{ fontSize: 25 }} />
                </div>
              </div>
            </div>
            <Percentage value={row.has_ended ? 100 : row.process} />
          </div>
        ))}
      </div>
    );
  }

  function filterTitleChange(e) {
    dispatch(overlapListSetFilterTitle(e.target.value));
  }

  function filterTitleKeyPress(e) {
    const code = e.which || e.keyCode;
    if (code === 13 && filterTitle.length > 0) {
      dispatch(overlapListRequest());
    }
  }

  return (
    <>
      <Container>
        <div className="grid">
          <div className="head">
            <div className="row new-container">
              <Link to="/overlap/new" className="button-new">
                NEW OVERLAP
              </Link>
            </div>
            <div className="row">
              <h2 className="page-title">Overlap</h2>
              <div className="container-input-search">
                <InputSearch
                  placeholder="Search overlap by title"
                  value={filterTitle}
                  onChange={filterTitleChange}
                  onKeyPress={filterTitleKeyPress}
                />
              </div>
            </div>
          </div>
          <div className="overlap-container">{renderList()}</div>
          {currentPage < lastPage && (
            <LoadMore
              isLoading={loadingPaginate}
              isError={errorPaginate}
              onLoad={() => dispatch(overlapListRequest(true))}
            />
          )}
        </div>
        {renderModal()}
      </Container>
      <Footer />
    </>
  );
}
