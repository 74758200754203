import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin: 0;
  padding: 20px 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.primary};
  width: 100%;
  height: 46px;

  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #fff;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  background-color: #f5f5f5;

  .form {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
  }

  .formGroup {
    display: flex;
    align-items: center;
    margin-right: 20px;

    label {
      margin-right: 8px;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      color: #55565a;
    }

    input {
      width: 390px;
      height: 33px;
      max-width: 100%;
      border: 0px;
      padding: 0 16px;

      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .successMessage {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0;

    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const TableMetrics = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: 86px;
  padding: 0 32px;
  background-color: #f5f5f5;

  .total {
    margin-right: 8px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #55565a;
    margin-right: 60px;
    white-space: nowrap;
    flex: 0 0 1;
  }

  .metrics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 16px;
      text-transform: uppercase;
      color: #55565a;

      span {
        display: block;
        font-size: 13px;
        margin-bottom: 16px;
      }
    }
  }
`;

export const ContainerEmpty = styled.section`
  margin-top: 24px;
`;

export const ModalContent = styled.div`
  display: flex;
  width: 100%;

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: 0px;
    padding: 0px;

    img {
      width: 100%;
    }
  }

  .picture {
    display: flex;
    flex: 1;
    width: 50%;
    background-color: #f5f5f5;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 50%;
    padding: 44px 24px;
  }

  .title {
    display: block;
    text-align: center;
    font-family: Rubik;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 32px;
    color: #55565a;
  }

  .engagementMetrics {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      color: #55565a;
      margin-right: 24px;

      img {
        width: 26px;
        margin-right: 12px;
      }
    }
  }

  .description {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #55565a;
    max-width: 340px;
    margin: 8px auto 24px;
  }
`;
