import React from 'react';
import PropTypes from 'prop-types';

import { TableContainer, Tbody, Thead } from './styles';

function Table({ theadList, tbodyList }) {
  return (
    <TableContainer>
      <Thead>
        <tr>
          {theadList.map((thead) => (
            <th key={thead.id} {...thead.props}>
              {thead.content}
            </th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {tbodyList.map((row) => (
          <tr key={row.id} {...(row.onClick && `onClick=${row.onClick}`)}>
            {row.elements.map((element) => (
              <td
                key={element.id}
                className={element.center ? 'text-center' : ''}
              >
                {element.content}
              </td>
            ))}
          </tr>
        ))}
      </Tbody>
    </TableContainer>
  );
}

const stringOrElement = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
]);

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

Table.propTypes = {
  theadList: PropTypes.arrayOf(
    PropTypes.shape({
      id: stringOrNumber.isRequired,
      content: stringOrElement.isRequired,
      props: PropTypes.shape({}),
    })
  ).isRequired,
  tbodyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: stringOrNumber.isRequired,
      onClick: PropTypes.func,
      elements: PropTypes.arrayOf(
        PropTypes.shape({
          id: stringOrNumber.isRequired,
          content: stringOrElement.isRequired,
          center: PropTypes.bool,
          props: PropTypes.shape({}),
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Table;
