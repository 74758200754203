import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  background: ${colorPalette.primary};
  padding: 30px 0;

  .row {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16.7px;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-align: center;
    }

    a {
      font-size: 16.7px;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
