import { takeLatest, put, all, call } from 'redux-saga/effects';

import { apiBeCampaign } from '~/services/api';

import {
  duplicateCreatorsActions,
  duplicateCreatorsSuccess,
  duplicateCreatorsFailure,
} from './actions';

export function* duplicate({ payload }) {
  try {
    const { campaingIds, creatorsNetworksIds } = payload;

    yield all(
      campaingIds.map((campaignId) =>
        call(apiBeCampaign.patch, `campaigns/${campaignId}/creator_networks`, {
          creator_networks: creatorsNetworksIds,
        })
      )
    );

    yield put(duplicateCreatorsSuccess());
  } catch (error) {
    yield put(duplicateCreatorsFailure());
  }
}

export default all([
  takeLatest(duplicateCreatorsActions.DUPLICATE_CREATORS_REQUEST, duplicate),
]);
