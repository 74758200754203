import { combineReducers } from 'redux';

import auth from './auth/reducer';
import menu from './menu/reducer';
import search from './search/reducer';
import overlapList from './overlap/list/reducer';
import overlapNew from './overlap/new/reducer';
import profile from './profile/reducer';
import tagGroups from './tagGroups/reducer';
import toc from './toc/reducer';
import sov from './sov/reducer';
import channel from './channel/reducer';
import credits from './credits/reducer';
import creator from './creator/profile/reducer';
import audienceProfile from './creator/audienceProfile/reducer';
import creatorPosts from './creator/posts/reducer';
import creatorStatistics from './creator/statistics/reducer';
import changePassword from './changePassword/reducer';
import addNewUser from './addNewUser/reducer';
import checkCode from './invite/checkCode/reducer';
import savePassword from './invite/savePassword/reducer';
import forgotPasswordRequest from './forgotPassword/request/reducer';
import forgotPasswordCheckCode from './forgotPassword/checkCode/reducer';
import forgotPasswordSave from './forgotPassword/save/reducer';
import campaignsList from './campaigns/list/reducer';
import campaignsNew from './campaigns/new/reducer';
import campaignsDetail from './campaigns/detail/reducer';
import campaignsHeader from './campaigns/header/reducer';
import trackingLink from './campaigns/detail/tracking/trackingClick/reducer';
import trackingPosts from './campaigns/detail/tracking/trackingPosts/reducer';
import storiesMonitor from './campaigns/detail/storiesMonitor/reducer';
import creatorsQueue from './creatorsQueue/reducer';
import duplicateCreators from './campaigns/detail/duplicateCreators/reducer';

export default combineReducers({
  auth,
  menu,
  search,
  overlapList,
  overlapNew,
  profile,
  tagGroups,
  toc,
  sov,
  channel,
  credits,
  creator,
  audienceProfile,
  creatorPosts,
  creatorStatistics,
  changePassword,
  addNewUser,
  checkCode,
  savePassword,
  forgotPasswordRequest,
  forgotPasswordCheckCode,
  forgotPasswordSave,
  campaignsList,
  campaignsNew,
  campaignsDetail,
  campaignsHeader,
  trackingLink,
  trackingPosts,
  storiesMonitor,
  creatorsQueue,
  duplicateCreators,
});
