import produce from 'immer';
import { trackingPostActions } from './actions';

export const INITIAL_STATE = {
  add: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  list: {
    loading: false,
    error: false,
    data: null,
    page: 1,
    last_page: null,
    total: null,
  },
};

export default function trackingPosts(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case trackingPostActions.TRACKING_POST_ADD_REQUEST: {
        draft.add.loading = true;
        draft.add.success = false;
        draft.add.error = false;
        draft.add.data = null;
        break;
      }
      case trackingPostActions.TRACKING_POST_ADD_SUCCESS: {
        draft.add.loading = false;
        draft.add.error = false;
        draft.add.success = true;
        draft.add.data = action.payload;
        break;
      }
      case trackingPostActions.TRACKING_POST_ADD_FAILURE: {
        draft.add.loading = false;
        draft.add.success = false;
        draft.add.error = true;
        draft.add.data = action.payload;
        break;
      }
      case trackingPostActions.TRACKING_POST_LIST_REQUEST: {
        const { page } = action.payload;

        draft.list.loading = true;
        draft.list.error = false;
        draft.list.data = [];
        draft.list.page = page;
        draft.list.last_page = null;
        draft.list.total = null;
        break;
      }
      case trackingPostActions.TRACKING_POST_LIST_SUCCESS: {
        const { data, lastPage, total } = action.payload;

        draft.list.loading = false;
        draft.list.error = false;
        draft.list.data = data;
        draft.list.last_page = lastPage;
        draft.list.total = total;
        break;
      }
      case trackingPostActions.TRACKING_POST_LIST_FAILURE: {
        draft.list.loading = false;
        draft.list.error = true;
        draft.list.data = [];
        break;
      }
      default:
    }
  });
}
