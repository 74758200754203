export default {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',
  SET_TOKENS: '@auth/SET_TOKENS',

  SIGN_OUT_REQUEST: '@auth/SIGN_OUT_REQUEST',

  MENU_OPEN: '@menu/MENU_OPEN',
  MENU_CLOSE: '@menu/MENU_CLOSE',
  MENU_SELECT: '@menu/MENU_SELECT',

  SEARCH_SET_FILTER: '@search/SEARCH_SET_FILTER',
  SEARCH_SET_NETWORK: '@search/SEARCH_SET_NETWORK',
  SEARCH_SET_ORDER_BY: '@search/SEARCH_SET_ORDER_BY',
  SEARCH_SET_PAGE: '@search/SEARCH_SET_PAGE',

  SEARCH_CLEAR_INSTAGRAM: '@search/SEARCH_CLEAR_INSTAGRAM',
  SEARCH_CLEAR_YOUTUBE: '@search/SEARCH_CLEAR_YOUTUBE',

  SEARCH_PLACES_SET: '@search/PLACES_SET',
  SEARCH_PLACES_SELECT: '@search/PLACES_SELECT',
  SEARCH_PLACES_REMOVE: '@search/PLACES_REMOVE',
  SEARCH_PLACES_REQUEST: '@search/PLACES_REQUEST',
  SEARCH_PLACES_SUCCESS: '@search/PLACES_SUCCESS',
  SEARCH_PLACES_FAILURE: '@search/PLACES_FAILURE',

  SEARCH_REGIONS_RESET: '@search/SEARCH_REGIONS_RESET',
  SEARCH_REGIONS_FULL_RESET: '@search/SEARCH_REGIONS_FULL_RESET',
  SEARCH_REGIONS_SELECT: '@search/SEARCH_REGIONS_SELECT',
  SEARCH_LOAD_REGIONS_REQUEST: '@search/SEARCH_LOAD_REGIONS_REQUEST',
  SEARCH_LOAD_REGIONS_SUCCESS: '@search/SEARCH_LOAD_REGIONS_SUCCESS',
  SEARCH_LOAD_REGIONS_FAILURE: '@search/SEARCH_LOAD_REGIONS_FAILURE',

  SEARCH_LOAD_CATEGORYS_REQUEST: '@search/SEARCH_LOAD_CATEGORYS_REQUEST',
  SEARCH_LOAD_CATEGORYS_SUCCESS: '@search/SEARCH_LOAD_CATEGORYS_SUCCESS',
  SEARCH_LOAD_CATEGORYS_FAILURE: '@search/SEARCH_LOAD_CATEGORYS_FAILURE',

  SEARCH_INSTAGRAM_REQUEST: '@search/SEARCH_INSTAGRAM_REQUEST',
  SEARCH_YOUTUBE_REQUEST: '@search/SEARCH_YOUTUBE_REQUEST',
  SEARCH_SUCCESS: '@search/SEARCH_SUCCESS',
  SEARCH_FAILURE: '@search/SEARCH_FAILURE',

  SEARCH_PAGINATE_REQUEST: '@search/SEARCH_PAGINATE_REQUEST',
  SEARCH_PAGINATE_SUCCESS: '@search/SEARCH_PAGINATE_SUCCESS',
  SEARCH_PAGINATE_FAILURE: '@search/SEARCH_PAGINATE_FAILURE',

  SEARCH_SORT_BY_REQUEST: '@search/SEARCH_SORT_BY_REQUEST',
  SEARCH_SORT_BY_SUCCESS: '@search/SEARCH_SORT_BY_SUCCESS',
  SEARCH_SORT_BY_FAILURE: '@search/SEARCH_SORT_BY_FAILURE',

  INFLUENCER_SELECT_TAB: '@influencer/INFLUENCER_SELECT_TAB',

  INFLUENCER_REQUEST: '@influencer/INFLUENCER_REQUEST',
  INFLUENCER_SUCCESS: '@influencer/INFLUENCER_SUCCESS',
  INFLUENCER_FAILURE: '@influencer/INFLUENCER_FAILURE',

  INFLUENCER_POSTS_REQUEST: '@influencer/INFLUENCER_POSTS_REQUEST',
  INFLUENCER_POSTS_SUCCESS: '@influencer/INFLUENCER_POSTS_SUCCESS',
  INFLUENCER_POSTS_FAILURE: '@influencer/INFLUENCER_POSTS_FAILURE',

  INFLUENCER_POSTS_PAGINATE_REQUEST:
    '@influencer/INFLUENCER_POSTS_PAGINATE_REQUEST',
  INFLUENCER_POSTS_PAGINATE_SUCCESS:
    '@influencer/INFLUENCER_POSTS_PAGINATE_SUCCESS',
  INFLUENCER_POSTS_PAGINATE_FAILURE:
    '@influencer/INFLUENCER_POSTS_PAGINATE_FAILURE',

  INFLUENCER_PUBLI_POSTS_REQUEST: '@influencer/INFLUENCER_PUBLI_POSTS_REQUEST',
  INFLUENCER_PUBLI_POSTS_SUCCESS: '@influencer/INFLUENCER_PUBLI_POSTS_SUCCESS',
  INFLUENCER_PUBLI_POSTS_FAILURE: '@influencer/INFLUENCER_PUBLI_POSTS_FAILURE',

  INFLUENCER_PUBLI_POSTS_PAGINATE_REQUEST:
    '@influencer/INFLUENCER_PUBLI_POSTS_PAGINATE_REQUEST',
  INFLUENCER_PUBLI_POSTS_PAGINATE_SUCCESS:
    '@influencer/INFLUENCER_PUBLI_POSTS_PAGINATE_SUCCESS',
  INFLUENCER_PUBLI_POSTS_PAGINATE_FAILURE:
    '@influencer/INFLUENCER_PUBLI_POSTS_PAGINATE_FAILURE',

  INFLUENCER_BRANDS_REQUEST: '@influencer/INFLUENCER_BRANDS_REQUEST',
  INFLUENCER_BRANDS_SUCCESS: '@influencer/INFLUENCER_BRANDS_SUCCESS',
  INFLUENCER_BRANDS_FAILURE: '@influencer/INFLUENCER_BRANDS_FAILURE',

  INFLUENCER_BRANDS_PAGINATE_REQUEST:
    '@influencer/INFLUENCER_BRANDS_PAGINATE_REQUEST',
  INFLUENCER_BRANDS_PAGINATE_SUCCESS:
    '@influencer/INFLUENCER_BRANDS_PAGINATE_SUCCESS',
  INFLUENCER_BRANDS_PAGINATE_FAILURE:
    '@influencer/INFLUENCER_BRANDS_PAGINATE_FAILURE',

  INFLUENCER_POSTS_WITH_PEAK_REQUEST:
    '@influencer/INFLUENCER_POSTS_WITH_PEAK_REQUEST',
  INFLUENCER_POSTS_WITH_PEAK_SUCCESS:
    '@influencer/INFLUENCER_POSTS_WITH_PEAK_SUCCESS',
  INFLUENCER_POSTS_WITH_PEAK_FAILURE:
    '@influencer/INFLUENCER_PUBLI_POSTS_WITH_PEAK_FAILURE',

  INFLUENCER_POSTS_WITH_PEAK_PAGINATE_REQUEST:
    '@influencer/INFLUENCER_POSTS_WITH_PEAK_PAGINATE_REQUEST',
  INFLUENCER_POSTS_WITH_PEAK_PAGINATE_SUCCESS:
    '@influencer/INFLUENCER_POSTS_WITH_PEAK_PAGINATE_SUCCESS',
  INFLUENCER_POSTS_WITH_PEAK_PAGINATE_FAILURE:
    '@influencer/INFLUENCER_PUBLI_POSTS_WITH_PEAK_PAGINATE_FAILURE',

  OVERLAP_SET_TITLE: '@overlap/OVERLAP_SET_TITLE',

  OVERLAP_LIST_RESET: '@overlap/OVERLAP_LIST_RESET',
  OVERLAP_LIST_SET_FILTER_TITLE: '@overlap/OVERLAP_LIST_SET_FILTER_TITLE',
  OVERLAP_LIST_REQUEST: '@overlap/OVERLAP_LIST_REQUEST',
  OVERLAP_LIST_SUCCESS: '@overlap/OVERLAP_LIST_SUCCESS',
  OVERLAP_LIST_FAILURE: '@overlap/OVERLAP_LIST_FAILURE',

  OVERLAP_LIST_DELETE_REQUEST: '@overlap/OVERLAP_LIST_DELETE_REQUEST',
  OVERLAP_LIST_DELETE_SUCCESS: '@overlap/OVERLAP_LIST_DELETE_SUCCESS',
  OVERLAP_LIST_DELETE_FAILURE: '@overlap/OVERLAP_LIST_DELETE_FAILURE',

  OVERLAP_DETAIL_REQUEST: '@overlap/OVERLAP_DETAIL_REQUEST',
  OVERLAP_DETAIL_SUCCESS: '@overlap/OVERLAP_DETAIL_SUCCESS',
  OVERLAP_DETAIL_FAILURE: '@overlap/OVERLAP_DETAIL_FAILURE',

  OVERLAP_NEW_SEARCH_SET_KEYWORD: '@overlap/OVERLAP_NEW_SEARCH_SET_KEYWORD',
  OVERLAP_NEW_SEARCH_REQUEST: '@overlap/OVERLAP_NEW_SEARCH_REQUEST',
  OVERLAP_NEW_SEARCH_SUCCESS: '@overlap/OVERLAP_NEW_SEARCH_SUCCESS',
  OVERLAP_NEW_SEARCH_FAILURE: '@overlap/OVERLAP_NEW_SEARCH_FAILURE',

  OVERLAP_NEW_SEARCH_PAGINATE_REQUEST:
    '@overlap/OVERLAP_NEW_SEARCH_PAGINATE_REQUEST',
  OVERLAP_NEW_SEARCH_PAGINATE_SUCCESS:
    '@overlap/OVERLAP_NEW_SEARCH_PAGINATE_SUCCESS',
  OVERLAP_NEW_SEARCH_PAGINATE_FAILURE:
    '@overlap/OVERLAP_NEW_SEARCH_PAGINATE_FAILURE',

  OVERLAP_NEW_SEARCH_RESET: '@overlap/OVERLAP_NEW_SEARCH_RESET',

  OVERLAP_NEW_SAVE_REQUEST: '@overlap/OVERLAP_NEW_SAVE_REQUEST',
  OVERLAP_NEW_SAVE_SUCCESS: '@overlap/OVERLAP_NEW_SAVE_SUCCESS',
  OVERLAP_NEW_SAVE_FAILURE: '@overlap/OVERLAP_NEW_SAVE_FAILURE',

  OVERLAP_NEW_DELETE_REQUEST: '@overlap/OVERLAP_NEW_DELETE_REQUEST',
  OVERLAP_NEW_DELETE_SUCCESS: '@overlap/OVERLAP_NEW_DELETE_SUCCESS',
  OVERLAP_NEW_DELETE_FAILURE: '@overlap/OVERLAP_NEW_DELETE_FAILURE',

  OVERLAP_NEW_CLEAR_LIST: '@overlap/OVERLAP_NEW_CLEAR_LIST',

  OVERLAP_EDIT_GET_INFLUENCERS_REQUEST:
    '@overlap/OVERLAP_EDIT_GET_INFLUENCERS_REQUEST',
  OVERLAP_EDIT_GET_INFLUENCERS_SUCCESS:
    '@overlap/OVERLAP_EDIT_GET_INFLUENCERS_SUCCESS',
  OVERLAP_EDIT_GET_INFLUENCERS_FAILURE:
    '@overlap/OVERLAP_EDIT_GET_INFLUENCERS_FAILURE',

  PROFILE_FETCH_REQUEST: '@profile/PROFILE_FETCH_REQUEST',
  PROFILE_FETCH_SUCCESS: '@profile/PROFILE_FETCH_SUCCESS',
  PROFILE_FETCH_FAILURE: '@profile/PROFILE_FETCH_FAILURE',
  UPDATE_PROFILE_SETTINGS_REQUEST: '@profile/UPDATE_PROFILE_SETTINGS_REQUEST',
  UPDATE_PROFILE_SETTINGS_SUCCESS: '@profile/UPDATE_PROFILE_SETTINGS_SUCCESS',
  UPDATE_PROFILE_SETTINGS_FAILURE: '@profile/UPDATE_PROFILE_SETTINGS_FAILURE',

  TAG_GROUPS_LIST_REQUEST: '@tagGroups/TAG_GROUPS_LIST_REQUEST',
  TAG_GROUPS_LIST_SUCCESS: '@tagGroups/TAG_GROUPS_LIST_SUCCESS',
  TAG_GROUPS_LIST_FAILURE: '@tagGroups/TAG_GROUPS_LIST_FAILURE',

  TAG_GROUPS_LIST_SELECT_ITEM: '@tagGroups/TAG_GROUPS_LIST_SELECT_ITEM',

  TAG_GROUPS_LIST_CHECK_ITEM: '@tagGroups/TAG_GROUPS_LIST_CHECK_ITEM',

  TAG_GROUPS_LIST_SEARCH: '@tagGroups/TAG_GROUPS_LIST_SEARCH',

  TAG_GROUPS_LIST_REPROCESS_REQUEST:
    '@tagGroups/TAG_GROUPS_LIST_REPROCESS_REQUEST',
  TAG_GROUPS_LIST_REPROCESS_SUCCESS:
    '@tagGroups/TAG_GROUPS_LIST_REPROCESS_SUCCESS',
  TAG_GROUPS_LIST_REPROCESS_FAILURE:
    '@tagGroups/TAG_GROUPS_LIST_REPROCESS_FAILURE',

  TAG_GROUPS_CREATE_REQUEST: '@tagGroups/TAG_GROUPS_CREATE_REQUEST',
  TAG_GROUPS_CREATE_SUCCESS: '@tagGroups/TAG_GROUPS_CREATE_SUCCESS',
  TAG_GROUPS_CREATE_FAILURE: '@tagGroups/TAG_GROUPS_CREATE_FAILURE',

  TAG_GROUPS_UPDATE_REQUEST: '@tagGroups/TAG_GROUPS_UPDATE_REQUEST',
  TAG_GROUPS_UPDATE_SUCCESS: '@tagGroups/TAG_GROUPS_UPDATE_SUCCESS',
  TAG_GROUPS_UPDATE_FAILURE: '@tagGroups/TAG_GROUPS_UPDATE_FAILURE',

  TAG_GROUPS_DELETE_REQUEST: '@tagGroups/TAG_GROUPS_DELETE_REQUEST',
  TAG_GROUPS_DELETE_SUCCESS: '@tagGroups/TAG_GROUPS_DELETE_SUCCESS',
  TAG_GROUPS_DELETE_FAILURE: '@tagGroups/TAG_GROUPS_DELETE_FAILURE',

  TOC_GROUPS_SELECT: '@toc/TOC_GROUPS_SELECT',

  TOC_GROUPS_LIST_REQUEST: '@toc/TOC_GROUPS_LIST_REQUEST',
  TOC_GROUPS_LIST_SUCCESS: '@toc/TOC_GROUPS_LIST_SUCCESS',
  TOC_GROUPS_LIST_FAILURE: '@toc/TOC_GROUPS_LIST_FAILURE',

  TOC_SELECT_TAG: '@toc/TOC_SELECT_TAG',
  TOC_REQUEST: '@toc/TOC_REQUEST',
  TOC_SUCCESS: '@toc/TOC_SUCCESS',
  TOC_FAILURE: '@toc/TOC_FAILURE',

  SOV_LIST_SEARCH: '@sov/SOV_LIST_SEARCH',

  SOV_LIST_SELECT: '@sov/SOV_LIST_SELECT',

  SOV_LIST_REQUEST: '@sov/SOV_LIST_REQUEST',
  SOV_LIST_SUCCESS: '@sov/SOV_LIST_SUCCESS',
  SOV_LIST_FAILURE: '@sov/SOV_LIST_FAILURE',

  SOV_LIST_CLEAR_SELECTED: '@sov/SOV_LIST_CLEAR_SELECTED',
  SOV_LIST_CLEAR: '@sov/SOV_LIST_CLEAR',

  CHANNEL_ADD_REQUEST: '@channel/CHANNEL_ADD_REQUEST',
  CHANNEL_ADD_SUCCESS: '@channel/CHANNEL_ADD_SUCCESS',
  CHANNEL_ADD_FAILURE: '@channel/CHANNEL_ADD_FAILURE',
  CHANNEL_ADD_RESET: '@channel/CHANNEL_ADD_RESET',

  CHANNEL_UPDATE_REQUEST: '@channel/CHANNEL_UPDATE_REQUEST',
  CHANNEL_UPDATE_SUCCESS: '@channel/CHANNEL_UPDATE_SUCCESS',
  CHANNEL_UPDATE_FAILURE: '@channel/CHANNEL_UPDATE_FAILURE',
  CHANNEL_UPDATE_RESET: '@channel/CHANNEL_UPDATE_RESET',

  CREDITS_REQUEST: '@credits/CREDITS_REQUEST',
  CREDITS_SUCCESS: '@credits/CREDITS_SUCCESS',
  CREDITS_FAILURE: '@credits/CREDITS_FAILURE',

  CREATOR_SELECT_TAB: '@creator/CREATOR_SELECT_TAB',

  CREATOR_REQUEST: '@creator/CREATOR_REQUEST',
  CREATOR_SUCCESS: '@creator/CREATOR_SUCCESS',
  CREATOR_FAILURE: '@creator/CREATOR_FAILURE',
};
