import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  campaignsActions,
  campaignsListSuccess,
  campaignsListFailure,
  campaignsListDeleteSuccess,
  campaignsListDeleteFailure,
} from './actions';

import { apiBeCampaign } from '~/services/api';

export function* getCampaigns({ payload }) {
  try {
    const { page, filter } = payload;

    const limit = 6;

    const params = { page, limit, title: filter };

    const response = yield call(apiBeCampaign.get, 'campaigns', { params });

    const { data } = response;

    const totalPages = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(campaignsListSuccess(data, page, totalPages));
  } catch (err) {
    yield put(campaignsListFailure(err.message));
  }
}

export function* deleteCampaign({ payload }) {
  try {
    const { campaignId } = payload;
    yield call(apiBeCampaign.delete, `campaigns/${campaignId}`);
    yield put(campaignsListDeleteSuccess(campaignId));
  } catch (err) {
    yield put(campaignsListDeleteFailure());
  }
}

export default all([
  takeLatest(campaignsActions.CAMPAIGNS_LIST_REQUEST, getCampaigns),
  takeLatest(campaignsActions.CAMPAIGNS_LIST_DELETE_REQUEST, deleteCampaign),
]);
