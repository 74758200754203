import { takeLatest, call, put, all } from 'redux-saga/effects';
import types from '../types';

import { apiTocSov } from '~/services/api';

import { listSuccess, listFailure } from './actions';

export function* getGroups() {
  try {
    const response = yield call(apiTocSov.get, 'groups');
    yield put(listSuccess(response.data));
  } catch (err) {
    yield put(listFailure());
  }
}

export default all([takeLatest(types.SOV_LIST_REQUEST, getGroups)]);
