import React from 'react';
import PropTypes from 'prop-types';

import { MdKeyboardArrowDown } from 'react-icons/md';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { Container } from './styles';

function LoadMore({ isLoading, isError, onLoad }) {
  const render = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <LoadingDataErrorHandling refreshData={onLoad} />;
    }

    return (
      <div className="container-button-see-more" onClick={onLoad}>
        <div className="icon-wrapper">
          <MdKeyboardArrowDown size="16" />
        </div>
        <span>See more</span>
      </div>
    );
  };

  return <Container>{render()}</Container>;
}

LoadMore.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default LoadMore;
