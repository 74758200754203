import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 100%;
  padding-right: 25px;

  @media (max-width: 1920px) {
    width: 500px;
    padding-left: 100px;
  }

  @media (max-width: 1366px) {
    width: 400px;
    padding-left: 100px;
  }

  .description {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
  }

  .input-search {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;

    .icon-wrapper {
      color: #979797;
    }

    input {
      border: none;
      padding: 10px;
      display: flex;
      flex: 1;
      color: rgba(0, 0, 0, 0.38);
      background-color: #f5f5f5;
      font-size: 16px;
      margin-left: 10px;

      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
      &::-moz-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  .total-selecteds {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 20px 10px 16px;
    border-bottom: 1px solid #f5f5f5;
    height: 80px;

    svg {
      cursor: pointer;
    }

    span {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .group-list {
    height: 100%;
    margin-top: 10px;

    .group-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;

      .group-name {
        margin-left: 35px;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 700;
        font-family: 'Rubik';
      }
    }
  }
`;
