import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  profileFetchSuccess,
  profileUpdateSettingsSuccess,
  profileUpdateSettingsFailure,
} from './actions';

import { searchRequest } from '../search/actions';

import types from '../types';
import { apiBeTracking, apiBeUser } from '~/services/api';

function addXAuthHeaders(user) {
  apiBeTracking.defaults.headers['X-User-ID'] = user.id;
  apiBeTracking.defaults.headers['X-Org-ID'] = user.organization.id;
}

export function* fetchProfile() {
  try {
    const response = yield call(apiBeUser.get, 'me');
    addXAuthHeaders(response.data);
    yield put(profileFetchSuccess(response.data));
  } catch (err) {
    // do something
  }
}

export function* updateSettings({ payload }) {
  try {
    const { requestPayload, settings } = payload;
    yield call(apiBeUser.patch, 'me/settings', requestPayload);
    yield put(profileUpdateSettingsSuccess(settings));

    const searchState = yield select((state) => state.search);

    if (searchState[searchState.network_selected].data) {
      yield put(searchRequest());
    }
  } catch (err) {
    yield put(profileUpdateSettingsFailure());
  }
}

export default all([
  takeLatest(types.PROFILE_FETCH_REQUEST, fetchProfile),
  takeLatest(types.UPDATE_PROFILE_SETTINGS_REQUEST, updateSettings),
]);
