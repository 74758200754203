import { takeLatest, call, put, all } from 'redux-saga/effects';
import history from '~/services/history';

import { apiBeUser } from '~/services/api';

import { setToken } from '../../auth/sagas';

import {
  savePasswordActions,
  savePasswordSuccess,
  savePasswordFailure,
} from './actions';

export function* save({ payload }) {
  try {
    const { code, password } = payload;
    const response = yield call(apiBeUser.post, 'users/invite/register', {
      code,
      password,
      app: process.env.REACT_APP_API_CLIENT_ID,
    });

    const { data } = response;

    setToken(data);

    yield put(savePasswordSuccess(data));

    history.push('/search');
  } catch (err) {
    yield put(savePasswordFailure());
  }
}

export default all([
  takeLatest(savePasswordActions.SAVE_PASSWORD_REQUEST, save),
]);
