import { apiBeAuth } from '~/services/api';
import { setTokens, signOut } from './modules/auth/actions';

export default function tokenMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
      const { type } = action;

      if (!type.includes('_REQUEST') || type.includes('CREDITS_REQUEST')) {
        return next(action);
      }

      const { token } = getState().auth;

      if (!token) {
        return next(action);
      }

      const currentUnixTimeStamp = Math.floor(+new Date() / 1000);

      const isExpired = currentUnixTimeStamp > token.expires_at;

      if (token.refresh_token && isExpired) {
        try {
          const response = await apiBeAuth.post('auth/refresh', {
            refresh: token.refresh_token,
          });

          dispatch({
            type: 'persist/REHYDRATE',
            payload: {
              auth: {
                token: response.data,
                signed: true,
                loading: false,
                message: null,
              },
              _persist: {
                version: -1,
                rehydrated: true,
              },
            },
            err: undefined,
            key: 'bell',
          });
          dispatch(setTokens(response.data));

          return next(action);
        } catch (error) {
          dispatch(signOut());
        }
      }
      return next(action);
    };
}
