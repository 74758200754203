import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 40px;

  .grid {
    flex: 1;

    .head {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .container-input-search {
          width: 300px;
        }

        &.new-container {
          justify-content: flex-end;

          .button-new {
            background: ${colorPalette.primary};
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 700;
            border-radius: 5px;
            border: none;
            padding: 0 20px;
            height: 36px;
            cursor: pointer;
            align-items: center;
            display: flex;
          }
        }
      }
    }

    .overlap-container {
      .overlap-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 25px 25px;

        .overlap-list-item {
          width: 350px;
          background-color: #f5f5f5;
          border-radius: 5px;
          border: solid 1px rgba(0, 0, 0, 0.12);
          padding-top: 15px;
          display: flex;
          flex-direction: column;

          &:last-child {
            margin-bottom: 0;
          }

          .overlap-name {
            font-family: 'Rubik';
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            letter-spacing: 0.25px;
            color: rgb(0, 0, 0);
            margin-bottom: 10px;
            padding: 0px 15px;
            height: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .info {
            letter-spacing: 0.25px;
            color: #707070;
            padding: 0 15px;
          }

          .bottom-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            padding: 0 15px;

            .profiles {
              display: flex;
              flex-direction: row;
              align-items: center;

              .profile-item {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                align-items: center;

                &:last-child {
                  margin-right: 0;
                }

                .thumbnail {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-color: #ccc;
                }
              }

              .remains {
                letter-spacing: 0.25px;
                color: #707070;
              }
            }

            .options {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;

              .icon-wrapper {
                margin-left: 10px;
                color: rgba(0, 0, 0, 0.6);
                cursor: pointer;
              }
            }
          }

          .percentage {
            height: 5px;
          }
        }
      }
    }
  }
`;

export const Percentage = styled.div`
  background-color: ${colorPalette.primary};
  width: ${(props) => `${props.value}%`};
  height: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: ${(props) => (props.value < 100 ? '0' : '5px')};
`;
