import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined';
import MoodOutlinedIcon from '@material-ui/icons/MoodOutlined';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { AddCircle, RemoveCircle } from '@material-ui/icons/';
import {
  campaignsDetailCreatorAddRequest,
  campaignsDetailCreatorDeleteRequest,
} from '~/store/modules/campaigns/detail/actions';

import Modal from '~/components/Modal';
import ModalDeleteContent from '~/components/ModalDeleteContent';

import { abbreviateNumber, timeBetweenPast } from '~/helpers';

import { Container } from './styles';

const tabIcons = {
  INSTAGRAM: () => <InstagramIcon />,
  YOUTUBE: () => <YouTubeIcon />,
};

function ProfileItem({ data }) {
  const dispatch = useDispatch();

  const [tabSelected, setTabSelected] = useState(0);

  const [
    modalRemoveCreatorCampaignIsOpen,
    setModalRemoveCreatorCampaignIsOpen,
  ] = useState(false);

  const loadingRemoveCreatorCampaign = useSelector(
    (state) => state.campaignsDetail.delete_creator.loading
  );
  const errorRemoveCreatorCampaign = useSelector(
    (state) => state.campaignsDetail.delete_creator.error
  );
  const confirmRemoveCreatorCampaign = useSelector(
    (state) => state.campaignsDetail.delete_creator.confirmDelete
  );

  const { data: profile } = useSelector((state) => state.profile);

  const campaignId = profile?.settings?.active_campaign?.id || null;

  const creator_network_selected = data.creator_networks[tabSelected];

  useEffect(() => {
    if (confirmRemoveCreatorCampaign > 0) {
      setModalRemoveCreatorCampaignIsOpen(false);
    }
  }, [confirmRemoveCreatorCampaign]);

  const selectTab = (index) => setTabSelected(index);

  const handleAddCreator = () => {
    const creatorData = {
      creator_id: data.id,
      creator_network: creator_network_selected,
      created: new Date(),
      added_by: { name: profile.name },
    };
    dispatch(campaignsDetailCreatorAddRequest(creatorData, true, campaignId));
  };

  const handleOpenRemoveCreatorCampaignModal = () => {
    setModalRemoveCreatorCampaignIsOpen(true);
  };

  const handleCloseRemoveCreatorCampaignModal = () => {
    setModalRemoveCreatorCampaignIsOpen(false);
  };

  const handleRemoveCreator = () => {
    dispatch(
      campaignsDetailCreatorDeleteRequest(
        [creator_network_selected.id],
        true,
        campaignId
      )
    );
  };

  const renderButtonCampaign = () => {
    if (!profile?.settings?.active_campaign?.id) {
      return null;
    }

    if (creator_network_selected.is_in_active_campaign) {
      return (
        <div
          className="button-campaign remove"
          onClick={handleOpenRemoveCreatorCampaignModal}
        >
          <RemoveCircle fontSize="inherit" style={{ color: '#b00020' }} />
        </div>
      );
    }

    return (
      <div className="button-campaign add" onClick={handleAddCreator}>
        <AddCircle fontSize="inherit" style={{ color: '#4bab33' }} />
      </div>
    );
  };

  return (
    <>
      <Container>
        {creator_network_selected.can_be_added_to_campaign &&
          renderButtonCampaign()}
        <div className="presentation">
          <Link to={`creator/${data.id}`}>
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${creator_network_selected.profile_picture})`,
              }}
            />
          </Link>
          <div className="infos">
            <div className="networks-tabs">
              {data.creator_networks.map((row, index) => (
                <div
                  onClick={() => selectTab(index)}
                  key={row.network}
                  className={`tab ${
                    creator_network_selected.network === row.network && 'active'
                  }`}
                >
                  {tabIcons[row.network]()}
                </div>
              ))}
            </div>
            <Link to={`creator/${data.id}`}>
              <h3 className="title">{creator_network_selected.title}</h3>
              {creator_network_selected.network !== 'YOUTUBE' && (
                <span className="identifier">{`@${creator_network_selected.identifier}`}</span>
              )}
            </Link>
          </div>
        </div>
        <div className="statistics">
          <div className="statistics-item">
            <div className="icon-wrapper">
              <CollectionsOutlinedIcon />
            </div>
            <div className="description">{`${abbreviateNumber(
              creator_network_selected?.total_posts || 0,
              1
            )} posts`}</div>
          </div>

          <div className="statistics-item">
            <div className="icon-wrapper">
              <MoodOutlinedIcon />
            </div>
            <div className="description">{`${abbreviateNumber(
              creator_network_selected?.total_followers || 0,
              1
            )} followers`}</div>
          </div>

          <div className="statistics-item">
            <div className="icon-wrapper">
              <PeopleOutlineOutlinedIcon />
            </div>
            <div className="description">{`${abbreviateNumber(
              creator_network_selected?.statistic?.reach || 0,
              1
            )} estimated reach`}</div>
          </div>

          <div className="statistics-item">
            <div className="icon-wrapper">
              <ThumbsUpDownOutlinedIcon />
            </div>
            <div className="description">{`${abbreviateNumber(
              creator_network_selected?.statistic?.fix_all_posts?.aem || 0,
              1
            )} engagement`}</div>
          </div>

          <div className="statistics-item">
            <div className="icon-wrapper">
              <ThumbsUpDownOutlinedIcon />
            </div>
            <div className="description">{`${abbreviateNumber(
              creator_network_selected?.statistic?.engagement_tax || 0,
              1
            )}% engagement tax`}</div>
          </div>

          <div className="statistics-item">
            <div className="icon-wrapper">
              <AccessTimeOutlinedIcon />
            </div>
            <div className="description">{`updated ${timeBetweenPast(
              creator_network_selected?.updated
            )} ago`}</div>
          </div>
        </div>
      </Container>
      <Modal
        width={400}
        height={250}
        isOpen={modalRemoveCreatorCampaignIsOpen}
        handleCloseModal={handleCloseRemoveCreatorCampaignModal}
      >
        <ModalDeleteContent
          loading={loadingRemoveCreatorCampaign}
          error={errorRemoveCreatorCampaign}
          handleConfirmDelete={handleRemoveCreator}
          handleClose={handleCloseRemoveCreatorCampaignModal}
          title="Remove creator"
          text={`Are you sure you want to remove it from the "${profile?.settings?.active_campaign?.title}" campaign?`}
        />
      </Modal>
    </>
  );
}

ProfileItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    language: PropTypes.string,
    creator_networks: PropTypes.arrayOf(
      PropTypes.shape({
        creator_id: PropTypes.number,
        network: PropTypes.string,
        title: PropTypes.string,
        network_id: PropTypes.string,
        identifier: PropTypes.string,
        is_in_active_campaign: PropTypes.bool,
        bio: PropTypes.string,
        is_private: PropTypes.string,
        total_followers: PropTypes.number,
        classification: PropTypes.string,
        profile_picture: PropTypes.string,
        status: PropTypes.string,
        statistic: PropTypes.shape({
          engagement_tax: PropTypes.number,
          reach: PropTypes.number,
          fix_all_posts: PropTypes.shape({
            aem: PropTypes.number,
          }),
          stories_views: PropTypes.number,
        }),
        total_posts: PropTypes.number,
        updated: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ProfileItem;
