export const audienceActions = {
  AUDIENCE_REQUEST: '@audience/AUDIENCE_REQUEST',
  AUDIENCE_SUCCESS: '@audience/AUDIENCE_SUCCESS',
  AUDIENCE_FAILURE: '@audience/AUDIENCE_FAILURE',
};

export function audienceRequest() {
  return {
    type: audienceActions.AUDIENCE_REQUEST,
  };
}

export function audienceSuccess(data) {
  return {
    type: audienceActions.AUDIENCE_SUCCESS,
    payload: { data },
  };
}

export function audienceFailure() {
  return {
    type: audienceActions.AUDIENCE_FAILURE,
  };
}
