import React from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { Container } from './styles';

export default function ModalDelete({
  handleClose,
  handleConfirmDelete,
  loading,
  error,
  title,
  text,
}) {
  const render = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <LoadingDataErrorHandling refreshData={handleConfirmDelete} />;
    }

    return (
      <>
        <div className="head">
          <div className="icon-wrapper close-modal">
            <MdClose size="30" onClick={() => handleClose()} />
          </div>
        </div>
        <div className="modal-description">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
        <div className="modal-options">
          <span onClick={() => handleClose()}>CANCEL</span>
          <span onClick={() => handleConfirmDelete()}>DELETE</span>
        </div>
      </>
    );
  };

  return <Container>{render()}</Container>;
}

ModalDelete.defaultProps = {
  title: 'Delete',
  text: 'Are you sure you want to delete this data?',
};

ModalDelete.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};
