import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';
import Search from '~/pages/Search';
import Creator from '~/pages/Creator';
import Overlap from '~/pages/Overlap/List';
import OverlapNew from '~/pages/Overlap/New';
import TagGroups from '~/pages/TagGroups';
import ShareOfVoice from '~/pages/ShareOfVoice';
import ChangePassword from '~/pages/ChangePassword';
import AddNewUser from '~/pages/AddNewUser';
import Invite from '~/pages/Invite';
import ForgotPasswordRequest from '~/pages/ForgotPassword/Request';
import ForgotPasswordSave from '~/pages/ForgotPassword/Save';
import Campaigns from '~/pages/Campaigns/List';
import CampaignDetail from '~/pages/Campaigns/Detail';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/search" isPrivate exact component={Search} />
      <Route isPrivate path="/creator/:creatorId" component={Creator} />
      <Route path="/overlap/new" isPrivate component={OverlapNew} />
      <Route path="/overlap/edit/:id" isPrivate component={OverlapNew} />
      <Route path="/overlap" isPrivate exact component={Overlap} />
      <Route path="/tag-groups" isPrivate exact component={TagGroups} />
      <Route path="/share-of-voice" isPrivate exact component={ShareOfVoice} />
      <Route
        path="/change-password"
        isPrivate
        exact
        component={ChangePassword}
      />
      <Route path="/add-new-user" isPrivate exact component={AddNewUser} />
      <Route path="/invite/new" exact component={Invite} />
      <Route path="/forgot-password" exact component={ForgotPasswordRequest} />
      <Route
        path="/forgot-password/change-password"
        exact
        component={ForgotPasswordSave}
      />
      <Route path="/campaigns" isPrivate exact component={Campaigns} />
      <Route path="/campaigns/:id" isPrivate exact component={CampaignDetail} />
    </Switch>
  );
}
