import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { menuClose, selectMenu } from '~/store/modules/menu/actions';
import Button from '~/components/Button';
import InputPassword from '~/components/InputPassword';

import { Container, Title, Subtitle, FormResponse } from './styles';
import {
  changePasswordRequest,
  resetFormMessage,
} from '~/store/modules/changePassword/actions';
import Footer from '~/containers/Footer';

const formSchema = Yup.object().shape({
  currentpassword: Yup.string().required('required'),
  newpassword: Yup.string()
    .min(6, '')
    .trim('the password cannot include trailing spaces')
    .when('currentpassword', {
      is: (val) => val !== '' && val !== undefined,
      then: Yup.string().required(''),
      otherwise: Yup.string(),
    }),
  confirmpassword: Yup.string()
    .min(6, '')
    .oneOf([Yup.ref('newpassword'), null], 'password does not match')
    .required(''),
});

function ChangePassword() {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    (state) => state.changePassword
  );

  useEffect(() => {
    dispatch(menuClose());
    dispatch(selectMenu('change-password'));
    return () => {
      dispatch(resetFormMessage());
    };
  }, [dispatch]);

  function renderFooterContent() {
    if (error) {
      return <p>Password could not be changed!</p>;
    }

    return (
      <>
        <div>{success && <p>Password successfully changed!</p>}</div>
      </>
    );
  }

  return (
    <>
      <Container>
        <Formik
          initialValues={{
            currentpassword: '',
            newpassword: '',
            confirmpassword: '',
          }}
          validationSchema={formSchema}
          onSubmit={(values, { resetForm }) => {
            dispatch(
              changePasswordRequest(
                values.currentpassword,
                values.newpassword,
                values.confirmpassword
              )
            );
            resetForm();
          }}
        >
          {({ values, errors, handleChange, handleBlur }) => (
            <div className="box-content">
              <Form>
                <Title>Change password</Title>
                <Subtitle>
                  Enter your new password of at least 6 characters.
                </Subtitle>
                <InputPassword
                  label="current password"
                  placeholder="Current password"
                  name="currentpassword"
                  value={values.currentpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.currentpassword}
                />
                <InputPassword
                  label="new password"
                  placeholder="New password"
                  name="newpassword"
                  value={values.newpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.newpassword}
                  minimumCheckCharacters
                />
                <InputPassword
                  label="confirm password"
                  placeholder="Confirm password"
                  name="confirmpassword"
                  value={values.confirmpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.confirmpassword}
                  minimumCheckCharacters
                  classError={errors.confirmpassword}
                />
                <FormResponse>
                  {renderFooterContent()}
                  <Button className="btn" onClick={() => {}}>
                    {loading ? 'SAVING' : 'SAVE PASSWORD'}
                  </Button>
                </FormResponse>
              </Form>
            </div>
          )}
        </Formik>
      </Container>
      <Footer />
    </>
  );
}

export default ChangePassword;
