import types from '../types';

export function channelAddRequest(identifiers, network) {
  return {
    type: types.CHANNEL_ADD_REQUEST,
    payload: { identifiers, network },
  };
}

export function channelAddSuccess() {
  return {
    type: types.CHANNEL_ADD_SUCCESS,
  };
}

export function channelAddFailure() {
  return {
    type: types.CHANNEL_ADD_FAILURE,
  };
}

export function channelAddReset() {
  return {
    type: types.CHANNEL_ADD_RESET,
  };
}

export function channelUpdateRequest() {
  return {
    type: types.CHANNEL_UPDATE_REQUEST,
  };
}

export function channelUpdateSuccess() {
  return {
    type: types.CHANNEL_UPDATE_SUCCESS,
  };
}

export function channelUpdateFailure() {
  return {
    type: types.CHANNEL_UPDATE_FAILURE,
  };
}

export function channelUpdateReset() {
  return {
    type: types.CHANNEL_UPDATE_RESET,
  };
}
