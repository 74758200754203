import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 65px 30px;
  align-items: center;
  background: #f5f5f5;

  .content {
    flex: 1;

    h2 {
      font-size: 20px;
      letter-spacing: 0.25px;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 40px;
      font-family: 'Rubik';
    }

    .row-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100px;

      input {
        width: 460px;
        border-radius: 10px;
        background-color: #fff;
        border: none;
        padding: 15px 20px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.38);

        &::placeholder {
          color: rgba(0, 0, 0, 0.38);
          font-family: 'Hind';
        }
      }
      .done-tasks {
        color: #24e84e;
        margin-left: 30px;
      }
    }

    .tag-list {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .tag-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 4px;
        background-color: #e4e4e4;
        padding: 10px;
        margin: 10px 5px;

        .keyword {
        }

        .button-delete-tag-item {
          margin-left: 10px;
          cursor: pointer;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #8d8d8d;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .icon-wrapper {
            color: #fafafa;
          }
        }
      }
    }
  }
`;
