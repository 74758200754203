import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin-right: 48px;
  position: relative;
  z-index: 15;

  .select-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 214px;
    height: 36px;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: #f5f5f5;
    cursor: pointer;

    .title {
      width: 180px;
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.69;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .drop-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 46px;
    right: 0;
    width: 533px;
    height: 400px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
    background-color: #fafafa;
    z-index: 15;

    .head-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .input-search-wrapper {
        width: 362px;
      }

      .button-new {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 1.25px;
        text-align: center;
        color: ${colorPalette.primary};
        cursor: pointer;
      }
    }

    .container-list {
      margin-top: 25px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .no-results {
        width: 325px;
        line-height: 1.37;
        letter-spacing: 0.25px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        margin: 0 auto;
      }
    }
  }
`;
