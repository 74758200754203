import produce from 'immer';
import { creatorPostsTypes } from './actions';

export const INITIAL_STATE = {
  allPosts: {
    type: 'allPosts',
    current_page: 1,
    last_page: 1,
    data: [],
    loading: false,
    error: false,
    paginate: {
      loading: false,
      error: false,
    },
  },
  adPosts: {
    type: 'adPosts',
    current_page: 1,
    last_page: 1,
    data: [],
    loading: false,
    error: false,
    paginate: {
      loading: false,
      error: false,
    },
  },
  abnormalPosts: {
    type: 'abnormalPosts',
    current_page: 1,
    last_page: 1,
    data: [],
    loading: false,
    error: false,
    paginate: {
      loading: false,
      error: false,
    },
  },
};

export default function creatorPosts(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case creatorPostsTypes.POSTS_REQUEST: {
        const { typePost, isPaginate } = action.payload;
        if (isPaginate) {
          draft[typePost].paginate.loading = true;
          draft[typePost].paginate.error = false;
        } else {
          draft[typePost].loading = true;
          draft[typePost].error = false;
        }
        break;
      }
      case creatorPostsTypes.POSTS_SUCCESS: {
        const { data, lastPage, typePost, isPaginate } = action.payload;

        draft[typePost].last_page = lastPage;

        if (isPaginate) {
          draft[typePost].current_page += 1;
          draft[typePost].data = [...draft[typePost].data, ...data];
          draft[typePost].paginate.loading = false;
        } else {
          draft[typePost].data = data;
          draft[typePost].loading = false;
        }
        break;
      }
      case creatorPostsTypes.POSTS_FAILURE: {
        const { typePost, isPaginate } = action.payload;
        if (isPaginate) {
          draft[typePost].paginate.loading = false;
          draft[typePost].paginate.error = true;
        } else {
          draft[typePost].loading = false;
          draft[typePost].error = true;
        }
        break;
      }
      default:
    }
  });
}
