import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 25px;
  margin-right: 15px;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;

    .infos {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);
      }

      .added-by {
        font-weight: bold;
      }
      .created {
        margin-left: 5px;
      }
    }
  }

  .comment {
    margin-top: 10px;
    padding: 8px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #efefef;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
  }
`;
