import styled from 'styled-components';
import Button from '~/components/Button';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #f5f5f5;

  .box-content {
    display: flex;
    flex-direction: column;
    width: 607px;
    height: 500px;
    padding: 40px 72px;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 32px auto;

    h2 {
      margin: 0 3px 24px 0;
      font-family: 'Rubik';
      font-size: 34.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: #000000;
    }

    p {
      font-size: 16.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;

      span {
        color: ${colorPalette.primary};
        font-weight: bold;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      input[type='text'],
      input[type='email'] {
        width: 463px;
        height: 56px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.32);
        padding: 16px;

        font-family: 'Rubik';
        font-size: 16.3px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
      }

      span {
        font-family: 'Rubik';
        font-size: 12.2px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 0.4px;
        color: red;
        margin-top: 5px;
      }
    }

    .footer-form-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      .form-response {
        font-size: 16.7px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        color: ${colorPalette.primary};

        &.error {
          color: red;
        }
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 214px;
`;
