export const statisticsTypes = {
  STATISTICS_REQUEST: '@statistics/STATISTICS_REQUEST',
  STATISTICS_SUCCESS: '@statistics/STATISTICS_SUCCESS',
  STATISTICS_FAILURE: '@statistics/STATISTICS_FAILURE',
};

export const statisticsRequest = () => ({
  type: statisticsTypes.STATISTICS_REQUEST,
});

export const statisticsSuccess = (data) => ({
  type: statisticsTypes.STATISTICS_SUCCESS,
  payload: { data },
});

export const statisticsFailure = () => ({
  type: statisticsTypes.STATISTICS_FAILURE,
});
