export const savePasswordActions = {
  SAVE_PASSWORD_REQUEST: '@invite/savePassword/SAVE_PASSWORD_REQUEST',
  SAVE_PASSWORD_SUCCESS: '@invite/savePassword/SAVE_PASSWORD_SUCCESS',
  SAVE_PASSWORD_FAILURE: '@invite/savePassword/SAVE_PASSWORD_FAILURE',
};

export function savePasswordRequest(code, password) {
  return {
    type: savePasswordActions.SAVE_PASSWORD_REQUEST,
    payload: { code, password },
  };
}

export function savePasswordSuccess(data) {
  return {
    type: savePasswordActions.SAVE_PASSWORD_SUCCESS,
    payload: { data },
  };
}

export function savePasswordFailure() {
  return {
    type: savePasswordActions.SAVE_PASSWORD_FAILURE,
  };
}
