import types from '../types';

export function selectGroup(groupId) {
  return {
    type: types.TOC_GROUPS_SELECT,
    payload: { groupId },
  };
}

export function tocGroupsListRequest() {
  return {
    type: types.TOC_GROUPS_LIST_REQUEST,
  };
}

export function tocGroupsListSuccess(data) {
  return {
    type: types.TOC_GROUPS_LIST_SUCCESS,
    payload: { data },
  };
}

export function tocGroupsListFailure() {
  return {
    type: types.TOC_GROUPS_LIST_FAILURE,
  };
}

export function tocSelectTag(tag) {
  return {
    type: types.TOC_SELECT_TAG,
    payload: { tag },
  };
}

export function tocRequest(isPaginate = false) {
  return {
    type: types.TOC_REQUEST,
    payload: { isPaginate },
  };
}

export function tocSuccess(data, last_page, isPaginate = false) {
  return {
    type: types.TOC_SUCCESS,
    payload: { data, last_page, isPaginate },
  };
}

export function tocFailure(isPaginate = false) {
  return {
    type: types.TOC_FAILURE,
    payload: { isPaginate },
  };
}
