import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;

export const ColLeft = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.primary};
  width: 50%;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Rubik';
    font-size: 61px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 120px;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: 50px;
    }
  }

  img {
    @media (max-width: 1366px) {
      height: 400px;
    }
    @media (max-width: 1280px) {
      height: 300px;
    }
  }
`;

export const ColRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  .container-content {
    display: flex;
    flex-direction: column;
    width: 525px;

    h2 {
      font-family: 'Rubik';
      font-size: 61px;
      font-weight: 500;
      color: ${colorPalette.primary};

      @media (max-width: 1280px) {
        font-size: 50px;
      }
    }

    form {
      width: 464px;
      display: flex;
      flex-direction: column;

      h3 {
        font-family: 'Rubik';
        font-size: 34px;
        font-weight: 500;
        margin: 70px 0;
        text-align: center;

        @media (max-width: 1280px) {
          margin: 50px 0;
        }
      }

      input {
        width: 464px;
        height: 56px;
        padding: 16px;
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.32);
        margin-bottom: 44px;
        font-family: 'Rubik';
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
      }

      .form-response {
        padding: 10px;
        text-align: center;
        color: red;
        margin-bottom: 20px;
      }

      .forgot-password {
        color: ${colorPalette.primary};
        font-size: 16.7px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        text-align: center;
        text-decoration: underline;
        margin-top: 35px;
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 464px;
  height: 40px;
  padding: 13px 16px 11px;
  font-family: 'Hind';
  font-size: 14.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #ffffff;
`;
