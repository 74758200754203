import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import types from '../types';

import { creditsSuccess, creditsFailure } from './actions';

export function* getCredits() {
  try {
    const response = yield call(
      axios.get,
      'https://script.google.com/macros/s/AKfycbyAyQpeLTCi15dUixi2K_FojwfjOk9kdQPmKvX5LnHHQmHPjWEe/exec'
    );
    yield put(creditsSuccess(response.data));
  } catch (err) {
    yield put(creditsFailure());
  }
}

export default all([takeLatest(types.CREDITS_REQUEST, getCredits)]);
