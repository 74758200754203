import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  .track-of-competition {
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 24px;
    margin-top: 20px;

    p {
      font-size: 16px;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.6);
    }

    .MuiFormControl-root {
      .MuiSelect-selectMenu {
        min-width: 150px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
          0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        padding: 10px;
        background: #fff;
      }
      .MuiSelect-icon {
        color: ${colorPalette.primary};
      }
    }

    .results {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 10px;

      .tags {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        .tag-item {
          border-radius: 4px;
          border: solid 1px rgba(0, 0, 0, 0.12);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 15px;
          margin-right: 35px;
          margin-top: 30px;
          cursor: pointer;

          &.selected {
            background: ${colorPalette.primary};

            .tag-name {
              color: #fff;
            }
          }

          &:hover {
            background: #f3f3f3;
          }

          &:last-child {
            margin-right: 0;
          }

          .tag-total-posts {
            position: absolute;
            right: -15px;
            top: -15px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: solid 2px #ffffff;
            background-color: #f5f5f5;
            letter-spacing: 0.5px;
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .tag-name {
            letter-spacing: 0.25px;
            text-align: center;
            color: rgba(0, 0, 0, 0.87);
          }
        }
      }

      .posts {
        margin-top: 35px;

        h4 {
          font-weight: 700;
          font-size: 20px;
        }

        .posts-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;

          .post-list-item {
            display: flex;
            flex-direction: column;
            margin-right: 35px;
            margin-top: 30px;

            &:last-child {
              margin-right: 0;
            }

            .thumb {
              width: 100px;
              height: 100px;
              background-color: #ccc;
              background-size: cover;
              background-repeat: no-repeat;
              margin-bottom: 10px;
              cursor: pointer;
            }

            .posts-list-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 5px 0;

              span {
                margin-left: 5px;
              }

              a {
                color: #797979;
              }
            }
          }
        }
      }

      .container-see-more {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 40px 0;

        .button-see-more {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin-left: 5px;
            color: ${colorPalette.primary};
          }
        }
      }
    }
  }
`;
