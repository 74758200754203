import produce from 'immer';
import { addNewUserActions } from './actions';

export const INITIAL_STATE = {
  loading: false,
  success: 0,
  error: '',
};

export default function addNewUser(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case addNewUserActions.ADD_NEW_USER_REQUEST: {
        draft.loading = true;
        draft.success = 0;
        draft.error = '';
        break;
      }
      case addNewUserActions.ADD_NEW_USER_SUCCESS: {
        draft.loading = false;
        draft.success += 1;
        break;
      }
      case addNewUserActions.ADD_NEW_USER_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case addNewUserActions.ADD_NEW_USER_RESET: {
        draft.loading = false;
        draft.success = 0;
        draft.error = '';
        break;
      }
      default:
    }
  });
}
