import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { profileFetchRequest } from '~/store/modules/profile/actions';
import { creditsRequest } from '~/store/modules/credits/actions';

import Header from '~/containers/Header';

import Loader from '~/components/Loader';

import { Wrapper, ContainerLoader } from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();

  const { data: dataProfile, loading: loadingProfile } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (!dataProfile) dispatch(profileFetchRequest());
    dispatch(creditsRequest());
  }, [dispatch, dataProfile]);

  if (loadingProfile) {
    return (
      <ContainerLoader>
        <Loader />
      </ContainerLoader>
    );
  }

  return (
    <Wrapper>
      <Header />
      {children}
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
