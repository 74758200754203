import React, { memo } from 'react';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { Container } from './styles';

const CreatorListItem = ({ creator, handleSelect }) => {
  return (
    <Container onClick={handleSelect}>
      <div
        className="avatar"
        style={{ backgroundImage: `url(${creator.profile_picture})` }}
      />
      <div className="infos">
        <span className="title">{creator.title}</span>
        {creator.network !== 'YOUTUBE' && (
          <span className="identifier">{creator.identifier}</span>
        )}
        <span className="network">{creator.network}</span>
      </div>
      <div className="icon-wrapper">
        <Add />
      </div>
    </Container>
  );
};

CreatorListItem.propTypes = {
  creator: PropTypes.shape({
    profile_picture: PropTypes.string,
    title: PropTypes.string,
    identifier: PropTypes.string,
    network: PropTypes.string,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default memo(CreatorListItem);
