import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdDelete, MdTextFormat } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Modal from '~/components/Modal';

import {
  tagGroupsListRequest,
  tagGroupsListSearch,
  tagGroupsListSelectItem,
  tagGroupsListCheckItem,
  tagGroupsCreateRequest,
  tagGroupsUpdateRequest,
  tagGroupsDeleteRequest,
} from '~/store/modules/tagGroups/actions';

import ModalCreateContent from './ModalCreate';
import ModalUpdateContent from './ModalUpdate';

import Loader from '~/components/Loader';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import InputSearch from '~/components/InputSearch';
import ModalDeleteContent from '~/components/ModalDeleteContent';

import { Container } from './styles';
import colorPalette from '~/styles/colorPalette';

export default function GroupList() {
  const dispatch = useDispatch();

  const scrollBarStyles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  };

  const [searchValue, setSearchValue] = useState('');

  const listLoading = useSelector((state) => state.tagGroups.list.loading);
  const listData = useSelector((state) => state.tagGroups.list.data);
  const listDataSearch = useSelector(
    (state) => state.tagGroups.list.dataSearch
  );
  const groupSelectedId = useSelector(
    (state) => state.tagGroups.list.groupSelectedId
  );
  const groupsChecked = listData.filter((group) => group?.checked);

  const createLoading = useSelector((state) => state.tagGroups.create.loading);
  const confirmCreate = useSelector(
    (state) => state.tagGroups.create.confirmCreate
  );
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);

  const updateLoading = useSelector((state) => state.tagGroups.update.loading);
  const confirmUpdate = useSelector(
    (state) => state.tagGroups.update.confirmUpdate
  );
  const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);

  const deleteLoading = useSelector((state) => state.tagGroups.delete.loading);
  const deleteError = useSelector((state) => state.tagGroups.delete.error);
  const confirmDelete = useSelector(
    (state) => state.tagGroups.delete.confirmDelete
  );
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  useEffect(() => {
    async function getGroups() {
      await dispatch(tagGroupsListRequest());
    }
    getGroups();
  }, [dispatch]);

  useEffect(() => {
    setModalCreateIsOpen(false);
  }, [confirmCreate]);

  useEffect(() => {
    setModalUpdateIsOpen(false);
  }, [confirmUpdate]);

  useEffect(() => {
    setModalDeleteIsOpen(false);
  }, [confirmDelete]);

  // Create Functions
  function handleShowModalCreate() {
    setModalCreateIsOpen(true);
  }

  function handleHideModalCreate() {
    setModalCreateIsOpen(false);
  }

  function handleConfirmCreate(groupName) {
    if (groupName.trim().length > 0) {
      dispatch(tagGroupsCreateRequest(groupName));
    }
  }

  function renderModalCreate() {
    return (
      <Modal
        isOpen={modalCreateIsOpen}
        handleCloseModal={handleHideModalCreate}
        width={400}
        height={220}
      >
        <ModalCreateContent
          loading={createLoading}
          handleConfirmCreate={handleConfirmCreate}
          handleHideModal={handleHideModalCreate}
        />
      </Modal>
    );
  }

  // Update Functions
  function handleShowModalUpdate() {
    setModalUpdateIsOpen(true);
  }

  function handleHideModalUpdate() {
    setModalUpdateIsOpen(false);
  }

  function handleConfirmUpdate(groupSelected) {
    dispatch(tagGroupsUpdateRequest(groupSelected));
  }

  function renderModalUpdate() {
    return (
      <Modal
        isOpen={modalUpdateIsOpen}
        handleCloseModal={handleHideModalUpdate}
        width={400}
        height={220}
      >
        <ModalUpdateContent
          loading={updateLoading}
          handleConfirmUpdate={handleConfirmUpdate}
          handleHideModal={handleHideModalUpdate}
        />
      </Modal>
    );
  }

  // Delete Functions

  function handleShowModalDelete() {
    setModalDeleteIsOpen(true);
  }

  function handleHideModalDelete() {
    setModalDeleteIsOpen(false);
  }

  function handleConfirmDelete() {
    const groupsIds = groupsChecked.map((group) => group.id);
    dispatch(tagGroupsDeleteRequest(groupsIds));
  }

  function renderModalDelete() {
    return (
      <Modal
        isOpen={modalDeleteIsOpen}
        handleCloseModal={handleHideModalDelete}
        width={400}
        height={220}
      >
        <ModalDeleteContent
          loading={deleteLoading}
          error={deleteError}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleHideModalDelete}
          title="Delete Group"
          text="Do you want to delete this group?"
        />
      </Modal>
    );
  }

  // Search Functions
  function handleInputSearchChange(e) {
    const inputSearchValue = e.target.value;
    setSearchValue(inputSearchValue);
    dispatch(tagGroupsListSearch(inputSearchValue));
  }

  // Select group

  function handleSelectGroup(groupId) {
    dispatch(tagGroupsListSelectItem(groupId));
  }

  function handleCheckGroup(groupId) {
    dispatch(tagGroupsListCheckItem(groupId));
  }

  function renderGroups() {
    if (listLoading) {
      return <Loader />;
    }

    const listDataRender =
      listDataSearch.length > 0 ? listDataSearch : listData;

    return listDataRender.map((group) => (
      <div
        key={group.id}
        className={`group-list-item ${
          groupSelectedId && groupSelectedId === group.id ? 'selected' : ''
        }`}
        onClick={() => handleSelectGroup(group.id)}
      >
        <div className="checkbox-container">
          <Checkbox name="group[]" onClick={() => handleCheckGroup(group.id)} />
        </div>
        <div className="infos">
          <div className="group-name">{group.name}</div>
          <div className="group-extra-infos">
            {group?.tags?.length || '0'} tags |{' '}
            {format(new Date(group.created), 'dd/MM/yyyy')}
          </div>
        </div>
        {group?.is_processing && (
          <div className="progress" title={`${group.total_processed}%`}>
            <CircularProgressbar
              value={group.total_processed}
              strokeWidth={14}
              styles={buildStyles({
                pathColor: colorPalette.primary,
              })}
            />
          </div>
        )}
      </div>
    ));
  }

  return (
    <Container>
      <div className="container-groups-header">
        <h2 className="page-title">Tag groups</h2>
        <Button onClick={() => handleShowModalCreate()}>NEW</Button>
      </div>
      <InputSearch
        value={searchValue}
        onChange={handleInputSearchChange}
        placeholder="Search tag group"
      />
      <div className="group-list-options">
        <div
          title="Renomear"
          className={`group-list-options-button ${
            (groupsChecked.length === 0 || groupsChecked.length > 1) &&
            'inactive'
          }`}
          onClick={() => groupsChecked.length === 1 && handleShowModalUpdate()}
        >
          <div className="icon-wrapper">
            <MdTextFormat size="25" />
          </div>
        </div>
        <div
          title="Deletar"
          className={`group-list-options-button ${
            groupsChecked.length === 0 && 'inactive'
          }`}
          onClick={() => groupsChecked.length > 0 && handleShowModalDelete()}
        >
          <div className="icon-wrapper">
            <MdDelete size="25" />
          </div>
        </div>
      </div>
      <div className="group-list">
        <Scrollbars style={scrollBarStyles}>{renderGroups()}</Scrollbars>
      </div>
      {renderModalCreate()}
      {renderModalUpdate()}
      {renderModalDelete()}
    </Container>
  );
}
