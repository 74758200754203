import produce from 'immer';
import { searchNewTypes } from './actions';
import { campaignsDetailActions } from '../campaigns/detail/actions';

export const fieldsInitialState = {
  instagram: {
    keywords: {
      value: [],
    },
    onlyBio: {
      value: false,
    },
    keywordsRemoved: {
      value: [],
    },
    periodFrom: {
      value: '',
    },
    periodTo: {
      value: '',
    },
    regionInfluencerPostMore: {
      value: '',
    },
    audienceRegion: {
      value: '',
    },
    audienceRegionLimit: {
      value: 0,
    },
    audienceGender: {
      value: 'all',
    },
    audienceGenderLimit: {
      value: 0,
    },
    audienceAge: {
      value: 'all',
    },
    audienceAgeLimit: {
      value: 0,
    },
    aemMin: {
      value: '',
    },
    aemMax: {
      value: '',
    },
    followersMin: {
      value: '',
    },
    followersMax: {
      value: '',
    },
  },
};

export const INITIAL_STATE = {
  network_selected: 'instagram',

  instagram: {
    data: null,
    page: 1,
    last_page: 1,
    loading: false,
    error: false,
    paginate: {
      loading: false,
      error: false,
    },
    fields: fieldsInitialState.instagram,
  },
};

export default function search(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case searchNewTypes.SEARCH_SET_FILTER: {
        const { network_selected } = draft;

        const { field_name, field_value } = action.payload;
        draft[network_selected].fields[field_name].value = field_value;
        break;
      }
      case searchNewTypes.SEARCH_SET_NETWORK: {
        draft.network_selected = action.payload.network;
        break;
      }
      case searchNewTypes.SEARCH_REQUEST: {
        const { isPaginate } = action.payload;

        const { network_selected } = draft;

        if (isPaginate) {
          draft[network_selected].paginate.loading = true;
          draft[network_selected].paginate.error = false;
        } else {
          draft[network_selected].loading = true;
          draft[network_selected].error = false;
        }

        break;
      }
      case searchNewTypes.SEARCH_CREATOR_SUCCESS: {
        const { data, last_page, isPaginate } = action.payload;

        const { network_selected } = draft;

        draft[network_selected].last_page = last_page;

        if (isPaginate) {
          draft[network_selected].page += 1;
          draft[network_selected].paginate.loading = false;
          const current_data = draft[network_selected].data;
          draft[network_selected].data = [...current_data, ...data];
        } else {
          draft[network_selected].loading = false;
          draft[network_selected].data = action.payload.data;
        }

        break;
      }
      case searchNewTypes.SEARCH_CREATOR_FAILURE: {
        const { isPaginate } = action.payload;

        const { network_selected } = draft;

        if (isPaginate) {
          draft[network_selected].paginate.loading = false;
          draft[network_selected].paginate.error = true;
        } else {
          draft[network_selected].loading = false;
          draft[network_selected].error = true;
        }

        break;
      }
      case searchNewTypes.SEARCH_CLEAR: {
        const { network_selected } = draft;

        draft[network_selected].data = null;
        draft[network_selected].page = 1;
        draft[network_selected].last_page = 1;
        draft[network_selected].loading = false;
        draft[network_selected].error = false;
        draft[network_selected].paginate.loading = false;
        draft[network_selected].paginate.error = false;
        draft[network_selected].fields = fieldsInitialState[network_selected];
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST: {
        const { creator: creatorPayload, isActiveCampaign } = action.payload;

        if (draft[state.network_selected].data) {
          draft[state.network_selected].data = draft[
            state.network_selected
          ].data.map((creator) => {
            creator.creator_networks = creator.creator_networks.map(
              (creator_network) => {
                if (
                  creator_network.id === creatorPayload.creator_network.id &&
                  isActiveCampaign
                ) {
                  creator_network.is_in_active_campaign = true;
                }

                return creator_network;
              }
            );

            return creator;
          });
        }
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE: {
        if (draft[state.network_selected].data) {
          draft[state.network_selected].data = draft[
            state.network_selected
          ].data.map((creator) => {
            creator.creator_networks = creator.creator_networks.map(
              (creator_network) => {
                if (creator_network.id === action.payload.creatorNetworkId) {
                  creator_network.is_in_active_campaign = false;
                }

                return creator_network;
              }
            );

            return creator;
          });
        }
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_SUCCESS: {
        const { creatorNetworkIds, isActiveCampaign } = action.payload;

        if (draft[state.network_selected].data) {
          draft[state.network_selected].data = draft[
            state.network_selected
          ].data.map((creator) => {
            creator.creator_networks = creator.creator_networks.map(
              (creator_network) => {
                if (
                  creatorNetworkIds.includes(creator_network.id) &&
                  isActiveCampaign
                ) {
                  creator_network.is_in_active_campaign = false;
                }

                return creator_network;
              }
            );

            return creator;
          });
        }
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE: {
        const { creatorNetworkIds, isActiveCampaign } = action.payload;

        if (draft[state.network_selected].data) {
          draft[state.network_selected].data = draft[
            state.network_selected
          ].data.map((creator) => {
            creator.creator_networks = creator.creator_networks.map(
              (creator_network) => {
                if (
                  creatorNetworkIds.includes(creator_network.id) &&
                  isActiveCampaign
                ) {
                  creator_network.is_in_active_campaign = true;
                }

                return creator_network;
              }
            );

            return creator;
          });
        }
        break;
      }
      default:
    }
  });
}
