import produce from 'immer';
import { checkCodeActions } from './actions';

export const INITIAL_STATE = {
  loading: false,
  isValid: 'not-checked',
};

export default function checkCode(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case checkCodeActions.CHECK_CODE_REQUEST: {
        draft.loading = true;
        draft.isValid = 'not-checked';
        break;
      }
      case checkCodeActions.CHECK_CODE_SUCCESS: {
        draft.loading = false;
        draft.isValid = 'valid';
        break;
      }
      case checkCodeActions.CHECK_CODE_FAILURE: {
        draft.loading = false;
        draft.isValid = 'invalid';
        break;
      }
      default:
    }
  });
}
