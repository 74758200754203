export const searchNewTypes = {
  SEARCH_SET_FILTER: '@searchCreator/SEARCH_SET_FILTER',
  SEARCH_SET_NETWORK: '@searchCreator/SEARCH_SET_NETWORK',

  SEARCH_REQUEST: '@searchCreator/SEARCH_REQUEST',
  SEARCH_CREATOR_SUCCESS: '@searchCreator/SEARCH_CREATOR_SUCCESS',
  SEARCH_CREATOR_FAILURE: '@searchCreator/SEARCH_CREATOR_FAILURE',
  SEARCH_CLEAR: '@searchCreator/SEARCH_CLEAR',
};

export function searchSetFilter(field_name, field_value) {
  return {
    type: searchNewTypes.SEARCH_SET_FILTER,
    payload: { field_name, field_value },
  };
}

export function searchSetNetwork(network) {
  return {
    type: searchNewTypes.SEARCH_SET_NETWORK,
    payload: { network },
  };
}

export function searchRequest(isPaginate = false) {
  return {
    type: searchNewTypes.SEARCH_REQUEST,
    payload: { isPaginate },
  };
}

export function searchSuccess(data, last_page, isPaginate = false) {
  return {
    type: searchNewTypes.SEARCH_CREATOR_SUCCESS,
    payload: { data, last_page, isPaginate },
  };
}

export function searchFailure(isPaginate = false) {
  return {
    type: searchNewTypes.SEARCH_CREATOR_FAILURE,
    payload: { isPaginate },
  };
}

export function searchClear() {
  return {
    type: searchNewTypes.SEARCH_CLEAR,
  };
}
