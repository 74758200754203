import { takeLatest, call, put, all } from 'redux-saga/effects';

import { apiBeAuth } from '~/services/api';

import {
  checkCodeActions,
  checkCodeSuccess,
  checkCodeFailure,
} from './actions';

export function* check({ payload }) {
  try {
    const { code } = payload;
    yield call(apiBeAuth.get, `auth/forgot_password/check_code?code=${code}`);
    yield put(checkCodeSuccess());
  } catch (err) {
    yield put(checkCodeFailure());
  }
}

export default all([takeLatest(checkCodeActions.CHECK_CODE_REQUEST, check)]);
