import React, { memo } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import { Container } from './styles';

function InputSearch({ height, lightTheme, ...props }) {
  return (
    <Container height={height} lightTheme={lightTheme}>
      <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.38)' }} />
      <input type="text" {...props} />
    </Container>
  );
}

InputSearch.defaultProps = {
  height: 50,
  lightTheme: false,
};

InputSearch.propTypes = {
  height: PropTypes.number,
  lightTheme: PropTypes.bool,
};

export default memo(InputSearch);
