import produce from 'immer';
import types from '../types';

export const INITIAL_STATE = {
  groups: {
    loading: false,
    error: false,
    data: [],
    selected: '',
  },
  dataByGroup: {
    tagSelected: '',
    loading: false,
    error: false,
    data: [],
    current_page: 1,
    last_page: 1,
    paginate: {
      loading: false,
      error: false,
    },
  },
};

export default function toc(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATOR_REQUEST: {
        draft.dataByGroup.tagSelected = '';
        draft.dataByGroup.loading = false;
        draft.dataByGroup.error = false;
        draft.dataByGroup.data = [];
        draft.dataByGroup.current_page = 1;
        draft.dataByGroup.last_page = 1;
        break;
      }
      case types.TOC_GROUPS_SELECT: {
        draft.groups.selected = action.payload.groupId;
        break;
      }
      case types.TOC_GROUPS_LIST_REQUEST: {
        draft.groups.loading = true;
        draft.groups.error = false;
        draft.groups.selected = 'loading';
        draft.groups.data = [];
        break;
      }
      case types.TOC_GROUPS_LIST_SUCCESS: {
        draft.groups.loading = false;
        draft.groups.data = action.payload.data;
        draft.groups.selected = 'select';
        break;
      }
      case types.TOC_GROUPS_LIST_FAILURE: {
        draft.groups.loading = false;
        draft.groups.error = true;
        draft.groups.selected = 'error';
        break;
      }
      case types.TOC_SELECT_TAG: {
        draft.dataByGroup.tagSelected = action.payload.tag;
        break;
      }
      case types.TOC_REQUEST: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.dataByGroup.paginate.loading = true;
          draft.dataByGroup.paginate.error = false;
        } else {
          draft.dataByGroup.current_page = 1;
          draft.dataByGroup.last_page = 1;
          draft.dataByGroup.loading = true;
          draft.dataByGroup.error = false;
        }
        break;
      }
      case types.TOC_SUCCESS: {
        const { data, last_page, isPaginate } = action.payload;

        draft.dataByGroup.last_page = last_page;

        if (isPaginate) {
          draft.dataByGroup.current_page += 1;
          draft.dataByGroup.data.post = [
            ...draft.dataByGroup.data.post,
            ...data.post,
          ];
          draft.dataByGroup.paginate.loading = false;
        } else {
          draft.dataByGroup.loading = false;
          draft.dataByGroup.data = data;
        }
        break;
      }
      case types.TOC_FAILURE: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.dataByGroup.paginate.loading = false;
          draft.dataByGroup.paginate.error = true;
        } else {
          draft.dataByGroup.loading = false;
          draft.dataByGroup.error = true;
        }
        break;
      }
      default:
    }
  });
}
