import { takeLatest, call, put, all } from 'redux-saga/effects';

import { apiBeAuth } from '~/services/api';

import {
  forgotPasswordRequestActions,
  sendEmailSuccess,
  sendEmailFailure,
} from './actions';

export function* sendEmail({ payload }) {
  try {
    const { email } = payload;
    yield call(apiBeAuth.post, 'auth/forgot_password', {
      email,
      endpoint: `${window.location.host}/forgot-password/change-password`,
      app: process.env.REACT_APP_API_CLIENT_ID,
    });
    yield put(sendEmailSuccess());
  } catch (err) {
    yield put(sendEmailFailure());
  }
}

export default all([
  takeLatest(forgotPasswordRequestActions.SEND_EMAIL_REQUEST, sendEmail),
]);
