export const creatorPostsTypes = {
  POSTS_REQUEST: '@creatorPosts/POSTS_REQUEST',
  POSTS_SUCCESS: '@creatorPosts/POSTS_SUCCESS',
  POSTS_FAILURE: '@creatorPosts/POSTS_FAILURE',
};

export function postsRequest(typePost, isPaginate = false, filter = '') {
  return {
    type: creatorPostsTypes.POSTS_REQUEST,
    payload: { typePost, isPaginate, filter },
  };
}

export function postsSuccess(data, lastPage, typePost, isPaginate = false) {
  return {
    type: creatorPostsTypes.POSTS_SUCCESS,
    payload: { data, lastPage, typePost, isPaginate },
  };
}

export function postsFailure(typePost, isPaginate = false) {
  return {
    type: creatorPostsTypes.POSTS_FAILURE,
    payload: { typePost, isPaginate },
  };
}
