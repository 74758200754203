import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { MdCheckCircle } from 'react-icons/md';
import {
  ContainerButton,
  ContainerMessageStatus,
  ContainerModal,
  ContainerSave,
  LabelCampaign,
  TitleModal,
  WrapperList,
} from './styles';

import { campaignsListRequest } from '~/store/modules/campaigns/list/actions';
import Pagination from '~/components/Pagination';
import Checkbox from '~/components/Checkbox';
import Button from '~/components/Button';

import {
  duplicateCreatorsRequest,
  duplicateCreatorsReset,
} from '~/store/modules/campaigns/detail/duplicateCreators/actions';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import InputSearch from '~/components/InputSearch';

function ModalCopyCreators({ handleCloseModal, creatorsNetworksIds }) {
  const [campaingIds, setCampaingIds] = useState([]);

  const {
    data: listData,
    page: currentPage,
    loading: loadingList,
    error: errorList,
    totalPages,
  } = useSelector((state) => state.campaignsList.list);

  const [filterTitle, setFilterTitle] = useState('');

  const loadingDuplicate = useSelector(
    (state) => state.duplicateCreators.loading
  );
  const successDuplicate = useSelector(
    (state) => state.duplicateCreators.success
  );
  const errorDuplicate = useSelector((state) => state.duplicateCreators.error);

  const dispatch = useDispatch();

  useEffect(() => {
    if (listData.length === 0) {
      dispatch(campaignsListRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    if (successDuplicate) {
      setTimeout(() => {
        handleCloseModal();
        dispatch(duplicateCreatorsReset());
      }, 5000);
    }
  }, [dispatch, successDuplicate]);

  const filterTitleChange = (e) => {
    setFilterTitle(e.target.value);
  };

  const handleInputSearch = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      dispatch(campaignsListRequest(1, filterTitle));
    }
  };

  const handlePaginate = (page) => {
    if (currentPage === page) return;
    dispatch(campaignsListRequest(page, filterTitle));
  };

  const duplicateCreators = () => {
    dispatch(duplicateCreatorsRequest(campaingIds, creatorsNetworksIds));
  };

  const handleSelectCampaing = (campaingId) => {
    const isExist = campaingIds.includes(campaingId);
    const newCampaing = isExist
      ? campaingIds.filter((id) => id !== campaingId)
      : [...campaingIds, campaingId];
    setCampaingIds(newCampaing);
  };

  if (errorDuplicate) {
    return <LoadingDataErrorHandling refreshData={duplicateCreators} />;
  }

  if (errorList) {
    return (
      <LoadingDataErrorHandling
        refreshData={() =>
          dispatch(campaignsListRequest(currentPage, filterTitle))
        }
      />
    );
  }
  if (successDuplicate) {
    return (
      <ContainerModal>
        <ContainerButton>
          <Button type="button" onClick={handleCloseModal} width={50}>
            x
          </Button>
        </ContainerButton>
        <ContainerMessageStatus>
          <MdCheckCircle />
          <h1>SAVED</h1>
        </ContainerMessageStatus>
      </ContainerModal>
    );
  }
  return (
    <ContainerModal>
      <ContainerButton>
        <Button type="button" onClick={handleCloseModal} width={50}>
          x
        </Button>
      </ContainerButton>

      <TitleModal>COPY CREATORS</TitleModal>
      <ContainerSave>
        <Button type="button" onClick={duplicateCreators}>
          SAVE
        </Button>

        <InputSearch
          aria-label="copy_creators_campaign_search"
          placeholder="Search in campaigns"
          value={filterTitle}
          onChange={filterTitleChange}
          onKeyPress={handleInputSearch}
        />
      </ContainerSave>

      <table>
        <LabelCampaign>
          <tr>
            <th>SELECT</th>
            <th>
              <span>CAMPAIGN NAME</span>
            </th>
            <th>
              <span>CLIENT NAME</span>
            </th>
            <th>
              <span>CREATED BY</span>
            </th>
            <th>
              <span>CREATED ON</span>
            </th>
            <th>
              <span>MODIFIED ON</span>
            </th>
          </tr>
        </LabelCampaign>
        <WrapperList>
          {loadingDuplicate || loadingList ? (
            <tr>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            listData.map((row) => (
              <tr key={row.id}>
                <td>
                  <Checkbox
                    name="group[]"
                    onClick={() => handleSelectCampaing(row.id)}
                    checked={campaingIds.includes(row.id)}
                  />
                </td>
                <td> {row.title}</td>
                <td>{row.client}</td>
                <td>{row.created_by.name}</td>
                <td>{format(new Date(row.created), 'dd/MM/yyyy')}</td>
                <td>{format(new Date(row.updated), 'dd/MM/yyyy')}</td>
              </tr>
            ))
          )}
        </WrapperList>
      </table>

      <Pagination
        handleFirstPage={() => handlePaginate(1)}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePaginate={handlePaginate}
        handleLastPage={() => handlePaginate(totalPages)}
      />
    </ContainerModal>
  );
}

ModalCopyCreators.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default ModalCopyCreators;
