import produce from 'immer';
import { creatorsQueueActions } from './actions';

export const INITIAL_STATE = {
  creators: [],
  loading: false,
  error: false,
  page: 1,
  last_page: 1,
  paginate: {
    loading: false,
    error: false,
  },
};

export default function creatorsQueue(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case creatorsQueueActions.CREATORS_QUEUE_REQUEST: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.paginate.loading = true;
          draft.paginate.error = false;
        } else {
          draft.page = 1;
          draft.last_page = 1;
          draft.loading = true;
          draft.error = false;
        }

        break;
      }
      case creatorsQueueActions.CREATORS_QUEUE_SUCCESS: {
        const { creators, lastPage, isPaginate } = action.payload;

        draft.last_page = lastPage;

        if (isPaginate) {
          draft.page += 1;
          draft.paginate.loading = false;
          draft.creators = [...draft.creators, ...creators];
        } else {
          draft.loading = false;
          draft.creators = creators;
        }
        break;
      }
      case creatorsQueueActions.CREATORS_QUEUE_FAILURE: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.paginate.loading = false;
          draft.paginate.error = true;
        } else {
          draft.loading = false;
          draft.error = true;
        }
        break;
      }
      default:
    }
  });
}
