import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  setOrderBy,
  listRequest,
  registerReset,
  listReset,
  toggleStoriesStatusReset,
} from '~/store/modules/campaigns/detail/storiesMonitor/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import ModalCloseContainer from '~/components/ModalCloseContainer';
import Configure from './Configure';
import Monitored from './Monitored';

import { Container } from './styles';

function ModalStoriesMonitor({ handleCloseModal }) {
  const dispatch = useDispatch();

  const selectedCreatorId = useSelector(
    (state) => state.campaignsDetail.selectedCreatorId
  );
  const campaign = useSelector((state) => state.campaignsDetail.campaign);
  const list = useSelector((state) => state.storiesMonitor.list);
  const creatorList = useSelector(
    (state) => state.campaignsDetail.creators_list.data
  );
  const creator = creatorList.find(
    (item) => item.creator_id === selectedCreatorId
  );

  useEffect(() => {
    dispatch(listRequest(creator.creator_network.id, campaign.data.id));

    return () => {
      dispatch(registerReset());
      dispatch(listReset());
      dispatch(toggleStoriesStatusReset());
      dispatch(setOrderBy('DESC'));
    };
  }, [dispatch, creator, campaign.data]);

  const renderContent = () => {
    if (list.loading) {
      return <Loader />;
    }

    if (list.error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() =>
            dispatch(listRequest(creator.creator_network.id, campaign.data.id))
          }
        />
      );
    }

    return (
      <>
        <Configure />
        <Monitored />
      </>
    );
  };

  return (
    <Container>
      <ModalCloseContainer handleCloseModal={handleCloseModal} />
      <div className="content">{renderContent()}</div>
    </Container>
  );
}

ModalStoriesMonitor.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default ModalStoriesMonitor;
