import styled from 'styled-components';

export const Container = styled.div``;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 16%;

  div {
    display: flex;
  }

  p {
    margin-left: 8px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.25px;
    font: 14 px 'Hind', sans-serif;
    color: #00000099;
  }
`;

export const ContentContainer = styled.div``;
