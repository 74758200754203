export const campaignsNewActions = {
  CAMPAIGNS_NEW_REQUEST: '@campaigns/new/CAMPAIGNS_NEW_REQUEST',
  CAMPAIGNS_NEW_SUCCESS: '@campaigns/new/CAMPAIGNS_NEW_SUCCESS',
  CAMPAIGNS_NEW_FAILURE: '@campaigns/new/CAMPAIGNS_NEW_FAILURE',
  CAMPAIGNS_NEW_RESET: '@campaigns/new/CAMPAIGNS_NEW_RESET',
};

export function campaignsNewRequest(data) {
  return {
    type: campaignsNewActions.CAMPAIGNS_NEW_REQUEST,
    payload: { data },
  };
}

export function campaignsNewSuccess(data) {
  return {
    type: campaignsNewActions.CAMPAIGNS_NEW_SUCCESS,
    payload: { data },
  };
}

export function campaignsNewFailure(message) {
  return {
    type: campaignsNewActions.CAMPAIGNS_NEW_FAILURE,
    payload: { message },
  };
}

export function campaignsNewReset() {
  return {
    type: campaignsNewActions.CAMPAIGNS_NEW_RESET,
  };
}
