import React, { useRef, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import Loader from '~/components/Loader';
import InputSearch from '~/components/InputSearch';

import { useOutsideAlerter } from '~/helpers';

import { Container, List } from './styles';

const InputSearchList = ({
  height,
  data = [],
  handleInputChange,
  loading,
  renderList,
  handleInputFocus,
  handleFinalScroll,
  loadingPaginate,
  placeholder,
  currentPage,
  lastPage,
  keyword,
}) => {
  const [visible, setVisible] = useState(false);

  const searchRef = useRef(null);

  useEffect(() => {
    if (keyword.length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [keyword]);

  useOutsideAlerter(searchRef, () => {
    setVisible(false);
  });

  const handleFocus = useCallback(() => {
    setVisible(true);
    handleInputFocus();
  }, [handleInputFocus]);

  const handleScroll = useCallback(
    (e) => {
      const finalScroll =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (finalScroll && !loadingPaginate && !loading && currentPage < lastPage)
        handleFinalScroll();
    },
    [currentPage, handleFinalScroll, lastPage, loading, loadingPaginate]
  );

  const renderLoadingPaginate = useCallback(() => {
    return loadingPaginate && <Loader />;
  }, [loadingPaginate]);

  const renderLocalList = useCallback(() => {
    if (loading) {
      return <Loader />;
    }

    return renderList(data);
  }, [data, loading, renderList]);

  return (
    <Container ref={searchRef}>
      <InputSearch
        height={height}
        placeholder={placeholder}
        type="text"
        onChange={handleInputChange}
        onFocus={handleFocus}
        value={keyword}
      />
      {(data.length > 0 || loading) && visible && (
        <List top={height} onScroll={handleScroll}>
          {renderLocalList()}
          {renderLoadingPaginate()}
        </List>
      )}
      {data.length === 0 && keyword.length > 0 && !loading && visible && (
        <List top={height} onScroll={handleScroll}>
          <div className="no-results">No results found</div>
        </List>
      )}
    </Container>
  );
};

InputSearchList.defaultProps = {
  height: 50,
  handleInputFocus: () => false,
};

InputSearchList.propTypes = {
  height: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      displayName: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  renderList: PropTypes.func.isRequired,
  handleInputFocus: PropTypes.func,
  handleFinalScroll: PropTypes.func.isRequired,
  loadingPaginate: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  keyword: PropTypes.string.isRequired,
};

export default memo(InputSearchList);
