/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
import { useEffect } from 'react';
import { call } from 'redux-saga/effects';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds,
} from 'date-fns';

export function abbreviateNumber(number, decPlaces = 0, textFull = false) {
  if (typeof number === 'undefined') number = 0;

  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = textFull
    ? [' thousand', ' millions', ' billion', ' trillions']
    : ['k', 'M', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

export function generateRandomHexadecimal() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}

export function getIdentifierByUrl(text) {
  const splitedUrl = text.split('/');

  if (splitedUrl.length === 0) {
    return text;
  }

  const removedEmptyStrings = splitedUrl.filter((row) => row.length > 0);

  return removedEmptyStrings[removedEmptyStrings.length - 1];
}

export function clearIdentifiers(text) {
  text = text
    .trim()
    .replace('@', '')
    .replace(/(\r\n\t|\n|\r\t)/gm, ',')
    .replace('; ', ',')
    .replace('| ', ',')
    .replace(', ', ',')
    .replace(' ', ',')
    .replace(';', ',')
    .replace('|', ',');

  text = text.split(',');

  const fixedBreakLines = text.filter((row) => row.length > 0);

  const filterIdentifiers = fixedBreakLines.map((row) =>
    getIdentifierByUrl(row)
  );

  return filterIdentifiers;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function* serviceWrapperSaga(fn, ...args) {
  try {
    return yield call(fn, ...args);
  } catch (e) {
    const { response } = e;

    return response;
  }
}

export function toSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function copyToClipboard(value) {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const timeBetweenPast = (date) => {
  const days = differenceInDays(new Date(), new Date(date));
  const hours = differenceInHours(new Date(), new Date(date));
  const minutes = differenceInMinutes(new Date(), new Date(date));
  const seconds = differenceInSeconds(new Date(), new Date(date));
  const miliseconds = differenceInMilliseconds(new Date(), new Date(date));

  if (days > 0) {
    return `${days} ${days > 1 ? 'days' : 'day'}`;
  }

  if (hours > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  }

  if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
  }

  if (seconds > 0) {
    return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`;
  }

  if (miliseconds > 0) {
    return 'just now';
  }

  return '';
};

export const dateIsValid = (date) => date.match(/(\d{4})-(\d{2})-(\d{2})/);
