import types from '../types';

export function signInRequest(email, password) {
  return {
    type: types.SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signInSuccess(data) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: { data },
  };
}

export function signInFailure(message) {
  return {
    type: types.SIGN_IN_FAILURE,
    payload: { message },
  };
}

export function signOut() {
  return {
    type: types.SIGN_OUT_REQUEST,
  };
}

export function setTokens(tokens) {
  return {
    type: types.SET_TOKENS,
    payload: { tokens },
  };
}
