import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ColLeft = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.primary};
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Rubik';
    font-size: 61px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 120px;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: 50px;
    }
  }

  img {
    @media (max-width: 1366px) {
      height: 400px;
    }
    @media (max-width: 1280px) {
      height: 300px;
    }
  }
`;

export const ColRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  .container-content {
    display: flex;
    flex-direction: column;
    width: 464px;

    form {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-family: 'Rubik';
      font-size: 34px;
      font-weight: 500;
      margin: 70px 0;
      text-align: center;

      @media (max-width: 1280px) {
        margin: 50px 0;
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 464px;
  height: 40px;
  padding: 13px 16px 11px;
  font-family: 'Hind';
  font-size: 14.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #ffffff;
`;
