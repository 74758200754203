import { takeLatest, put, all } from 'redux-saga/effects';

import { apiBeUser } from '~/services/api';
import { serviceWrapperSaga } from '~/helpers';

import {
  addNewUserActions,
  addNewUserSuccess,
  addNewUserFailure,
} from './actions';

export function* addUser({ payload }) {
  const { data } = payload;

  const response = yield serviceWrapperSaga(apiBeUser.post, `users/invite`, {
    ...data,
    endpoint: `${window.location.host}/invite/new`,
    app: process.env.REACT_APP_API_CLIENT_ID,
  });

  if (response.status >= 400) {
    yield put(addNewUserFailure(response.data.message));
    return;
  }

  yield put(addNewUserSuccess());
}

export default all([
  takeLatest(addNewUserActions.ADD_NEW_USER_REQUEST, addUser),
]);
