import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

export default function Intro() {
  const profileData = useSelector((state) => state.creator.profile.data);
  const networkIndexSelected = useSelector(
    (state) => state.creator.tabs.networkIndexSelected
  );
  const networkTypeSelected = useSelector(
    (state) => state.creator.tabs.networkTypeSelected
  );
  const data = profileData.creator_networks[networkIndexSelected];

  return (
    <Container>
      <div className="head">
        <div
          className="user-picture"
          style={{ backgroundImage: `url(${data.profile_picture})` }}
        />
        <div className="col-infos">
          <div className="user-name">{data.title}</div>
          {networkTypeSelected !== 'YOUTUBE' && (
            <div className="user-identifier">@{data.identifier}</div>
          )}
        </div>
      </div>
      <div className="description-label">Bio:</div>
      <div className="description" title={data.bio}>
        {data.bio}
      </div>
    </Container>
  );
}
