import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f5f5f5;
  height: 100%;
  padding: 50px;

  .container-metrics {
    width: 150px;
    margin-bottom: 20px;

    .MuiFormControl-root {
      .MuiSelect-selectMenu {
        min-width: 150px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
          0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        padding: 10px;
        background: #fff;
      }
      .MuiSelect-icon {
        color: ${colorPalette.primary};
      }
      .MuiSelect-selectMenu {
        font-family: 'Hind';
        font-size: 14px;
      }
    }

    select {
      background: #fff;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      margin-bottom: 20px;
      min-width: 145px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .table-totals {
    margin-top: 20px;
    border-radius: 2px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fafafa;
    padding: 16px 0;

    thead {
      tr {
        th {
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.38);
          text-align: center;
          font-weight: 400;

          &.empty {
            width: 185px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }

        td {
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.6);
          text-align: center;
          padding: 10px 0;

          &.group-name {
            font-family: Rubik;
            font-weight: 500;
            letter-spacing: 0.1px;
            text-align: left;
            padding-left: 24px;
          }
        }
      }
    }
  }
`;
