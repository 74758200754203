import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';

import { FiCalendar, FiExternalLink } from 'react-icons/fi';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { postsRequest } from '~/store/modules/creator/posts/actions';

import { abbreviateNumber } from '~/helpers';
import Loader from '~/components/Loader';
import Modal from '~/components/Modal';
import InputSearch from '~/components/InputSearch';

import Avarages from '../../../Instagram/Posts/Avarages';

import ModalContent from '../Modal';

import { Container } from './styles';

function AllPosts() {
  const dispatch = useDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurretIndex, setModalCurretIndex] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const loading = useSelector((state) => state.creatorPosts.allPosts.loading);
  const { data, current_page, last_page } = useSelector(
    (state) => state.creatorPosts.allPosts
  );
  const loadingPaginate = useSelector(
    (state) => state.creatorPosts.allPosts.paginate.loading
  );

  useEffect(() => {
    dispatch(postsRequest('allPosts'));
  }, [dispatch]);

  function handleShowModal(index) {
    setModalCurretIndex(index);
    setModalIsOpen(true);
  }

  function handleHideModal() {
    setModalIsOpen(false);
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        handleCloseModal={handleHideModal}
        width={880}
        height={600}
      >
        <ModalContent
          index={modalCurretIndex}
          data={data}
          handleHideModal={handleHideModal}
        />
      </Modal>
    );
  }

  function renderPosts() {
    if (data.length > 0) {
      return data.map((row, index) => (
        <div className="post-item" key={row.id}>
          <div
            className="thumb"
            onClick={() => handleShowModal(index)}
            style={{
              backgroundImage: `url(${
                row.medias.length > 0 ? row.medias[0]?.path : ''
              })`,
            }}
          />
          <ul>
            <li className="title">
              <h3>{row.title}</h3>
            </li>
            <li>
              <div className="icon-wrapper-posts">
                <FiCalendar size="15" />
              </div>
              <span>{format(new Date(row.published_at), 'dd/MM/yyyy')}</span>
            </li>
            <li>
              <div className="icon-wrapper-posts">
                <VisibilityIcon style={{ fontSize: 15, color: '#797979' }} />
              </div>
              <span>{abbreviateNumber(row.total_views)}</span>
            </li>
            <li>
              <div className="icon-wrapper-posts">
                <AiOutlineLike size="15" />
              </div>
              <span>{abbreviateNumber(row.total_likes)}</span>
            </li>
            <li>
              <div className="icon-wrapper-posts">
                <AiOutlineDislike size="15" />
              </div>
              <span>{abbreviateNumber(row.total_dislikes)}</span>
            </li>
            <li>
              <div className="icon-wrapper-posts">
                <CommentOutlinedIcon
                  style={{ fontSize: 15, color: '#797979' }}
                />
              </div>
              <span>{abbreviateNumber(row.total_comments)}</span>
            </li>
            <li>
              <a href={row.permalink} target="_blank" rel="noopener noreferrer">
                <div className="icon-wrapper-posts">
                  <FiExternalLink size="15" />
                </div>
              </a>
            </li>
          </ul>
        </div>
      ));
    }

    return null;
  }

  function handleSeeMore() {
    dispatch(postsRequest('allPosts', true, searchValue));
  }

  function renderSeeMore() {
    if (data && data.length > 0 && !loadingPaginate) {
      return (
        <div className="button-see-more" onClick={handleSeeMore}>
          <div className="icon-wrapper">
            <MdKeyboardArrowDown size="16" />
          </div>
          <span>See more</span>
        </div>
      );
    }

    return null;
  }

  function renderLoadingSeeMore() {
    if (loadingPaginate) {
      return <Loader />;
    }

    return null;
  }

  function handleInputSearchKeyDown(e) {
    if (
      (e.keyCode === 9 || e.keyCode === 13) &&
      searchValue.trim().length > 0
    ) {
      dispatch(postsRequest('allPosts', false, searchValue));
    }
  }

  function renderSearch() {
    if (data && data.length === 0) return null;

    return (
      <div className="container-search">
        <InputSearch
          placeholder="Search posts"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleInputSearchKeyDown}
        />
      </div>
    );
  }

  function render() {
    if (loading) {
      return <Loader />;
    }

    if (data && data.length === 0) {
      return <div className="no-results">No posts found</div>;
    }

    return (
      <>
        {renderSearch()}
        <div className="content-posts wide">{renderPosts()}</div>
        {current_page < last_page && (
          <>
            <div className="container-loading-see-more">
              {renderLoadingSeeMore()}
            </div>
            <div className="container-see-more">{renderSeeMore()}</div>
          </>
        )}
        <Avarages typePost="allPosts" />
      </>
    );
  }

  return (
    <Container>
      {render()}
      {renderModal()}
    </Container>
  );
}

export default AllPosts;
