import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { selectMenu } from '~/store/modules/menu/actions';

import List from './List';
import Results from './Results';

import { Container } from './styles';

function ShareOfVoice() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectMenu('share-of-voice'));
  }, [dispatch]);

  return (
    <Container>
      <List />
      <Results />
    </Container>
  );
}

export default ShareOfVoice;
