import produce from 'immer';
import { campaignsActions } from './actions';
import { campaignsNewActions } from '../new/actions';

export const INITIAL_STATE = {
  list: {
    data: [],
    loading: true,
    error: false,
    page: 1,
    totalPages: 1,
    filter: {
      title: '',
    },
  },
  delete: {
    loading: false,
    error: false,
    completedDelete: 0,
  },
};

export default function campaignsList(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case campaignsActions.CAMPAIGNS_LIST_RESET: {
        draft.list.data = [];
        draft.list.loading = true;
        draft.list.error = false;
        draft.list.page = 1;
        draft.list.totalPages = 1;
        draft.list.filter.title = '';
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_REQUEST: {
        draft.list.loading = true;
        draft.list.error = false;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_SUCCESS: {
        const { data, page, lastPage } = action.payload;

        draft.list.page = page;
        draft.list.totalPages = lastPage;
        draft.list.loading = false;
        draft.list.data = data;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_FAILURE: {
        draft.list.loading = false;
        draft.list.error = true;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_DELETE_REQUEST: {
        draft.delete.loading = true;
        draft.delete.error = false;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_DELETE_SUCCESS: {
        const filteredList = draft.list.data.filter(
          (campaign) => campaign.id !== action.payload.campaignId
        );
        draft.list.data = filteredList;
        draft.delete.loading = false;
        draft.delete.error = false;
        draft.delete.completedDelete += 1;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_DELETE_FAILURE: {
        draft.delete.loading = false;
        draft.delete.error = true;
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_SET_FILTER_TITLE: {
        draft.list.filter.title = action.payload.title;
        break;
      }
      case campaignsNewActions.CAMPAIGNS_NEW_SUCCESS: {
        draft.list.data = [action.payload.data, ...state.list.data];
        break;
      }
      default:
    }
  });
}
