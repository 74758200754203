import types from '../types';

export function profileFetchRequest() {
  return {
    type: types.PROFILE_FETCH_REQUEST,
  };
}

export function profileFetchSuccess(profile) {
  return {
    type: types.PROFILE_FETCH_SUCCESS,
    payload: { profile },
  };
}

export function profileFetchFailure() {
  return {
    type: types.PROFILE_FETCH_FAILURE,
  };
}

export function profileUpdateSettingsRequest(requestPayload, settings) {
  return {
    type: types.UPDATE_PROFILE_SETTINGS_REQUEST,
    payload: { requestPayload, settings },
  };
}

export function profileUpdateSettingsSuccess(settings) {
  return {
    type: types.UPDATE_PROFILE_SETTINGS_SUCCESS,
    payload: { settings },
  };
}

export function profileUpdateSettingsFailure() {
  return {
    type: types.UPDATE_PROFILE_SETTINGS_FAILURE,
  };
}
