export const campaignsDetailActions = {
  SET_SELECTED_CREATOR: '@campaigns/detail/SET_SELECTED_CREATOR',
  CAMPAIGNS_DETAIL_REQUEST: '@campaigns/detail/CAMPAIGNS_DETAIL_REQUEST',
  CAMPAIGNS_DETAIL_SUCCESS: '@campaigns/detail/CAMPAIGNS_DETAIL_SUCCESS',
  CAMPAIGNS_DETAIL_FAILURE: '@campaigns/detail/CAMPAIGNS_DETAIL_FAILURE',
  CAMPAIGNS_DETAIL_CREATOR_LIST_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_LIST_REQUEST',
  CAMPAIGNS_DETAIL_CREATOR_LIST_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_LIST_SUCCESS',
  CAMPAIGNS_DETAIL_CREATOR_LIST_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_LIST_FAILURE',
  CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST',
  CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE',
  CAMPAIGNS_DETAIL_EDIT_TITLE_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_TITLE_REQUEST',
  CAMPAIGNS_DETAIL_EDIT_TITLE_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_TITLE_SUCCESS',
  CAMPAIGNS_DETAIL_EDIT_TITLE_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_TITLE_FAILURE',
  CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_REQUEST',
  CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_SUCCESS',
  CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_FAILURE',
  CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_RESET:
    '@campaigns/detail/CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_RESET',
  CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST',
  CAMPAIGNS_DETAIL_CREATOR_DELETE_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_DELETE_SUCCESS',
  CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE',
  CAMPAIGNS_DETAIL_COMMENTS_LIST_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_LIST_REQUEST',
  CAMPAIGNS_DETAIL_COMMENTS_LIST_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_LIST_SUCCESS',
  CAMPAIGNS_DETAIL_COMMENTS_LIST_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_LIST_FAILURE',
  CAMPAIGNS_DETAIL_COMMENTS_ADD_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_ADD_REQUEST',
  CAMPAIGNS_DETAIL_CREATOR_ADD_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_CREATOR_ADD_SUCCESS',
  CAMPAIGNS_DETAIL_COMMENTS_ADD_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_ADD_SUCCESS',
  CAMPAIGNS_DETAIL_COMMENTS_ADD_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_ADD_FAILURE',
  CAMPAIGNS_DETAIL_COMMENTS_EDIT_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_EDIT_REQUEST',
  CAMPAIGNS_DETAIL_COMMENTS_EDIT_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_EDIT_SUCCESS',
  CAMPAIGNS_DETAIL_COMMENTS_EDIT_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_COMMENTS_EDIT_FAILURE',
  CAMPAIGNS_DETAIL_TRACKING_SAVE_REQUEST:
    '@campaigns/detail/CAMPAIGNS_DETAIL_TRACKING_SAVE_REQUEST',
  CAMPAIGNS_DETAIL_TRACKING_SAVE_SUCCESS:
    '@campaigns/detail/CAMPAIGNS_DETAIL_TRACKING_SAVE_SUCCESS',
  CAMPAIGNS_DETAIL_TRACKING_SAVE_FAILURE:
    '@campaigns/detail/CAMPAIGNS_DETAIL_TRACKING_SAVE_FAILURE',
  CAMPAIGNS_DETAIL_TRACKING_SAVE_RESET:
    '@campaigns/detail/CAMPAIGNS_DETAIL_TRACKING_SAVE_RESET',
};

export function setSelectedCreatorStore(creatorId) {
  return {
    type: campaignsDetailActions.SET_SELECTED_CREATOR,
    payload: { creatorId },
  };
}

export function campaignsDetailRequest(id) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_REQUEST,
    payload: { id },
  };
}

export function campaignsDetailSuccess(data) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_SUCCESS,
    payload: { data },
  };
}

export function campaignsDetailFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_FAILURE,
  };
}

export function campaignsDetailCreatorListRequest(id, page = 1) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_REQUEST,
    payload: { id, page },
  };
}

export function campaignsDetailCreatorListSuccess(data, currentPage, lastPage) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_SUCCESS,
    payload: { data, currentPage, lastPage },
  };
}

export function campaignsDetailCreatorListFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_FAILURE,
  };
}

export function campaignsDetailCreatorAddRequest(
  creator,
  isActiveCampaign = false,
  campaignId = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST,
    payload: { creator, isActiveCampaign, campaignId },
  };
}

export function campaignsDetailCreatorAddSuccess(creatorNetworkId) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_SUCCESS,
    payload: { creatorNetworkId },
  };
}

export function campaignsDetailCreatorAddFailure(
  creatorNetworkId,
  isActiveCampaign = false
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE,
    payload: { creatorNetworkId, isActiveCampaign },
  };
}

export function campaignsDetailEditTitleRequest(title) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_REQUEST,
    payload: { title },
  };
}

export function campaignsDetailEditTitleSuccess(title) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_SUCCESS,
    payload: { title },
  };
}

export function campaignsDetailEditTitleFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_FAILURE,
  };
}

export function campaignsDetailEditCampaignRequest(data) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_REQUEST,
    payload: { data },
  };
}

export function campaignsDetailEditCampaignSuccess(data) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_SUCCESS,
    payload: { data },
  };
}

export function campaignsDetailEditCampaignFailure(message) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_FAILURE,
    payload: { message },
  };
}

export function campaignsDetailEditCampaignReset() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_RESET,
  };
}

export function campaignsDetailCreatorDeleteRequest(
  creatorNetworkIds,
  isActiveCampaign = false,
  campaignId = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST,
    payload: { creatorNetworkIds, isActiveCampaign, campaignId },
  };
}

export function campaignsDetailCreatorDeleteSuccess(
  creatorNetworkIds,
  isActiveCampaign = false
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_SUCCESS,
    payload: { creatorNetworkIds, isActiveCampaign },
  };
}

export function campaignsDetailCreatorDeleteFailure(
  creatorNetworkIds,
  isActiveCampaign = false
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE,
    payload: { creatorNetworkIds, isActiveCampaign },
  };
}

export function campaignsDetailCommentsListRequest(
  creatorNetworkId,
  campaignId = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_REQUEST,
    payload: { creatorNetworkId, campaignId },
  };
}

export function campaignsDetailCommentsListSuccess(data) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_SUCCESS,
    payload: { data },
  };
}

export function campaignsDetailCommentsListFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_FAILURE,
  };
}

export function campaignsDetailCommentsAddRequest(
  creatorNetworkId,
  comment,
  campaignId = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_REQUEST,
    payload: { creatorNetworkId, comment, campaignId },
  };
}

export function campaignsDetailCommentsAddSuccess(data) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_SUCCESS,
    payload: { data },
  };
}

export function campaignsDetailCommentsAddFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_FAILURE,
  };
}

export function campaignsDetailCommentsEditRequest(
  creatorNetworkId,
  commentId,
  comment,
  oldComment,
  campaignId = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_REQUEST,
    payload: { creatorNetworkId, commentId, comment, oldComment, campaignId },
  };
}

export function campaignsDetailCommentsEditSuccess() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_SUCCESS,
  };
}

export function campaignsDetailCommentsEditFailure(
  creatorNetworkId,
  commentId,
  oldComment
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_FAILURE,
    payload: { creatorNetworkId, commentId, oldComment },
  };
}

export function campaignsDetailTrackingSaveRequest(
  creatorNetworks,
  campaignId,
  tracking
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_REQUEST,
    payload: { creatorNetworks, campaignId, tracking },
  };
}

export function campaignsDetailTrackingSaveSuccess(
  creatorId = null,
  tracking = null
) {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_SUCCESS,
    payload: { creatorId, tracking },
  };
}

export function campaignsDetailTrackingSaveFailure() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_FAILURE,
  };
}

export function campaignsDetailTrackingSaveReset() {
  return {
    type: campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_RESET,
  };
}
