import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .table-scroll {
    display: block;
    width: 100%;
    height: 558px;
    overflow-y: auto;
  }

  .empty-campaigns {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    align-items: center;

    img {
      width: 238px;
      height: 280px;
      align-self: center;
    }

    h3 {
      font-family: Rubik;
      font-size: 16.3px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.48;
      letter-spacing: 0.15px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      margin: 24px 0;
    }

    .btn-empty-campaigns {
      width: 312px;
    }
  }
`;

export const Header = styled.div`
  width: 1376px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 47px;

  .first-column {
    display: flex;
    align-items: center;
    margin-right: 32px;

    h2 {
      margin-left: 64px;
      font-family: Rubik;
      font-size: 34.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: #000000;
    }
  }

  .second-column {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    div {
      width: 439px;
    }

    input {
      padding: 13px 16px 9px 8px;
      font-family: Hind;
      font-size: 16.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
    }
  }
`;

export const WrapperCampaigns = styled.table`
  font-family: Rubik;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  max-width: 1376px;
  width: 100%;
  border-collapse: collapse;
`;

export const LabelCampaigns = styled.thead`
  .campaigns-head-tr {
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  span:hover {
    color: ${colorPalette.primary};
    cursor: pointer;
  }

  th {
    padding-bottom: 16px;

    &:first-child {
      padding-left: 64px;
    }

    &:last-child {
      padding-right: 64px;
    }
  }
`;

export const WrapperList = styled.tbody`
  td {
    padding: 23px 0;

    &:first-child {
      padding-left: 64px;
    }

    &:last-child {
      padding-right: 64px;
    }
  }

  .campaigns-list-tr {
    :hover {
      background-color: rgba(90, 19, 217, 0.07);
      cursor: pointer;
    }

    :nth-child(odd) {
      background-color: #f5f5f5;

      :hover {
        background-color: rgba(90, 19, 217, 0.07);
        cursor: pointer;
      }
    }
  }

  .delete-campaign {
    align-self: center;
    text-align: center;
  }
`;
