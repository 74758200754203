import produce from 'immer';
import { campaignsHeaderActions } from './actions';
import { campaignsNewActions } from '../new/actions';
import { campaignsActions } from '../list/actions';
import { campaignsDetailActions } from '../detail/actions';

export const INITIAL_STATE = {
  filter: { title: '' },
  data: [],
  page: 1,
  last_page: 1,
  loading: false,
  error: false,
  paginate: {
    loading: false,
    error: false,
  },
};

export default function campaignsHeader(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case campaignsHeaderActions.CAMPAIGNS_HEADER_SET_FILTER: {
        const { title } = action.payload;

        draft.filter.title = title;

        break;
      }
      case campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_REQUEST: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.paginate.loading = true;
          draft.paginate.error = false;
        } else {
          draft.loading = true;
          draft.error = false;
        }

        break;
      }
      case campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_SUCCESS: {
        const { data, last_page, isPaginate } = action.payload;

        draft.last_page = last_page;

        if (isPaginate) {
          draft.page += 1;
          draft.paginate.loading = false;
          draft.data = [...draft.data, ...data];
        } else {
          draft.loading = false;
          draft.data = action.payload.data;
        }

        break;
      }
      case campaignsHeaderActions.CAMPAIGNS_HEADER_LIST_FAILURE: {
        const { isPaginate } = action.payload;

        if (isPaginate) {
          draft.paginate.loading = false;
          draft.paginate.error = true;
        } else {
          draft.loading = false;
          draft.error = true;
        }

        break;
      }
      case campaignsNewActions.CAMPAIGNS_NEW_SUCCESS: {
        draft.data = [action.payload.data, ...state.data];
        break;
      }
      case campaignsActions.CAMPAIGNS_LIST_DELETE_SUCCESS: {
        const filteredList = draft.data.filter(
          (campaign) => campaign.id !== action.payload.campaignId
        );
        draft.data = filteredList;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST: {
        const { campaignId } = action.payload;

        draft.data = draft.data.map((campaign) => {
          if (campaign.id === campaignId) {
            campaign.total_creator_networks += 1;
          }

          return campaign;
        });
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST: {
        const { campaignId } = action.payload;

        draft.data = draft.data.map((campaign) => {
          if (campaign.id === campaignId) {
            campaign.total_creator_networks -= 1;
          }

          return campaign;
        });
        break;
      }
      default:
    }
  });
}
