import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';

import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

import {
  listRequest,
  search,
  select,
  clearSelected,
} from '~/store/modules/sov/actions';

import Loader from '~/components/Loader';
import Checkbox from '~/components/Checkbox';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { Container } from './styles';

function List() {
  const dispatch = useDispatch();

  const scrollBarStyles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  };

  const [searchValue, setSearchValue] = useState('');

  const loading = useSelector((state) => state.sov.list.loading);
  const error = useSelector((state) => state.sov.list.error);
  const data = useSelector((state) => state.sov.list.data);
  const dataSearch = useSelector((state) => state.sov.list.dataSearch);
  const selected = useSelector((state) => state.sov.list.selected);

  useEffect(() => {
    dispatch(listRequest());
  }, [dispatch]);

  function handleInputSearchChange(e) {
    const inputSearchValue = e.target.value;
    setSearchValue(inputSearchValue);
    dispatch(search(inputSearchValue));
  }

  function renderList() {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling refreshData={() => dispatch(listRequest())} />
      );
    }

    const listDataRender = dataSearch.length > 0 ? dataSearch : data;

    return listDataRender.map((group) => (
      <div key={group.id} className="group-list-item">
        <Checkbox
          name="group[]"
          onClick={() => dispatch(select(group.id))}
          checked={selected.includes(group.id)}
        />
        <div className="group-name">{group.name}</div>
      </div>
    ));
  }

  return (
    <Container>
      <h2 className="page-title">Share of Voice</h2>
      <div className="description">Select two or more groups to view graph</div>
      <div className="input-search">
        <div className="icon-wrapper">
          <AiOutlineSearch size="28" />
        </div>
        <input
          type="text"
          value={searchValue}
          onChange={handleInputSearchChange}
          placeholder="Search tag group"
        />
      </div>
      <div className="total-selecteds">
        {selected.length > 0 && (
          <>
            <IndeterminateCheckBoxOutlinedIcon
              onClick={() => dispatch(clearSelected())}
              style={{ fontSize: 25, color: '#818181' }}
            />
            <span>{selected.length} selected</span>
          </>
        )}
      </div>
      <div className="group-list">
        <Scrollbars style={scrollBarStyles}>{renderList()}</Scrollbars>
      </div>
    </Container>
  );
}

export default List;
