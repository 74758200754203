import React from 'react';

import Followers from './Followers';

import { Container } from './styles';

export default function Evolutions() {
  return (
    <Container>
      <Followers />
    </Container>
  );
}
