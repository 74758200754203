import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 20px;

  .box-title {
    padding: 24px;
    margin-bottom: 0;

    &::before {
      left: 0 !important;
    }
  }

  .tabs-posts {
    display: flex;
    flex-direction: row;

    .tab-post {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0;
      cursor: pointer;
      border-bottom: 2px solid #dbdbdb;
      text-transform: uppercase;
      font-weight: bold;
      flex: 1;
      color: rgba(0, 0, 0, 0.6);

      &.active {
        border-bottom: 2px solid ${colorPalette.primary};
        color: ${colorPalette.primary};
      }

      span {
        color: ${colorPalette.primary};
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 7px;
        font-size: 16px;
      }
    }
  }

  .tab-post-content {
    .no-results {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      font-weight: bold;
      font-size: 16px;
    }

    .container-search {
      width: 500px;
      padding: 0 24px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .container-metrics {
        margin-left: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .metric-item {
          margin-right: 35px;
          border: 1px solid ${colorPalette.primary};
          width: 119px;
          height: 72px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            margin: 3px 0;
            letter-spacing: 0.5px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }

    .content-posts {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 1rem;
      padding: 0 24px;

      .post-item {
        display: flex;
        flex-direction: column;

        .thumb {
          width: 100px;
          height: 100px;
          background-color: #ccc;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 10px;
          cursor: pointer;
        }

        ul {
          li {
            margin-bottom: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;

            h3 {
              font-size: 10px;
              line-height: 12px;
            }

            &:last-child {
              margin: 0;
            }

            .icon-wrapper-posts {
              display: flex;
              color: #797979;
              align-items: center;
            }

            span {
              font-size: 12px;
              letter-spacing: 0.4px;
              color: rgba(0, 0, 0, 0.87);
              margin-left: 5px;
              display: flex;
              align-items: center;
              line-height: 12px;
            }
          }
        }
      }

      &.wide {
        grid-template-columns: repeat(5, 1fr);

        .post-item {
          .thumb {
            width: 200px;
            height: 100px;
          }

          ul {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            li {
              &:not(.title) {
                width: 50%;
              }
            }
          }
        }
      }
    }

    .container-see-more {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 40px 0;

      .button-see-more {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          margin-left: 5px;
          color: ${colorPalette.primary};
        }
      }
    }

    .avarages {
      .box-title {
        padding-left: 10px;

        &::before {
          display: none;
        }
      }
    }
  }
`;
