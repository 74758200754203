export const campaignsActions = {
  CAMPAIGNS_LIST_RESET: '@campaigns/CAMPAIGNS_LIST_RESET',
  CAMPAIGNS_LIST_REQUEST: '@campaigns/CAMPAIGNS_LIST_REQUEST',
  CAMPAIGNS_LIST_SUCCESS: '@campaigns/CAMPAIGNS_LIST_SUCCESS',
  CAMPAIGNS_LIST_FAILURE: '@campaigns/CAMPAIGNS_LIST_FAILURE',
  CAMPAIGNS_LIST_DELETE_REQUEST: '@campaigns/CAMPAIGNS_LIST_DELETE_REQUEST',
  CAMPAIGNS_LIST_DELETE_SUCCESS: '@campaigns/CAMPAIGNS_LIST_DELETE_SUCCESS',
  CAMPAIGNS_LIST_DELETE_FAILURE: '@campaigns/CAMPAIGNS_LIST_DELETE_FAILURE',
  CAMPAIGNS_LIST_UPDATE_REQUEST: '@campaigns/CAMPAIGNS_LIST_UPDATE_REQUEST',
  CAMPAIGNS_LIST_SET_FILTER_TITLE: '@campaigns/CAMPAIGNS_LIST_SET_FILTER_TITLE',
};

export function campaignsListReset() {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_RESET,
  };
}

export function campaignsListRequest(page = 1, filter = null) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_REQUEST,
    payload: { page, filter },
  };
}

export function campaignsListSuccess(data, page, lastPage) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_SUCCESS,
    payload: { data, page, lastPage },
  };
}

export function campaignsListFailure(error) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_FAILURE,
    payload: { error },
  };
}

export function campaignsListDeleteRequest(campaignId) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_DELETE_REQUEST,
    payload: { campaignId },
  };
}

export function campaignsListDeleteSuccess(campaignId) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_DELETE_SUCCESS,
    payload: { campaignId },
  };
}

export function campaignsListDeleteFailure() {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_DELETE_FAILURE,
  };
}

export function campaignsListSetFilterTitle(title) {
  return {
    type: campaignsActions.CAMPAIGNS_LIST_SET_FILTER_TITLE,
    payload: { title },
  };
}
