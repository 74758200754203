import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Bar } from 'react-chartjs-2';

import { Container } from './styles';

export default function AudienceProfileGraphics() {
  const { genders } = useSelector((state) => state.audienceProfile.data);

  const male = genders.filter((row) => row.gender === 'male');
  const female = genders.filter((row) => row.gender === 'female');

  const { total: maleTotal, ages: maleAges } = male[0];
  const { total: femaleTotal, ages: femaleAges } = female[0];

  if (maleAges.length === 0 && femaleAges.length === 0) return null;

  const dataChart = {
    labels: [
      '13 - 17',
      '18 - 24',
      '25 - 34',
      '35 - 44',
      '45 - 54',
      '55 - 64',
      '65+',
    ],
    datasets: [
      {
        label: 'Male (%)',
        backgroundColor: 'rgba(89,229,130,0.2)',
        borderColor: 'rgba(89,229,130,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(89,229,130,0.4)',
        hoverBorderColor: 'rgba(89,229,130,1)',
        data: [
          (maleAges[0] && maleAges[0]?.total) || 0,
          (maleAges[1] && maleAges[1]?.total) || 0,
          (maleAges[2] && maleAges[2]?.total) || 0,
          (maleAges[3] && maleAges[3]?.total) || 0,
          (maleAges[4] && maleAges[4]?.total) || 0,
          (maleAges[5] && maleAges[5]?.total) || 0,
          (maleAges[6] && maleAges[6]?.total) || 0,
        ],
      },
      {
        label: 'Female (%)',
        backgroundColor: 'rgba(118,36,232,0.2)',
        borderColor: 'rgba(118,36,232,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(118,36,232,0.4)',
        hoverBorderColor: 'rgba(118,36,232,1)',
        data: [
          (femaleAges[0] && femaleAges[0]?.total) || 0,
          (femaleAges[1] && femaleAges[1]?.total) || 0,
          (femaleAges[2] && femaleAges[2]?.total) || 0,
          (femaleAges[3] && femaleAges[3]?.total) || 0,
          (femaleAges[4] && femaleAges[4]?.total) || 0,
          (femaleAges[5] && femaleAges[5]?.total) || 0,
          (femaleAges[6] && femaleAges[6]?.total) || 0,
        ],
      },
    ],
  };

  return (
    <Container>
      <div className="graphics">
        <Bar
          data={dataChart}
          width={815}
          height={360}
          options={{
            maintainAspectRatio: false,
            responsive: true,
          }}
        />
      </div>
      <div className="total">
        <div className="single-chart">
          <CircularProgressbar
            value={Math.round(femaleTotal)}
            strokeWidth={12}
            styles={buildStyles({
              pathColor: 'rgba(118,36,232,0.7)',
              trailColor: 'rgba(89,229,130,0.7)',
            })}
          />
        </div>
        <div className="col-legends">
          <div className="row-legend">
            <div className="icon female" />
            <div className="gender-label">
              Female {`${Math.round(femaleTotal)}%`}
            </div>
          </div>
          <div className="row-legend">
            <div className="icon male" />
            <div className="gender-label">
              Male {`${Math.round(maleTotal)}%`}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
