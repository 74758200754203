import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
`;
