/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { monthNames, abbreviateNumber } from '~/helpers';

import { Container } from './styles';

function Results() {
  const [metricSelected, setMetricSelected] = useState('total_posts');

  const groups = useSelector((state) => state.sov.list.data);
  const selected = useSelector((state) => state.sov.list.selected);
  const groupsSelected = groups.filter((group) => selected.includes(group.id));

  if (groupsSelected.length < 2) {
    return <Container />;
  }

  function handleChangeMetric(e) {
    setMetricSelected(e.target.value);
  }

  const totals = [];

  const series = [];

  const months = [];

  for (let group = 0; group < groupsSelected.length; group += 1) {
    const groupRow = groupsSelected[group];
    const { tags } = groupRow;
    const serieItem = { name: groupRow.name, data: [] };

    let totalPosts = 0;
    let totalLikes = 0;
    let totalComments = 0;
    let totalEngagement = 0;

    for (let tag = 0; tag < tags.length; tag += 1) {
      const { periods } = tags[tag];

      for (let period = 0; period < periods.length; period += 1) {
        const periodRow = periods[period];
        if (serieItem.data.length <= period) {
          serieItem.data.push(periodRow[metricSelected]);
        } else {
          // Sum the metrics when a group has more than one tag in the period
          serieItem.data[period] += periodRow[metricSelected];
        }
        const periodName = `${monthNames[periodRow.month]}/${periodRow.year}`;
        if (!months.includes(periodName)) {
          months.unshift(periodName);
        }
        totalPosts += periodRow.total_posts;
        totalLikes += periodRow.total_likes;
        totalComments += periodRow.total_comments;
        totalEngagement += periodRow.total_engagement;
      }
    }

    totals.push({
      name: groupRow.name,
      totalPosts: `${abbreviateNumber(totalPosts, 1, true)}`,
      totalLikes: `${abbreviateNumber(totalLikes, 1, true)}`,
      totalComments: `${abbreviateNumber(totalComments, 1, true)}`,
      totalEngagement: `${abbreviateNumber(totalEngagement, 1, true)}`,
    });

    series.push(serieItem);
  }

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 6,
      },
    },
    legend: {
      fontFamily: 'Hind, sans-serif',
      fontWeight: 500,
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Hind, sans-serif',
          fontWeight: 500,
        },
        formatter: (seriesName) => abbreviateNumber(seriesName, 3),
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          fontFamily: 'Hind, sans-serif',
          fontWeight: 500,
        },
      },
    },
  };

  return (
    <Container>
      <div className="container-metrics">
        <FormControl variant="outlined">
          <Select
            id="demo-simple-select-outlined"
            value={metricSelected}
            onChange={handleChangeMetric}
          >
            <MenuItem value="total_posts">Posts</MenuItem>
            <MenuItem value="total_likes">Likes</MenuItem>
            <MenuItem value="total_comments">Comments</MenuItem>
            <MenuItem value="total_engagement">Engagement</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Chart options={options} series={series} type="line" height={400} />
      <table className="table-totals">
        <thead>
          <tr>
            <th className="empty" />
            <th>Posts</th>
            <th>Likes</th>
            <th>Comments</th>
            <th>Engagement</th>
          </tr>
        </thead>
        <tbody>
          {totals.map((group) => (
            <tr key={group.id}>
              <td className="group-name">{group.name}</td>
              <td>{group.totalPosts}</td>
              <td>{group.totalLikes}</td>
              <td>{group.totalComments}</td>
              <td>{group.totalEngagement}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

export default Results;
