import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';

import { Container } from './styles';
import colorPalette from '~/styles/colorPalette';

const MySlider = withStyles({
  root: {
    color: colorPalette.primary,
  },
})(Slider);

export default function AudiencePercentage({ handleChange, initialValue }) {
  const [value, setValue] = useState(initialValue);

  const marks = [
    {
      value: 20,
      label: '20%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 100,
      label: '100°%',
    },
  ];

  function handleSliderChange(event, newValue) {
    setValue(newValue);
    handleChange(newValue);
  }

  function handleInputChange(event) {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  }

  function handleBlur() {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  }

  return (
    <Container>
      <span className="input-label">Minimum percentage:</span>
      <input
        type="number"
        placeholder="Enter %"
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <div className="container-slider">
        <MySlider
          value={typeof value === 'number' ? value : 0}
          step={20}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
          aria-labelledby="input-slider"
          marks={marks}
          getAriaValueText={(valueText) => `${valueText}%`}
        />
      </div>
    </Container>
  );
}

AudiencePercentage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initialValue: PropTypes.number.isRequired,
};
