import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import { checkCodeRequest } from '~/store/modules/invite/checkCode/actions';
import { savePasswordRequest } from '~/store/modules/invite/savePassword/actions';

import InputPassword from '~/components/InputPassword';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import ColLeftImage from '~/assets/images/Invite/col-left-image.png';

import { Container, ColLeft, ColRight, ButtonSubmit } from './styles';

const formSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, '')
    .trim('the password cannot include trailing spaces')
    .required(''),
  confirmPassword: Yup.string()
    .min(6, '')
    .oneOf([Yup.ref('password'), null], 'password does not match')
    .required(''),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Invite() {
  const query = useQuery();
  const code = query.get('code');

  const dispatch = useDispatch();

  const { loading: loadingCheckCode, isValid } = useSelector(
    (state) => state.checkCode
  );

  const { loading: loadingSave, error: errorSave } = useSelector(
    (state) => state.savePassword
  );

  useEffect(() => {
    dispatch(checkCodeRequest(code));
  }, [dispatch, code]);

  const renderButtonSubmit = (password) => {
    if (errorSave) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(savePasswordRequest(code, password))}
        />
      );
    }

    return (
      <ButtonSubmit onClick={() => false}>
        {loadingSave ? 'LOADING...' : 'LOGIN'}
      </ButtonSubmit>
    );
  };

  const renderFormContent = () => {
    if (isValid === 'not-checked') {
      return (
        <h3>
          There was an error validating your invitation, please reload the page
        </h3>
      );
    }

    if (isValid === 'invalid') {
      return (
        <>
          <h3>Link expired</h3>
          <p>
            Sorry, the link you are trying to access has expired. It is valid
            for <span>24 hours.</span> Please ask the person who registered you
            to do the process again.
          </p>
        </>
      );
    }

    if (isValid === 'valid') {
      return (
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={formSchema}
          onSubmit={(values) => {
            dispatch(savePasswordRequest(code, values.password));
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <h3>Create a password</h3>
              <InputPassword
                placeholder="Password"
                name="password"
                value={values.password}
                minimumCheckCharacters
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.password}
              />
              <InputPassword
                placeholder="Confirm password"
                name="confirmPassword"
                value={values.confirmPassword}
                minimumCheckCharacters
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.confirmPassword}
                classError={errors.confirmPassword}
              />
              {renderButtonSubmit(values.password)}
            </form>
          )}
        </Formik>
      );
    }

    return null;
  };

  const render = () => {
    if (loadingCheckCode) {
      return <Loader />;
    }

    return (
      <>
        <ColLeft>
          <div className="content">
            <h1>Bell</h1>
            <img src={ColLeftImage} alt="Social" />
          </div>
        </ColLeft>
        <ColRight>
          <div className="container-content">{renderFormContent()}</div>
        </ColRight>
      </>
    );
  };

  return <Container>{render()}</Container>;
}
