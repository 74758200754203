import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';

import { Container } from './styles';

export default function Checkbox({
  name,
  checked,
  borderColor,
  onChange,
  ...rest
}) {
  const [checkedInternal, setCheckedInternal] = useState(checked);
  const checkboxRef = useRef();

  function handleChecked() {
    setCheckedInternal(!checkedInternal);
    checkboxRef.current.click();
  }

  useEffect(() => {
    setCheckedInternal(checked);
  }, [checked]);

  return (
    <Container
      className={`${checkedInternal ? 'checked' : ''}`}
      onClick={handleChecked}
      borderColor={borderColor}
    >
      <input
        name={name}
        ref={checkboxRef}
        type="checkbox"
        checked={checkedInternal}
        onChange={onChange}
        {...rest}
      />
      {checkedInternal && (
        <div className="icon-wrapper">
          <FaCheck />
        </div>
      )}
    </Container>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  borderColor: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  borderColor: 'rgba(0, 0, 0, 0.38)',
  onChange: () => false,
};
