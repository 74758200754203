import React from 'react';

import Intro from '../Intro';
import EstimatedStatistics from './EstimatedStatistics';
import Posts from './Posts';

import { Container, Header } from './styles';

function Youtube() {
  return (
    <Container>
      <Header>
        <Intro />
        <EstimatedStatistics />
      </Header>
      <Posts />
    </Container>
  );
}

export default Youtube;
