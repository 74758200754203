import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { audienceRequest } from '~/store/modules/creator/audienceProfile/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import Graphics from './Graphics';
import Divisions from './Divisions';
import Regions from './Regions';

import { Container, Row } from './styles';

export default function AudienceProfile() {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector(
    (state) => state.audienceProfile
  );

  useEffect(() => {
    dispatch(audienceRequest());
  }, [dispatch]);

  function render() {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(audienceRequest())}
        />
      );
    }

    if (
      !data?.countries ||
      !data?.cities ||
      !data?.genders ||
      data?.countries?.length === 0 ||
      data?.cities?.length === 0 ||
      data?.genders?.length === 0
    ) {
      return (
        <div className="no-data">This creator does not have audience data</div>
      );
    }

    if (data) {
      return (
        <>
          <Graphics />
          <Row>
            <Divisions />
            <Regions />
          </Row>
        </>
      );
    }

    return null;
  }

  return (
    <Container>
      <div className="box-title">Audience profile</div>
      {render()}
    </Container>
  );
}
