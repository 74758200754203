import styled from 'styled-components';

export const Container = styled.div`
  width: 480px;
  height: 270px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  margin-left: 35px;

  .items-statistics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem 0;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 5px;

      &.border {
        border-right: 1px solid #f1f1f1;
      }

      &:last-child {
        margin-right: 0;
      }

      .icon-wrapper {
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }

      span {
        font-size: 12px;
        line-height: 1.31;
        letter-spacing: 0.4px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`;
