import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .input-label {
    line-height: 1.37;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  input {
    width: 100px;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #dbdbdb;
    background: none;
    line-height: 1.37;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 25px;

    &::placeholder {
      line-height: 1.37;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .container-slider {
    width: 90%;
  }
`;
