import produce from 'immer';
import types from '../../types';
import { campaignsDetailActions } from '../../campaigns/detail/actions';

export const INITIAL_STATE = {
  profile: {
    data: null,
    loading: false,
    error: false,
  },
  tabs: {
    networkIndexSelected: 0,
    networkTypeSelected: null,
  },
};

export default function creator(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATOR_SELECT_TAB: {
        draft.tabs.networkIndexSelected = action.payload.networkIndex;
        draft.tabs.networkTypeSelected = action.payload.networkType;
        break;
      }
      case types.CREATOR_REQUEST: {
        draft.profile.loading = true;
        draft.profile.error = false;
        break;
      }
      case types.CREATOR_SUCCESS: {
        const { data } = action.payload;

        draft.profile.loading = false;
        draft.profile.data = data;
        draft.tabs.networkTypeSelected = data.creator_networks[0].network;
        break;
      }
      case types.CREATOR_FAILURE: {
        draft.profile.loading = false;
        draft.profile.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST: {
        if (draft.profile.data) {
          draft.profile.data.creator_networks[
            state.tabs.networkIndexSelected
          ].is_in_active_campaign = true;
        }

        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE: {
        if (draft.profile.data) {
          draft.profile.data.creator_networks[
            state.tabs.networkIndexSelected
          ].is_in_active_campaign = false;
        }

        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST: {
        if (draft.profile.data?.creator_networks) {
          draft.profile.data.creator_networks[
            state.tabs.networkIndexSelected
          ].is_in_active_campaign = false;
        }
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE: {
        draft.profile.data.creator_networks[
          state.tabs.networkIndexSelected
        ].is_in_active_campaign = true;

        break;
      }
      default:
    }
  });
}
