import produce from 'immer';
import { audienceActions } from './actions';

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
};

export default function audienceProfile(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case audienceActions.AUDIENCE_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case audienceActions.AUDIENCE_SUCCESS: {
        const { data } = action.payload;

        draft.data = data;
        draft.loading = false;
        break;
      }
      case audienceActions.AUDIENCE_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}
