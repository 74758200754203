import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .back-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      span {
        color: ${colorPalette.primary};
        font-weight: bold;
        margin-left: 5px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 157px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #bfbfbf;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 4px;

        &.with-border {
          border-right: 1px solid #bfbfbf;
        }
      }
    }
  }

  .slide-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .media-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 460px;
      margin: 0 20px;

      img {
        max-width: 100%;
        height: 460px;
      }
    }
  }
`;
