import { takeLatest, put, all, select } from 'redux-saga/effects';

import { apiBeCampaign } from '~/services/api';
import { serviceWrapperSaga } from '~/helpers';

import {
  campaignsNewActions,
  campaignsNewSuccess,
  campaignsNewFailure,
} from './actions';

export function* createCampaign({ payload }) {
  const profile = yield select((state) => state.profile.data);

  const { data } = payload;
  const response = yield serviceWrapperSaga(apiBeCampaign.post, 'campaigns', {
    ...data,
  });

  if (response.status >= 400) {
    yield put(campaignsNewFailure(response.data.message));
    return;
  }

  const campaign = {
    id: response.headers.etag,
    created_by: { name: profile.name },
    created: new Date(),
    updated: new Date(),
    ...data,
  };

  yield put(campaignsNewSuccess(campaign));
}

export default all([
  takeLatest(campaignsNewActions.CAMPAIGNS_NEW_REQUEST, createCampaign),
]);
