import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import types from '../../types';

import { apiOverlap } from '~/services/api';

import {
  overlapListSuccess,
  overlapListFailure,
  overlapListDeleteSuccess,
  overlapListDeleteFailure,
} from './actions';

export function* getOverlap({ payload }) {
  const { isPaginate } = payload;
  try {
    const { page, filter } = yield select((state) => state.overlapList.list);
    const { title } = filter;

    const limit = 20;

    const params = { page: isPaginate ? page + 1 : page, limit, title };

    const response = yield call(apiOverlap.get, 'overlaps', { params });

    const { data } = response;

    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);

    yield put(overlapListSuccess(data, lastPage, isPaginate));
  } catch (error) {
    yield put(overlapListFailure(isPaginate));
  }
}

export function* deleteOverlap({ payload }) {
  try {
    const { overlapId } = payload;
    yield call(apiOverlap.delete, `overlaps/${overlapId}`);
    yield put(overlapListDeleteSuccess(overlapId));
  } catch (err) {
    yield put(overlapListDeleteFailure());
  }
}

export default all([
  takeLatest(types.OVERLAP_LIST_REQUEST, getOverlap),
  takeLatest(types.OVERLAP_LIST_DELETE_REQUEST, deleteOverlap),
]);
