import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';

import { audienceRequest } from '~/store/modules/creator/audienceProfile/actions';

import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { abbreviateNumber } from '~/helpers';

import { Container } from './styles';

export default function Followers() {
  const dispatch = useDispatch();

  const {
    data: dataAudience,
    loading,
    error,
  } = useSelector((state) => state.audienceProfile);

  function renderFollowersChart() {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <LoadingDataErrorHandling
          refreshData={() => dispatch(audienceRequest())}
        />
      );
    }

    if (
      !dataAudience ||
      !dataAudience.followers_evolution ||
      dataAudience.followers_evolution.length === 0
    ) {
      return (
        <div className="no-data">
          This creator does not have data for the evolution of followers
        </div>
      );
    }

    const followersChart = dataAudience.followers_evolution;

    const labels = [];

    const data = [];

    const months = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];

    followersChart.forEach((item) => {
      const date = new Date(item.date);
      const month = date.getMonth();
      labels.unshift(`${months[month]} ${date.getFullYear()}`);
      data.unshift(item.total_followers);
    });

    const colorRgb = '118,36,232';

    const dataChart = {
      labels,
      datasets: [
        {
          label: 'Evolution of followers',
          fill: false,
          lineTension: 0.1,
          backgroundColor: `rgba(${colorRgb},0.4)`,
          borderColor: `rgba(${colorRgb},1)`,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: `rgba(${colorRgb},1)`,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: `rgba(${colorRgb},1)`,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data,
        },
      ],
    };

    const options = {
      responsive: true,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            ticks: {
              callback(value) {
                return `${abbreviateNumber(value, 1)}`;
              },
            },
          },
        ],
      },
    };

    return <Line data={dataChart} options={options} height={70} />;
  }

  return (
    <Container>
      <div className="box-title">Evolution of followers</div>
      {renderFollowersChart()}
    </Container>
  );
}
