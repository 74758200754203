import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

function Modal({ width, height, children, isOpen, handleCloseModal }) {
  const styles = {
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 20,
    },
    content: {
      display: 'flex',
      position: 'absolute',
      maxWidth: `${width}px`,
      maxHeight: `${height}px`,
      borderRadius: '4px',
      margin: 'auto',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: '#ffffff',
      boxShadow:
        '0 5px 5px - 3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={styles}
      className="Modal"
      overlayClassName="Overlay"
    >
      {children}
    </ReactModal>
  );
}

export default Modal;

Modal.defaultProps = {
  width: 400,
  height: 220,
};

Modal.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
