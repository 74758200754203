import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiBeCreator } from '~/services/api';

import { audienceActions, audienceSuccess, audienceFailure } from './actions';

export function* getAudienceProfile() {
  try {
    const { networkIndexSelected } = yield select(
      (state) => state.creator.tabs
    );
    const { id: creatorId, creator_networks } = yield select(
      (state) => state.creator.profile.data
    );

    const { id: creatorNetworkId } = creator_networks[networkIndexSelected];

    const response = yield call(
      apiBeCreator.get,
      `creators/${creatorId}/creator_networks/${creatorNetworkId}/audience`
    );

    const { data } = response;

    yield put(audienceSuccess(data));
  } catch (err) {
    yield put(audienceFailure());
  }
}

export default all([
  takeLatest(audienceActions.AUDIENCE_REQUEST, getAudienceProfile),
]);
