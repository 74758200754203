import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiBeCreator } from '~/services/api';

import { creatorPostsTypes, postsSuccess, postsFailure } from './actions';

export function* getPosts({ payload }) {
  const { filter, typePost, isPaginate } = payload;
  try {
    const { networkIndexSelected: networkIndex } = yield select(
      (state) => state.creator.tabs
    );
    const { id: creatorId, creator_networks } = yield select(
      (state) => state.creator.profile.data
    );
    const { id: creatorNetworkId } = creator_networks[networkIndex];

    const { current_page } = yield select(
      (state) => state.creatorPosts[typePost]
    );

    const page = isPaginate ? current_page + 1 : current_page;
    const limit = 8;

    const params = {
      term: filter,
      page,
      limit,
    };

    if (typePost === 'allPosts') {
      params.is_common = 1;
      params.is_advertising = 0;
    }

    if (typePost === 'adPosts') {
      params.is_advertising = 1;
    }

    if (typePost === 'abnormalPosts') {
      params.is_abnormal = 1;
    }

    const response = yield call(
      apiBeCreator.get,
      `creators/${creatorId}/creator_networks/${creatorNetworkId}/posts`,
      {
        params,
      }
    );

    const { data } = response;

    const lastPage = Math.round(response.headers['x-total-count'] / limit);

    yield put(postsSuccess(data, lastPage, typePost, isPaginate));
  } catch (err) {
    yield put(postsFailure(typePost, isPaginate));
  }
}

export default all([takeLatest(creatorPostsTypes.POSTS_REQUEST, getPosts)]);
