export const trackingLinkActions = {
  SET_ORDER_BY: '@campaigns/trackingLink/SET_ORDER_BY',
  SET_START_DATE: '@campaigns/trackingLink/SET_START_DATE',
  SET_END_DATE: '@campaigns/trackingLink/SET_END_DATE',
  REGISTER_REQUEST: '@campaigns/trackingLink/REGISTER_REQUEST',
  REGISTER_SUCCESS: '@campaigns/trackingLink/REGISTER_SUCCESS',
  REGISTER_FAILURE: '@campaigns/trackingLink/REGISTER_FAILURE',
  REGISTER_RESET: '@campaigns/trackingLink/REGISTER_RESET',
  LIST_REQUEST: '@campaigns/trackingLink/LIST_REQUEST',
  LIST_REQUEST_PAGINATE: '@campaigns/trackingLink/LIST_REQUEST_PAGINATE',
  LIST_SUCCESS: '@campaigns/trackingLink/LIST_SUCCESS',
  LIST_FAILURE: '@campaigns/trackingLink/LIST_FAILURE',
  LIST_RESET: '@campaigns/trackingLink/LIST_RESET',
  TOTAL_CLICKS_REQUEST: '@campaigns/trackingLink/TOTAL_CLICKS_REQUEST',
  TOTAL_CLICKS_SUCCESS: '@campaigns/trackingLink/TOTAL_CLICKS_SUCCESS',
  TOTAL_CLICKS_FAILURE: '@campaigns/trackingLink/TOTAL_CLICKS_FAILURE',
  TOGGLE_STORIES_STATUS_REQUEST:
    '@campaigns/trackingLink/TOGGLE_STORIES_STATUS_REQUEST',
  TOGGLE_STORIES_STATUS_SUCCESS:
    '@campaigns/trackingLink/TOGGLE_STORIES_STATUS_SUCCESS',
  TOGGLE_STORIES_STATUS_FAILURE:
    '@campaigns/trackingLink/TOGGLE_STORIES_STATUS_FAILURE',
  TOGGLE_STORIES_STATUS_RESET:
    '@campaigns/trackingLink/TOGGLE_STORIES_STATUS_RESET',
};

export function setOrderBy(order = 'DESC') {
  return {
    type: trackingLinkActions.SET_ORDER_BY,
    payload: { order },
  };
}

export function setStartDate(startDate) {
  return {
    type: trackingLinkActions.SET_START_DATE,
    payload: { startDate },
  };
}

export function setEndDate(endDate) {
  return {
    type: trackingLinkActions.SET_END_DATE,
    payload: { endDate },
  };
}

export function registerRequest(payload) {
  return {
    type: trackingLinkActions.REGISTER_REQUEST,
    payload,
  };
}

export function registerSuccess(monitorId, startTime, endTime) {
  return {
    type: trackingLinkActions.REGISTER_SUCCESS,
    payload: { monitorId, startTime, endTime },
  };
}

export function registerFailure(error) {
  return {
    type: trackingLinkActions.REGISTER_FAILURE,
    payload: { error },
  };
}

export function registerReset() {
  return {
    type: trackingLinkActions.REGISTER_RESET,
  };
}

export function listRequest(campaignId, isPaginate = true, page = 1) {
  return {
    type: trackingLinkActions.LIST_REQUEST,
    payload: { campaignId, isPaginate, page },
  };
}

export function listRequestPaginate(campaignId, page) {
  return {
    type: trackingLinkActions.LIST_REQUEST_PAGINATE,
    payload: { campaignId, page },
  };
}

export function listSuccess(data, lastPage, isPaginate = false, total) {
  return {
    type: trackingLinkActions.LIST_SUCCESS,
    payload: { data, lastPage, isPaginate, total },
  };
}

export function listFailure(isPaginate = false) {
  return {
    type: trackingLinkActions.LIST_FAILURE,
    payload: { isPaginate },
  };
}

export function totalClicksRequest(campaignId) {
  return {
    type: trackingLinkActions.TOTAL_CLICKS_REQUEST,
    payload: { campaignId },
  };
}

export function totalClicksSuccess(data) {
  return {
    type: trackingLinkActions.TOTAL_CLICKS_SUCCESS,
    payload: data,
  };
}

export function totalClicksError(data) {
  return {
    type: trackingLinkActions.TOTAL_CLICKS_REQUEST,
    payload: data,
  };
}

export function listReset() {
  return {
    type: trackingLinkActions.LIST_RESET,
  };
}

export function toggleStoriesStatusRequest(storyId, show) {
  return {
    type: trackingLinkActions.TOGGLE_STORIES_STATUS_REQUEST,
    payload: { storyId, show },
  };
}

export function toggleStoriesStatusSuccess(storyId, show) {
  return {
    type: trackingLinkActions.TOGGLE_STORIES_STATUS_SUCCESS,
    payload: { storyId, show },
  };
}

export function toggleStoriesStatusFailure() {
  return {
    type: trackingLinkActions.TOGGLE_STORIES_STATUS_FAILURE,
  };
}

export function toggleStoriesStatusReset() {
  return {
    type: trackingLinkActions.TOGGLE_STORIES_STATUS_RESET,
  };
}
