import types from '../types';

// SEARCH
export function tagGroupsListSearch(groupName) {
  return {
    type: types.TAG_GROUPS_LIST_SEARCH,
    payload: { groupName },
  };
}

// LIST
export function tagGroupsListRequest() {
  return {
    type: types.TAG_GROUPS_LIST_REQUEST,
  };
}

export function tagGroupsListSuccess(data) {
  return {
    type: types.TAG_GROUPS_LIST_SUCCESS,
    payload: { data },
  };
}

export function tagGroupsListFailure() {
  return {
    type: types.TAG_GROUPS_LIST_FAILURE,
  };
}

// SELECT ITEM
export function tagGroupsListSelectItem(groupId) {
  return {
    type: types.TAG_GROUPS_LIST_SELECT_ITEM,
    payload: { groupId },
  };
}

// CHECK ITEM
export function tagGroupsListCheckItem(groupId) {
  return {
    type: types.TAG_GROUPS_LIST_CHECK_ITEM,
    payload: { groupId },
  };
}

// REPROCESS
export function tagGroupsListReprocessRequest(groupsIds) {
  return {
    type: types.TAG_GROUPS_LIST_REPROCESS_REQUEST,
    payload: { groupsIds },
  };
}

export function tagGroupsListReprocessSuccess(groupsIds) {
  return {
    type: types.TAG_GROUPS_LIST_REPROCESS_SUCCESS,
    payload: { groupsIds },
  };
}

export function tagGroupsListReprocessFailure() {
  return {
    type: types.TAG_GROUPS_LIST_REPROCESS_FAILURE,
  };
}

// CREATE
export function tagGroupsCreateRequest(name, created = new Date()) {
  return {
    type: types.TAG_GROUPS_CREATE_REQUEST,
    payload: { name, created },
  };
}

export function tagGroupsCreateSuccess(data) {
  return {
    type: types.TAG_GROUPS_CREATE_SUCCESS,
    payload: { data },
  };
}

export function tagGroupsCreateFailure() {
  return {
    type: types.TAG_GROUPS_CREATE_FAILURE,
  };
}

// UPDATE
export function tagGroupsUpdateRequest(data, isTag = false) {
  return {
    type: types.TAG_GROUPS_UPDATE_REQUEST,
    payload: { data, isTag },
  };
}

export function tagGroupsUpdateSuccess(data, isTag) {
  return {
    type: types.TAG_GROUPS_UPDATE_SUCCESS,
    payload: { data, isTag },
  };
}

export function tagGroupsUpdateFailure() {
  return {
    type: types.TAG_GROUPS_UPDATE_FAILURE,
  };
}

// DELETE
export function tagGroupsDeleteRequest(groupsIds) {
  return {
    type: types.TAG_GROUPS_DELETE_REQUEST,
    payload: { groupsIds },
  };
}

export function tagGroupsDeleteSuccess(groupsIds) {
  return {
    type: types.TAG_GROUPS_DELETE_SUCCESS,
    payload: { groupsIds },
  };
}

export function tagGroupsDeleteFailure() {
  return {
    type: types.TAG_GROUPS_DELETE_FAILURE,
  };
}
