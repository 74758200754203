export const trackingPostActions = {
  TRACKING_POST_ADD_REQUEST:
    '@campaigns/detail/tracking/TRACKING_POST_ADD_REQUEST',
  TRACKING_POST_ADD_SUCCESS:
    '@campaigns/detail/tracking/TRACKING_POST_ADD_SUCCESS',
  TRACKING_POST_ADD_FAILURE:
    '@campaigns/detail/tracking/TRACKING_POST_ADD_FAILURE',
  TRACKING_POST_LIST_REQUEST:
    '@campaigns/detail/tracking/TRACKING_POST_LIST_REQUEST',
  TRACKING_POST_LIST_SUCCESS:
    '@campaigns/detail/tracking/TRACKING_POST_LIST_SUCCESS',
  TRACKING_POST_LIST_FAILURE:
    '@campaigns/detail/tracking/TRACKING_POST_LIST_FAILURE',
};

export function trackingPostAddRequest(networkIdentifier, campaignId = null) {
  return {
    type: trackingPostActions.TRACKING_POST_ADD_REQUEST,
    payload: { networkIdentifier, campaignId },
  };
}

export function trackingPostAddSuccess(data) {
  return {
    type: trackingPostActions.TRACKING_POST_ADD_SUCCESS,
    payload: { data },
  };
}

export function trackingPostAddFailure() {
  return {
    type: trackingPostActions.TRACKING_POST_ADD_FAILURE,
  };
}

export function trackingPostListRequest(
  campaignId = null,
  page,
  networkIdentifier = null
) {
  return {
    type: trackingPostActions.TRACKING_POST_LIST_REQUEST,
    payload: { networkIdentifier, campaignId, page },
  };
}

export function trackingPostListSuccess(data, lastPage, total) {
  return {
    type: trackingPostActions.TRACKING_POST_LIST_SUCCESS,
    payload: { data, lastPage, total },
  };
}

export function trackingPostListFailure() {
  return {
    type: trackingPostActions.TRACKING_POST_LIST_FAILURE,
  };
}
