import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  width: 690px;
  height: 270px;

  .head {
    display: flex;
    flex-direction: row;

    .user-picture {
      width: 64px;
      height: 64px;
      border-radius: 3px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #ccc;
    }

    .col-infos {
      margin-left: 10px;

      .user-name {
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
        font-family: 'Rubik';
      }
      .user-identifier {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 7px;
      }
    }
  }

  .description-label {
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Rubik';
    margin-top: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 110px;
  }
  .extra-infos {
    margin-top: 40px;

    p {
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
      margin: 15px 0;
      font-family: 'Rubik';
    }
  }
`;
