import produce from 'immer';
import types from '../types';

const INITIAL_STATE = {
  active: false,
  menuSelectedSlug: '',
};

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.MENU_OPEN: {
        draft.active = true;
        break;
      }
      case types.MENU_CLOSE: {
        draft.active = false;
        break;
      }
      case types.MENU_SELECT: {
        draft.menuSelectedSlug = action.payload.slug;
        break;
      }
      case types.SIGN_OUT_REQUEST:
      case types.INFLUENCER_REQUEST:
      case types.OVERLAP_LIST_REQUEST:
      case types.SOV_LIST_REQUEST:
      case types.TAG_GROUPS_LIST_REQUEST: {
        draft.active = false;
        break;
      }
      default:
    }
  });
}
