import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const TableContainer = styled.table`
  font-family: Rubik;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  tr {
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  th {
    padding: 16px 24px;
    text-align: center;

    &:hover {
      color: ${colorPalette.primary};
      cursor: pointer;
    }
  }
`;

export const Tbody = styled.tbody`
  td {
    padding: 24px 16px;

    &.text-center {
      text-align: center;
    }
  }

  tr {
    :hover {
      background-color: rgba(90, 19, 217, 0.07);
      cursor: pointer;
    }

    :nth-child(odd) {
      background-color: #f5f5f5;

      :hover {
        background-color: rgba(90, 19, 217, 0.07);
        cursor: pointer;
      }
    }
  }
`;
