import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { ThumbUpAltOutlined, HighlightOffOutlined } from '@material-ui/icons/';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { setSelectedCreatorStore } from '~/store/modules/campaigns/detail/actions';
import { registerRequest } from '~/store/modules/campaigns/detail/storiesMonitor/actions';

import Button from '~/components/Button';
import Loader from '~/components/Loader';

import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 135,
  },
}));

const today = format(new Date(), 'yyyy-MM-dd');

function Configure() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const register = useSelector((state) => state.storiesMonitor.register);
  const list = useSelector((state) => state.storiesMonitor.list);

  const selectedCreatorId = useSelector(
    (state) => state.campaignsDetail.selectedCreatorId
  );
  const campaign = useSelector((state) => state.campaignsDetail.campaign);

  const creatorList = useSelector(
    (state) => state.campaignsDetail.creators_list.data
  );

  const creator = creatorList.find(
    (item) => item.creator_id === selectedCreatorId
  );

  const [dateStart, setDateStart] = useState(
    list.data ? list.data.start_time.replace('00:00:00', '').trim() : today
  );
  const [dateFinish, setDateFinish] = useState(
    list.data ? list.data.end_time.replace('00:00:00', '').trim() : today
  );

  const isValidDates = () => {
    if (dateStart.length < 10 || dateFinish.length < 10) {
      return false;
    }

    return true;
  };

  const handleRegister = () => {
    if (!isValidDates()) return;

    dispatch(
      registerRequest({
        creator_id: creator.creator_id,
        creator_network_id: creator.creator_network.id,
        campaign_id: campaign.data.id,
        start_time: `${dateStart} 00:00:00`,
        end_time: `${dateFinish} 00:00:00`,
      })
    );
  };

  const handleChangeSelectedCreator = (event) => {
    const creatorId = event.target.value;
    dispatch(setSelectedCreatorStore(creatorId));
  };

  const renderContent = () => {
    if (register.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="container-users">
          <FormControl variant="outlined">
            <Select
              value={selectedCreatorId}
              onChange={handleChangeSelectedCreator}
            >
              {creatorList.map((row) => (
                <MenuItem key={row.creator_id} value={row.creator_id}>
                  {row.creator_network.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <h2 className="title-section">Configure Stories Monitor</h2>
        <p>Enter the period of time we will monitor stories</p>
        <div className="fields">
          <TextField
            id="date"
            label="From"
            type="date"
            defaultValue={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="field-wrapper">
            <TextField
              id="date"
              label="To"
              type="date"
              defaultValue={dateFinish}
              onChange={(e) => setDateFinish(e.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="buttons-container">
          <Button
            className={`btn-start ${!isValidDates() && 'inative'}`}
            onClick={handleRegister}
          >
            SAVE MONITOR
          </Button>
        </div>
        {register.success && (
          <div className="container-status">
            <div className="status-content">
              <ThumbUpAltOutlined style={{ color: '#59e582' }} size={15} />
              <span>The stories monitor was saved</span>
            </div>
          </div>
        )}
        {register.error.length > 0 && (
          <div className="container-status">
            <div className="status-content">
              <HighlightOffOutlined style={{ color: '#b00020' }} size={15} />
              <span>{register.error}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  return <Container>{renderContent()}</Container>;
}

export default Configure;
