import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import Loader from '~/components/Loader';

import { Container } from './styles';

export default function ModalUpdate({
  handleHideModal,
  handleConfirmUpdate,
  loading,
}) {
  const listData = useSelector((state) => state.tagGroups.list.data);
  const groupsChecked = listData.filter((group) => group?.checked);

  const [groupName, setGroupName] = useState(groupsChecked[0].name);

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  function handleSubmitUpdate() {
    const { created, organization, id, tags, updated_at } = groupsChecked[0];

    handleConfirmUpdate({
      name: groupName,
      created,
      organization,
      id,
      tags,
      updated_at,
      checked: true,
    });
  }

  function handleInputChange(event) {
    setGroupName(event.target.value);
  }

  function handleInputKeyUp(e) {
    if (e.keyCode === 13 && groupName.trim().length > 0) handleSubmitUpdate();
  }

  function render() {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="head">
          <div className="icon-wrapper close-modal">
            <MdClose size="30" onClick={() => handleHideModal()} />
          </div>
        </div>
        <div className="modal-description">
          <h3>Name this group</h3>
          <input
            ref={inputRef}
            type="text"
            value={groupName}
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
          />
        </div>
        <div className="modal-options">
          <span onClick={() => handleHideModal()}>CANCEL</span>
          <span onClick={() => handleSubmitUpdate()}>OK</span>
        </div>
      </>
    );
  }
  return <Container>{render()}</Container>;
}

ModalUpdate.propTypes = {
  handleHideModal: PropTypes.func.isRequired,
  handleConfirmUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
