import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/Button';

import { Container } from './styles';

export default function LoadingDataErrorHandling({ refreshData }) {
  return (
    <Container>
      <p>An error has occurred, please try again</p>
      <Button onClick={refreshData}>Reload</Button>
    </Container>
  );
}

LoadingDataErrorHandling.propTypes = {
  refreshData: PropTypes.func.isRequired,
};
