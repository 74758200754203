import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #e8e8e8;

  .container-users {
    width: 100%;
    margin-bottom: 10px;

    .MuiSelect-select {
      width: 280px;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: ${colorPalette.text};
    }
  }

  .fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;

    .field-wrapper {
      margin-left: 32px;
    }

    input[type='date'] {
      font-size: 14px;
      font-family: 'Hind';
      color: ${colorPalette.text};
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .container-status {
    display: flex;
    flex: 1;
    align-items: flex-end;

    .status-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 282px;
      height: 48px;
      padding: 14px 16px 14px 13px;
      border-radius: 2px;
      background-color: #e6e6e6;

      span {
        margin-left: 10px;
        color: ${colorPalette.text};
      }
    }
  }
`;
