import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiBeCreator } from '~/services/api';

import {
  statisticsTypes,
  statisticsSuccess,
  statisticsFailure,
} from './actions';

export function* getStatistics() {
  try {
    const { networkIndexSelected } = yield select(
      (state) => state.creator.tabs
    );
    const { id: creatorId, creator_networks } = yield select(
      (state) => state.creator.profile.data
    );

    const { id: creatorNetworkId } = creator_networks[networkIndexSelected];

    const response = yield call(
      apiBeCreator.get,
      `creators/${creatorId}/creator_networks/${creatorNetworkId}/statistics`
    );

    const { data } = response;

    yield put(statisticsSuccess(data));
  } catch (err) {
    yield put(statisticsFailure());
  }
}

export default all([
  takeLatest(statisticsTypes.STATISTICS_REQUEST, getStatistics),
]);
