/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '~/components/Checkbox';
import { CheckboxContainer, Container, ContentContainer } from './styles';

import TrackingClicks from './TrackingClicks';
import TrackingPosts from './TrackingPosts';

function Tracking({ campaingId }) {
  const [postFilter, setPostFilter] = useState(true);
  const [clickFilter, setClicksFilter] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const [hasTrackingClicks, setHasTrackingClicks] = useState(false);

  useEffect(() => {
    if (!postFilter && !clickFilter) {
      setPostFilter(true);
    }
  }, [postFilter]);

  useEffect(() => {
    if (!postFilter && !clickFilter) {
      setClicksFilter(true);
    }
  }, [clickFilter]);

  useEffect(() => {
    setShowFilters(hasTrackingClicks);
  }, [hasTrackingClicks]);

  const hasLoadClicks = (qtdClicks) => {
    setHasTrackingClicks(qtdClicks > 0);
  };

  const handleSelectClicksFilter = (event) => {
    const { checked } = event.target;
    setClicksFilter(checked);
  };

  const handleSelectPostFilter = (event) => {
    const { checked } = event.target;
    setPostFilter(checked);
  };

  const renderClicksContent = () => {
    return (
      <TrackingClicks campaingId={campaingId} onLoadingClicks={hasLoadClicks} />
    );
  };

  const renderPostContent = () => {
    return <TrackingPosts campaingId={campaingId} />;
  };

  return (
    <Container>
      {showFilters && (
        <CheckboxContainer>
          {hasTrackingClicks && (
            <div>
              <Checkbox
                name="clicks"
                checked={clickFilter}
                onChange={(event) => handleSelectClicksFilter(event)}
              />
              <p>Click Tracking</p>
            </div>
          )}
          <div>
            <Checkbox
              name="post"
              checked={postFilter}
              onChange={(event) => handleSelectPostFilter(event)}
            />
            <p>Post</p>
          </div>
        </CheckboxContainer>
      )}
      <ContentContainer>
        {clickFilter && renderClicksContent()}
        {postFilter && renderPostContent()}
      </ContentContainer>
    </Container>
  );
}

Tracking.propTypes = {
  campaingId: PropTypes.string.isRequired,
};

export default Tracking;
