export const storiesMonitorActions = {
  SET_ORDER_BY: '@campaigns/storiesMonitor/SET_ORDER_BY',
  SET_START_DATE: '@campaigns/storiesMonitor/SET_START_DATE',
  SET_END_DATE: '@campaigns/storiesMonitor/SET_END_DATE',
  REGISTER_REQUEST: '@campaigns/storiesMonitor/REGISTER_REQUEST',
  REGISTER_SUCCESS: '@campaigns/storiesMonitor/REGISTER_SUCCESS',
  REGISTER_FAILURE: '@campaigns/storiesMonitor/REGISTER_FAILURE',
  REGISTER_RESET: '@campaigns/storiesMonitor/REGISTER_RESET',
  LIST_REQUEST: '@campaigns/storiesMonitor/LIST_REQUEST',
  LIST_SUCCESS: '@campaigns/storiesMonitor/LIST_SUCCESS',
  LIST_FAILURE: '@campaigns/storiesMonitor/LIST_FAILURE',
  LIST_RESET: '@campaigns/storiesMonitor/LIST_RESET',
  TOGGLE_STORIES_STATUS_REQUEST:
    '@campaigns/storiesMonitor/TOGGLE_STORIES_STATUS_REQUEST',
  TOGGLE_STORIES_STATUS_SUCCESS:
    '@campaigns/storiesMonitor/TOGGLE_STORIES_STATUS_SUCCESS',
  TOGGLE_STORIES_STATUS_FAILURE:
    '@campaigns/storiesMonitor/TOGGLE_STORIES_STATUS_FAILURE',
  TOGGLE_STORIES_STATUS_RESET:
    '@campaigns/storiesMonitor/TOGGLE_STORIES_STATUS_RESET',
};

export function setOrderBy(order = 'DESC') {
  return {
    type: storiesMonitorActions.SET_ORDER_BY,
    payload: { order },
  };
}

export function setStartDate(startDate) {
  return {
    type: storiesMonitorActions.SET_START_DATE,
    payload: { startDate },
  };
}

export function setEndDate(endDate) {
  return {
    type: storiesMonitorActions.SET_END_DATE,
    payload: { endDate },
  };
}

export function registerRequest(payload) {
  return {
    type: storiesMonitorActions.REGISTER_REQUEST,
    payload,
  };
}

export function registerSuccess(monitorId, startTime, endTime) {
  return {
    type: storiesMonitorActions.REGISTER_SUCCESS,
    payload: { monitorId, startTime, endTime },
  };
}

export function registerFailure(error) {
  return {
    type: storiesMonitorActions.REGISTER_FAILURE,
    payload: { error },
  };
}

export function registerReset() {
  return {
    type: storiesMonitorActions.REGISTER_RESET,
  };
}

export function listRequest(
  creatorNetworkId,
  campaignId,
  isPaginate = false,
  startDate = null,
  endDate = null
) {
  return {
    type: storiesMonitorActions.LIST_REQUEST,
    payload: { creatorNetworkId, campaignId, isPaginate, startDate, endDate },
  };
}

export function listSuccess(data, lastPage, isPaginate = false) {
  return {
    type: storiesMonitorActions.LIST_SUCCESS,
    payload: { data, lastPage, isPaginate },
  };
}

export function listFailure(isPaginate = false) {
  return {
    type: storiesMonitorActions.LIST_FAILURE,
    payload: { isPaginate },
  };
}

export function listReset() {
  return {
    type: storiesMonitorActions.LIST_RESET,
  };
}

export function toggleStoriesStatusRequest(storyId, show) {
  return {
    type: storiesMonitorActions.TOGGLE_STORIES_STATUS_REQUEST,
    payload: { storyId, show },
  };
}

export function toggleStoriesStatusSuccess(storyId, show) {
  return {
    type: storiesMonitorActions.TOGGLE_STORIES_STATUS_SUCCESS,
    payload: { storyId, show },
  };
}

export function toggleStoriesStatusFailure() {
  return {
    type: storiesMonitorActions.TOGGLE_STORIES_STATUS_FAILURE,
  };
}

export function toggleStoriesStatusReset() {
  return {
    type: storiesMonitorActions.TOGGLE_STORIES_STATUS_RESET,
  };
}
