import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import EmptyCampaigns from '~/assets/images/Campaigns/empty-campaigns.png';

import { campaignsNewReset } from '~/store/modules/campaigns/new/actions';
import { selectMenu, menuClose } from '~/store/modules/menu/actions';
import {
  campaignsListDeleteRequest,
  campaignsListRequest,
} from '~/store/modules/campaigns/list/actions';

import Button from '~/components/Button';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';
import Pagination from '~/components/Pagination';
import Modal from '~/components/Modal';
import ModalDeleteContent from '~/components/ModalDeleteContent';
import InputSearch from '~/components/InputSearch';

import ModalNewCampaign from './ModalNewCampaign';

import {
  Container,
  Header,
  WrapperCampaigns,
  LabelCampaigns,
  WrapperList,
} from './styles';

export default function Campaigns() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalNewCampaignIsOpen, setModalNewCampaignIsOpen] = useState(false);
  const [modalCampaignDelete, setModalCampaignDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [filterTitle, setFilterTitle] = useState('');

  const {
    loading: listLoading,
    error: listError,
    page: currentPage,
    totalPages,
    data: listData,
  } = useSelector((state) => state.campaignsList.list);

  const { loading: loadingDelete, error: errorLoading } = useSelector(
    (state) => state.campaignsList.delete
  );

  const completedDelete = useSelector(
    (state) => state.campaignsList.delete.completedDelete
  );

  const handleOpenNewCampaignModal = () => {
    setModalNewCampaignIsOpen(true);
  };

  const handleCloseNewCampaignModal = useCallback(() => {
    setModalNewCampaignIsOpen(false);
    dispatch(campaignsNewReset());
  }, [dispatch]);

  const handleShowDeleteModal = useCallback(
    (campaignId) => {
      const campaignName = listData.find((item) => item.id === campaignId);
      setModalIsOpen(true);
      setModalCampaignDelete(campaignId);
      setSelectedCampaign(campaignName);
    },
    [listData]
  );

  const handleRowClick = useCallback(
    (row) => {
      history.push(`/campaigns/${row.id}`);
    },
    [history]
  );

  useEffect(() => {
    dispatch(selectMenu('campaigns'));
    dispatch(menuClose());
    dispatch(campaignsListRequest());
  }, [dispatch]);

  useEffect(() => {
    setModalIsOpen(false);
    if (completedDelete > 0 && listData.length === 0) {
      dispatch(campaignsListRequest());
    }
  }, [completedDelete, dispatch, listData.length]);

  function filterTitleChange(e) {
    setFilterTitle(e.target.value);
  }

  function handleInputSearch(e) {
    const code = e.keyCode || e.which;
    if (code === 13) {
      dispatch(campaignsListRequest(1, filterTitle));
    }
  }

  function handleHideModal() {
    setModalIsOpen(false);
    setModalCampaignDelete(null);
  }

  function handleConfirmDelete() {
    dispatch(campaignsListDeleteRequest(modalCampaignDelete));
  }

  function handlePaginate(page) {
    if (currentPage === page) return;
    dispatch(campaignsListRequest(page, filterTitle));
  }

  function renderModalDeleteCampaign() {
    return (
      <Modal
        isOpen={modalIsOpen}
        handleCloseModal={handleHideModal}
        width={400}
        height={240}
      >
        <ModalDeleteContent
          error={errorLoading}
          loading={loadingDelete}
          title="Delete campaign"
          text={`Do you want to delete the ${selectedCampaign.title} campaign?`}
          handleConfirmDelete={handleConfirmDelete}
          handleClose={handleHideModal}
        />
      </Modal>
    );
  }

  function renderList() {
    return listData && !listError ? (
      <>
        <WrapperCampaigns>
          <LabelCampaigns>
            <tr className="campaigns-head-tr">
              <th width="358">
                <span>CAMPAIGN NAME</span>
              </th>
              <th width="260">
                <span>CLIENT NAME</span>
              </th>
              <th width="250">
                <span>CREATED BY</span>
              </th>
              <th width="203">
                <span>CREATED ON</span>
              </th>
              <th width="191">
                <span>MODIFIED ON</span>
              </th>
              <th width="50">
                <span>DELETE</span>
              </th>
            </tr>
          </LabelCampaigns>
          <WrapperList>
            {listData.map((row) => (
              <tr
                className="campaigns-list-tr"
                key={row.id}
                onClick={() => handleRowClick(row)}
              >
                <td>{row.title}</td>
                <td>{row.client}</td>
                <td>{row.created_by?.name}</td>
                <td>{format(new Date(row.created), 'dd/MM/yyyy')}</td>
                <td>{format(new Date(row.updated), 'dd/MM/yyyy')}</td>
                <td className="delete-campaign">
                  <div
                    onClick={(event) =>
                      handleShowDeleteModal(row.id, event.stopPropagation())
                    }
                  >
                    <DeleteOutlineIcon
                      style={{
                        cursor: 'pointer',
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 24,
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </WrapperList>
        </WrapperCampaigns>
        <Pagination
          handleFirstPage={() => handlePaginate(1)}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePaginate={handlePaginate}
          handleLastPage={() => handlePaginate(totalPages)}
        />
      </>
    ) : (
      !listError && (
        <div className="empty-campaigns">
          <img src={EmptyCampaigns} alt="Empty campaigns" />
          <h3>You haven’t created any campaigns yet</h3>
          <Button
            className="btn-empty-campaigns"
            onClick={handleOpenNewCampaignModal}
          >
            NEW CAMPAIGN
          </Button>
        </div>
      )
    );
  }

  function renderError() {
    return (
      <LoadingDataErrorHandling
        refreshData={() => dispatch(campaignsListRequest())}
      />
    );
  }

  return (
    <Container>
      <Header>
        <div className="first-column">
          <h2>Campaigns</h2>
        </div>
        {listData && !listError && (
          <div className="second-column">
            <Button width={174} onClick={handleOpenNewCampaignModal}>
              NEW CAMPAIGN
            </Button>
            <InputSearch
              placeholder="Search in campaigns"
              value={filterTitle}
              onChange={filterTitleChange}
              onKeyPress={handleInputSearch}
            />
          </div>
        )}
      </Header>
      {listLoading ? <Loader /> : renderList()}
      {listError && renderError()}
      {renderModalDeleteCampaign()}
      <Modal
        width={600}
        height={545}
        isOpen={modalNewCampaignIsOpen}
        handleCloseModal={handleCloseNewCampaignModal}
      >
        <ModalNewCampaign handleClose={handleCloseNewCampaignModal} />
      </Modal>
    </Container>
  );
}
