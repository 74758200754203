import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 375px;
  margin-left: 100px;
  padding: 30px 30px 30px 0;

  .container-groups-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .page-title {
      margin: 0;
    }

    button {
      width: 96px;
      height: 36px;
      padding: 0;
    }
  }

  .group-list-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid #bfbfbf;
    margin-top: 10px;

    .group-list-options-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      cursor: pointer;
      padding: 5px 0;

      &.inactive {
        cursor: default;

        .icon-wrapper {
          color: #e0e0e0;
        }
      }

      &:not(.inactive) {
        &:hover {
          background: #e9e4f3;

          .icon-wrapper {
            color: ${colorPalette.primary};
          }
        }
      }

      .icon-wrapper {
        color: #818181;
      }

      .ldio-2kuacvfnrbd div {
        width: 12px;
        height: 12px;
      }
      .ldio-2kuacvfnrbd div {
        animation: ldio-2kuacvfnrbd 1s linear infinite;
        top: 12.5px;
        left: 12.5px;
      }
      .loadingio-spinner-rolling-c79fgkrc9id {
        width: 25px;
        height: 25px;
      }
    }
  }

  .group-list {
    height: 672px;
    margin-top: 10px;

    .group-list-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 15px;
      cursor: pointer;
      border-bottom: 1px solid #f3f3f3;

      &.selected {
        background: #e9e4f3;
      }

      &:hover {
        background: #e9e4f3;
      }

      .checkbox-container {
        margin-right: 20px;
        display: flex;
      }

      .infos {
        display: flex;
        flex: 1;
        flex-direction: column;

        .group-name {
          font-size: 16px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);
          margin-bottom: 5px;
          font-weight: 700;
          font-family: 'Rubik';
        }

        .group-extra-infos {
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 30px;
        height: 30px;
      }
    }
  }
`;
