import React, { useState } from 'react';

import AllPosts from './AllPosts';
import AdPosts from './AdPosts';
import AbnormalPosts from './AbnormalPosts';

import { Container } from '../../Instagram/Posts/styles';

export default function Posts() {
  const [tabActive, setTabActive] = useState(0);
  const tabs = [
    { title: 'common posts', render: () => <AllPosts /> },
    { title: 'ad posts', render: () => <AdPosts /> },
    { title: 'outlier posts', render: () => <AbnormalPosts /> },
  ];

  return (
    <div className="row">
      <div className="box-content posts">
        <Container>
          <div className="box-title">Videos</div>

          <div className="tabs-posts">
            {tabs.map((tab, index) => (
              <div
                key={Math.random()}
                className={`tab-post ${tabActive === index && 'active'}`}
                onClick={() => setTabActive(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>

          <div className="tab-post-content">{tabs[tabActive].render()}</div>
        </Container>
      </div>
    </div>
  );
}
