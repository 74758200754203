import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addNewUserRequest,
  addNewUserReset,
} from '~/store/modules/addNewUser/actions';
import { selectMenu, menuClose } from '~/store/modules/menu/actions';

import Footer from '~/containers/Footer';
import Loader from '~/components/Loader';

import { validateEmail } from '~/helpers';

import { Container, ButtonSubmit } from './styles';

function AddNewUser() {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { loading, success, error } = useSelector((state) => state.addNewUser);

  useEffect(() => {
    dispatch(selectMenu('add-new-user'));
    dispatch(menuClose());
    dispatch(addNewUserReset());
  }, [dispatch]);

  const handleInputNameChange = (e) => {
    setName(e.target.value);
  };

  const handleInputEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handleSubmit = () => {
    if (name.length === 0) {
      setNameError('Name is required');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid e-mail');
      return;
    }

    dispatch(addNewUserRequest({ name, email }));
  };

  const renderFooterContent = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <div className={`form-response ${error.length > 0 && 'error'}`}>
          {success > 0 && 'E-mail successfully sent!'}
          {error.length > 0 && error}
        </div>
        <ButtonSubmit onClick={handleSubmit}>ADD USER</ButtonSubmit>
      </>
    );
  };

  return (
    <>
      <Container>
        <div className="grid">
          <div className="box-content">
            <h2>Add new user</h2>
            <p>
              Enter the name and email of the new user. We will send him an
              email containing a link to access the platform.
            </p>
            <p>
              This invitation is valid for <span>24 hours.</span> If the
              invitation expires, it is necessary to invite the new user again.
            </p>
            <div className="row">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={handleInputNameChange}
              />
              {nameError.length > 0 && <span>{nameError}</span>}
            </div>
            <div className="row">
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={email}
                onChange={handleInputEmailChange}
              />
              {emailError.length > 0 && <span>{emailError}</span>}
            </div>
            <div className="footer-form-container">{renderFooterContent()}</div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default AddNewUser;
