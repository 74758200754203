import types from '../../types';

// -------- TABS --------
export function selectTab(networkIndex, networkType) {
  return {
    type: types.CREATOR_SELECT_TAB,
    payload: { networkIndex, networkType },
  };
}

// -------- INFLUENCER --------

export function creatorRequest(creatorId) {
  return {
    type: types.CREATOR_REQUEST,
    payload: { creatorId },
  };
}

export function creatorSuccess(data) {
  return {
    type: types.CREATOR_SUCCESS,
    payload: { data },
  };
}

export function creatorFailure() {
  return {
    type: types.CREATOR_FAILURE,
  };
}
