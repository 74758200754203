import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
`;

export const ContainerMessageStatus = styled.div`
  align-items: center;
  color: ${colorPalette.primary};
  display: flex;
  flex-direction: column;
  font-size: 130px;
  justify-content: center;
  height: 100%;

  h1 {
    font-size: 100px;
    margin-top: 30px;
  }

  h2 {
    font-size: 50px;
    margin-top: 30px;
    color: red;
  }
`;

export const ContainerButton = styled.div`
  button {
    font-size: 20px;
    position: absolute;
    right: 0;
  }
`;

export const ContainerSave = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  div {
    width: 350px;
  }
`;

export const TitleModal = styled.h1`
  margin-top: 40px;
  text-align: center;
`;

export const LabelCampaign = styled.thead`
  width: 100%;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-weight: bold;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    th {
      width: 120px;
      margin: 20px 0;
    }
  }
  span:hover {
    color: ${colorPalette.primary};
    cursor: pointer;
  }
`;

export const WrapperList = styled.tbody`
  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    :hover {
      background-color: rgba(90, 19, 217, 0.07);
      cursor: pointer;
    }

    :nth-child(odd) {
      background-color: #f5f5f5;

      :hover {
        background-color: rgba(90, 19, 217, 0.07);
        cursor: pointer;
      }
    }

    td {
      padding: 23px 0;
      width: 120px;
    }
  }

  .delete-campaign {
    align-self: center;
    text-align: center;
  }
`;
