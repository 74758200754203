import React from 'react';

import Intro from '../Intro';
import EstimatedStatistics from './EstimatedStatistics';
import AudienceProfile from '../AudienceProfile';
import Posts from './Posts';
import Evolutions from './Evolutions';

import { Container, Header } from './styles';

export default function Instagram() {
  return (
    <Container>
      <Header>
        <Intro />
        <EstimatedStatistics />
      </Header>
      <AudienceProfile />
      <Posts />
      <Evolutions />
    </Container>
  );
}
