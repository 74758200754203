import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .icon-wrapper {
      color: #818181;

      &:hover {
        color: ${colorPalette.primary};
      }

      &.close-modal {
        cursor: pointer;
      }
    }
  }

  .modal-description {
    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .modal-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;

    span {
      color: #818181;
      cursor: pointer;
      font-weight: bold;
      letter-spacing: 1.25px;
      color: rgba(0, 0, 0, 0.6);
      margin-left: 30px;

      &:hover {
        color: ${colorPalette.primary};
      }
    }
  }
`;
