import produce from 'immer';
import types from '../types';

export const INITIAL_STATE = {
  list: {
    data: [],
    dataSearch: [],
    groupSelectedId: null,
    loading: false,
    error: false,
  },
  create: {
    loading: false,
    confirmCreate: 0,
    error: false,
  },
  update: {
    loading: false,
    saved: false,
    confirmUpdate: 0,
    confirmTagCreate: 0,
    error: false,
  },
  delete: {
    loading: false,
    confirmDelete: 0,
    error: false,
  },
  reprocess: {
    loading: false,
    error: false,
  },
};

export default function tagGroups(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.TAG_GROUPS_LIST_SEARCH: {
        const searchValue = action.payload.groupName.toLowerCase();
        draft.list.dataSearch = draft.list.data.filter(
          (group) => group.name.toLowerCase().indexOf(searchValue) > -1
        );
        break;
      }
      case types.TAG_GROUPS_LIST_REQUEST: {
        draft.list.data = [];
        draft.list.loading = true;
        draft.list.error = false;
        break;
      }
      case types.TAG_GROUPS_LIST_SUCCESS: {
        draft.list.data = action.payload.data;
        draft.list.loading = false;
        draft.list.error = false;
        break;
      }
      case types.TAG_GROUPS_LIST_FAILURE: {
        draft.list.data = [];
        draft.list.loading = false;
        draft.list.error = true;
        break;
      }
      case types.TAG_GROUPS_LIST_SELECT_ITEM: {
        draft.list.groupSelectedId = action.payload.groupId;
        draft.update.saved = false;
        break;
      }
      case types.TAG_GROUPS_LIST_CHECK_ITEM: {
        const newList = draft.list.data.map((group) => {
          if (group.id === action.payload.groupId) {
            if (group.checked) {
              group.checked = !group.checked;
            } else {
              group.checked = true;
            }
          }
          return group;
        });
        draft.list.data = newList;
        break;
      }
      case types.TAG_GROUPS_LIST_REPROCESS_REQUEST: {
        draft.reprocess.loading = true;
        draft.reprocess.error = false;
        break;
      }
      case types.TAG_GROUPS_LIST_REPROCESS_SUCCESS: {
        draft.reprocess.loading = false;
        draft.reprocess.error = false;
        draft.list.data = draft.list.data.map((group) => {
          if (action.payload.groupsIds.includes(group.id)) {
            group.is_processing = true;
            group.total_processed = 0;
          }
          return group;
        });
        break;
      }
      case types.TAG_GROUPS_LIST_REPROCESS_FAILURE: {
        draft.reprocess.loading = false;
        draft.reprocess.error = true;
        break;
      }
      case types.TAG_GROUPS_CREATE_REQUEST: {
        draft.create.loading = true;
        break;
      }
      case types.TAG_GROUPS_CREATE_SUCCESS: {
        draft.create.loading = false;
        draft.create.confirmCreate += 1;
        draft.list.data = [action.payload.data, ...draft.list.data];
        draft.list.groupSelectedId = action.payload.data.id;
        break;
      }
      case types.TAG_GROUPS_CREATE_FAILURE: {
        draft.create.loading = false;
        draft.create.error = true;
        break;
      }
      case types.TAG_GROUPS_UPDATE_REQUEST: {
        draft.update.loading = true;
        draft.update.confirmTagCreate += 1;
        draft.update.saved = false;

        const newList = draft.list.data.map((row) => {
          if (row.id === action.payload.data.id) {
            return action.payload.data;
          }
          return row;
        });
        draft.list.data = newList;
        break;
      }
      case types.TAG_GROUPS_UPDATE_SUCCESS: {
        draft.update.loading = false;
        draft.update.confirmUpdate += 1;
        if (action.payload.isTag) {
          draft.update.saved = true;
        }
        break;
      }
      case types.TAG_GROUPS_UPDATE_FAILURE: {
        draft.update.loading = false;
        draft.update.error = true;
        break;
      }
      case types.TAG_GROUPS_DELETE_REQUEST: {
        draft.delete.loading = true;
        break;
      }
      case types.TAG_GROUPS_DELETE_SUCCESS: {
        draft.list.groupSelectedId = null;
        draft.delete.loading = false;
        draft.delete.confirmDelete += 1;

        draft.list.data = draft.list.data.filter(
          (row) => action.payload.groupsIds.includes(row.id) === false
        );
        break;
      }
      case types.TAG_GROUPS_DELETE_FAILURE: {
        draft.delete.loading = false;
        draft.delete.error = true;
        break;
      }
      default:
    }
  });
}
