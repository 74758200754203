import React from 'react';
import { Close } from '@material-ui/icons';

import { Container } from './styles';

function ModalCloseContainer({ handleCloseModal }) {
  return (
    <Container>
      <Close
        style={{
          color: 'rgba(0, 0, 0, 0.54)',
          cursor: 'pointer',
        }}
        onClick={handleCloseModal}
      />
    </Container>
  );
}

export default ModalCloseContainer;
