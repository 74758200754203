import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;

  .no-data {
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 70px;
`;
