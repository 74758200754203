import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 44px;
  position: relative;
  input {
    width: 100%;
    height: 56px;
    border: ${(props) =>
      props.classError ? '2px solid #b00020' : '1px solid rgba(0, 0, 0, 0.32)'};
    border-radius: 4px;
    font-family: 'Rubik', sans-serif;
    font-size: 16.3px;
    line-height: 1.48px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 16px;
  }
  span {
    font-family: 'Rubik', sans-serif;
    font-size: 12.2px;
    line-height: 1.31;
    letter-spacing: 0.4px;
    color: ${(props) => (props.classError ? '#b00020' : 'rgba(0, 0, 0, 0.6)')};
    position: absolute;
    display: block;
    bottom: -20px;
    left: 0px;
    width: 100%;
    text-indent: 16px;
    display: flex;
    align-items: center;
  }
  div {
    position: absolute;
    top: 0;
    right: 0px;
    height: 56px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: block;
      fill: rgba(0, 0, 0, 0.54);
      cursor: pointer;
    }
  }
`;
