import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.button`
  background: ${colorPalette.primary};
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  padding: 0 20px;
  width: ${(props) => `${props.width}px`};
  height: 36px;
  cursor: pointer;
  align-items: center;
  display: flex;
  letter-spacing: 1.25px;

  &:not(.negative) {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }

  &.negative {
    color: ${colorPalette.primary};
    background: transparent;
    border: solid 1px rgba(0, 0, 0, 0.12);

    &:not(.inative):hover {
      color: #fff;
      background: ${colorPalette.primary};
      border: solid 1px ${colorPalette.primary};

      svg {
        color: #fff !important;
      }
    }
  }
  &.inative {
    color: rgba(0, 0, 0, 0.38);
    background: #e4e4e4;
    cursor: default;

    svg {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.38);
    background: #e4e4e4;
    cursor: not-allowed;

    svg {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  &.loading svg {
    display: none !important;
  }
`;

export const RotateIcon = styled.span`
  display: flex;
  align-items: center;

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  svg {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
`;
