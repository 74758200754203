import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e4e4e4;
  padding: 0 40px;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 15;

  .box-menu-logo {
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .icon-wrapper {
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
    }

    .main-link {
      color: ${colorPalette.primary};
      font-family: 'Rubik';
      font-size: 34.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }

  .box-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;

    .nav-item {
      min-width: 160px;
      border-bottom: 2px solid #fff;
      padding-bottom: 15px;
      text-align: center;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        border-bottom: 2px solid ${colorPalette.primary};

        a {
          color: ${colorPalette.primary};
        }
      }

      .link {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.25px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .box-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;

    .container-credits {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 40px;

      .amount {
        padding: 5px 15px;
        border: 1px solid ${colorPalette.primary};
        border-radius: 4px;
        text-align: center;
        letter-spacing: 1.25px;
        color: rgba(0, 0, 0, 0.38);

        .ldio-2kuacvfnrbd div {
          width: 12px;
          height: 12px;
        }
        .ldio-2kuacvfnrbd div {
          animation: ldio-2kuacvfnrbd 1s linear infinite;
          top: 12.5px;
          left: 12.5px;
        }
        .loadingio-spinner-rolling-c79fgkrc9id {
          width: 25px;
          height: 25px;
        }

        .error {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .error-description {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;

            div {
              color: #f15750;
              font-size: 12px;
              margin-left: 5px;
            }
          }
        }
      }

      span {
        font-weight: bold;
        letter-spacing: 1.25px;
        color: rgba(0, 0, 0, 0.38);
        margin-left: 10px;
      }
    }

    .icon-wrapper {
      color: rgba(0, 0, 0, 0.6);
    }

    .user-infos {
      display: flex;
      flex-direction: column;
      position: relative;

      .float-user-menu {
        width: 340px;
        position: absolute;
        right: 0;
        top: 58px;
        background: #fafafa;
        z-index: 100;
        border: 1px solid #e4e4e4;
        border-top: none;

        .infos-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 15px 25px;
          border-bottom: 1px solid #eaeaea;

          .user-icon {
            color: ${colorPalette.primary};
          }

          .infos-content {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .name {
              color: rgba(0, 0, 0, 0.6);
              letter-spacing: 0.25px;
              font-weight: 700;
            }

            .organization {
              color: rgba(0, 0, 0, 0.6);
              letter-spacing: 0.25px;
            }
          }
        }

        ul {
          li {
            padding: 15px 25px;

            &:not(.divider):hover {
              background: #ebebeb;
            }

            .content-nav {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;

              &.active {
                .icon-wrapper {
                  color: ${colorPalette.primary};
                }
                span {
                  color: ${colorPalette.primary};
                }
              }

              .icon-wrapper {
                color: rgba(0, 0, 0, 0.6);
              }

              span {
                color: rgba(0, 0, 0, 0.6);
                margin-left: 20px;
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }
    }
  }
`;
