import { takeLatest, put, all, select, call } from 'redux-saga/effects';

import { apiBeStoriesMonitor } from '~/services/api';
import { serviceWrapperSaga } from '~/helpers';

import {
  storiesMonitorActions,
  registerSuccess,
  registerFailure,
  listSuccess,
  listFailure,
  toggleStoriesStatusSuccess,
  toggleStoriesStatusFailure,
} from './actions';

export function* register({ payload }) {
  const dataList = yield select((state) => state.storiesMonitor.list.data);

  const method = dataList?.id
    ? apiBeStoriesMonitor.patch
    : apiBeStoriesMonitor.post;

  const url = dataList?.id
    ? `monitor_creator_networks/${dataList.id}`
    : 'monitor_creator_networks';

  const response = yield serviceWrapperSaga(method, url, payload);

  if (response.status >= 400) {
    yield put(registerFailure(response.data.message));
    return;
  }

  yield put(
    registerSuccess(response.headers.etag, payload.start_time, payload.end_time)
  );
}

export function* fetch({ payload }) {
  const { creatorNetworkId, campaignId, isPaginate, startDate, endDate } =
    payload;
  const orderBy = yield select((state) => state.storiesMonitor.orderBy);
  const list = yield select((state) => state.storiesMonitor.list);

  const { page } = list;

  const limit = 20;

  const params = {
    page: isPaginate ? page + 1 : page,
    limit,
    creator_network_id: creatorNetworkId,
    campaign_id: campaignId,
    order_by: `published_at ${orderBy}`,
  };

  if (startDate) params.start_date = startDate;
  if (endDate) params.end_date = endDate;

  const response = yield serviceWrapperSaga(
    apiBeStoriesMonitor.get,
    'monitor_creator_networks',
    { params }
  );

  if (response.status >= 500) {
    yield put(listFailure(isPaginate));
  }

  if (response.status === 404) {
    yield put(listSuccess(null, list.last_page, isPaginate));
  } else {
    const lastPage = Math.ceil(response.headers['x-total-count'] / limit);
    yield put(listSuccess(response.data, lastPage, isPaginate));
  }
}

export function* toggleStoriesStatus({ payload }) {
  try {
    const { storyId, show } = payload;

    yield call(apiBeStoriesMonitor.patch, `stories/${storyId}`, { show });

    yield put(toggleStoriesStatusSuccess(storyId, show));
  } catch (error) {
    yield put(toggleStoriesStatusFailure());
  }
}

export default all([
  takeLatest(storiesMonitorActions.REGISTER_REQUEST, register),
  takeLatest(storiesMonitorActions.LIST_REQUEST, fetch),
  takeLatest(
    storiesMonitorActions.TOGGLE_STORIES_STATUS_REQUEST,
    toggleStoriesStatus
  ),
]);
