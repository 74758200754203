import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .graphics {
    display: flex;
    flex-direction: row;
  }
  .total {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-left: 20px;
    justify-content: center;

    .single-chart {
      width: 88px;
      justify-content: space-around;
    }
    .col-legends {
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      justify-content: center;

      .row-legend {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-bottom: 5px;

          &.female {
            background: rgba(118, 36, 232, 0.7);
          }
          &.male {
            background: rgba(89, 229, 130, 0.7);
          }
        }
        .gender-label {
          font-size: 12px;
          line-height: 1.31;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
`;
