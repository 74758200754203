import React, { useEffect, useCallback } from 'react';
import { Avatar } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  listRequest,
  listRequestPaginate,
  totalClicksRequest,
} from '~/store/modules/campaigns/detail/tracking/trackingClick/actions';
import {
  getTableBodyArray,
  getTableHeaderArray,
} from '~/helpers/utils/table.util';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';
import LoadingDataErrorHandling from '~/components/LoadingDataErrorHandling';

import { Container, TableHeader, TableMetrics } from './styles';

const renderAvatar = (imageSrc) => (
  <Avatar
    alt="Name here"
    src={imageSrc}
    variant="rounded"
    style={{ width: '71px', height: '80px' }}
  />
);

const renderCreatorNetwork = (network) => {
  if (network === 'INSTAGRAM') {
    return <InstagramIcon />;
  }
  if (network === 'YOUTUBE') {
    return <YoutubeIcon />;
  }

  return null;
};

const formatDate = (date) => format(new Date(date), 'dd/MM/YYY');

function TrackingClicks({ campaingId, onLoadingClicks }) {
  const dispatch = useDispatch();

  const {
    data: trackings,
    last_page: lastPageTrackingLink,
    page: currentPageTrackingLink,
    total: totalTrackingList,
    error: errorTrackingList,
    loading: loadingTrackingList,
  } = useSelector((state) => state.trackingLink.list);

  useEffect(() => {
    onLoadingClicks(trackings?.length || 0);
  }, [onLoadingClicks, trackings]);

  const { loading: loadingTotalLinks, data: totalClicks } = useSelector(
    (state) => state.trackingLink.total_clicks
  );

  const handleFirstPageTrackingLink = () => {
    dispatch(listRequestPaginate(campaingId, true, 1));
  };

  const handleLastPageTrackingLink = () => {
    dispatch(listRequest(campaingId, true, lastPageTrackingLink));
  };

  const handlePaginateTrackingLink = (selectedPage) => {
    dispatch(listRequest(campaingId, true, selectedPage));
  };

  const setTableBody = useCallback(() => {
    const tableBody = trackings.map((item) => {
      const { created_at, link, tracking } = item;
      const { creator_network, prefix } = link;
      const { first_click, last_click, total_unique, total } = tracking;

      const avatar = creator_network?.profile_picture;
      const user = `@${creator_network?.identifier}`;
      const addedBy = '-';
      const addedOn = formatDate(created_at);
      const network = renderCreatorNetwork(creator_network?.network);
      const firstClick = first_click ? formatDate(first_click) : '-';
      const lastClick = last_click ? formatDate(last_click) : '-';

      return {
        elements: [
          { content: renderAvatar(avatar) },
          { content: user, center: true },
          { content: addedBy, center: true },
          { content: addedOn, center: true },
          { content: network, center: true },
          { content: prefix, center: true },
          { content: firstClick, center: true },
          { content: lastClick, center: true },
          { content: total_unique, center: true },
          { content: total, center: true },
        ],
      };
    });

    return getTableBodyArray(tableBody);
  }, [trackings]);

  const getInitialData = useCallback(() => {
    dispatch(listRequest(campaingId, true));
    dispatch(totalClicksRequest(campaingId));
  }, [dispatch, campaingId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (loadingTrackingList || !trackings) {
    return <Loader />;
  }

  if (errorTrackingList) {
    return (
      <LoadingDataErrorHandling
        refreshData={() => dispatch(listRequest(campaingId, true))}
      />
    );
  }

  return (
    <Container>
      {!loadingTotalLinks && totalClicks && (
        <TableMetrics>
          <div className="total">TOTAL CLICKS:</div>
          <div className="metrics">
            <li>
              <span>Total Links</span>
              {totalTrackingList}
            </li>
            <li>
              <span>Unique Clicks</span>
              {totalClicks.total_unique}
            </li>
            <li>
              <span>Total Clicks</span>
              {totalClicks.total}
            </li>
          </div>
        </TableMetrics>
      )}
      <TableHeader>CLICK TRACKING</TableHeader>
      <Table
        theadList={getTableHeaderArray([
          { content: 'CLICK TRACKING', props: { width: 90 } },
          { content: 'USER' },
          { content: 'ADDED BY' },
          { content: 'ADDED ON' },
          { content: 'NETWORK' },
          { content: 'PREFIX' },
          { content: 'FIRST CLICK' },
          { content: 'LAST CLICK' },
          { content: 'UNIQUE CLICKS' },
          { content: 'TOTAL CLICKS' },
        ])}
        tbodyList={setTableBody()}
      />
      <Pagination
        handleFirstPage={handleFirstPageTrackingLink}
        handleLastPage={handleLastPageTrackingLink}
        handlePaginate={handlePaginateTrackingLink}
        currentPage={currentPageTrackingLink}
        totalPages={lastPageTrackingLink}
      />
    </Container>
  );
}

TrackingClicks.propTypes = {
  campaingId: PropTypes.string.isRequired,
  onLoadingClicks: PropTypes.func.isRequired,
};

export default TrackingClicks;
