export const checkCodeActions = {
  CHECK_CODE_REQUEST: '@forgotPassword/checkCode/CHECK_CODE_REQUEST',
  CHECK_CODE_SUCCESS: '@forgotPassword/checkCode/CHECK_CODE_SUCCESS',
  CHECK_CODE_FAILURE: '@forgotPassword/checkCode/CHECK_CODE_FAILURE',
};

export function checkCodeRequest(code) {
  return {
    type: checkCodeActions.CHECK_CODE_REQUEST,
    payload: { code },
  };
}

export function checkCodeSuccess() {
  return {
    type: checkCodeActions.CHECK_CODE_SUCCESS,
  };
}

export function checkCodeFailure() {
  return {
    type: checkCodeActions.CHECK_CODE_FAILURE,
  };
}
