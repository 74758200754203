import styled from 'styled-components';
import colorPalette from '~/styles/colorPalette';

import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ColLeft = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.primary};
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Rubik';
    font-size: 61px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 120px;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: 50px;
    }
  }

  img {
    @media (max-width: 1366px) {
      height: 400px;
    }
    @media (max-width: 1280px) {
      height: 300px;
    }
  }
`;

export const ColRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  .container-content {
    display: flex;
    flex-direction: column;
    width: 464px;

    h3 {
      font-family: 'Rubik';
      font-size: 34px;
      font-weight: 500;
      text-align: center;

      @media (max-width: 1280px) {
        margin: 50px 0;
      }
    }

    .message-sent {
      width: 144px;
      height: 262px;
      margin: 30px auto 0;
    }

    h4 {
      font-size: 16.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.87);
      margin: 30px 0 40px;
    }

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      input[type='email'] {
        width: 463px;
        height: 56px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.32);
        padding: 16px;

        font-family: 'Rubik';
        font-size: 16.3px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
      }

      span {
        font-family: 'Rubik';
        font-size: 12.2px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 0.4px;
        color: red;
        margin-top: 5px;
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 464px;
  height: 40px;
  padding: 13px 16px 11px;
  font-family: 'Hind';
  font-size: 14.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
`;
