import { takeLatest, call, put, all } from 'redux-saga/effects';
import types from '../types';

import history from '~/services/history';
import {
  apiTocSov,
  apiOverlap,
  apiBeAuth,
  apiBeUser,
  apiBeCreator,
  apiBeCampaign,
  apiBeStoriesMonitor,
} from '~/services/api';

import { signInSuccess, signInFailure } from './actions';

export function setToken(token) {
  apiTocSov.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiOverlap.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiBeCreator.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiBeAuth.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiBeUser.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiBeCampaign.defaults.headers.Authorization = `Bearer ${token.access_token}`;
  apiBeStoriesMonitor.defaults.headers.Authorization = `Bearer ${token.access_token}`;
}

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(apiBeAuth.post, `auth/sign_in`, {
      email,
      password,
      app: process.env.REACT_APP_API_CLIENT_ID,
    });

    const { data } = response;

    setToken(data);

    yield put(signInSuccess(data));

    history.push('/search');
  } catch (err) {
    yield put(signInFailure('Usuário ou senha inválidos'));
  }
}

export function persistToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    setToken(token);
  }
}

export function logout() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', persistToken),
  takeLatest(types.SIGN_IN_REQUEST, signIn),
  takeLatest(types.SIGN_OUT_REQUEST, logout),
]);
