import produce from 'immer';
import { campaignsDetailActions } from './actions';

export const INITIAL_STATE = {
  selectedCreatorId: null,
  campaign: {
    data: null,
    loading: false,
    error: false,
  },
  creators_list: {
    data: [],
    loading: false,
    error: false,
    currentPage: 1,
    lastPage: 1,
  },
  edit_title: {
    error: false,
  },
  edit_campaign: {
    loading: false,
    success: false,
    error: '',
  },
  add_creator: {
    loading: false,
    error: false,
  },
  delete_creator: {
    loading: false,
    confirmDelete: 0,
    error: false,
  },
  comments_list: {
    data: [],
    loading: false,
    error: false,
  },
  comments_add: {
    loading: false,
    error: false,
  },
  comments_edit: {
    loading: false,
    error: false,
  },
  tracking_save: {
    loading: false,
    error: false,
    saved: false,
  },
};

export default function campaignsDetail(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case campaignsDetailActions.SET_SELECTED_CREATOR: {
        draft.selectedCreatorId = action.payload.creatorId;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_REQUEST: {
        draft.campaign.loading = true;
        draft.campaign.error = false;
        draft.edit_title.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_SUCCESS: {
        draft.campaign.loading = false;
        draft.campaign.data = action.payload.data;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_FAILURE: {
        draft.campaign.loading = false;
        draft.campaign.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_REQUEST: {
        draft.creators_list.loading = true;
        draft.creators_list.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_SUCCESS: {
        draft.creators_list.loading = false;
        draft.creators_list.data = action.payload.data;
        draft.creators_list.currentPage = action.payload.currentPage;
        draft.creators_list.lastPage = action.payload.lastPage;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_LIST_FAILURE: {
        draft.creators_list.loading = false;
        draft.creators_list.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_REQUEST: {
        const { creator } = action.payload;

        draft.add_creator.loading = true;
        draft.add_creator.error = false;

        draft.creators_list.data = [creator, ...draft.creators_list.data];
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_SUCCESS: {
        draft.add_creator.loading = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_ADD_FAILURE: {
        draft.add_creator.loading = false;
        draft.add_creator.error = true;

        draft.creators_list.data = draft.creators_list.data.filter(
          (creator) =>
            creator.creator_network.id !== action.payload.creatorNetworkId
        );
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_REQUEST: {
        draft.edit_title.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_SUCCESS: {
        draft.campaign.data.title = action.payload.title;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_TITLE_FAILURE: {
        draft.edit_title.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_REQUEST: {
        draft.edit_campaign.loading = true;
        draft.edit_campaign.success = false;
        draft.edit_campaign.error = '';
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_SUCCESS: {
        const { title, client, product, briefing } = action.payload.data;

        draft.edit_campaign.loading = false;
        draft.edit_campaign.success = true;

        draft.campaign.data.title = title;
        draft.campaign.data.client = client;
        draft.campaign.data.product = product;
        draft.campaign.data.briefing = briefing;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_FAILURE: {
        draft.edit_campaign.loading = false;
        draft.edit_campaign.error = action.payload.message;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_EDIT_CAMPAIGN_RESET: {
        draft.edit_campaign.loading = false;
        draft.edit_campaign.success = false;
        draft.edit_campaign.error = '';
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_REQUEST: {
        draft.delete_creator.loading = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_SUCCESS: {
        const { creatorNetworkIds } = action.payload;

        draft.delete_creator.loading = false;
        draft.delete_creator.confirmDelete += 1;

        draft.creators_list.data = draft.creators_list.data.filter(
          (creator) => !creatorNetworkIds.includes(creator.creator_network.id)
        );
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_CREATOR_DELETE_FAILURE: {
        draft.delete_creator.loading = false;
        draft.delete_creator.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_REQUEST: {
        draft.comments_list.loading = true;
        draft.comments_list.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_SUCCESS: {
        draft.comments_list.loading = false;
        draft.comments_list.data = action.payload.data;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_LIST_FAILURE: {
        draft.comments_list.loading = false;
        draft.comments_list.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_REQUEST: {
        draft.comments_add.loading = true;
        draft.comments_add.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_SUCCESS: {
        draft.comments_add.loading = false;
        draft.comments_list.data = [
          action.payload.data,
          ...draft.comments_list.data,
        ];
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_ADD_FAILURE: {
        draft.comments_add.loading = false;
        draft.comments_add.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_REQUEST: {
        const { commentId, comment } = action.payload;

        draft.comments_edit.loading = true;
        draft.comments_edit.error = false;

        draft.comments_list.data = draft.comments_list.data.map((row) => {
          if (row.id === commentId) {
            row.comment = comment;
          }

          return row;
        });
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_SUCCESS: {
        draft.comments_edit.loading = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_COMMENTS_EDIT_FAILURE: {
        const { commentId, oldComment } = action.payload;

        draft.comments_edit.loading = false;
        draft.comments_edit.error = true;

        draft.comments_list.data = draft.comments_list.data.map((row) => {
          if (row.id === commentId) {
            row.comment = oldComment;
          }

          return row;
        });
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_REQUEST: {
        draft.tracking_save.saved = false;
        draft.tracking_save.loading = true;
        draft.tracking_save.error = false;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_SUCCESS: {
        const { creatorId, tracking } = action.payload;

        draft.tracking_save.loading = false;
        draft.tracking_save.saved = true;

        if (creatorId && tracking) {
          draft.creators_list.data = draft.creators_list.data.map((creator) => {
            if (creator.creator_id === creatorId) {
              creator.tracking = creator.tracking.map((track) => {
                if (track.id === tracking.id) {
                  track.prefix = tracking.prefix;
                  track.redirects = [
                    {
                      redirect_to: tracking.redirect_to,
                    },
                    ...track.redirects,
                  ];
                }

                return track;
              });
            }

            return creator;
          });
        }
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_FAILURE: {
        draft.tracking_save.saved = false;
        draft.tracking_save.loading = false;
        draft.tracking_save.error = true;
        break;
      }
      case campaignsDetailActions.CAMPAIGNS_DETAIL_TRACKING_SAVE_RESET: {
        draft.tracking_save.saved = false;
        draft.tracking_save.loading = false;
        draft.tracking_save.error = false;
        break;
      }
      default:
    }
  });
}
