import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 47%;

  .column-division {
    display: flex;
    flex-direction: column;
    width: 240px;

    &.interests {
      flex: 1;

      ul {
        li {
          padding-right: 15px;
        }
      }
    }

    &.countries {
      ul {
        li {
          padding-right: 15px;
        }
      }
    }

    &.cities {
      ul {
        li {
          padding-right: 15px;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.48;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
      font-family: 'Rubik';
    }
    ul {
      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: flex;
          align-items: center;
          font-size: 16px;
          letter-spacing: 0.5px;
          color: rgba(0, 0, 0, 0.6);

          img {
            width: 30px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;
