import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { searchRequest } from '~/store/modules/search/actions';

import {
  channelAddRequest,
  channelAddReset,
} from '~/store/modules/channel/actions';

import SearchEmpty from '~/assets/images/Search/search-empty.png';
import NoResults from '~/assets/images/Search/no-results.png';

import Loader from '~/components/Loader';
import LoadMore from '~/components/LoadMore';
import Modal from '~/components/Modal';

import ProfileItem from './ProfileItem';

import ModalAddChannel from '~/containers/Header/Menu/ModalAddChannel';

import { Container } from './styles';

export default function Results() {
  const dispatch = useDispatch();

  const [modalAddChannelIsOpen, setModalAddChannelIsOpen] = useState(false);

  const channelLoading = useSelector((state) => state.channel.add.loading);
  const channelSuccess = useSelector((state) => state.channel.add.success);
  const channelError = useSelector((state) => state.channel.add.error);

  const network = useSelector((state) => state.search.network_selected);

  const loading = useSelector((state) => state.search[network].loading);
  const error = useSelector((state) => state.search[network].error);
  const data = useSelector((state) => state.search[network].data);

  const page = useSelector((state) => state.search[network].page);
  const lastPage = useSelector((state) => state.search[network].last_page);

  const { loading: loadingPaginate, error: errorPaginate } = useSelector(
    (state) => state.search[network].paginate
  );

  function handleShowModalAddChannel() {
    setModalAddChannelIsOpen(true);
  }

  function handleHideModalAddChannel() {
    setModalAddChannelIsOpen(false);
    dispatch(channelAddReset());
  }

  function renderModalAddChannel() {
    return (
      <Modal
        isOpen={modalAddChannelIsOpen}
        handleCloseModal={handleHideModalAddChannel}
        width={600}
        height={420}
      >
        <ModalAddChannel
          error={channelError}
          success={channelSuccess}
          loading={channelLoading}
          handleAddChannel={(profiles, networkName) =>
            dispatch(channelAddRequest(profiles, networkName))
          }
          handleHideModal={handleHideModalAddChannel}
        />
      </Modal>
    );
  }

  function renderEmpty() {
    return (
      <div className="search-empty">
        <img src={SearchEmpty} alt="SearchEmpty" />
      </div>
    );
  }

  function renderNoResults() {
    return (
      ((data && data.length === 0) || error) &&
      !loading && (
        <div className="no-results">
          <div className="no-results-content">
            <img src={NoResults} alt="Sem resultados" />
            <div className="info">
              <h3>We couldn't find anything for your search</h3>
              <p>Suggestions:</p>
              <p>- Make sure all words are spelled correctly</p>
              <p>
                - Try removing price and category filters (if you are using it)
              </p>
              <p>- Try more general keywords</p>
              <p>
                - If you did not find the influencer you were looking for, add
                it by{' '}
                <span onClick={handleShowModalAddChannel}>clicking here</span>
              </p>
            </div>
          </div>
        </div>
      )
    );
  }

  function renderResults() {
    return (
      data &&
      !loading &&
      data.map((row) => <ProfileItem key={row.id} data={row} />)
    );
  }

  return (
    <Container>
      {renderModalAddChannel()}
      <div className="profiles">{renderResults()}</div>
      {page < lastPage && (
        <LoadMore
          isLoading={loadingPaginate}
          isError={errorPaginate}
          onLoad={() => dispatch(searchRequest(true))}
        />
      )}
      {loading && <Loader />}
      {renderNoResults()}
      {!data && !loading && !error && (
        <div className="search-empty">{renderEmpty()}</div>
      )}
    </Container>
  );
}
