import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import types from '../../types';
import history from '~/services/history';

import { apiOverlap } from '~/services/api';

import {
  overlapNewSaveSuccess,
  overlapNewSaveFailure,
  overlapEditGetInfluencersSuccess,
  overlapEditGetInfluencersFailure,
  overlapNewDeleteSuccess,
  overlapNewDeleteFailure,
} from './actions';

export function* saveInfluencers({ payload }) {
  try {
    const { profiles, overlapId } = payload;
    const title = yield select((state) => state.overlapNew.title);

    const creator_networks = profiles.map((row) => ({
      creator_id: row.creator_id,
      creator_network_id: row.creator_network.id,
    }));

    const requestPayload = { title, creator_networks };

    const typeRequest = overlapId ? apiOverlap.patch : apiOverlap.post;
    const url = overlapId ? `overlaps/${overlapId}` : 'overlaps';

    const response = yield call(typeRequest, url, requestPayload);

    yield put(overlapNewSaveSuccess());

    if (!overlapId) history.push(`/overlap/edit/${response.headers.etag}`);
  } catch (err) {
    yield put(overlapNewSaveFailure());
  }
}

export function* getInfluencersByOverlapId({ payload }) {
  try {
    const { overlapId } = payload;
    const response = yield call(apiOverlap.get, `overlaps/${overlapId}`);

    yield put(overlapEditGetInfluencersSuccess(response.data));
  } catch (err) {
    yield put(overlapEditGetInfluencersFailure());
  }
}

export function* deleteOverlap({ payload }) {
  try {
    const { overlapId } = payload;
    yield call(apiOverlap.delete, `overlaps/${overlapId}`);
    yield put(overlapNewDeleteSuccess(overlapId));
    history.push('/overlap');
  } catch (err) {
    yield put(overlapNewDeleteFailure());
  }
}

export default all([
  takeLatest(types.OVERLAP_NEW_SAVE_REQUEST, saveInfluencers),
  takeLatest(
    types.OVERLAP_EDIT_GET_INFLUENCERS_REQUEST,
    getInfluencersByOverlapId
  ),
  takeLatest(types.OVERLAP_NEW_DELETE_REQUEST, deleteOverlap),
]);
